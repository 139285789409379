import React, { createContext, useState, useContext, useEffect } from 'react';
import { api } from '@services/api';
import { AxiosError } from 'axios';
import { type UserDTO } from '@dtos/UserDTO';
import {
  storageUserGet,
  storageUserRemove,
  storageUserSave
} from '@base/storage/storangeUser';
import {
  storageAuthTokenGet,
  storageAuthTokenRemove,
  storageAuthTokenSave
} from '@base/storage/storageAuthToken';
import { AppError } from '@utils/AppError';

interface IAuthContext {
  validateEmail: boolean;
  signIn: (password: string) => void;
  signOut: () => void;
  validateEmailUser: (email: string) => void;
  updateUserProfile: (serUpdated: UserDTO) => Promise<void>;
  handlePrevState: () => void;
  user: UserDTO;
  messageError: string;
}
interface Props {
  children: React.ReactNode;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<UserDTO>({} as UserDTO);
  const [validateEmail, setValidateEmail] = useState(false);
  const [messageError, setMessageError] = useState('');

  async function userAndTokenUpdate(userData: UserDTO, token: string) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    setUser(userData);
  }

  async function storageUserAndTokenSave(
    userData: UserDTO,
    token: string,
    refresh_token: string
  ) {
    try {
      await storageUserSave(userData);
      await storageAuthTokenSave({ token, refresh_token });
    } catch (error) {
      if (error instanceof AppError) {
        setMessageError(error.message);
      }
    }
  }

  async function validateEmailUser(email: string) {
    try {
      const { data } = await api.post(`ravim/login`, { login_usuario: email });

      if (data.exists) {
        setValidateEmail(true);
        setMessageError('');
      }
    } catch (error) {
      if (error instanceof AppError) {
        setMessageError(error.message);
      }
    }
  }

  const signIn = async (password: string) => {
    try {
      const { data } = await api.post(`ravim/validar-senha`, {
        senha_usuario: password
      });

      if (data) {
        await storageUserAndTokenSave(data, data.token, data.refresh_token);

        userAndTokenUpdate(data, data.token);
      }
    } catch (error) {
      if (error instanceof AppError) {
        setMessageError(error.message);
      }
    }
  };

  function handlePrevState() {
    setValidateEmail(false);
    setMessageError('');
  }

  async function updateUserProfile(userUpdated: UserDTO) {
    try {
      setUser(userUpdated);
      await storageUserSave(userUpdated);
    } catch (error) {
      if (error instanceof AppError) {
        setMessageError(error.message);
      }
    }
  }

  function handleLagout() {
    try {
      api.post('/logout', { token: user.token });
    } catch (error) {
      throw error;
    }
  }

  async function signOut() {
    try {
      setUser({} as UserDTO);
      setValidateEmail(false);
      await storageUserRemove();
      await storageAuthTokenRemove();
      handleLagout();
      window.location.pathname = '/';
    } catch (error) {
      if (error instanceof AppError) {
        setMessageError(error.message);
      }
    }
  }

  async function loadUserData() {
    try {
      const userLogged = await storageUserGet();
      const { token } = await storageAuthTokenGet();

      if (token && userLogged) {
        userAndTokenUpdate(userLogged, token);
      }
    } catch (error) {
      if (error instanceof AppError) {
        setMessageError(error.message);
      }
    }
  }

  useEffect(() => {
    loadUserData();
  }, []);

  useEffect(() => {
    const subScribe = api.registerInterceptTokenManager(signOut);

    return () => {
      subScribe();
    };
  }, [signOut]);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        validateEmailUser,
        validateEmail,
        handlePrevState,
        updateUserProfile,
        user,
        messageError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
