import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  Container
} from '@material-ui/core';
import { ItsTelemedicina } from './agendamentoTelemedicina';
import { NotIsTelemedicina } from './agendamentoPresencial';
import { Link, useLocation } from 'react-router-dom';
import { AgendamentoExterno } from './agendamentoExterno';
import { AtendimentoHibrido } from './agendamentoHibrido';
import { AgendamentoToxicologico } from './agendamentoToxicologico';

export interface IStatus {
  location: {
    state: {
      tipo_agendamento: string;
      status: boolean;
      exit?: boolean;
    };
  };
}

export const MarcarHorario: React.FC<IStatus> = (props) => {
  const [telemedicina, setTelemedicna] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const optionSelected = () => {
    if (telemedicina !== '') {
      setOpen(false);
    } else {
      alert('Selecione uma opção');
    }
  };

  const handleCancelOption = () => {
    if (!telemedicina || telemedicina) {
      setTelemedicna('');
      setOpen(false);
    }
  };

  const Telemedicina = () => {
    switch (telemedicina) {
      case 'telemedicina':
        return <ItsTelemedicina {...props} />;
      case 'presencial':
        return <NotIsTelemedicina {...props} />;
      case 'externo':
        return <AgendamentoExterno {...props} />;
      case 'hibrido':
        return <AtendimentoHibrido {...props} />;
      case 'toxicologico':
        return <AgendamentoToxicologico {...props} />;
      default:
        return (
          !telemedicina &&
          !open && (
            <p style={{ textAlign: 'center', fontSize: 22 }}>
              Nenhum agendamento selecionado
            </p>
          )
        );
    }
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    if (props.location.state.exit) {
      setOpen(true);
      setTelemedicna('');
    }
  }, [props.location.state.exit]);

  useEffect(() => {
    if (!props.location.state.status) return;
    setOpen(props.location.state.status);
  }, [props.location.state]);

  return (
    <Container>
      <Dialog open={open} maxWidth="xs">
        <DialogTitle>Selecione o tipo de atendimento</DialogTitle>
        <DialogContent>
          <FormControl
            style={{
              padding: 20,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <RadioGroup
              defaultValue="Sim"
              style={{ flexDirection: 'column' }}
              value={telemedicina}
              onChange={(e) => {
                setTelemedicna(e.target.value);
              }}
            >
              <FormControlLabel
                control={<Radio />}
                value="telemedicina"
                label="Telemedicina"
                style={{ marginRight: 50 }}
              />
              <FormControlLabel
                control={<Radio />}
                value="presencial"
                label="Presencial"
              />
              <FormControlLabel
                control={<Radio />}
                value="externo"
                label="Externo"
              />
              <FormControlLabel
                control={<Radio />}
                value="hibrido"
                label="Híbrido"
              />
              <FormControlLabel
                control={<Radio />}
                value="toxicologico"
                label="Toxicológico"
              />
            </RadioGroup>
          </FormControl>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleCancelOption}>Cancelar</Button>

            <Link
              to={{
                pathname: '/agendar',
                state: { tipo_agendamento: telemedicina }
              }}
              style={{ textDecoration: 'none' }}
            >
              <Button onClick={optionSelected}>Salvar</Button>
            </Link>
          </div>
        </DialogContent>
      </Dialog>
      <Telemedicina />
    </Container>
  );
};
