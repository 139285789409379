import { Button } from '@components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Content } from './styles';
import { CadastroFuncionarioFormData } from '../ModalCadastrarFuncionario';
import { ToastMessage } from '@components/ToastMessage';
import { Color } from '@material-ui/lab';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteEmployee } from '@api/delete-employee';
import { toast } from 'sonner';

interface PropsModal {
  openModal: boolean;
  closeModal: (action: boolean) => void;
  data: CadastroFuncionarioFormData;
}

export function ModalDeletarFuncionario({
  openModal,
  closeModal,
  data
}: PropsModal) {
  const [toastMessage, setToastMessage] = useState({
    cod: '',
    message: '',
    color: '' as Color,
    show: false
  });

  const queryClient = useQueryClient();

  const { mutateAsync: deleteUser } = useMutation({
    mutationFn: deleteEmployee,
    onSuccess(_, { cpf }) {
      updateCache(cpf);
      toast.success('Deletado com sucesso.');
      closeModal(false);
    },
    onError() {
      toast.error('Erro ao deletar');
    }
  });

  function updateCache(cpf: string) {
    const employees = queryClient.getQueryData<CadastroFuncionarioFormData[]>([
      'getUserAdm'
    ]);

    if (!employees) {
      return;
    }

    const updateList = employees.forEach((cache) => {
      if (!cache) {
        return;
      }

      if (cache.cpf === cpf) {
        return {
          ...employees
        };
      }

      return employees;
    });
    queryClient.setQueryData(['getUserAdm'], updateList);

    queryClient.refetchQueries();
  }

  return (
    <Dialog open={openModal} onClose={closeModal} fullWidth>
      <DialogTitle>Desativar funcionário</DialogTitle>
      <DialogContent>
        <Content>
          <p>
            Nome: <span>{data.nome}</span>
          </p>
          <p>
            CPF: <span>{data.cpf}</span>
          </p>
        </Content>
      </DialogContent>
      <DialogActions>
        <Button
          title="Cancelar"
          variantstyle="DEFAULT"
          onClick={() => {
            closeModal(false);
          }}
        />
        <Button
          onClick={() => deleteUser({ cpf: data.cpf })}
          title="Desativar"
          variantstyle="RED"
        />
      </DialogActions>
    </Dialog>
  );
}
