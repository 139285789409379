import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const PaperContainer = styled(Paper)`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px;
  grid-gap: 0.4rem;

  p {
    font-size: 16px;
    width: 15rem;
    word-wrap: break-word;
    text-align: center;
  }

  button {
    all: unset;
    background-color: ${({ theme }) => theme.COLOR.RED};
    max-width: 2rem;
    min-width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 5px;
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: red;
    }
  }
`;

export const DivResult = styled.div`
  p {
    font-size: 16px;

    span {
      font-weight: bold;
    }
  }
`;
