import styled, { css } from 'styled-components';
import { Button, CircularProgress } from '@material-ui/core';

export type ButtonTypeStyleProps =
  | 'PRIMARY'
  | 'SECUNDARY'
  | 'RED'
  | 'PURPLE'
  | 'LIGHT_PINK'
  | 'DARK_PINK'
  | 'DEFAULT';

interface Props {
  variantstyle: ButtonTypeStyleProps;
}

const variantBackGround = (variantstyle: ButtonTypeStyleProps) => {
  return {
    PRIMARY: css`
      background-color: ${({ theme }) => theme.COLOR.GREEN};
      color: ${({ theme }) => theme.COLOR.WHITE};
    `,
    SECUNDARY: css`
      background-color: ${({ theme }) => theme.COLOR.BLUE};
      color: ${({ theme }) => theme.COLOR.WHITE};
    `,
    RED: css`
      background-color: ${({ theme }) => theme.COLOR.RED};
      color: ${({ theme }) => theme.COLOR.WHITE};
    `,

    PURPLE: css`
      background-color: ${({ theme }) => theme.COLOR.PURPLE};
      color: ${({ theme }) => theme.COLOR.WHITE};
    `,
    LIGHT_PINK: css`
      background-color: ${({ theme }) => theme.COLOR.LIGHT_PINK};
      color: ${({ theme }) => theme.COLOR.WHITE};
    `,
    DARK_PINK: css`
      background-color: ${({ theme }) => theme.COLOR.DARK_PINK};
      color: ${({ theme }) => theme.COLOR.WHITE};
    `,
    DEFAULT: css`
      background-color: ${({ theme }) => theme.COLOR.DEFAULT};
      color: ${({ theme }) => theme.COLOR.WHITE};
    `
  }[variantstyle];
};

const variantColorHover = (variantstyle: ButtonTypeStyleProps) => {
  return {
    PRIMARY: css`
      color: ${({ theme }) => theme.COLOR.GREEN};
      border: 1px solid ${({ theme }) => theme.COLOR.GREEN};
    `,
    SECUNDARY: css`
      color: ${({ theme }) => theme.COLOR.BLUE};
      border: 1px solid ${({ theme }) => theme.COLOR.BLUE};
    `,
    RED: css`
      border: 1px solid ${({ theme }) => theme.COLOR.RED};
      color: ${({ theme }) => theme.COLOR.RED};
    `,

    PURPLE: css`
      border: 1px solid ${({ theme }) => theme.COLOR.PURPLE};
      color: ${({ theme }) => theme.COLOR.PURPLE};
    `,
    LIGHT_PINK: css`
      border: 1px solid ${({ theme }) => theme.COLOR.LIGHT_PINK};
      color: ${({ theme }) => theme.COLOR.LIGHT_PINK};
    `,
    DARK_PINK: css`
      border: 1px solid ${({ theme }) => theme.COLOR.DARK_PINK};
      color: ${({ theme }) => theme.COLOR.DARK_PINK};
    `,
    DEFAULT: css`
      color: ${({ theme }) => theme.COLOR.DEFAULT};
      border: 1px solid ${({ theme }) => theme.COLOR.DEFAULT};
    `
  }[variantstyle];
};

export const ButtonStyled = styled.button<Props>`
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  ${({ variantstyle }) => variantBackGround(variantstyle)}
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  transition-duration: 0.3s;
  font-weight: bold;
  color: #fff;
  height: 51px;
  &:not(:disabled):hover {
    height: 51px;
    background-color: #fff;
    transition: 0.3s;
    transition-duration: 0.3s;
    ${({ variantstyle }) => variantColorHover(variantstyle)};
  }
  &:not(:disabled):after {
    content: '';
    ${({ variantstyle }) => variantBackGround(variantstyle)}
    display: block;
    position: absolute;
    align-items: center;
    justify-content: center;
    padding-top: 45px;
    padding-left: 45px;
    opacity: 0;
    transition: all 0.4s;
    border-radius: 50%;
  }
  &:not(:disabled):active {
    background-color: #fff;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.COLOR.DEFAULT};
  }
`;

export const DivTitleIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
`;

export const Loading = styled(CircularProgress).attrs<Props>({
  size: 20,
  color: 'inherit'
})`
  &:hover {
    color: ${({ variantstyle }) =>
      variantstyle === 'PRIMARY'
        ? ({ theme }) => theme.COLOR.DEFAULT
        : ({ theme }) => theme.COLOR.RED};
  }
`;
