import { Button } from '@components/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  ContainerFieldsLogin,
  ContainerFieldsUser,
  ContainerSwitch,
  SwitchAdm
} from './styles';
import { useAuth } from '@context/auth';
import { Autocomplete, Color } from '@material-ui/lab';
import { type GetContratos } from '@api/get-contratos';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import { AppError } from '@utils/AppError';
import { api } from '@services/api';
import { ToastMessage } from '@components/ToastMessage';
import { SearchContratos } from '@components/SearchContratos';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createEmployee } from '@api/create-employee';
import { queryClient } from '@base/lib/react-query';
import { AxiosError } from 'axios';

export const loginType = [
  { id: 0, tipo: 'Atendimento', value: 'atendimento' },
  { id: 1, tipo: 'Financeiro', value: 'financeiro' },
  { id: 2, tipo: 'Médico', value: 'medico' },
  { id: 3, tipo: 'Gerente', value: 'gerente' }
];

interface PropsModal {
  openModal: boolean;
  closeModal: (action: boolean) => void;
}

export const cadastroFuncionarioSchema = z.object({
  nome: z.string().min(5, { message: 'Esse campo é obrigatório' }),
  cpf: z.string().min(11, { message: 'Esse campo é obrigatório' }),
  rg: z.string().optional(),
  senha_usuario: z
    .string()
    .min(4, { message: 'A senha deve conter mais de 4 caracters.' }),
  login_usuario: z.string().email({ message: 'Esse campo é obrigatório' }),
  administrador: z.boolean().default(false),
  tipo_login: z
    .enum(['atendimento', 'financeiro', 'medico', 'gerente'])
    .default('atendimento'),
  cod_contrato: z
    .object({
      nome_contrato: z.string(),
      cod_contrato: z.string()
    })
    .required()
    .refine((cod) => cod !== null, { message: 'Esse campo é obrigátorio.' }),
  usuario: z.string()
});

export type CadastroFuncionarioFormData = z.infer<
  typeof cadastroFuncionarioSchema
>;

export function ModalCadastrarFuncionario({
  openModal,
  closeModal
}: PropsModal) {
  const { user } = useAuth();
  const hora = new Date();
  const newHora = hora.toLocaleString('pt-BR', { hour12: false });
  const userUpdate = `${user.nome} ${newHora}`;

  const [showPassword, setShowPassword] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    cod: '',
    message: '',
    color: '' as Color,
    show: false
  });
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting }
  } = useForm<CadastroFuncionarioFormData>({
    resolver: zodResolver(cadastroFuncionarioSchema),
    defaultValues: {
      usuario: userUpdate
    }
  });

  //console.log(errors);

  const { mutateAsync: employee, isPending } = useMutation({
    mutationFn: createEmployee
  });

  function updateCache() {
    queryClient.refetchQueries();
  }

  async function handleSubmitFuncionario(data: CadastroFuncionarioFormData) {
    
    try {
      await employee({
        nome: data.nome,
        cpf: data.cpf,
        administrador: data.administrador,
        cod_contrato: data.cod_contrato.cod_contrato,
        login_usuario: data.login_usuario,
        senha_usuario: data.senha_usuario,
        tipo_login: data.tipo_login,
        rg: data.rg,
        usuario: data.usuario
      });

      closeModal(false);
      updateCache();
      reset();
    } catch (error) {
      if (error instanceof AppError) {
        setToastMessage({
          cod: String(error.code),
          color: 'error',
          message: error.message,
          show: true
        });
      }
    }
  }

  function handleCloseModal() {
    closeModal(false);
    reset();
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  return (
    <Dialog open={openModal} onClose={closeModal} fullWidth>
      <form onSubmit={handleSubmit(handleSubmitFuncionario)}>
        <DialogTitle>Cadastrar funcionário</DialogTitle>
        <DialogContent>
          <ContainerSwitch>
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item>Normal</Grid>
              <Controller
                name="administrador"
                control={control}
                defaultValue={false}
                render={({ field: { name, onChange, value } }) => {
                  return (
                    <SwitchAdm
                      defaultChecked={false}
                      name={name}
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                    />
                  );
                }}
              />
              <Grid item>ADM</Grid>
            </Grid>
          </ContainerSwitch>
          <Typography variant="h5" style={{ margin: 10, fontWeight: 'bold' }}>
            Dados do usuário
          </Typography>
          <ContainerFieldsUser>
            <TextField
              label="Nome completo"
              variant="outlined"
              error={!!errors.nome?.message}
              helperText={errors.nome?.message}
              {...register('nome')}
            />
            <TextField
              label="CPF"
              variant="outlined"
              error={!!errors.cpf?.message}
              helperText={errors.cpf?.message}
              {...register('cpf')}
            />
            <TextField label="RG" variant="outlined" {...register('rg')} />
          </ContainerFieldsUser>
          <Typography variant="h5" style={{ margin: 10, fontWeight: 'bold' }}>
            Dados do login
          </Typography>
          <ContainerFieldsLogin>
            <TextField
              label="E-mail"
              variant="outlined"
              error={!!errors.login_usuario?.message}
              helperText={errors.login_usuario?.message}
              {...register('login_usuario')}
            />
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Senha
              </InputLabel>
              <OutlinedInput
                label="senha"
                type={showPassword ? 'text' : 'password'}
                id="outlined-adornment-password"
                error={!!errors.senha_usuario?.message}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Eye /> : <EyeSlash />}
                    </IconButton>
                  </InputAdornment>
                }
                {...register('senha_usuario')}
              />
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel>Tipo do login</InputLabel>
              <Select
                error={!!errors.tipo_login?.message}
                label="Tipo do login"
                defaultValue={'atendimento'}
                {...register('tipo_login')}
              >
                {loginType.map((type) => {
                  return (
                    <MenuItem value={type.value} key={type.id}>
                      {type.tipo}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <SearchContratos
              name="cod_contrato"
              control={control}
              errors={errors.cod_contrato?.message}
            />
          </ContainerFieldsLogin>
        </DialogContent>
        <DialogActions>
          <Button
            title="Cancelar"
            variantstyle="DEFAULT"
            onClick={handleCloseModal}
            type="button"
          />
          <Button
            type="submit"
            title="Salvar"
            variantstyle="SECUNDARY"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          />
        </DialogActions>
      </form>
      <ToastMessage
        code={toastMessage.cod}
        message={toastMessage.message}
        show={toastMessage.show}
        severity={toastMessage.color}
        onClose={() => {
          setToastMessage({
            cod: toastMessage.cod,
            color: toastMessage.color,
            message: toastMessage.message,
            show: isPending
          });
        }}
      />
    </Dialog>
  );
}
