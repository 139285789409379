import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

interface AutocompleteCnpjProps {
  cnpjExcel: any[];
  selectedCnpj: string;
  onChangeRazaoSocial: (name: string) => void;
  onChangeSelectedCnpj: (cnpj: string) => void;
}

export function AutocompleteCnpj({
  cnpjExcel,
  selectedCnpj,
  onChangeRazaoSocial,
  onChangeSelectedCnpj
}: AutocompleteCnpjProps) {
  return (
    <Autocomplete
      fullWidth
      id="tags-outlined"
      options={cnpjExcel}
      getOptionLabel={(option: any) =>
        `${option.cnpj}` ? `${option.cnpj} - ${option.razao_social}` : ''
      }
      onChange={(e, newValue: any) => {
        onChangeRazaoSocial(newValue.razao_social);
        onChangeSelectedCnpj(newValue.cnpj);
      }}
      value={selectedCnpj}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="CNPJ"
          placeholder="CNPJ"
        />
      )}
    />
  );
}
