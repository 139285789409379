import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface IEditContract {
  nome_contrato: string;
  cod_contrato: string;
  data_criacao: string | Date;
  data_renovacao: string | Date;
  arquivo: File | null;
  exames: object[];
  risco: object[];
  servico: object[];
  num_conv_med: string;
  num_conv_odont: string;
  num_conv_acade: string;
  num_seg_vida: string;
  num_apol_pat: string;
}

interface IEditContractArray {
  informationEdit: IEditContract[];
}

const initialState: IEditContractArray = {
  informationEdit: [
    {
      nome_contrato: '',
      cod_contrato: '',
      data_criacao: '',
      data_renovacao: '',
      arquivo: null,
      exames: [],
      risco: [],
      servico: [],
      num_conv_med: '',
      num_conv_odont: '',
      num_conv_acade: '',
      num_seg_vida: '',
      num_apol_pat: ''
    }
  ]
};

export const editContractEmp = createSlice({
  name: 'contratoEmpresa',
  initialState,
  reducers: {
    editContrato: (state, actions: PayloadAction<any>) => {
      state.informationEdit = actions.payload;
    }
  }
});

export const { editContrato } = editContractEmp.actions;
export default editContractEmp.reducer;
