import React, { useState } from 'react';
import * as M from '@material-ui/core';
import { api } from '@services/api';
import { LoadingPage } from '@components/LoadingPage';
import { cpfValidation, maskCPF } from '@utils/masks';
import { ToastMessage } from '@components/ToastMessage';
import { type Color } from '@material-ui/lab';
import { Kit as KitData, type KitProps } from '@dtos/kitDTO';
import { type IColaborador } from '@dtos/colaboradorDTO';
import { PreCadastroKit } from './PreCadastroKit';
import { PreencherKit } from './PreencherKit';
import { AppError } from '@utils/AppError';

export default function Kit() {
  const [antecendente, setAntecedente] = useState<KitData>({} as KitData);
  const [cpfColab, setCpfColab] = useState('');
  const [user, setUser] = useState<IColaborador[]>([]);
  const [genero, setGenero] = useState('');
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(false);
  const [validation, setValidation] = useState<boolean>(true);
  const [messageServer, setMessageServer] = useState({
    code: 0,
    message: '',
    severety: '' as Color,
    show: false
  });
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [dateBirth, setDateBirth] = useState('');

  async function handleDateUpdate() {
    try {
      const res = await api.post(`/ravim/colab-kit-salvo`, {
        cpf_colaborador: cpfColab,
        cnpj_cpf_comer: ''
      });
      setAntecedente(res.data[0].kit[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function dataEditColaborador() {
    try {
      setLoading(true);
      const res = await api.post(`/ravim/colab-kit`, {
        cpf_colaborador: cpfColab
      });
      handleDateUpdate();
      if (res.status === 404) {
        setMessageServer({
          code: res.status,
          message: res.data.message,
          severety: 'warning',
          show: true
        });

        setFields(false);
      } else if (res.status === 400) {
        setMessageServer({
          code: res.status,
          message: res.data.message,
          severety: 'warning',
          show: true
        });
        setFields(false);
        return;
      } else {
        setFields(true);
        setUser(res.data);
        setGenero(res.data[0].genero);
        setMessageServer({
          code: res.status,
          message: res.statusText,
          severety: 'success',
          show: true
        });
      }
      handleSelectedValueRadio('nome_colaborador', res.data[0].nome_completo);
    } catch (error) {
      if (error instanceof AppError) {
        setMessageServer({
          code: error.code,
          message: error.message,
          severety: 'error',
          show: true
        });
      }
    } finally {
      setLoading(false);
    }
  }

  async function fetchSendKit() {
    try {
      const data = {
        cpf_colaborador: cpfColab,
        nome_colaborador: antecendente.nome_colaborador || name,
        nascimento: dateBirth,
        peso: antecendente.peso || '',
        altura: antecendente.altura || '',
        Diabetes_1: antecendente.Diabetes_1 || '',
        Parentesco_1: antecendente.Parentesco_1 || '',
        Parentesco_2: antecendente.Parentesco_2 || '',
        Parentesco_3: antecendente.Parentesco_3 || '',
        Parentesco_4: antecendente.Parentesco_4 || '',
        Parentesco_5: antecendente.Parentesco_5 || '',
        Parentesco_6: antecendente.Parentesco_6 || '',
        Parentesco_7: antecendente.Parentesco_7 || '',
        Parentesco_8: antecendente.Parentesco_8 || '',
        Doenca_do_Coracao: antecendente.Doenca_do_Coracao || '',
        Doenca_Psiquiatricas: antecendente.Doenca_Psiquiatricas || '',
        Tuberculose: antecendente.Tuberculose || '',
        Cancer: antecendente.Cancer || '',
        Pressao_Alta: antecendente.Pressao_Alta || '',
        Alergias: antecendente.Alergias || '',
        Outras: antecendente.Outras || '',
        Doencas_do_coracao_Pressoao_Alta:
          antecendente.Doencas_do_coracao_Pressoao_Alta || '',
        Bronquite_Asma_Rinite: antecendente.Bronquite_Asma_Rinite || '',
        Hemorroidas: antecendente.Hemorroidas || '',
        Doencas_renais: antecendente.Doencas_renais || '',
        Hernias: antecendente.Hernias || '',
        Doencas_do_figado: antecendente.Doencas_do_figado || '',
        Alguma_deficiencia_fisica: antecendente.Alguma_deficiencia_fisica || '',
        Diabetes_2: antecendente.Diabetes_2 || '',
        Ja_foi_submetido_a_cirurgia:
          antecendente.Ja_foi_submetido_a_cirurgia || '',
        Doencas_do_estomago: antecendente.Doencas_do_estomago || '',
        Ja_esteve_internado: antecendente.Ja_esteve_internado || '',
        Resfriados_frequentes_Sinusite:
          antecendente.Resfriados_frequentes_Sinusite || '',
        Dificuldade_em_tarefa_pesada:
          antecendente.Dificuldade_em_tarefa_pesada || '',
        Problemas_de_visao: antecendente.Problemas_de_visao || '',
        Tabagismo: antecendente.Tabagismo || '',
        Problemas_de_audicao: antecendente.Problemas_de_audicao || '',
        Etilismo_bebe: antecendente.Etilismo_bebe || '',
        Otite_cronica_Zumbido_no_ouvido:
          antecendente.Otite_cronica_Zumbido_no_ouvido || '',
        Se_considera_acima_do_peso:
          antecendente.Se_considera_acima_do_peso || '',
        Dor_de_cabeca_Tontura_Convulsoes:
          antecendente.Dor_de_cabeca_Tontura_Convulsoes || '',
        Sofreu_ou_sofre_de_insonia:
          antecendente.Sofreu_ou_sofre_de_insonia || '',
        Doencas_de_pele: antecendente.Doencas_de_pele || '',
        Se_considera_estressado: antecendente.Se_considera_estressado || '',
        Dores_nas_costas: antecendente.Dores_nas_costas || '',
        Pratica_esporte_regularmente:
          antecendente.Pratica_esporte_regularmente || '',
        Reumatismo_Dor_nas_juntas: antecendente.Reumatismo_Dor_nas_juntas || '',
        Faz_uso_de_medicamento: antecendente.Faz_uso_de_medicamento || '',
        Sofreu_alguma_fratura_ossea:
          antecendente.Sofreu_alguma_fratura_ossea || '',
        Outra_doenca: antecendente.Outra_doenca || '',
        Doenca_psiquiatrica: antecendente.Doenca_psiquiatrica || '',
        Afastado_por_mais_de_15_dias:
          antecendente.Afastado_por_mais_de_15_dias || '',
        Ja_sofreu_acidente_trabalho:
          antecendente.Ja_sofreu_acidente_trabalho || '',
        Recebeu_indenizacao_acidente_trabalho:
          antecendente.Recebeu_indenizacao_acidente_trabalho || '',
        Ja_apresentou_alguma_doenca_trabalho_profssional:
          antecendente.Ja_apresentou_alguma_doenca_trabalho_profssional || '',
        Ja_se_submeteu_exame_complementar_anterior:
          antecendente.Ja_se_submeteu_exame_complementar_anterior || '',
        Ja_apresentou_afastamento_superior_a_15_dias:
          antecendente.Ja_apresentou_afastamento_superior_a_15_dias || '',
        Suas_condicoes_de_saude_exigem:
          antecendente.Suas_condicoes_de_saude_exigem || '',
        Ja_exerceu_atividade_com_uso_de_EPI:
          antecendente.Ja_exerceu_atividade_com_uso_de_EPI || '',
        Necessita_de_uso_de_EPI_em_suas:
          antecendente.Necessita_de_uso_de_EPI_em_suas || '',
        Data_da_ultima_mestruacao_caso_Sexo_Feminino:
          antecendente.Data_da_ultima_mestruacao_caso_Sexo_Feminino || '',
        observacoes: antecendente.observacoes || '',
        Varizes: antecendente.Varizes || '',
        Nome_doenca: antecendente.Nome_doenca || '',
        Nome_doenca_2: antecendente.Nome_doenca_2 || ''
      };

      await api.post('/ravim/kit', data);
      setMessageServer({
        code: 200,
        message: 'Salvo com sucesso!',
        severety: 'success',
        show: true
      });
      setFields(false);
    } catch (error) {
      if (error instanceof AppError) {
        console.log(error);
        setMessageServer({
          code: error.code,
          message: error.message,
          severety: 'error',
          show: true
        });
      }
    }
  }

  function handleSelectedValueRadio(name: string, value: string) {
    setAntecedente((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  function validationCPF(cpf: string) {
    cpf = cpf.replace(/[.-]/g, '');
    if (cpfValidation(cpf)) {
      setValidation(false);
    } else {
      setValidation(true);
    }
    return cpf;
  }

  return (
    <M.Container>
      <LoadingPage open={loading} onClick={() => null} />
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <M.Paper
          style={{
            padding: 20,
            marginTop: 20,
            justifyContent: 'center',
            alignItems: 'center',
            width: '50%'
          }}
        >
          <M.FormGroup style={{ flexDirection: 'row', gap: 30 }}>
            <M.TextField
              placeholder="CPF"
              variant="outlined"
              style={{ width: '70%' }}
              value={cpfColab}
              onKeyDown={(event) => {
                if (event.key === 'Enter') dataEditColaborador();
              }}
              onChange={(value) => {
                setCpfColab(maskCPF(validationCPF(value.target.value)));
              }}
              inputProps={{ maxLength: 14 }}
            />
            <M.Button
              variant="contained"
              color={validation ? 'default' : 'secondary'}
              onClick={dataEditColaborador}
              disabled={validation}
            >
              Consultar Kit
            </M.Button>
          </M.FormGroup>
        </M.Paper>
      </div>
      {fields ? (
        ''
      ) : (
        <M.Typography
          variant="h6"
          style={{
            alignItems: 'center',
            marginTop: 40,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {messageServer.message}
        </M.Typography>
      )}

      {String(messageServer.code) === '400' ? (
        <PreCadastroKit
          genero={genero}
          user={user}
          fetchSendKit={fetchSendKit}
          handleSelectedValueRadio={handleSelectedValueRadio}
          cpf={cpfColab}
          handleCpf={(value) => {
            setCpf(cpfColab);
          }}
          handleNomeCompleto={(value) => {
            setName(value);
          }}
          handleNomeDataDeNascimento={(value) => {
            setDateBirth(value);
          }}
          value={antecendente}
        />
      ) : (
        <></>
      )}

      {fields && fields ? (
        <PreencherKit
          genero={genero}
          user={user}
          fetchSendKit={fetchSendKit}
          handleSelectedValueRadio={handleSelectedValueRadio}
          value={antecendente}
        />
      ) : (
        <></>
      )}
      <ToastMessage
        code={String(messageServer.code)}
        message={messageServer.message}
        show={messageServer.show}
        severity={messageServer.severety}
        onClose={() => {
          setMessageServer({
            code: messageServer.code,
            message: messageServer.message,
            severety: messageServer.severety,
            show: false
          });
        }}
      />
    </M.Container>
  );
}
