import React, { useEffect, useState } from 'react';
import * as M from '@material-ui/core';

import { type CredentialProps } from '@pages/Agendamentos/MarcarHorario/agendamentoHibrido';
import * as S from './styles';
import { maskDate } from '@utils/masks';
import { Close, Description } from '@material-ui/icons';

interface CardProp {
  data: CredentialProps[];
  onDelete: (id: number) => void;
  onGuia?: (id: number) => void;
}

export function CardHibridCad({ data, onDelete, onGuia }: CardProp) {
  return (
    <M.Container>
      {data.map((cardData, index) => (
        <S.ContainerPaper key={cardData.id_exame_hibrido}>
          <M.Paper>
            <S.ContainerInsideCard>
              <S.DivButtons>
                <S.DivButton>
                  <S.ButtonDelete
                    onClick={() => { onDelete(cardData.id_exame_hibrido); }}
                  >
                    <Close />
                  </S.ButtonDelete>
                  {onGuia && (
                    <S.ButtonGuia onClick={() => { onGuia(index); }}>
                      <Description />
                    </S.ButtonGuia>
                  )}
                </S.DivButton>
              </S.DivButtons>
              <S.DivInfo>
                <M.Typography>
                  <S.Span>Tipo de atendimento:</S.Span>{' '}
                  {cardData.tipo_agendamento}
                </M.Typography>
                <M.Typography>
                  <S.Span>Nome do credenciado:</S.Span>{' '}
                  {cardData.nome_comercial}
                </M.Typography>
              </S.DivInfo>
              <S.DivInfo>
                <M.Typography>
                  <S.Span>Data do agendamento: </S.Span>
                  {maskDate(cardData.data_agendamento)}
                </M.Typography>
                <M.Typography>
                  <S.Span>Horário: </S.Span>
                  {cardData.horario}
                </M.Typography>
                <M.Typography>
                  <S.Span>Ordem de chegada: </S.Span>
                  {cardData.ordem_chegada}
                </M.Typography>
              </S.DivInfo>
              {cardData.linkDoatendimento === '' ? (
                <></>
              ) : (
                <M.Typography>
                  <S.Span>Link do atendimento: </S.Span>
                  <M.Link href={cardData.linkDoatendimento} target="_blank">
                    {cardData.linkDoatendimento}
                  </M.Link>
                </M.Typography>
              )}
              <br></br>
              <M.Typography>
                <S.Span>Exames:</S.Span>
              </M.Typography>
              <S.BoxExames>
                <M.Typography variant="subtitle2">
                  {cardData.exames &&
                    cardData.exames.map((exame, index) => (
                      <S.DivExames key={index}>
                        {exame.exame_credenciado}
                      </S.DivExames>
                    ))}
                </M.Typography>
              </S.BoxExames>
            </S.ContainerInsideCard>
          </M.Paper>
        </S.ContainerPaper>
      ))}
    </M.Container>
  );
}
