import React from 'react';
import { CircularProgress, TextField, TextFieldProps } from '@material-ui/core';
import { maskCPF } from '@utils/masks';

type InputSearchColabProps = TextFieldProps & {
  label: string;
  value: string;
  textError: string;
  errorShow: boolean;
  isLoading?: boolean;
  onChange: (cpf: string) => Promise<void>;
};

export function InputSearchColab({
  value,
  label,
  textError,
  errorShow,
  isLoading = false,
  onChange,
  ...rest
}: InputSearchColabProps) {
  function handleMaskCpf(cpf: string) {
    onChange(maskCPF(cpf));
  }
  return (
    <TextField
      fullWidth
      label={label}
      value={value}
      onChange={(e) => handleMaskCpf(e.target.value)}
      variant="outlined"
      inputProps={{ maxLength: 14 }}
      helperText={textError}
      error={errorShow}
      InputProps={{
        endAdornment: isLoading ? (
          <CircularProgress color="inherit" size={20} />
        ) : null
      }}
      {...rest}
    />
  );
}
