import React from 'react'
import { LinkProps, Link } from 'react-router-dom'

type NavLinkProps = LinkProps



export function NavLink(props: NavLinkProps){
    return(
        <Link
        style={{textDecoration:'none'}}
            {...props}
        />
    )
}