import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
  CircularProgress,
  TextField
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { api } from '@services/api';
import { maskCPF, maskCoin, maskDate } from '@utils/masks';
import { Excel } from '@components/Excel';
import { FilterDateExcel } from '@components/FilterDateExcel';
import usePagination from '@hooks/usePagination';
import { Button } from '@components/Button';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { Pagination } from '@material-ui/lab';

export interface IAgenda {
  id_agendamento: number;
  num_agenda: string;
  nome: string;
  cpf_colaborador: string;
  telemedicina: string;
  nome_comercial: string;
  cidade: string;
  estado: string;
  data_agendamento: string;
  ordem_chegada: string;
  horario: string;
  tipo_aso: string;
  observacao: string;
  status: string;
  usuario: string;
  cpf_cnpj_comer: string;
  cnpj: string;
  razao_social: string;
  tipo_agendamento: string;
  situacao: string;
  ghe: string;
  local_obra: string;
}

export interface IEmpresa {
  id_exame_contr_empresa: number;
}

export interface IExames extends IEmpresa {
  exame_empresa: string;
  valor_empresa: string;
}

function createData(
  cnpj: string,
  nome_comercial: string,
  cpf: string,
  nome: string,
  data: string,
  tipo_aso: string,
  status: string,
  num_agenda: string,
  cnpj_: string,
  razao_social: string,
  tipo_agendamento: string
) {
  return {
    cnpj,
    nome_comercial,
    cpf,
    nome,
    data,
    tipo_aso,
    status,
    num_agenda,
    cnpj_,
    razao_social,
    tipo_agendamento
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [exames, setExames] = useState<IExames[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  async function reqExames(
    cpf_colaborador: string,
    num_agenda: string,
    tipo_agendamento: string
  ) {
    const data = {
      cpf_colaborador,
      num_agenda,
      tipo_agendamento,
      tipo: 'empresa'
    };

    try {
      if (!open) {
        const res = await api.post(`/ravim/contrato-exames`, data);
        setExames(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleOpenTable = (
    cpf: string,
    num_agenda: string,
    tipo_agendamento: string
  ) => {
    setOpen(!open);
    if (!open) {
      reqExames(cpf, num_agenda, tipo_agendamento);
    }
  };

  function soma() {
    return exames.length > 0
      ? exames
          ?.map((e) =>
            parseFloat(
              e?.valor_empresa !== '' && e?.valor_empresa !== 'None'
                ? e?.valor_empresa
                : '0'
            )
          )
          .reduce((a, b) => a + b, 0)
      : '';
  }

  // console.log(row, row.cnpj)
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              { handleOpenTable(row.cpf, row.num_agenda, row.tipo_agendamento); }
            }
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.cnpj_}
        </TableCell>
        <TableCell align="right">{row.razao_social}</TableCell>
        <TableCell align="right">{row.nome}</TableCell>
        <TableCell align="right">{row.cpf}</TableCell>
        <TableCell align="right">{row.tipo_aso}</TableCell>
        <TableCell align="right">{row.tipo_agendamento}</TableCell>
        <TableCell align="right">{row.status}</TableCell>
        <TableCell align="right">{row.data}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, background: '#e3e3e3' }}
          colSpan={9}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Exames
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Credenciado</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell>Exame</TableCell>
                    <TableCell align="right">Valor unitário</TableCell>
                    <TableCell align="right">Preço total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exames.length > 0 ? (
                    <>
                      <TableCell></TableCell>
                      <TableCell>{row.nome_comercial}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                  ) : (
                    <></>
                  )}

                  {exames.length > 0 ? (
                    exames?.map((historyRow, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            {maskDate(row.data)}
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>

                          <TableCell>{historyRow?.exame_empresa}</TableCell>
                          <TableCell align="right">
                            {isNaN(Number(historyRow?.valor_empresa))
                              ? '-'
                              : Number(
                                  historyRow?.valor_empresa
                                ).toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL'
                                })}
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <Typography>
                      {loading ? <CircularProgress /> : 'Exames não cadastrado'}
                    </Typography>
                  )}

                  {exames.length > 0 ? (
                    <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        {soma().toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                      </TableCell>
                    </>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export interface IExcel {
  nome: string;
  cpf_colaborador: string;
  cnpj: string;
  razao_social: string;
  tipo_agendamento: string;
  cpf_cnpj_comer: string;
  nome_comercial: string;
  data_agendamento: string;
  status: string;
  tipo_aso: string;
  exame_empresa_colaborador: string;
  valor_empresa_colaborador: string;
  exame_empresa_hibrido: string;
  valor_empresa_hibrido: string;
  exame_empresa_toxicologico: string;
  valor_empresa_toxicologico: string;
}

export const ExamesEmpresas = () => {
  const [colab, setColab] = useState<IAgenda[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState('');
  const [excel, setExcel] = useState<IExcel[]>([]);
  const [selectedCnpj, setSelectedCnpj] = useState<any>();
  const [nomeEmpresa, setNomeEmpresa] = useState<string>('');
  const [cnpExcel, setCnpjExcel] = useState<IAgenda[]>([]);
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [isLoadingCnpj, setIsLoadingCnpj] = useState(true);
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState('');

  const [page, setPage] = useState(1);
  const PER_PAGE = 30;

  const count = Math.ceil(colab.length / PER_PAGE);
  const _DATA = usePagination(colab, PER_PAGE);

  const filterContractsPerPage =
    _DATA.currentData().length > 0
      ? _DATA
          .currentData()
          ?.filter(
            (contrato: IAgenda) =>
              contrato?.cpf_cnpj_comer
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              contrato?.nome_comercial
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              contrato.nome.toUpperCase().includes(search.toUpperCase()) ||
              contrato.cpf_colaborador
                .toUpperCase()
                .includes(search.toUpperCase())
          )
      : [];

  const filterContracts =
    colab.length > 0
      ? colab.filter(
          (contrato: IAgenda) =>
            contrato.cnpj?.toUpperCase().includes(search.toUpperCase()) ||
            contrato.razao_social
              ?.toUpperCase()
              .includes(search.toUpperCase()) ||
            contrato.nome?.toUpperCase().includes(search.toUpperCase()) ||
            contrato.cpf_colaborador
              .toUpperCase()
              .includes(search.toUpperCase())
        )
      : [];

  const filter = search.length > 0 ? filterContracts : filterContractsPerPage;

  const rows = filter.map((colab) => {
    return createData(
      colab.cpf_cnpj_comer,
      colab.nome_comercial,
      maskCPF(colab.cpf_colaborador),
      colab.nome,
      maskDate(colab.data_agendamento),
      colab.tipo_aso,
      colab.status,
      colab.num_agenda,
      colab.cnpj,
      colab.razao_social,
      colab.tipo_agendamento
    );
  });

  const ajustExcel = excel.map((ex, i) => {
    return {
      cnpj: ex.cnpj,
      razao_social: ex.razao_social,
      cpf: ex.cpf_colaborador,
      data_agendamento: ex.data_agendamento
        ? maskDate(ex.data_agendamento)
        : '',
      nome: ex.nome,
      nome_comercial: ex.nome_comercial,
      status: ex.status,
      tipo_aso: ex.tipo_aso,
      tipo_agendamento: ex.tipo_agendamento,
      exame:
        ex.exame_empresa_colaborador ||
        ex.exame_empresa_hibrido ||
        ex.exame_empresa_toxicologico,
      valor: maskCoin(
        isNaN(
          Number(
            ex.valor_empresa_colaborador ||
              ex.valor_empresa_hibrido ||
              ex.valor_empresa_toxicologico
          )
        )
          ? 0
          : Number(
              ex.valor_empresa_colaborador ||
                ex.valor_empresa_hibrido ||
                ex.valor_empresa_toxicologico
            )
      )
    };
  });

  async function reqExcel() {
    const data = {
      cnpj: selectedCnpj || '',
      tipo: 'empresa',
      data_ano: dataInicio.split('-')[0],
      data_mes: dataInicio.split('-')[1]
    };

    try {
      const res = await api.post(`/ravim/excel`, data);

      setExcel(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function reqCnpjExcel() {
    try {
      setIsLoadingCnpj(true);
      const data = {
        tipo: 'empresa'
      };

      const response = await api.post(`/ravim/cnpj-excel`, data);
      setCnpjExcel(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingCnpj(false);
    }
  }

  async function reqAgenda() {
    try {
      const res = await api.get(`/ravim/agenda-financeiro`);
      setColab(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    setTimeout(() => {
      reqCnpjExcel();
    }, 5000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      reqAgenda();
    }, 2000);
  }, []);

  useEffect(() => {
    if (!value) {
      setSearch('');
    }
  }, [value]);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
          marginBottom: 20,
          alignItems: 'center'
        }}
      >
        <TextField
          value={value}
          onChange={(e) => { setValue(e.target.value); }}
          label="Pesquisar empresas"
          type="text"
          variant="outlined"
          style={{ background: '#FFF' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch(value);
            }
          }}
        />
        <Button
          variantstyle="SECUNDARY"
          icon={<MagnifyingGlass size={25} />}
          onClick={() => { handleSearch(value); }}
        />
      </div>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Exames Empresas
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20
        }}
      >
        <Typography variant="h6" align="left">
          Total: {colab.length}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 20,
            width: '90%'
          }}
        >
          <FilterDateExcel
            dataInicio={dataInicio}
            cnpjExcel={cnpExcel}
            selectedCnpj={selectedCnpj}
            onChangeDataInicio={(e) => { setDataInicio(e); }}
            onChangeRazaoSocial={(e) => { setNomeEmpresa(e); }}
            onChangeSelectedCnpj={(e) => { setSelectedCnpj(e); }}
          />
          <Excel
            reqExcell={reqExcel}
            excelData={ajustExcel.flat()}
            fileName={dataInicio.split('-')[1]}
            cnpjSelected={selectedCnpj}
            dataInicio={dataInicio}
            dataFim={dataFim}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 20
        }}
      >
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>CNPJ</TableCell>
                <TableCell align="right">Nome Empresa</TableCell>
                <TableCell align="right">Nome do Colaborador</TableCell>
                <TableCell align="right">CPF</TableCell>
                <TableCell align="right">Tipo de ASO</TableCell>
                <TableCell align="right">Tipo de Agendamento</TableCell>
                <TableCell align="right">Status do Agendamento</TableCell>
                <TableCell align="right">Data do Agendamento</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <Row key={index} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {loading && <CircularProgress />}
      </div>
    </Container>
  );
};
