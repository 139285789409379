import { api } from '@services/api';

export type EmployeesProps = {
  nome: string;
  cpf: string;
  senha_usuario: string;
  login_usuario: string;
  administrador: boolean;
  tipo_login: 'atendimento' | 'financeiro' | 'medico' | 'gerente';
  cod_contrato: {
    nome_contrato: string;
    cod_contrato: string;
  };
  rg: string;
  usuario: string;
}[];

export async function getFuncionarios() {
  const res = await api.get<EmployeesProps>('/ravim/usuarios');
  return res.data;
}
