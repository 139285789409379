import {
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Snackbar
} from '@material-ui/core';
import { HighlightOff, SettingsBackupRestore } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../../services/api';
import MuiAlert, { type Color } from '@material-ui/lab/Alert';
import { Button } from '@components/Button';
import usePagination from '@hooks/usePagination';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { Pagination } from '@material-ui/lab';
import { ContainerButtonsTop, DivSearch } from './styles';
import { NavLink } from '@components/NavLink';

interface ITab {
  cod_ghe: string;
  cod_obra: string;
  id_cad_ghe: number;
  local: string;
  nome_empresa: string;
  nome_ghe: string;
}

export const Ghe = () => {
  const [loading, setLoading] = useState(false);
  const [ghe, setGhe] = useState<ITab[]>([]);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [edit, setEdit] = useState<ITab>();
  const [dialog, setDialog] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color | undefined>();
  const [search, setSearch] = useState<string>('');

  const [value, setValue] = useState('');

  const [page, setPage] = useState(1);
  const PER_PAGE = 30;

  const count = Math.ceil(ghe.length / PER_PAGE);
  const _DATA = usePagination(ghe, PER_PAGE);

  const filterGHEPerPage =
    _DATA.currentData().length > 0
      ? _DATA
          .currentData()
          .filter(
            (gheSearch: ITab) =>
              gheSearch.cod_obra.toUpperCase().includes(search.toUpperCase()) ||
              gheSearch.cod_ghe.toUpperCase().includes(search.toUpperCase())
          )
      : [];

  const filterGHE =
    ghe.length > 0
      ? ghe.filter(
          (gheSearch: ITab) =>
            gheSearch.cod_obra.toUpperCase().includes(search.toUpperCase()) ||
            gheSearch.cod_ghe.toUpperCase().includes(search.toUpperCase())
        )
      : [];

  const filter = search.length > 0 ? filterGHE : filterGHEPerPage;

  async function gheTab() {
    try {
      setLoading(true);
      const res = await api.get(`ravim/ghe`);
      if (res.status === 404) return;
      setGhe(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  function deleteContract(value: ITab) {
    setDialogDelete(true);
    setEdit(value);
  }

  function closeDialog() {
    setDialog(false);
    setDialogDelete(false);
  }
  async function deleteContrato(cod_cont: any) {
    await api
      .delete(`/ravim/exclu-ghe`, { data: { cod_ghe: cod_cont } })
      .then((res) => {
        if (res.status === 200) {
          const newListGhe = ghe.filter(
            (codGhe: ITab) => codGhe.cod_ghe !== cod_cont
          );
          setSnack(true);
          setDialogDelete(false);
          setMessage(res.data.message);
          setSeverity('success');
          setCod(String(res.status));
          setGhe(newListGhe);
        } else {
          setSeverity('error');
          setSnack(true);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleSearch = (value: string) => {
    const removeSpaces = value.trim();
    setSearch(removeSpaces);
  };

  useEffect(() => {
    gheTab();
  }, []);

  useEffect(() => {
    if (!value) {
      setSearch('');
    }
  }, [value]);

  return (
    <Container>
      <ContainerButtonsTop>
        <DivSearch>
          <TextField
            label="Pesquisar GHE"
            type="text"
            variant="outlined"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(value);
              }
            }}
          />
          <Button
            variantstyle="SECUNDARY"
            icon={<MagnifyingGlass size={25} />}
            onClick={() => {
              handleSearch(value);
            }}
          />
        </DivSearch>
        <NavLink to="/cadastro-ghe">
          <Button title="Cadastro de GHE" />
        </NavLink>
      </ContainerButtonsTop>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        GHE
      </Typography>
      <Typography variant="h6" align="left">
        Total de GHE: {ghe.length}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 20
        }}
      >
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
        {loading ? (
          <TableContainer component={Paper}>
            <Table style={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Código Obra</TableCell>
                  <TableCell>Código GHE</TableCell>
                  <TableCell>Nome GHE</TableCell>
                  <TableCell>ATUALIZAR</TableCell>
                  <TableCell>DELETAR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter &&
                  filter.map((ghe: ITab, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{ghe.cod_obra}</TableCell>
                      <TableCell>{ghe.cod_ghe}</TableCell>
                      <TableCell>{ghe.nome_ghe}</TableCell>
                      <TableCell>
                        <Link to={{ pathname: '/edit-ghe', state: ghe }}>
                          <IconButton>
                            <SettingsBackupRestore
                              fontSize="medium"
                              style={{ color: '#422C59' }}
                            />
                          </IconButton>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            deleteContract(ghe);
                          }}
                        >
                          <HighlightOff
                            fontSize="medium"
                            style={{ color: 'red' }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>
      <Dialog open={dialogDelete} onClose={closeDialog}>
        <DialogTitle>
          Contrato a ser deletado, confira o nome e código
        </DialogTitle>
        <DialogContent>
          <Typography>
            Nome: <b>{edit?.nome_ghe}</b>
          </Typography>
          <Typography>
            Código: <b>{edit?.cod_ghe}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            title="Cancelar"
            variantstyle="SECUNDARY"
          />

          <Button
            title="Deletar"
            variantstyle="RED"
            autoFocus
            onClick={async () => {
              await deleteContrato(edit?.cod_ghe);
            }}
          />
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
