import {
  Button,
  Chip,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
  withStyles,
  Container,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api } from '@services/api';
import { estados } from '@data/estados';
import { planos } from '@data/planos';
import { maskCharSpecial } from '@utils/masks';
import { AppError } from '@utils/AppError';

interface IExames {
  id_exames: string;
  nome: string;
}

interface IRiscos {
  id_risco: string;
  nome: string;
}

interface ICnpj {
  bairro: string;
  cep: string;
  cidade: string;
  cnae: string;
  cnae_secundario: string;
  complemento: string;
  estado: string;
  idcad_empresa: number;
  inf_contrato: string;
  nome_empresa: string;
  nome_fantacia: string;
  num_cnpj: string;
  numero: number | null;
  razao_social: string;
  rua: string;
}

interface LocalObra {
  cod_local: string;
  cnpj_local: string;
  nome: string;
  razao_social: string;
  data_inicio: string;
  data_fim: string;
  num_cnpj: string;
  rua: string;
  numero: string;
  cep: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
}

interface IExamesToxico {
  id_exame_tox: string;
  nome: string;
}

const ButtonAdd = withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B'
    }
  }
}))(Button);
const ButtonSair = withStyles(() => ({
  root: {
    background: 'red',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'red',
      color: 'red',
      border: 'solid 1px red'
    }
  }
}))(Button);

const AddExames = withStyles(() => ({
  root: {
    background: 'green',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'green',
      color: 'green',
      border: 'solid 1px red'
    }
  }
}))(Button);

export const Cadastroghi = () => {
  const [selectLocalObra, setSelectLocalObra] = useState<LocalObra[]>([]);
  const [selectedLocalObra, setSelectedLocalObra] = useState();

  const [codGhe, setCodGhe] = useState('');
  const [nomeGhe, setNomeGhe] = useState('');
  const [nomeMedico, setNomeMedico] = useState('');
  const [crm, setCrm] = useState<string>('');
  const [estadoCrm, setEstadoCrm] = useState<string>('');

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color>();
  const [contratos, setContratos] = useState<ICnpj[]>([]);
  const [obsASO, setObsASO] = useState<string>('');

  const [risco, setRisco] = useState([]);
  const [listRisco, setListRisco] = useState([]);
  const [newData, setNewData] = useState([]);
  const [valor, setValor] = useState<number | null>(null);
  const [novoValor, setNovoValor] = useState<object[]>([]);
  const [campoValor, setCampoValor] = useState<object[]>([]);
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [singleExame, setSingleExame] = useState<IExames | null>(null);
  const [valorTotal, setValorTotal] = useState<number>(0);
  const [plano, setPlano] = useState<string>('');
  const [valorPlano, setValorPlano] = useState<string>('');
  const [asoCheck, setAsoCheck] = useState({
    admissional: false,
    demissional: false,
    periodico: false,
    mudancaDeRiscos: false,
    retornoAoTrabalho: false,
    monitoramento: false
  });

  const [valorToxico, setValorToxico] = useState<number | null>(null);
  const [novoValorToxico, setNovoValorToxico] = useState<object[]>([]);
  const [campoValorToxico, setCampoValorToxico] = useState<object[]>([]);
  const [dialogToxico, setDialogToxico] = useState<boolean>(false);
  const [dialogDeleteToxico, setDialogDeleteToxico] = useState(false);
  const [singleExameToxico, setSingleExameToxico] =
    useState<IExamesToxico | null>(null);
  const [valorTotalToxico, setValorTotalToxico] = useState<number>(0);
  const [newDataToxico, setNewDataToxico] = useState<IExamesToxico[]>([]);
  const [tagGhe, setTagGhe] = useState('');

  useEffect(() => {
    const resultExames = new Promise((resolve, reject) => {
      setTimeout(() => {
        comboExames();
        resolve(10);
      }, 1 * 1000);
    });

    const resultRiscos = new Promise((resolve, reject) => {
      setTimeout(() => {
        comboRiscos();
        resolve(20);
      }, 2 * 1000);
    });

    const resultServicos = new Promise((resolve, reject) => {
      setTimeout(() => {
        localObraReq();
        resolve(30);
      }, 3 * 1000);
    });

    const resultExamesToxicologico = new Promise((resolve, reject) => {
      setTimeout(() => {
        getExamesToxicologico();
        resolve(40);
      }, 4 * 1000);
    });

    const getContract = new Promise((resolve, reject) => {
      setTimeout(() => {
        contract();
        resolve(50);
      }, 5 * 1000);
    });

    Promise.all([
      resultExames,
      resultRiscos,
      resultServicos,
      resultExamesToxicologico,
      getContract
    ]);
  }, []);

  function getExamesToxicologico() {
    api
      .get('ravim/exame-tox')
      .then((res) => {
        setNewDataToxico(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function localObraReq() {
    await api
      .get(`ravim/localObra`)
      .then((res) => {
        if (res.status === 200) {
          setSelectLocalObra(res.data);
        }
        // console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function sendGHE() {
    const data = {
      cod_obra: selectedLocalObra,
      cod_ghe: tagGhe ? `${codGhe}-${tagGhe
        .replace(/(\s{1})/g, '-$1')
        .replace(/\s/g, '')}`.trim() :  codGhe.trim(),
      nome_ghe: nomeGhe.trim(),
      exames_ghe: campoValor,
      risco_ghe: risco,
      obs_aso: obsASO,
      nome_medico: nomeMedico.trim(),
      crm,
      crm_estado: estadoCrm,
      tipo_plano: plano,
      valor_plano: valorPlano,
      toxicologico: campoValorToxico
    };
    //console.log(data);
    api
      .post(`ravim/cad-ghe`, data)
      .then((res) => {
        setCod(String(res.status));
        setSnack(true);
        setSeverity('success');
        setMessage(res.data.message);
        setTimeout(() => {
          window.location.replace('/ghe');
        }, 3000);
      })
      .catch((err) => {
        if (err instanceof AppError) {
          console.log(err);
          setCod(String(err.code));
          setSnack(true);
          setSeverity('error');
          setMessage(err.message);
        }
      });
  }

  async function comboRiscos() {
    await api
      .get(`ravim/riscos`)
      .then((res) => {
        setListRisco(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function comboExames() {
    await api
      .get(`/ravim/exames`)
      .then((res) => {
        setNewData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function contract() {
    await api
      .get(`ravim/empresas-rh`)
      .then((res) => {
        if (res.status === 200) {
          setContratos(res.data);
        }
        // console.log(res.status)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // ============================= CRUD - EXAMES ==================================

  function closeDialog() {
    setDialog(false);
    setDialogDelete(false);
  }

  function saveNewValor() {
    setNovoValor([...campoValor]);
    setDialog(false);
  }

  function deleteExame(id: string) {
    const newList = campoValor.filter((exame: any) => exame.id_exames !== id);
    const verifyValues = newList.map((v: any) => {
      return isNaN(parseFloat(v.dias)) ? 0 : parseFloat(v.dias);
    });
    const sub = verifyValues.reduce(
      (soma: number, i: number) => soma - i * -1,
      0
    );

    setValorTotal(sub);
    setCampoValor(newList);
  }

  function valueExames() {
    if (singleExame === null) {
      alert('Selecione um exame');
    } else {
      const newVar = [...campoValor];
      const data = {
        id_exames: singleExame?.id_exames,
        nome: singleExame?.nome,
        dias: valor === null ? '0' : valor,
        admissional: asoCheck.admissional ? 'admissional' : '',
        demissional: asoCheck.demissional ? 'demissional' : '',
        periodico: asoCheck.periodico ? 'periodico' : '',
        mudanca_de_riscos: asoCheck.mudancaDeRiscos ? 'mudanca_de_riscos' : '',
        retorno_ao_trabalho: asoCheck.retornoAoTrabalho
          ? 'retorno_ao_trabalho'
          : '',
        monitoramento: asoCheck.monitoramento ? 'monitoramento' : ''
      };
      newVar.push(data);
      setCampoValor(newVar);

      const verifyValues = newVar.map((v: any) => {
        return isNaN(parseFloat(v.dias)) ? 0 : parseFloat(v.dias);
      });
      const soma = verifyValues.reduce((soma, i) => {
        return soma + i;
      });
      setValorTotal(soma);

      setSingleExame(null);
      setValor(null);
      setAsoCheck({
        admissional: false,
        demissional: false,
        periodico: false,
        mudancaDeRiscos: false,
        retornoAoTrabalho: false,
        monitoramento: false
      });
    }
  }

  // ============================= CRUD - EXAMES Toxicológico ==================================

  function closeDialogToxico() {
    setDialogToxico(false);
    setDialogDeleteToxico(false);
  }

  function saveNewValorToxico() {
    setNovoValorToxico([...campoValorToxico]);
    setDialogToxico(false);
  }

  function deleteExameToxico(id: string) {
    const newList = campoValorToxico.filter(
      (exame: any) => exame.id_exames !== id
    );
    const verifyValues = newList.map((v: any) => {
      return isNaN(parseFloat(v.dias)) ? 0 : parseFloat(v.dias);
    });
    const sub = verifyValues.reduce(
      (soma: number, i: number) => soma - i * -1,
      0
    );

    setValorTotalToxico(sub);
    setCampoValorToxico(newList);
  }

  function valueExamesToxico() {
    if (singleExameToxico === null) {
      alert('Selecione um exame');
    } else {
      const newVar = [...campoValorToxico];
      const data = {
        id_exames: singleExameToxico?.id_exame_tox,
        nome: singleExameToxico?.nome,
        dias: valorToxico === null ? '0' : valorToxico,
        admissional: asoCheck.admissional ? 'admissional' : '',
        demissional: asoCheck.demissional ? 'demissional' : '',
        periodico: asoCheck.periodico ? 'periodico' : '',
        mudanca_de_riscos: asoCheck.mudancaDeRiscos ? 'mudanca_de_riscos' : '',
        retorno_ao_trabalho: asoCheck.retornoAoTrabalho
          ? 'retorno_ao_trabalho'
          : '',
        monitoramento: asoCheck.monitoramento ? 'monitoramento' : ''
      };
      newVar.push(data);
      setCampoValorToxico(newVar);

      const verifyValues = newVar.map((v: any) => {
        return isNaN(parseFloat(v.dias)) ? 0 : parseFloat(v.dias);
      });
      const soma = verifyValues.reduce((soma, i) => {
        return soma + i;
      });
      setValorTotalToxico(soma);

      setSingleExameToxico(null);
      setValorToxico(null);
      setAsoCheck({
        admissional: false,
        demissional: false,
        periodico: false,
        mudancaDeRiscos: false,
        retornoAoTrabalho: false,
        monitoramento: false
      });
    }
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAsoCheck({ ...asoCheck, [event.target.name]: event.target.checked });
  };

  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Cadastrar GHE
      </Typography>
      <div>
        <Paper style={{ padding: 30, width: '100%' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ marginBottom: 20, fontWeight: 'bold' }}
              >
                Informações do GHE
              </Typography>
              <div className="containerFields">
                <Autocomplete
                  fullWidth
                  id="tags-outlined"
                  options={selectLocalObra}
                  getOptionLabel={(option: LocalObra) =>
                    `${option.cod_local}`
                      ? `${option.cnpj_local} - ${option.cod_local} - ${option.nome}`
                      : ''
                  }
                  onChange={(e, newValue: any) => {
                    setSelectedLocalObra(newValue.cod_local);
                    setCodGhe(newValue.cod_local);
                    if (newValue.cod_local !== codGhe) {
                      setTagGhe('');
                    }
                  }}
                  value={selectedLocalObra}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Locais/Obras Cadastrados"
                      placeholder="Locais/Obras Cadastrados"
                    />
                  )}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  label="Código GHE"
                  disabled
                  value={
                    tagGhe
                      ? `${codGhe}-${tagGhe
                          .replace(/(\s{1})/g, '-$1')
                          .replace(/\s/g, '')}`
                      : codGhe
                  }
                  onChange={(e) => {
                    setCodGhe(e.target.value);
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Tag GHE"
                  value={tagGhe}
                  disabled={!codGhe}
                  onChange={(e) => {
                    setTagGhe(maskCharSpecial(e.target.value));
                  }}
                />
              </div>

              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Nome GHE"
                  value={nomeGhe}
                  onChange={(e) => {
                    setNomeGhe(e.target.value);
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Médico responsável"
                  value={nomeMedico}
                  onChange={(e) => {
                    setNomeMedico(e.target.value);
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="CRM"
                  value={crm}
                  onChange={(e) => {
                    setCrm(e.target.value);
                  }}
                />
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Estado</InputLabel>
                  <Select
                    label="Estado"
                    value={estadoCrm}
                    onChange={(e: any) => {
                      setEstadoCrm(e.target.value);
                    }}
                  >
                    {estados.map((estado, index) => (
                      <MenuItem key={index} value={estado.estado}>
                        {estado.estado}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ margin: 10 }}>
                <TextField
                  style={{ width: '91.5%' }}
                  variant="outlined"
                  multiline
                  label="Observação ASO"
                  value={obsASO}
                  onChange={(e) => {
                    setObsASO(e.target.value);
                  }}
                  minRows={4}
                />
              </div>
              <div className="containerInput">
                <FormControl variant="outlined" style={{ width: '40%' }}>
                  <InputLabel>Plano</InputLabel>
                  <Select
                    label="Plano"
                    value={plano}
                    onChange={(e: any) => {
                      setPlano(e.target.value);
                    }}
                  >
                    {planos.map((plano, index) => (
                      <MenuItem key={index} value={plano.value}>
                        {plano.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography
                style={{ marginBottom: 20, fontWeight: 'bold' }}
                variant="h5"
              >
                Exames
              </Typography>
              <div
                className="containerFields"
                style={{ flexDirection: 'column' }}
              >
                <FormControl
                  style={{ marginRight: 20, width: '20%', marginBottom: 20 }}
                >
                  <AddExames
                    style={{ height: 55 }}
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      setDialog(!dialog);
                    }}
                  >
                    <Add style={{ fontSize: 20 }} />
                    Adicionar Exames
                  </AddExames>

                  <Dialog open={dialog} onClose={closeDialog}>
                    <DialogTitle>Adicionar Exames</DialogTitle>
                    <DialogContent>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 20
                          }}
                        >
                          <Autocomplete
                            style={{ width: 400 }}
                            onKeyDown={(e: any) => {
                              if (e.key === 'Enter') {
                                valueExames();
                              }
                            }}
                            id="tags-outlined"
                            options={newData}
                            getOptionLabel={(option: IExames) =>
                              `${option.id_exames} - ${option.nome}`
                                ? `${option.id_exames}-${option.nome}`
                                : ''
                            }
                            onChange={(e, newValue: any) => {
                              setSingleExame(newValue);
                            }}
                            value={singleExame}
                            getOptionDisabled={(option) => {
                              const valueDisbled = campoValor.map(
                                (exame: any, i: any) => exame
                              );
                              return !!valueDisbled.find(
                                (element) => element.nome === option.nome
                              );
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Exames"
                                placeholder="Exames"
                              />
                            )}
                          />
                          <TextField
                            onKeyDown={(e: any) => {
                              if (e.key === 'Enter') {
                                valueExames();
                              }
                            }}
                            type="number"
                            label="Dias"
                            variant="outlined"
                            value={valor || ''}
                            onChange={(e: any) => {
                              setValor(e.target.value);
                            }}
                            disabled={!asoCheck.periodico}
                          />
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={valueExames}
                          >
                            Adicionar
                          </Button>
                        </div>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.admissional}
                                onChange={handleCheck}
                                name="admissional"
                                color="primary"
                              />
                            }
                            label="Admissional"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.demissional}
                                onChange={handleCheck}
                                name="demissional"
                                color="primary"
                              />
                            }
                            label="Demissional"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.periodico}
                                onChange={handleCheck}
                                name="periodico"
                                color="primary"
                              />
                            }
                            label="Periódico"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.mudancaDeRiscos}
                                onChange={handleCheck}
                                name="mudancaDeRiscos"
                                color="primary"
                              />
                            }
                            label="Mudança de Riscos"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.retornoAoTrabalho}
                                onChange={handleCheck}
                                name="retornoAoTrabalho"
                                color="primary"
                              />
                            }
                            label="Retorno ao Trabalho"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.monitoramento}
                                onChange={handleCheck}
                                name="monitoramento"
                                color="primary"
                              />
                            }
                            label="Monitoramento"
                          />
                        </FormGroup>
                        {campoValor &&
                          campoValor.map((valores: any, i) => (
                            <Paper key={i}>
                              <div className="examesComValores">
                                <Typography>
                                  {valores.id_exames
                                    ? 'ID: ' + valores.id_exames
                                    : ''}
                                </Typography>
                                <Typography>Exame: {valores.nome}</Typography>
                                <Typography>
                                  {valores.dias === null ? 0 : valores.dias}{' '}
                                  Dias
                                </Typography>
                                <button
                                  className="btnDeleteExame"
                                  onClick={() => {
                                    deleteExame(valores.id_exames);
                                  }}
                                >
                                  <Delete />
                                </button>
                              </div>
                              <div
                                style={{
                                  padding: 20,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: 10,
                                  width: '100%',
                                  flexWrap: 'wrap'
                                }}
                              >
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.admissional}
                                </Typography>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.demissional}
                                </Typography>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.periodico}
                                </Typography>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.mudanca_de_riscos}
                                </Typography>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.retorno_ao_trabalho}
                                </Typography>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.monitoramento}
                                </Typography>
                              </div>
                            </Paper>
                          ))}
                      </div>
                      {valorTotal === 0 ? (
                        ''
                      ) : (
                        <div className="textValorGeral">
                          <Typography>
                            Total de exames lançados{' '}
                            <span className="textValor">
                              {campoValor.length}
                            </span>{' '}
                            - Total de Dias
                            <span className="textValor"> {valorTotal}</span>
                          </Typography>
                        </div>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={closeDialog} color="primary">
                        Cancelar
                      </Button>

                      <Button onClick={saveNewValor} color="primary" autoFocus>
                        Salvar
                      </Button>
                    </DialogActions>
                  </Dialog>
                </FormControl>
                <FormControl
                  style={{ marginRight: 20, width: '30%', marginBottom: 20 }}
                >
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={listRisco}
                    getOptionLabel={(option: IRiscos) =>
                      `${option.nome}`
                        ? `${option.id_risco} - ${option.nome}`
                        : ''
                    }
                    onChange={(e, newValue: any) => {
                      setRisco(newValue);
                    }}
                    value={risco}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Riscos"
                        placeholder="Riscos"
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  style={{ marginRight: 20, width: '30%', marginBottom: 20 }}
                >
                  <AddExames
                    style={{ height: 55 }}
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      setDialogToxico(!dialogToxico);
                    }}
                  >
                    <Add style={{ fontSize: 20 }} />
                    Adicionar Exames Toxicólogico
                  </AddExames>

                  <Dialog open={dialogToxico} onClose={closeDialogToxico}>
                    <DialogTitle>Adicionar Exames Toxicológico</DialogTitle>
                    <DialogContent>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 20
                          }}
                        >
                          <Autocomplete
                            style={{ width: 400 }}
                            onKeyDown={(e: any) => {
                              if (e.key === 'Enter') {
                                valueExamesToxico();
                              }
                            }}
                            id="tags-outlined"
                            options={newDataToxico}
                            getOptionLabel={(option: IExamesToxico) =>
                              `${option.id_exame_tox} - ${option.nome}`
                                ? `${option.id_exame_tox}-${option.nome}`
                                : ''
                            }
                            onChange={(e, newValue: any) => {
                              setSingleExameToxico(newValue);
                            }}
                            value={singleExameToxico}
                            getOptionDisabled={(option) => {
                              const valueDisbled = campoValorToxico.map(
                                (exame: any, i: any) => exame
                              );
                              return !!valueDisbled.find(
                                (element) => element.nome === option.nome
                              );
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Exames"
                                placeholder="Exames"
                              />
                            )}
                          />
                          <TextField
                            onKeyDown={(e: any) => {
                              if (e.key === 'Enter') {
                                valueExamesToxico();
                              }
                            }}
                            type="number"
                            label="Dias"
                            variant="outlined"
                            value={valorToxico || ''}
                            onChange={(e: any) => {
                              setValorToxico(e.target.value);
                            }}
                            disabled={!asoCheck.periodico}
                          />

                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={valueExamesToxico}
                          >
                            Adicionar
                          </Button>
                        </div>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.admissional}
                                onChange={handleCheck}
                                name="admissional"
                                color="primary"
                              />
                            }
                            label="Admissional"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.demissional}
                                onChange={handleCheck}
                                name="demissional"
                                color="primary"
                              />
                            }
                            label="Demissional"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.periodico}
                                onChange={handleCheck}
                                name="periodico"
                                color="primary"
                              />
                            }
                            label="Periódico"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.mudancaDeRiscos}
                                onChange={handleCheck}
                                name="mudancaDeRiscos"
                                color="primary"
                              />
                            }
                            label="Mudança de Riscos"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.retornoAoTrabalho}
                                onChange={handleCheck}
                                name="retornoAoTrabalho"
                                color="primary"
                              />
                            }
                            label="Retorno ao Trabalho"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asoCheck.monitoramento}
                                onChange={handleCheck}
                                name="monitoramento"
                                color="primary"
                              />
                            }
                            label="Monitoramento"
                          />
                        </FormGroup>
                        {campoValorToxico &&
                          campoValorToxico.map((valores: any, i) => (
                            <Paper key={i}>
                              <div className="examesComValores">
                                <Typography>
                                  {valores.id_exames
                                    ? 'ID: ' + valores.id_exames
                                    : ''}
                                </Typography>
                                <Typography>Exame: {valores.nome}</Typography>
                                <Typography>
                                  {valores.dias === null ? 0 : valores.dias}{' '}
                                  Dias
                                </Typography>
                                <button
                                  className="btnDeleteExame"
                                  onClick={() => {
                                    deleteExameToxico(valores.id_exames);
                                  }}
                                >
                                  <Delete />
                                </button>
                              </div>
                              <div
                                style={{
                                  padding: 20,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: 10,
                                  width: '100%',
                                  flexWrap: 'wrap'
                                }}
                              >
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.admissional}
                                </Typography>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.demissional}
                                </Typography>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.periodico}
                                </Typography>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.mudanca_de_riscos}
                                </Typography>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.retorno_ao_trabalho}
                                </Typography>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {valores.monitoramento}
                                </Typography>
                              </div>
                            </Paper>
                          ))}
                      </div>

                      {valorTotalToxico === 0 ? (
                        ''
                      ) : (
                        <div className="textValorGeral">
                          <Typography>
                            Total de exames lançados{' '}
                            <span className="textValor">
                              {campoValorToxico.length}
                            </span>{' '}
                            - Total de Dias{' '}
                            <span className="textValor">
                              {valorTotalToxico}
                            </span>
                          </Typography>
                        </div>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={closeDialogToxico} color="primary">
                        Cancelar
                      </Button>
                      <Button
                        onClick={saveNewValorToxico}
                        color="primary"
                        autoFocus
                      >
                        Salvar
                      </Button>
                    </DialogActions>
                  </Dialog>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Paper>

        <div className="btnCadastro">
          <Link to={'/ghe'} style={{ textDecoration: 'none' }}>
            <ButtonSair variant="outlined">Sair</ButtonSair>
          </Link>
          <ButtonAdd variant="outlined" onClick={sendGHE}>
            Cadastrar
          </ButtonAdd>
        </div>
      </div>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
