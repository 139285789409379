import React, { useEffect, useState } from 'react';
import { Button } from '@components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { type Control, useFieldArray } from 'react-hook-form';
import { Plus } from '@phosphor-icons/react';
import { Delete } from '@material-ui/icons';
import { useQuery } from '@tanstack/react-query';
import { api } from '@services/api';
import { DivResult, PaperContainer } from './styles';
import { maskCoin } from '@utils/masks';

export interface IExamesToxico {
  id_exame_toxi_contr_empresa?: number;
  id_exame_tox?: string;
  nome: string;
  valor: number;
}

interface Props {
  control: Control<any>;
  openModal: boolean;
  closeModal: (action: boolean) => void;
  getToxicologico?: IExamesToxico[];
  getNovoValor?: IExamesToxico[];
  getValorTotal?: number;
}

export function Toxicologico({
  control,
  openModal,
  closeModal,
  getNovoValor,
  getToxicologico,
  getValorTotal
}: Props) {
  const [dialogDeleteToxico, setDialogDeleteToxico] = useState(false);
  const [singleExameToxico, setSingleExameToxico] =
    useState<IExamesToxico | null>(null);
  const [valorTotalToxico, setValorTotalToxico] = useState(0);
  const [valorToxico, setValorToxico] = useState<number | null>(null);
  const [novoValorToxico, setNovoValorToxico] = useState<IExamesToxico[]>([]);
  const [campoValorToxico, setCampoValorToxico] = useState<IExamesToxico[]>([]);

  const { append, remove } = useFieldArray({
    control,
    name: 'exame_toxi'
  });

  function closeDialogToxico() {
    closeModal(false);
    setDialogDeleteToxico(false);
  }

  function saveNewValorToxico() {
    setNovoValorToxico([...campoValorToxico]);
    closeModal(false);
  }

  function deleteExameToxico(i: number) {
    const newList = campoValorToxico.filter((exame: any, index) => index !== i);
    const verifyValues = newList.map((v: any) => {
      return parseFloat(v.valor);
    });
    const sub = verifyValues.reduce(
      (soma: number, i: number) => soma - i * -1,
      0
    );

    remove(i);

    setValorTotalToxico(sub);
    setCampoValorToxico(newList);
  }

  function valueExamesToxico() {
    if (singleExameToxico === null || valorToxico === null) {
      alert('Preencha os dois campos');
      
    } else {
      const newVar = [...campoValorToxico];
      const data: IExamesToxico = {
        id_exame_tox: singleExameToxico?.id_exame_tox,
        id_exame_toxi_contr_empresa:
          singleExameToxico.id_exame_toxi_contr_empresa,
        nome: singleExameToxico?.nome,
        valor: Number(valorToxico) || 0
      };
      newVar.push(data);
      setCampoValorToxico(newVar);
      append({ ...data });

      const verifyValues = newVar.map((v: any) => {
        return parseFloat(v.valor);
      });
      const soma = verifyValues.reduce((soma, i) => {
        return soma + i;
      });
      setValorTotalToxico(soma);

      setSingleExameToxico(null);
      setValorToxico(null);
    }
  }

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ['toxicologico'],
    queryFn: async () => {
      const res = await api.get('/ravim/exame-tox');
      return res.data;
    },
    refetchInterval: 5000
  });

  useEffect(() => {
    if (getNovoValor && getToxicologico && getValorTotal) {
      setNovoValorToxico(getToxicologico);
      setCampoValorToxico(getToxicologico);
      setValorTotalToxico(getValorTotal);
    }
  }, [getNovoValor, getToxicologico, getValorTotal]);

  return (
    <Dialog open={openModal} onClose={closeDialogToxico}>
      <DialogTitle>Adicionar Exames Toxicológico</DialogTitle>
      <DialogContent>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: 20
            }}
          >
            <Autocomplete
              style={{ width: 400 }}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  valueExamesToxico();
                }
              }}
              id="tags-outlined"
              loading={isLoading}
              options={isSuccess ? data : []}
              getOptionLabel={(option: IExamesToxico) =>
                `${option.id_exame_tox} - ${option.nome}`
                  ? `${option.id_exame_tox}-${option.nome}`
                  : ''
              }
              onChange={(e, newValue: any) => {
                setSingleExameToxico(newValue);
              }}
              value={singleExameToxico}
              getOptionDisabled={(option) => {
                const valueDisbled = campoValorToxico.map(
                  (exame: any, i: any) => exame
                );
                return !!valueDisbled.find(
                  (element) => element.nome === option.nome
                );
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Exames"
                  placeholder="Exames"
                />
              )}
            />
            <TextField
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  valueExamesToxico();
                }
              }}
              type="number"
              label="Valor do Exame"
              variant="outlined"
              value={valorToxico || ''}
              onChange={(e: any) => { setValorToxico(e.target.value); }}
            />
            <Button
              icon={<Plus size={20} />}
              onClick={valueExamesToxico}
              variantstyle="SECUNDARY"
            />
          </div>
          {campoValorToxico &&
            campoValorToxico.map((valores, i: number) => (
              <PaperContainer key={i}>
                {valores.id_exame_tox && <p>{valores.id_exame_tox}</p>}
                <p>{valores.nome}</p>
                <p>{maskCoin(Number(valores.valor))}</p>
                <button onClick={() => deleteExameToxico(i)}>
                  <Delete />
                </button>
              </PaperContainer>
            ))}
        </div>
        {valorTotalToxico === 0 ? (
          ''
        ) : (
          <DivResult>
            <p>
              Total de exames lançados {campoValorToxico.length} - Valor Total{' '}
              <span>{maskCoin(valorTotalToxico)}</span>
            </p>
          </DivResult>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeDialogToxico}
          variantstyle="RED"
          title="Cancelar"
        />

        <Button
          onClick={saveNewValorToxico}
          variantstyle="PRIMARY"
          title="Salvar"
          disabled={campoValorToxico.length < 1}
        />
      </DialogActions>
    </Dialog>
  );
}
