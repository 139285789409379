import React from 'react';
import * as M from '@material-ui/core';
import { Exames } from './Exames';
import { Funcionarios } from './Funcionarios';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <M.Box p={3} style={{ height: 500 }}>
          {children}
        </M.Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`
  };
}

const useStyles = M.makeStyles((theme: M.Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: 'calc(100% + 280px)',
    maxWidth: '100%',
    marginBottom: 30
  }
}));

export default function Administrativo() {
  const classes = useStyles();
  const [value, setValue] = React.useState('one');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <M.Container className={classes.root} disableGutters>
      <M.AppBar position="static">
        <M.Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
          style={{ background: '#422C59' }}
        >
          <M.Tab
            value="one"
            label="Funcionários"
            wrapped
            {...a11yProps('one')}
          />
          <M.Tab value="two" label="Exames" wrapped {...a11yProps('one')} />
        </M.Tabs>
      </M.AppBar>
      <TabPanel value={value} index="one">
        <Funcionarios />
      </TabPanel>
      <TabPanel value={value} index="two">
        <Exames />
      </TabPanel>
    </M.Container>
  );
}
