import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  Container,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper
} from '@material-ui/core';
import { Add, HighlightOff, SettingsBackupRestore } from '@material-ui/icons';

import './styleDash.css';
import { Button } from '@components/Button';
import { Link } from 'react-router-dom';

export default function Dashboard() {
  return (
    <Container>
      <div>
        <h1>Página em construção</h1>
      </div>
      {/* <div className="topDash">
        <TextField label="Pesquisar empresas" type="text" variant="outlined" />
        <Link to="">
          <Button title="Adicionar" variantstyle="PRIMARY" icon={<Add />} />
        </Link>
      </div> */}
      {/* <div>
        <TableContainer component={Paper}>
          <Table style={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>CNPJ</TableCell>
                <TableCell>RAZÃO SOCIAL</TableCell>
                <TableCell>ENDEREÇO</TableCell>
                <TableCell>CEP</TableCell>
                <TableCell>ATUALIZAR</TableCell>
                <TableCell>DELETAR</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>000000000000000</TableCell>
                <TableCell>000000000000000</TableCell>
                <TableCell>000000000000000</TableCell>
                <TableCell>000000000000000</TableCell>
                <TableCell>
                  <SettingsBackupRestore
                    fontSize="medium"
                    style={{ color: '#422C59' }}
                  />
                </TableCell>
                <TableCell>
                  <HighlightOff fontSize="medium" style={{ color: 'red' }} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div> */}
    </Container>
  );
}
