import React, { useEffect, useState } from 'react';
import { Button } from '@components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Plus } from '@phosphor-icons/react';
import { Delete } from '@material-ui/icons';
import { type Control, useFieldArray } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { api } from '@services/api';
import { DivResult, PaperContainer } from './styles';
import { maskCoin } from '@utils/masks';

export interface IServico {
  id_servicos?: string;
  id_servico_contr_empresa?: number;
  nome: string;
  valor: number;
}

interface Props {
  control: Control<any>;
  openModal: boolean;
  closeModal: (action: boolean) => void;
  getServico?: IServico[];
  getNovoValor?: IServico[];
  getValorTotal?: number;
}

export function Servicos({
  control,
  openModal,
  closeModal,
  getServico,
  getNovoValor,
  getValorTotal
}: Props) {
  const [servico, setServico] = useState<IServico[]>([]);
  const [singleServico, setSingleServico] = useState<IServico | null>(null);
  const [valorSevico, setValorSevico] = useState<number | null>(0);
  const [campoValorSevices, setCampoValorServices] = useState<IServico[]>([]);
  const [valorTotalServices, setValorTotalServices] = useState<number | null>(
    0
  );

  const { append, remove } = useFieldArray({
    control,
    name: 'servico'
  });

  function valueServices() {
    if (singleServico === null || valorSevico === null) {
      alert('Preencha os dois campos');
      
    } else {
      const newVar = [...campoValorSevices];
      const data: IServico = {
        id_servicos: singleServico.id_servicos,
        id_servico_contr_empresa: singleServico.id_servico_contr_empresa,
        nome: singleServico?.nome,
        valor: Number(valorSevico)
      };
      newVar.push(data);
      setCampoValorServices(newVar);
      append({ ...data });

      const verifyValues = newVar.map((v: any) => {
        return parseFloat(v.valor);
      });
      const soma = verifyValues.reduce((soma, i) => {
        return soma + i;
      });
      setValorTotalServices(soma);

      setSingleServico(null);
      setValorSevico(null);
    }
  }

  function deleteSevice(i: number) {
    const newList = campoValorSevices.filter(
      (services: any, index) => index !== i
    );
    const verifyValues = newList.map((v: any) => {
      return parseFloat(v.valor);
    });
    const sub = verifyValues.reduce(
      (soma: number, i: number) => soma - i * -1,
      0
    );

    remove(i);
    setValorTotalServices(sub);
    setCampoValorServices(newList);
  }

  function saveNewValorServices() {
    setServico([...campoValorSevices]);
    closeModal(false);
  }

  const { data, isSuccess, isLoading, isFetching } = useQuery({
    queryKey: ['servicos'],
    queryFn: async () => {
      const res = await api.get(`/ravim/servicos`);
      return res.data;
    },
    refetchInterval: 8000
  });

  useEffect(() => {
    if (getServico && getNovoValor && getValorTotal) {
      setCampoValorServices(getServico);
      setServico(getServico);
      setValorTotalServices(getValorTotal);
    }
  }, [getServico, getNovoValor, getValorTotal]);

  return (
    <>
      <Dialog open={openModal} onClose={closeModal}>
        <DialogTitle>Adicionar Serviços</DialogTitle>
        <DialogContent>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 20
              }}
            >
              <Autocomplete
                style={{ width: 400 }}
                id="tags-outlined"
                loading={isLoading}
                options={isSuccess ? data : []}
                getOptionLabel={(option: IServico) =>
                  `${option.id_servicos}- ${option.nome}`
                    ? `${option.id_servicos}- ${option.nome}`
                    : ''
                }
                onChange={(e, newValue: any) => { setSingleServico(newValue); }}
                value={singleServico}
                getOptionDisabled={(option) => {
                  const valueDisbled = campoValorSevices.map(
                    (exame: any, i: any) => exame
                  );
                  return !!valueDisbled.find(
                    (element) => element.nome === option.nome
                  );
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Serviço"
                    placeholder="Serviço"
                  />
                )}
              />
              <TextField
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    valueServices();
                  }
                }}
                type="number"
                label="Valor do Serviço"
                variant="outlined"
                value={valorSevico || ''}
                onChange={(e: any) => { setValorSevico(e.target.value); }}
              />
              <Button
                icon={<Plus size={20} />}
                variantstyle="SECUNDARY"
                onClick={valueServices}
              />
            </div>
            {campoValorSevices &&
              campoValorSevices.map((valores, i: number) => (
                <PaperContainer key={i} className="examesComValores">
                  <p>{valores.nome}</p>
                  <p>{maskCoin(Number(valores.valor))}</p>
                  <button onClick={() => { deleteSevice(i); }}>
                    <Delete />
                  </button>
                </PaperContainer>
              ))}
          </div>
          {valorTotalServices === 0 ? (
            ''
          ) : (
            <DivResult>
              <p>
                Total de exames lançados {campoValorSevices.length} - Valor
                Total <span>{maskCoin(Number(valorTotalServices))}</span>
              </p>
            </DivResult>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => { closeModal(false); }}
            title="Cancelar"
            variantstyle="RED"
          />

          <Button
            disabled={campoValorSevices.length < 1}
            onClick={saveNewValorServices}
            title="Salvar"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
