import styled from 'styled-components';
import { DropzoneRootProps } from 'react-dropzone';

const getColor = (props: DropzoneRootProps) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-bottom: 10px;
`;

export const DivContainer = styled.div`
  border: 2px solid #ccc;
  padding: 10px;
`;

export const ContainerFiles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 16px;
  }
`;
