import React, { useState, useEffect, useCallback } from 'react';
import {
  Button as ButtonMaterial,
  Card,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Select,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  type Theme,
  Typography,
  createStyles,
  makeStyles,
  withStyles
} from '@material-ui/core';
import MuiAlert, { type Color } from '@material-ui/lab/Alert';
import { Link, useParams } from 'react-router-dom';
import SelectComponente from '@components/Select/SelectComponent';
import { api } from '@services/api';
import { CardHibrid } from '@components/CardHibrid';
import { Create, GetApp, Publish } from '@material-ui/icons';
import { PDF } from '@pages/Agendamentos/PDF/gerarPdf';
import { GuiaSingleExame } from '@pages/Agendamentos/PDF/guiaSingleExame';
import { type AxiosResponse } from 'axios';
import { LoadingPage } from '@components/LoadingPage';
import { getFileName, sendFileWithoutFake } from '@utils/masks';
import { ASO } from '@data/tipoAso';
import { type KitProps } from '@dtos/kitDTO';
import { Button } from '@components/Button';
import { type ScheduleProps } from '.';
import { NavLink } from '@components/NavLink';
import { AppError } from '@utils/AppError';
import { FichaClinica } from '@pages/Agendamentos/PDF/fichaClinica';
import { useQuery } from '@tanstack/react-query';
import { getDataScheduling } from '@api/get-data-schedule';

interface ISelectedExames {
  id_exame_ghe: number;
  cod_ghe: string;
  exame_credenciado: string;
  valor_credenciado: any;
}

export interface IColaborador {
  id_colaboradores: number;
  matricula_empresa: string;
  matricula_esocial: any;
  nome_completo: string;
  cpf_colaborador: string;
  rg: string;
  data_nascimento: string;
  genero: string;
  cnh: string;
  email: string;
  telefone: string;
  situacao: string;
  nis: string;
  funcao: string;
  setor: string;
  turno: string;
  cnpj: string;
  razao_social: string;
  data_admissao: string;
  horario_trab_entrada: string;
  horario_trab_saida: string;
  local_obra: string;
  cod_ghe: any;
  ghe: string;
}
interface INomeComercial {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  razao_social: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  telemedicina: string;
  observacao: string;
  tipo: string;
}

export interface ExamesHibrido {
  nome_comercial: string;
  data_agendamento: string;
  cpf_cnpj_comer: string;
  num_agenda: string;
  id_exame_hibrido: number;
  horario: string;
  ordem_chegada: string;
  linkDoatendimento: string;
  tipo_agendamento: string;
  exames: Exame[];
  tipo: string;
}

export interface Exame {
  exame_credenciado: string;
  valor_credenciado: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto'
    },
    cardHeader: {
      padding: theme.spacing(1, 2)
    },
    list: {
      width: '100%',
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto'
    },
    button: {
      margin: theme.spacing(0.5, 0)
    }
  })
);

const status = [
  { value: 'Solicitado' },
  { value: 'Confirmado' },
  { value: 'Reagendado' },
  { value: 'Atendido' },
  { value: 'Concluído' }
];

const ButtonUploadProntuario = withStyles(() => ({
  root: {
    background: '#e95779',
    color: '#FFF',
    border: 'none',
    width: 210,
    height: 40,
    '&:hover': {
      background: '#FFF',
      borderColor: '#e95779',
      color: '#e95779',
      border: 'solid 1px #e95779',
      width: 210,
      height: 40
    }
  }
}))(({ classes, ...props }: { classes: Record<string, string> }) => (
  <ButtonMaterial
    {...props}
    classes={classes}
    component="span"
    startIcon={<Publish />}
  />
));

const ButtonUploadAso = withStyles(() => ({
  root: {
    background: '#d46298',
    color: '#FFF',
    border: 'none',
    width: 210,
    height: 40,
    '&:hover': {
      background: '#FFF',
      borderColor: '#d46298',
      color: '#d46298',
      border: 'solid 1px #d46298',
      width: 210,
      height: 40
    }
  }
}))(({ classes, ...props }: { classes: Record<string, string> }) => (
  <ButtonMaterial
    {...props}
    classes={classes}
    component="span"
    startIcon={<Publish />}
  />
));

function not(a: ISelectedExames[], b: ISelectedExames[]) {
  return a.filter((value) => !b.includes(value));
}

function intersection(a: ISelectedExames[], b: ISelectedExames[]) {
  return a.filter((value) => b.includes(value));
}

function union(a: ISelectedExames[], b: ISelectedExames[]) {
  return [...a, ...not(b, a)];
}

const PAGE = 'HIBRIDO';
export type PropsAtendimento = 'telemedicina' | 'presencial';
export interface PropsReqPdfs {
  tipo: number;
  cpf_colaborador: string;
  cpf_cnpj_comer: string;
  num_agenda: string;
  index: number;
  tipoAtendimento: string;
}

export const EditAtendimentoHibrido = () => {
  const { id } = useParams<{ id: string }>();

  const classes = useStyles();
  const [isHideCredential, setIsHideCredential] = useState(false);

  const [listNomeComercial, setListNomeComercial] = useState<INomeComercial[]>(
    []
  );
  const [selectedNomeComercial, setSelectedNomeComercial] = useState('');
  const [cnpjComercial, setCnpjComercial] = useState('');
  const [emailAgendamento, setEmailAgendamento] = useState([]);

  const [statusAgendamento, setStatusAgendameto] = useState('');
  const [cpfColab, setCpfColab] = useState('');
  const [nomeColab, setNomeColab] = useState('');
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [dataAgendamento, setDataAgendamento] = useState('');
  const [horarioAgendamento, setHorarioAgendamento] = useState('');
  const [tipoAso, setTipoAso] = useState('');
  const [obs, setObs] = useState('');
  const [comboColab, setComboColab] = useState<IColaborador[]>([]);
  const [atendimento, setAtendimento] =
    useState<PropsAtendimento>('telemedicina');
  const [ordemChegada, setOrdemChegada] = useState('Sim');
  const [loading, setLoading] = useState(false);

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color>();
  const nome_user = localStorage.getItem('NameUser') || '';

  const [checked, setChecked] = React.useState<ISelectedExames[]>([]);
  const [left, setLeft] = React.useState<ISelectedExames[]>([]);
  const [right, setRight] = React.useState<ISelectedExames[]>([]);

  const [arquivoProntuario, setArquivoProntuario] = useState<File | string>('');
  const [arquivoSendProntuario, setArquivoSendProntuario] = useState<
    File | string
  >('');
  const [viewFileProntuario, setViewFileProntuario] = useState<
    object | string | null
  >('');

  const [arquivoASO, setArquivoASO] = useState<File | string>('');
  const [arquivoSendASO, setArquivoSendASO] = useState<File | string>('');
  const [viewFileASO, setViewFileASO] = useState<object | string | null>('');
  const [linkDoAtendimento, setLinkDoAtendimento] = useState('');

  const [examesHibrido, setExamesHibrido] = useState<ExamesHibrido[]>([]);

  const {
    data: getDataSchedule,
    error: errorDataSchedule,
    isFetching
  } = useQuery({
    queryKey: ['getSchedule'],
    queryFn: () => getDataScheduling(id)
  });

  async function sendAgendamento() {
    const hora = new Date();

    const newHora = hora.toLocaleString('pt-BR', { hour12: false });
    const data = {
      status: statusAgendamento,
      observacao: obs,
      tipo_aso: tipoAso,
      horario: horarioAgendamento,
      data_agendamento: dataAgendamento,
      estado,
      cidade,
      nome_comercial: getDataSchedule?.nome_comercial,
      tipo_agendamento: getDataSchedule?.tipo_agendamento,
      nome: nomeColab,
      cpf_colaborador: cpfColab,
      num_agenda: getDataSchedule?.num_agenda,
      usuario: `${nome_user} ${newHora}`,
      email_agendamento: emailAgendamento,
      cpf_cnpj_comer: getDataSchedule?.cpf_cnpj_comer,
      arquivo_aso: arquivoASO || getDataSchedule?.arquivo_aso,
      arquivo_prontuario:
        arquivoProntuario || getDataSchedule?.arquivo_prontuario,
      exames_hibrido: examesHibrido
    };
    //console.log(data);

    await api
      .post(`/ravim/atua-agendamento-hibrido`, data)
      .then((res) => {
        if (res.status === 200) {
          setCod(String(res.status));
          setSnack(true);
          setSeverity('success');
          setMessage(res.data.message);
          setTimeout(() => {
            window.location.replace('/agendamentos');
          }, 1000);
        } else {
          setCod(String(res.status));
          setSnack(true);
          setSeverity('error');
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        setCod(err.code);
        setSnack(true);
        setSeverity('error');
        setMessage(err.message);
      });

    const formData = new FormData();
    formData.append('arquivo1', arquivoSendProntuario);
    formData.append('arquivo2', arquivoSendASO);

    await api
      .post(`/file`, formData)
      .then((res: AxiosResponse) => {})
      .catch((err) => {});
  }

  async function reqStatus() {
    if (!getDataSchedule) return;
    const data = {
      tipo_agendamento: getDataSchedule.tipo_agendamento,
      tipo: atendimento
    };

    await api
      .post(`/ravim/telemed-clinicas`, data)
      .then((res) => {
        setListNomeComercial(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getExamesHibrido(cnpj: string) {
    const data = {
      cpf_colaborador: cpfColab,
      cpf_cnpj_comer: cnpj,
      tipo_aso: tipoAso,
      num_agenda: getDataSchedule?.num_agenda
    };
    try {
      const res = await api.post(`/ravim/exames-hibrido`, data);
      setExamesHibrido(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleAddExames(cnpj: INomeComercial) {
    const data = {
      cpf_colaborador: cpfColab,
      cpf_cnpj_comer: cnpj.cpf_cnpj,
      tipo_aso: tipoAso,
      num_agenda: ''
    };

    await api
      .post(`/ravim/exames-hibrido`, data)
      .then((res) => {
        setLeft(res.data);
        // console.log(res.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSelectedBusiness(newValue: any) {
    handleAddExames(newValue);
  }

  async function dataColaborador() {
    await api
      .get(`ravim/colaboradores`)
      .then((res) => {
        if (res.status === 200) {
          setComboColab(res.data);
        } else {
          setComboColab([]);
        }

        // console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const reqpdf = useCallback(async (dataPdf: PropsReqPdfs) => {
    const data = {
      cpf_colaborador: dataPdf.cpf_colaborador,
      cnpj_cpf_comer: dataPdf.cpf_cnpj_comer,
      num_agenda: dataPdf.num_agenda
    };
    try {
      const res = await api.post(`/ravim/pdf`, data);

      if (dataPdf.tipo === 1) {
        GuiaSingleExame(res.data, dataPdf.index, dataPdf.tipoAtendimento);
      } else {
        PDF(res.data, PAGE);
      }
    } catch (error) {
      if (error instanceof AppError) {
        setCod(String(error.code));
        setSnack(true);
        setSeverity('error');
        setMessage(error.message);
      }
    }
  }, []);

  async function downloadFile(arquivo: string) {
    const data = {
      arquivo
    };
    api
      .post(`/ravim/download`, data)
      .then((res) => {
        const url = res.data.dados;
        const link = document.createElement('a');
        link.href = url;
        link.download = arquivo.replace(/[_]/g, ' ');
        link.dispatchEvent(new MouseEvent('click'));
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  // Adicionar card de credenciado
  function handleAddCredential() {
    if (!getDataSchedule) return;
    const data: ExamesHibrido = {
      id_exame_hibrido: new Date().valueOf(),
      num_agenda: getDataSchedule.num_agenda,
      horario: horarioAgendamento,
      cpf_cnpj_comer: cnpjComercial,
      data_agendamento: dataAgendamento,
      nome_comercial: selectedNomeComercial || '',
      ordem_chegada: ordemChegada,
      tipo_agendamento: atendimento,
      exames: right,
      linkDoatendimento: linkDoAtendimento,
      tipo: listNomeComercial[0].tipo
    };

    setExamesHibrido((prevState) => [...prevState, data]);

    setLeft([]);
    setRight([]);
    setLinkDoAtendimento('');
    setDataAgendamento('');
    setHorarioAgendamento('');
    setCnpjComercial('');
    setCidade('');
    setEstado('');
  }

  // console.log(listNomeComercial)

  function handleDeleteCredential(id: number) {
    const newList = examesHibrido?.filter(
      (exame: any) => exame.id_exame_hibrido !== id
    );
    setExamesHibrido(newList);
  }

  function handleDisableFields() {
    if (ordemChegada === 'Sim' && atendimento !== 'telemedicina') {
      return true;
    } else {
      return false;
    }
  }

  function handleSelectCredential(text: PropsAtendimento) {
    if (text === 'telemedicina') {
      setRight([]);
      setLeft([]);
      setDataAgendamento('');
      setHorarioAgendamento('');
      setSelectedNomeComercial('');
      setAtendimento(text);
    } else if (text === 'presencial') {
      setRight([]);
      setLeft([]);
      setDataAgendamento('');
      setHorarioAgendamento('');
      setLinkDoAtendimento('');
      setOrdemChegada('Não');
      setSelectedNomeComercial('');
      setAtendimento(text);
    }
  }

  /* ================================================ */
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: ISelectedExames) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: ISelectedExames[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: ISelectedExames[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: React.ReactNode, items: ISelectedExames[]) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionado`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items &&
          items.map((value: ISelectedExames) => {
            const labelId = `transfer-list-all-item-${value.id_exame_ghe}-label`;

            return (
              <ListItem
                key={value.id_exame_ghe}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.includes(value)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${value.exame_credenciado}`}
                />
              </ListItem>
            );
          })}
        <ListItem />
      </List>
    </Card>
  );
  /* ============================ */

  const changeFileProntuario = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setArquivoProntuario(sendFileWithoutFake(event.target.value));
      setArquivoSendProntuario(event.target.files[0]);

      // console.log(event.target.files[0])

      setViewFileProntuario(URL.createObjectURL(event.target.files[0]));
    } else {
      console.log('cancelado');
    }
  };

  const changeFileASO = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setArquivoASO(sendFileWithoutFake(event.target.value));
      setArquivoSendASO(file);

      // console.log(event.target.files[0])

      setViewFileASO(URL.createObjectURL(event.target.files[0]));
    } else {
      console.log('cancelado');
    }
  };

  useEffect(() => {
    if (!getDataSchedule) return;
    const getDataColaborador = new Promise((resolve, reject) => {
      setLoading(true);
      setTimeout(() => {
        dataColaborador();
        resolve(10);
      }, 1 * 1000);
    });
    const getReqExamesHibrido = new Promise((resolve, reject) => {
      setTimeout(() => {
        getExamesHibrido(getDataSchedule.cpf_cnpj_comer);
        resolve(20);
      }, 2 * 1000);
    });

    Promise.all([getDataColaborador, getReqExamesHibrido]);
  }, [isFetching]);

  useEffect(() => {
    const getStatus = new Promise((resolve, reject) => {
      setTimeout(() => {
        reqStatus();
        setLoading(false);
        resolve(30);
      }, 1 * 100);
    });
    Promise.all([getStatus]);
  }, [atendimento]);

  useEffect(() => {
    if (statusAgendamento === '' && cpfColab === '' && tipoAso === '') {
      setIsHideCredential(true);
    } else {
      setIsHideCredential(false);
    }
  }, [statusAgendamento, cpfColab, tipoAso]);

  useEffect(() => {
    setStatusAgendameto(getDataSchedule?.status ?? '');
    setCpfColab(getDataSchedule?.cpf_colaborador ?? '');
    setNomeColab(getDataSchedule?.nome ?? '');
    setDataAgendamento(getDataSchedule?.data_agendamento ?? '');
    setHorarioAgendamento(getDataSchedule?.horario ?? '');
    setTipoAso(getDataSchedule?.tipo_aso ?? '');
    setObs(getDataSchedule?.observacao ?? '');
    setEstado(getDataSchedule?.estado ?? '');
    setCidade(getDataSchedule?.cidade ?? '');
  }, [isFetching]);

  if (!getDataSchedule) return;

  return (
    <Container>
      <LoadingPage open={isFetching} onClick={() => {}} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 20,
          gap: 20
        }}
      >
        {getDataSchedule.arquivo_aso !== 'None' &&
        getDataSchedule.arquivo_aso !== null &&
        !!getDataSchedule.arquivo_aso ? (
          <Button
            title="Download ASO"
            icon={<GetApp />}
            onClick={async () => {
              await downloadFile(
                getFileName(String(getDataSchedule.arquivo_aso))
              );
            }}
            variantstyle="DARK_PINK"
          />
        ) : (
          <></>
        )}
        {getDataSchedule.arquivo_prontuario !== 'None' &&
        getDataSchedule.arquivo_prontuario !== null &&
        !!getDataSchedule.arquivo_prontuario ? (
          <Button
            title="Download Prontuário"
            icon={<GetApp />}
            onClick={async () => {
              await downloadFile(
                getFileName(String(getDataSchedule.arquivo_prontuario))
              );
            }}
            variantstyle="LIGHT_PINK"
          />
        ) : (
          <></>
        )}

        <Button
          title="Download KIT"
          icon={<GetApp style={{ fontSize: 20 }} />}
          onClick={() => {
            reqpdf({
              tipo: 2,
              cpf_colaborador: getDataSchedule.cpf_colaborador,
              cpf_cnpj_comer: getDataSchedule.cpf_cnpj_comer,
              num_agenda: getDataSchedule.num_agenda,
              index: 0,
              tipoAtendimento: atendimento
            });
          }}
          variantstyle="PURPLE"
        />
      </div>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Editar Agendamento Híbrido
      </Typography>
      <Paper style={{ padding: 40 }}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Upload
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <input
                id="arquivoProntuario"
                name="fileProntuario"
                type="file"
                onChange={changeFileProntuario}
                style={{ display: 'none' }}
              />
              <label htmlFor="arquivoProntuario">
                <ButtonUploadProntuario>
                  Upload - Prontuário
                </ButtonUploadProntuario>
                <Typography>{String(arquivoProntuario)}</Typography>
              </label>
              <input
                id="arquivoASO"
                name="fileASO"
                type="file"
                onChange={changeFileASO}
                style={{ display: 'none' }}
              />
              <label htmlFor="arquivoASO">
                <ButtonUploadAso>Upload - ASO</ButtonUploadAso>

                <Typography>{String(arquivoASO)}</Typography>
              </label>
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Status
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl variant="outlined" style={{ width: '20%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  value={statusAgendamento}
                  onChange={(e: any) => {
                    setStatusAgendameto(e.target.value);
                  }}
                >
                  {status &&
                    status.map((status, index) => (
                      <MenuItem key={index} value={status.value}>
                        {status.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Colaborador
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                disabled
                label="CPF"
                variant="outlined"
                fullWidth
                value={cpfColab}
                onChange={(e) => {
                  setNomeColab(e.target.value);
                }}
              />
              <TextField
                disabled
                label="Nome"
                variant="outlined"
                fullWidth
                value={nomeColab}
                onChange={(e) => {
                  setNomeColab(e.target.value);
                }}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl variant="outlined" style={{ width: '20%' }}>
                <InputLabel>Tipo de ASO</InputLabel>
                <Select
                  label="Tipo de ASO"
                  value={tipoAso}
                  onChange={(e: any) => {
                    setTipoAso(e.target.value);
                  }}
                >
                  {ASO &&
                    ASO.map((aso, index) => (
                      <MenuItem key={index} value={aso.value}>
                        {aso.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                minRows={4}
                label="OBS"
                multiline
                variant="outlined"
                value={obs}
                onChange={(e) => {
                  setObs(e.target.value);
                }}
                fullWidth
              />
            </div>
            <Button
              icon={<Create />}
              onClick={() => {
                setIsHideCredential(!isHideCredential);
              }}
              variantstyle="SECUNDARY"
            />

            {isHideCredential ? (
              <>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', margin: 10 }}
                >
                  Credenciado
                </Typography>
                <div style={{ display: 'flex', gap: 20, margin: 15 }}>
                  <RadioGroup
                    defaultValue="Telemedicina"
                    style={{ flexDirection: 'row' }}
                    value={atendimento}
                    onChange={(e) => {
                      handleSelectCredential(
                        e.target.value as PropsAtendimento
                      );
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value="telemedicina"
                      label="Telemedicina"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value="presencial"
                      label="Presencial"
                    />
                  </RadioGroup>
                </div>

                <div style={{ display: 'flex', gap: 20, margin: 15 }}>
                  <SelectComponente
                    options={listNomeComercial}
                    onChange={(newValue) => {
                      setSelectedNomeComercial(newValue.nome_comercial);
                      setCnpjComercial(newValue.cpf_cnpj);
                      setCidade(newValue.estado);
                      setEstado(newValue.cidade);
                      handleSelectedBusiness(newValue);
                    }}
                    value={atendimento}
                    // defaultValue={selectedNomeComercial}
                  />
                </div>

                <div style={{ display: 'flex', gap: 20, margin: 15 }}>
                  <TextField
                    label="Data do agendamento"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={dataAgendamento}
                    onChange={(e) => {
                      setDataAgendamento(e.target.value);
                    }}
                  />
                  <TextField
                    label="Horário"
                    type="time"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={horarioAgendamento}
                    onChange={(e) => {
                      setHorarioAgendamento(e.target.value);
                    }}
                    disabled={handleDisableFields()}
                  />
                  {atendimento === 'presencial' ? (
                    <></>
                  ) : (
                    <TextField
                      label="Link do atendimento"
                      variant="outlined"
                      value={linkDoAtendimento}
                      onChange={(value) => {
                        setLinkDoAtendimento(value.target.value);
                      }}
                    />
                  )}

                  {atendimento !== 'telemedicina' ? (
                    <FormControl>
                      <Typography>Atendimento por ordem de chegada</Typography>
                      <RadioGroup
                        defaultValue="Sim"
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                        value={ordemChegada}
                        onChange={(e) => {
                          setOrdemChegada(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="Sim"
                          label="Sim"
                          style={{ marginRight: 50 }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="Não"
                          label="Não"
                        />
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.root}
                  >
                    <Grid item>{customList('Exames carregados', left)}</Grid>
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <ButtonMaterial
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleCheckedRight}
                          disabled={leftChecked.length === 0}
                          aria-label="move selected right"
                        >
                          &gt;
                        </ButtonMaterial>
                        <ButtonMaterial
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleCheckedLeft}
                          disabled={rightChecked.length === 0}
                          aria-label="move selected left"
                        >
                          &lt;
                        </ButtonMaterial>
                      </Grid>
                    </Grid>
                    <Grid item>{customList('Exames Selecionados', right)}</Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 20
                  }}
                >
                  <Button
                    onClick={handleAddCredential}
                    title="Adicionar no agendamento"
                    variantstyle={right.length <= 0 ? 'DEFAULT' : 'PRIMARY'}
                    disabled={right.length <= 0}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Paper>

      <CardHibrid
        onDelete={handleDeleteCredential}
        data={examesHibrido}
        onGuia={async (e) => {
          await reqpdf({
            tipo: 1,
            cpf_colaborador: getDataSchedule.cpf_colaborador,
            cpf_cnpj_comer: getDataSchedule.cpf_cnpj_comer,
            num_agenda: getDataSchedule.num_agenda,
            index: e,
            tipoAtendimento: examesHibrido[e].tipo_agendamento
          });
        }}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          marginTop: 20
        }}
      >
        <NavLink to="/agendamentos">
          <Button title="Sair" variantstyle="RED" />
        </NavLink>

        <Button title="Cadastrar" onClick={sendAgendamento} />
      </div>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
