import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface IInfos {
  infoEmpNome: string;
  infoEmpContrato: string | null;
  infoEmpEmails: any;
  infoEmpTelefone: string[];
  infoCnpjNumeroCnpj: string;
  infoCnpjRazaoSocial: string;
  infoCnpjNomeFantasia: string;
  infoCnpjCnae: string | undefined;
  infoCnpjCnaeSecundario: string;
  endComeRuaNumero: string;
  endComeCep: string;
  endComplemento: string;
  endComeBairro: string;
  endComeEstado: string;
  endComeCidade: string;
  endCobNumero: string;
  endCobCep: string;
  endCobComplemento: string;
  endCobBairro: string;
  endCobEstado: string;
  endCobCidade: string;
}

const initialState: IInfos = {
  infoEmpNome: '',
  infoEmpContrato: '',
  infoEmpEmails: [],
  infoEmpTelefone: [],
  infoCnpjNumeroCnpj: '',
  infoCnpjRazaoSocial: '',
  infoCnpjNomeFantasia: '',
  infoCnpjCnae: '',
  infoCnpjCnaeSecundario: '',
  endComeRuaNumero: '',
  endComeCep: '',
  endComplemento: '',
  endComeBairro: '',
  endComeEstado: '',
  endComeCidade: '',
  endCobNumero: '',
  endCobCep: '',
  endCobComplemento: '',
  endCobBairro: '',
  endCobEstado: '',
  endCobCidade: ''
};

export const cadEmpRh = createSlice({
  name: 'cadempresarh',
  initialState,
  reducers: {
    infoEmpNome: (state, actions) => ({
      ...state,
      infoEmpNome: actions.payload
    }),
    infoEmpContrato: (state, actions) => ({
      ...state,
      infoEmpContrato: actions.payload
    }),
    infoEmpEmails: (state, actions: PayloadAction<any>) => ({
      ...state,
      infoEmpEmails: actions.payload
    }),
    infoEmpTelefone: (state, actions) => ({
      ...state,
      infoEmpTelefone: actions.payload
    }),
    infoCnpjNumeroCnpj: (state, actions) => ({
      ...state,
      infoCnpjNumeroCnpj: actions.payload
    }),
    infoCnpjRazaoSocial: (state, actions) => ({
      ...state,
      infoCnpjRazaoSocial: actions.payload
    }),
    infoCnpjNomeFantasia: (state, actions) => ({
      ...state,
      infoCnpjNomeFantasia: actions.payload
    }),
    infoCnpjCnae: (state, actions) => ({
      ...state,
      infoCnpjCnae: actions.payload
    }),
    infoCnpjCnaeSecundario: (state, actions) => ({
      ...state,
      infoCnpjCnaeSecundario: actions.payload
    })
  }
});

export const {
  infoEmpNome,
  infoEmpContrato,
  infoCnpjNumeroCnpj,
  infoEmpEmails,
  infoEmpTelefone,
  infoCnpjRazaoSocial,
  infoCnpjNomeFantasia,
  infoCnpjCnae,
  infoCnpjCnaeSecundario
} = cadEmpRh.actions;
export default cadEmpRh.reducer;
