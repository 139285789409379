import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
  withStyles,
  Radio,
  Chip
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../../services/api';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import { Autocomplete } from '@material-ui/lab';
import {
  cpfValidation,
  maskCEP,
  maskCNPJ,
  maskCPF,
  maskPhone,
  validaCNPJ
} from '../../../utils/masks';
import { estados } from '@data/estados';

interface ICep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface ICnpj {
  id_cad_cont_cred: number;
  nome_contrato: string;
  cod_contrato: string;
  data_criacao: string;
  data_renovacao: string;
  arquivo: string;
  tipo: string;
}

const ButtonAdd = withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B'
    }
  }
}))(Button);
const ButtonSair = withStyles(() => ({
  root: {
    background: 'red',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'red',
      color: 'red',
      border: 'solid 1px red'
    }
  }
}))(Button);

interface ITelefone {
  id: string;
  telefone: string;
}

interface IEmails {
  id?: string;
  email: string;
}

export const CadastrarCredenciado = () => {
  const [nome, setNome] = useState('');
  const [cpfOuCnpj, setCpfOuCnpj] = useState('');
  const [emailAgendamento, setEmailAgendamento] = useState<any>([]);
  const [telAgendamento, setTelAgendamento] = useState<string[]>([]);
  const [emailFinanceiro, setEmailFinanceiro] = useState<string[]>([]);
  const [telFinanceiro, setTelFinanceiro] = useState<string[]>([]);
  const [emailComercial, setEmailComercial] = useState<string[]>([]);
  const [telComercial, setTelComercial] = useState<string[]>([]);
  const [diasFuncinamento, setDiasFuncionamento] = useState('');
  const [crm, setCrm] = useState('');
  const [trabalhoAgendado, setTrabalhoAgendado] = useState<string>('');
  const [pagAdiantado, setPagAdiantado] = useState<string>('');
  const [obs, setObs] = useState<string>('');
  const [telemedicina, setTelemedicina] = useState<string>('');

  const [estadoCrm, setEstadoCrm] = useState<any>();
  const [cnpj, setCnpj] = useState('');
  const [razao, setRazao] = useState<string>('');
  const [nomeComercial, setNomeComercial] = useState<string>('');
  const [contratos, setContratos] = useState<ICnpj[]>([]);
  const [selectedContratos, setSelectedContratos] = useState<any>();
  const [atendimentoToxicologico, setAtendimentoToxicologico] = useState('');

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState();
  const [cod, setCod] = useState(0);
  const [severity, setSeverity] = useState<Color>();

  const [cep, setCep] = useState<ICep>();
  const [cepSearch, setCepSearch] = useState<string>('');
  const [rua, setRua] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [complemento, setComplemento] = useState<string>('');
  const [bairro, setBairro] = useState<string>('');
  const [estado, setEstado] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');
  const [telefones, setTelefones] = useState<ITelefone[]>([]);
  const [email, setEmail] = useState<IEmails[]>([]);
  const [validationCpfOrCnpj, setValidationCpfOrCnpj] =
    useState<boolean>(false);

  // console.log(trabalhoAgendado)

  useEffect(() => {
    if (cepSearch.length === 9) {
      searchCep();
    }
    cnpjConsulta();
  }, [cepSearch]);

  async function searchCep() {
    const cep = cepSearch.replace('-', '');
    await api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        if (res.data) {
          setCep(res.data);
          setRua(res.data.logradouro);
          setBairro(res.data.bairro);
          setEstado(res.data.uf);
          setCidade(res.data.localidade);
        } else {
          setRua('');
          setBairro('');
          setEstado('');
          setCidade('');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function cadCredenciado() {
    const data = {
      num_cnpj_cpf: cpfOuCnpj,
      nome_comercial: nomeComercial.trim(),
      email_agendamento: emailAgendamento,
      email_financeiro: emailFinanceiro,
      email_comercial: emailComercial,
      tel_agendamento: telAgendamento,
      tel_comercial: telComercial,
      tel_financeiro: telFinanceiro,
      dias_funcionamento: diasFuncinamento.trim(),
      contrato: selectedContratos,
      crm_estado: estadoCrm,
      crm: crm.trim(),
      endComeRua: rua,
      endComeNumero: numero,
      endComeCep: cepSearch,
      endComplemento: complemento,
      endComeBairro: bairro,
      endComeCidade: cidade,
      endComeEstado: estado,
      nome_medico: nome.trim(),
      trab_agendamento: trabalhoAgendado,
      pagamento_adiantado: pagAdiantado,
      observacao: obs,
      razao_social: razao.trim(),
      telemedicina,
      toxicologico: atendimentoToxicologico
    };

    api
      .post(`ravim/clinicas`, data)
      .then((res) => {
        if (res.status === 200) {
          setCod(res.status);
          setSnack(true);
          setSeverity('success');
          setMessage(res.data.message);
          setTimeout(() => {
            window.location.replace('/credenciados');
          }, 3000);
        } else {
          setCod(res.status);
          setSnack(true);
          setSeverity('error');
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        setCod(err.code);
        setSnack(true);
        setSeverity('error');
        setMessage(err.message);
      });
  }

  async function cnpjConsulta() {
    await api
      .get(`/ravim/contratos-credenciados`)
      .then((res) => {
        if (res.status === 200) {
          setContratos(res.data);
        } else {
          alert('Erro ao carregar contratos');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function selectCpfOrCnpj(cpfOrCnpj: string) {
    if (cpfOrCnpj.length <= 11) {
      setCpfOuCnpj(maskCPF(cpfOrCnpj));
      if (cpfValidation(cpfOrCnpj)) {
        setValidationCpfOrCnpj(false);
      } else {
        setValidationCpfOrCnpj(true);
      }
    } else {
      setCpfOuCnpj(maskCNPJ(cpfOrCnpj));
      if (validaCNPJ(cpfOrCnpj)) {
        setValidationCpfOrCnpj(false);
      } else {
        setValidationCpfOrCnpj(true);
      }
    }
  }

  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Cadastrar Credenciado
      </Typography>
      <Paper style={{ padding: 40 }}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Credenciado
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <Autocomplete
                style={{ width: '50%' }}
                id="tags-outlined"
                options={contratos}
                getOptionLabel={(option: ICnpj) =>
                  `${option.cod_contrato}`
                    ? `${option.cod_contrato} - ${option.nome_contrato}`
                    : ''
                }
                onChange={(e, newValue: any) =>
                  { setSelectedContratos(newValue.cod_contrato); }
                }
                value={selectedContratos}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Contratos"
                    placeholder="Contratos"
                  />
                )}
              />
              <TextField
                label="Razão Social"
                variant="outlined"
                value={razao}
                onChange={(e) => { setRazao(e.target.value); }}
                fullWidth
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Nome Comercial"
                variant="outlined"
                value={nomeComercial}
                onChange={(e) => { setNomeComercial(e.target.value); }}
                fullWidth
              />
              <TextField
                error={validationCpfOrCnpj}
                label="CPF/CNPJ"
                variant="outlined"
                value={cpfOuCnpj}
                onChange={(e) => { selectCpfOrCnpj(e.target.value); }}
                fullWidth
                inputProps={{ maxLength: 16 }}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <Autocomplete
                value={emailAgendamento}
                onChange={(event, newValue) => {
                  setEmailAgendamento(newValue);
                }}
                multiple
                fullWidth
                id="tags-filled"
                options={email.map((option) => {
                  console.log(option);
                })}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="E-mails de Agendamento"
                    placeholder="Aperte enter para adicionar o e-mail"
                  />
                )}
              />
              <Autocomplete
                value={telAgendamento}
                onChange={(event, newValue) => {
                  setTelAgendamento(newValue.map((e) => maskPhone(e)));
                }}
                multiple
                fullWidth
                id="tags-filled"
                options={telefones.map((option) => option.telefone)}
                freeSolo
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Telefones Agendamento"
                    placeholder="Aperte enter para adicionar o telefone"
                  />
                )}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <Autocomplete
                value={emailFinanceiro}
                onChange={(event, newValue) => {
                  setEmailFinanceiro(newValue);
                }}
                multiple
                fullWidth
                id="tags-filled"
                options={telefones.map((option) => option.telefone)}
                freeSolo
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="E-mail Financeiro"
                    placeholder="Aperte enter para salvar o e-mail"
                  />
                )}
              />

              <Autocomplete
                value={telFinanceiro}
                onChange={(event, newValue) => {
                  setTelFinanceiro(newValue.map((e) => maskPhone(e)));
                }}
                multiple
                fullWidth
                id="tags-filled"
                options={telefones.map((option) => option.telefone)}
                freeSolo
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Telefones Financeiro"
                    placeholder="Aperte enter para salvar o telefone"
                  />
                )}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <Autocomplete
                value={emailComercial}
                onChange={(event, newValue) => {
                  setEmailComercial(newValue);
                }}
                multiple
                fullWidth
                id="tags-filled"
                options={telefones.map((option) => option.telefone)}
                freeSolo
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="E-mail Comercial"
                    placeholder="Aperte enter para salvar o e-mail"
                  />
                )}
              />
              <Autocomplete
                value={telComercial}
                onChange={(event, newValue) => {
                  setTelComercial(newValue.map((e) => maskPhone(e)));
                }}
                multiple
                fullWidth
                id="tags-filled"
                options={telefones.map((option) => option.telefone)}
                freeSolo
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Telefones Financeiro"
                    placeholder="Aperte enter para salvar o telefone"
                  />
                )}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Dias de funcionamento"
                variant="outlined"
                value={diasFuncinamento}
                onChange={(e) => { setDiasFuncionamento(e.target.value); }}
                fullWidth
              />
            </div>

            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Endereço
            </Typography>
            <div className="containerFields">
              <TextField
                fullWidth
                variant="outlined"
                value={cepSearch}
                onChange={(e: any) => { setCepSearch(maskCEP(e.target.value)); }}
                label="CEP"
                inputProps={{ maxLength: 8 }}
              />
              <TextField
                fullWidth
                variant="outlined"
                value={rua}
                onChange={(e: any) => { setRua(e.target.value); }}
                label="Rua"
              />
              <TextField
                fullWidth
                variant="outlined"
                value={numero}
                onChange={(e: any) => { setNumero(e.target.value); }}
                label="Número"
              />
              <TextField
                fullWidth
                variant="outlined"
                value={complemento}
                onChange={(e: any) => { setComplemento(e.target.value); }}
                label="Complemento"
              />
            </div>
            <div className="containerFields">
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e: any) => { setBairro(e.target.value); }}
                value={bairro}
                label="Bairro"
              />
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e: any) => { setEstado(e.target.value); }}
                value={estado}
                label="Estado"
              />
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e: any) => { setCidade(e.target.value); }}
                value={cidade}
                label="Cidade"
              />
            </div>
            <Typography variant="h5" style={{ margin: 10, fontWeight: 'bold' }}>
              Dados do Médico resposável
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Nome"
                variant="outlined"
                value={nome}
                onChange={(e) => { setNome(e.target.value); }}
                fullWidth
              />
              <TextField
                label="CRM"
                variant="outlined"
                value={crm}
                onChange={(e) => { setCrm(e.target.value); }}
                fullWidth
              />
              {/* <TextField label="Estado" variant='outlined' value={estadoCrm} onChange={(e) => setEstadoCrm(e.target.value)} fullWidth /> */}
              <Autocomplete
                fullWidth
                id="tags-outlined"
                options={estados}
                getOptionLabel={(option: any) =>
                  `${option.estado}` ? `${option.estado}` : ''
                }
                onChange={(e, newValue: any) => { setEstadoCrm(newValue.estado); }}
                value={estadoCrm}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Estado"
                    placeholder="Estado"
                  />
                )}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl component={Paper} style={{ padding: 20 }}>
                <FormGroup>Trabalha com Agendamento</FormGroup>
                <RadioGroup
                  defaultValue="Sim"
                  style={{ flexDirection: 'column' }}
                  value={trabalhoAgendado}
                  onChange={(e) => { setTrabalhoAgendado(e.target.value); }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value="Sim"
                    label="Sim"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="Não"
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl component={Paper} style={{ padding: 20 }}>
                <FormGroup>Pagamento adiantado?</FormGroup>
                <RadioGroup
                  defaultValue="Sim"
                  value={pagAdiantado}
                  onChange={(e) => { setPagAdiantado(e.target.value); }}
                  style={{ flexDirection: 'column' }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value="Sim"
                    label="Sim"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="Não"
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl component={Paper} style={{ padding: 20 }}>
                <FormGroup>Atendimento por telemedicina?</FormGroup>
                <RadioGroup
                  defaultValue="Sim"
                  value={telemedicina}
                  onChange={(e) => { setTelemedicina(e.target.value); }}
                  style={{ flexDirection: 'column' }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value="Sim"
                    label="Sim"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="Não"
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl component={Paper} style={{ padding: 20 }}>
                <FormGroup>Atendimento toxicológico?</FormGroup>
                <RadioGroup
                  defaultValue="Sim"
                  value={atendimentoToxicologico}
                  onChange={(e) => { setAtendimentoToxicologico(e.target.value); }}
                  style={{ flexDirection: 'column' }}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value="Sim"
                    label="Sim"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="Não"
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ margin: 15 }}>
              <TextField
                minRows={4}
                label="OBS"
                multiline
                variant="outlined"
                value={obs}
                onChange={(e) => { setObs(e.target.value); }}
                fullWidth
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          marginTop: 20
        }}
      >
        <Link to={'/credenciados'} style={{ textDecoration: 'none' }}>
          <ButtonSair variant="outlined">Sair</ButtonSair>
        </Link>
        <ButtonAdd
          disabled={validationCpfOrCnpj}
          variant="outlined"
          onClick={cadCredenciado}
        >
          Cadastrar
        </ButtonAdd>
      </div>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => { setSnack(!snack); }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
