import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../../services/api';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import { cpfValidation, maskCEP, maskCPF, maskDate, maskDateField, maskRG, sendDateToDb } from '../../../utils/masks';
import { LoadingPage } from '@components/LoadingPage';
import { gen, situ, tur } from '@data/colab';
import { AxiosError } from 'axios';
import { Button } from '@components/Button';
import { NavLink } from '@components/NavLink';

interface ICep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface IColaborador {
  location: {
    state: {
      cnh: string;
      cnpj: string;
      cod_ghe: string;
      cpf_colaborador: string;
      data_admissao: string;
      email: string;
      telefone: string;
      data_nascimento: string;
      funcao: string;
      genero: string;
      ghe: string;
      horario_trab_entrada: string;
      horario_trab_saida: string;
      id_colaboradores: number;
      local_obra: string;
      local_trabalho: string;
      matricula_empresa: string;
      matricula_esocial: string;
      nis: string;
      nome_completo: string;
      razao_social: string;
      rg: string;
      setor: string;
      situacao: string;
      turno: string;
    };
  };
}

interface IGhe {
  cod_ghe: string;
  cod_obra: string;
  id_cad_ghe: number;
  nome_ghe: string;
}

interface ICnpj {
  bairro: string;
  cep: string;
  cidade: string;
  cnae: string;
  cnae_secundario: string;
  complemento: string;
  estado: string;
  idcad_empresa: number;
  inf_contrato: string;
  nome_empresa: string;
  nome_fantacia: string;
  num_cnpj: string;
  numero: number | null;
  razao_social: string;
  rua: string;
}

export const EditarColaborador: React.FC<IColaborador> = (props) => {
  const [matriculaEmpresa, setMatriculaEmpresa] = useState('');
  const [matriculaEsocial, setMatriculaEsocial] = useState('');
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [cnh, setCnh] = useState('');
  const [pis, setPis] = useState('');
  const [funcao, setFuncao] = useState('');
  const [genero, setGenero] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [dataAdmissao, setDataAdmissao] = useState('');
  const [hTrabalhoEntrada, setHtrabalhoEntrada] = useState('');
  const [hTrabalhoSaida, setHtrabalhoSaida] = useState('');
  const [empresas, setEmpresas] = useState('');
  const [turno, setTurno] = useState('');
  const [situacao, setSituacao] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [razao, setRazao] = useState('');
  const [setor, setSetor] = useState('');
  const [localTrabalho, setLocalTrabalho] = useState('');
  const [localObraCombo, setLocalObraCombo] = useState<any>([]);
  const [localObra, setLocalObra] = useState<string>('');
  const [ghe, setGhe] = useState<IGhe[]>([]);
  const [codGhe, setCodGhe] = useState<string>('');
  const [contratos, setContratos] = useState<ICnpj[]>([]);
  const [codigo, setCodigo] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const [cep, setCep] = useState<ICep>();
  const [cepSearch, setCepSearch] = useState<string>('');
  const [rua, setRua] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [complemento, setComplemento] = useState<string>('');
  const [bairro, setBairro] = useState<string>('');
  const [estado, setEstado] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');

  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color | undefined>();

  // console.log(props.location.state)

  function loadingData() {
    setMatriculaEmpresa(props.location.state.matricula_empresa);
    setMatriculaEsocial(props.location.state.matricula_esocial);
    setNome(props.location.state.nome_completo);
    setCpf(props.location.state.cpf_colaborador);
    setRg(props.location.state.rg);
    setCnh(props.location.state.cnh);
    setPis(props.location.state.nis);
    setFuncao(props.location.state.funcao);
    setGenero(props.location.state.genero);
    setEmail(props.location.state.email);
    setTelefone(props.location.state.telefone);
    setNascimento(maskDate(props.location.state.data_nascimento));
    setDataAdmissao(maskDate(props.location.state.data_admissao));
    setHtrabalhoEntrada(props.location.state.horario_trab_entrada);
    setHtrabalhoSaida(props.location.state.horario_trab_saida);
    // setEmpresas(props.location.state);
    setTurno(props.location.state.turno);
    setSituacao(props.location.state.situacao);
    setCnpj(props.location.state.cnpj);
    setRazao(props.location.state.razao_social);
    setSetor(props.location.state.setor);
    setLocalTrabalho(props.location.state.local_trabalho);
    setLocalObra(props.location.state.local_obra);
    setCodGhe(props.location.state.cod_ghe);
    setCodigo(props.location.state.ghe.split(' / ')[0]);

    fetchLocalObra(props.location.state.cnpj);
  }

  async function searchCep() {
    const cep = cepSearch.replace('-', '');
    await api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        if (res.data) {
          setCep(res.data);
          setRua(res.data.logradouro);
          setBairro(res.data.bairro);
          setEstado(res.data.uf);
          setCidade(res.data.localidade);
        } else {
          setRua('');
          setBairro('');
          setEstado('');
          setCidade('');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function colaborador() {
    const data = {
      nome_completo: nome,
      cpf,
      rg,
      email,
      telefone,
      data_nascimento: sendDateToDb(nascimento),
      genero,
      cnh,
      situacao,
      nis: pis,
      funcao,
      setor,
      turno,
      data_admissao: sendDateToDb(dataAdmissao),
      num_cnpj: cnpj,
      razao_social: razao,
      horario_trab_entrada: hTrabalhoEntrada,
      horario_trab_saida: hTrabalhoSaida,
      local_obra: localObra,
      cod_ghe: codGhe,
      ghe: codGhe,
      rua,
      numero,
      cep: cepSearch,
      complemento,
      bairro,
      cidade,
      estado,
      matricula_empresa: matriculaEmpresa,
      matricula_esocial: matriculaEsocial
    };
    // console.log(data)
    try {
      setIsLoadingSave(true);
      const res = await api.post(`ravim/update-colaborador`, data);
      if (res.status === 200) {
        setCod(String(res.status));
        setSnack(true);
        setSeverity('success');
        setMessage(res.data.message);
        setTimeout(() => {
          window.location.replace('/colaboradores');
        }, 3000);
      } else {
        setCod(String(res.status));
        setSnack(true);
        setSeverity('error');
        setMessage(res.data.message);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setCod(String(error.code));
        setSnack(true);
        setSeverity('error');
        setMessage(error.message);
      }
    } finally {
      setIsLoadingSave(false);
    }
  }

  async function dataEditColaborador() {
    try {
      const res = await api.post(`ravim/colaboradores`, {
        cpf: props.location.state.cpf_colaborador
      });
      JSON.parse(res.data.endereco).map((end: any) => {
        setCepSearch(end.cep);
        setNumero(end.numero);
        setComplemento(end.complemento);
      });
    } catch (error) {
      if (error instanceof AxiosError) console.log(error.message);
    }
  }

  async function cnpjConsulta() {
    try {
      const response = await api.get(`ravim/empresas-rh`);
      setContratos(response.data);
      if (response.data.estado === 200) {
        setSnack(true);
        setMessage(response.data.message);
        setSeverity('success');
        setTimeout(() => {
          window.location.replace('/colaboradores');
        }, 2000);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error);
        setSeverity('error');
        setSnack(true);
        setMessage(error.message);
      }
    }
  }

  async function fetchLocalObra(cnpjSelected: string) {
    try {
      const response = await api.post(`ravim/empresas-rh`, {
        num_cnpj: cnpjSelected
      });
      setLocalObraCombo(JSON.parse(response.data.local_obra));
      JSON.parse(response.data.local_obra).map((local: any) => {
        setLocalTrabalho(local?.bairro);
        setLocalTrabalho(local?.cod_local);
        fetchGheColaborador(local.cod_local);
      });
      JSON.parse(response.data.empresa).map((empresa: any) => {
        setRazao(empresa?.razao_social);
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error);
      }
    }
  }

  async function fetchGheColaborador(cod_obra: string) {
    const data = {
      cod_obra
    };
    api
      .post(`ravim/colab-ghe`, data)
      .then((res) => {
        // console.log(JSON.parse(res.data?.dados))
        if (res.status === 404) {
          return;
        }
        // console.log(localObra.replace(/\s/g, ''))
        setGhe(JSON.parse(res.data?.dados));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const gheValues = (e: any) => {
    setCodGhe(e.target.value);
    setCodigo(e.target.value);
  };

  function handleLoading() {
    setLoading(loading);
  }

  useEffect(() => {
    if (cepSearch.length === 9) {
      searchCep();
    }
  }, [cepSearch]);

  useEffect(() => {
    loadingData();

    const getEditColaborador = new Promise((resolve, reject) => {
      setTimeout(() => {
        dataEditColaborador();
        resolve(20);
      }, 2 * 1000);
    });

    const getCnpjConsulta = new Promise((resolve, reject) => {
      setTimeout(() => {
        cnpjConsulta();
        setLoading(false);
        resolve(30);
      }, 3 * 1000);
    });

    setRazao('');
    setLocalTrabalho('');

    Promise.all([getEditColaborador, getCnpjConsulta]);
  }, []);

  return (
    <Container>
      <LoadingPage open={loading} onClick={handleLoading} />
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Editar Dados do Colaborador
      </Typography>
      <Paper style={{ padding: 40 }}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Colaborador
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Matrícula Empresa"
                variant="outlined"
                value={matriculaEmpresa}
                onChange={(e) => {
                  setMatriculaEmpresa(e.target.value);
                }}
              />
              <TextField
                label="Matrícula E-social"
                variant="outlined"
                value={matriculaEsocial}
                onChange={(e) => {
                  setMatriculaEsocial(e.target.value);
                }}
              />
              <TextField
                label="Nome"
                variant="outlined"
                value={nome}
                onChange={(e) => {
                  setNome(e.target.value);
                }}
                style={{ width: '80%' }}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                disabled
                label="CPF"
                variant="outlined"
                value={cpf}
                onChange={(e) => {
                  setCpf(maskCPF(e.target.value));
                }}
                fullWidth
                inputProps={{ maxLength: 14 }}
              />
              <TextField
                label="RG"
                variant="outlined"
                value={rg}
                onChange={(e) => {
                  setRg(e.target.value);
                }}
                fullWidth
              />
              <TextField
                label="CNH"
                variant="outlined"
                value={cnh}
                onChange={(e) => {
                  setCnh(e.target.value);
                }}
                fullWidth
              />
              <TextField
                label="PIS"
                variant="outlined"
                value={pis}
                onChange={(e) => {
                  setPis(e.target.value);
                }}
                fullWidth
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Função"
                variant="outlined"
                value={funcao}
                onChange={(e) => {
                  setFuncao(e.target.value);
                }}
                fullWidth
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Gênero</InputLabel>
                <Select
                  label="Gênero"
                  value={genero}
                  onChange={(e: any) => {
                    setGenero(e.target.value);
                  }}
                >
                  {gen.map((genero) => (
                    <MenuItem value={genero.genero} key={genero.id}>
                      {genero.genero}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="E-mail"
                variant="outlined"
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                fullWidth
              />
              <TextField
                label="Telefone"
                variant="outlined"
                value={telefone}
                onChange={(e: any) => {
                  setTelefone(e.target.value);
                }}
                fullWidth
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Nascimento"
                variant="outlined"
                value={nascimento}
                onChange={(e) => {
                  setNascimento(maskDateField(e.target.value));
                }}
              
               
                fullWidth
              />
              <TextField
                label="Data de Admissão"
                variant="outlined"
                value={dataAdmissao}
                onChange={(e) => {
                  setDataAdmissao(maskDateField(e.target.value));
                }}
                
                fullWidth
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Horário de Entrada"
                type="time"
                variant="outlined"
                value={hTrabalhoEntrada}
                onChange={(e) => {
                  setHtrabalhoEntrada(e.target.value);
                }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                label="Horário de Saída"
                type="time"
                variant="outlined"
                value={hTrabalhoSaida}
                onChange={(e) => {
                  setHtrabalhoSaida(e.target.value);
                }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />

              <FormControl variant="outlined" fullWidth>
                <InputLabel>Turno</InputLabel>
                <Select
                  label="Turno"
                  value={turno}
                  onChange={(e: any) => {
                    setTurno(e.target.value);
                  }}
                >
                  {tur.map((turno) => (
                    <MenuItem value={turno.turno} key={turno.id}>
                      {turno.turno}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl variant="outlined" style={{ width: '20%' }}>
                <InputLabel>Situação</InputLabel>
                <Select
                  label="Situação"
                  value={situacao}
                  onChange={(e: any) => {
                    setSituacao(e.target.value);
                  }}
                >
                  {situ.map((situacao) => (
                    <MenuItem value={situacao.situacao} key={situacao.id}>
                      {situacao.situacao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Setor"
                variant="outlined"
                value={setor}
                onChange={(e) => {
                  setSetor(e.target.value);
                }}
              />
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Endereço
            </Typography>
            <div className="containerFields">
              <TextField
                fullWidth
                variant="outlined"
                value={cepSearch}
                onChange={(e: any) => {
                  setCepSearch(maskCEP(e.target.value));
                }}
                label="CEP"
                inputProps={{ maxLength: 9 }}
              />
              <TextField
                fullWidth
                variant="outlined"
                value={rua}
                onChange={(e: any) => {
                  setRua(e.target.value);
                }}
                label="Rua"
              />
              <TextField
                fullWidth
                variant="outlined"
                value={numero}
                onChange={(e: any) => {
                  setNumero(e.target.value);
                }}
                label="Número"
              />
              <TextField
                fullWidth
                variant="outlined"
                value={complemento}
                onChange={(e: any) => {
                  setComplemento(e.target.value);
                }}
                label="Complemento"
              />
            </div>
            <div className="containerFields">
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e: any) => {
                  setBairro(e.target.value);
                }}
                value={bairro}
                label="Bairro"
              />
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e: any) => {
                  setEstado(e.target.value);
                }}
                value={estado}
                label="Estado"
              />
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e: any) => {
                  setCidade(e.target.value);
                }}
                value={cidade}
                label="Cidade"
              />
            </div>
            <Typography variant="h5" style={{ margin: 10, fontWeight: 'bold' }}>
              Empresa
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                fullWidth
                variant="outlined"
                disabled
                value={`${cnpj} - ${razao}`}
                label="CNPJ Cadastrados"
              />

              <FormControl variant="outlined" fullWidth>
                <InputLabel>Locais/Obras Cadastrados</InputLabel>
                <Select
                  label="Locais/Obras Cadastrados"
                  value={localObra}
                  onChange={(e: any) => {
                    setLocalObra(e.target.value);
                  }}
                >
                  <MenuItem value="">Nenhum</MenuItem>
                  {localObraCombo?.map((local: any) => (
                    <MenuItem value={local.cod_local} key={local.id_local_obra}>
                      {local.cod_local} - {local.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>GHE Cadastrados</InputLabel>
                <Select
                  label="GHE Cadastrados"
                  value={codGhe}
                  onChange={(e: any) => {
                    gheValues(e);
                  }}
                >
                  <MenuItem value="">Nenhum</MenuItem>
                  {ghe &&
                    ghe.map((local: IGhe, index: number) => (
                      <MenuItem
                        value={local.cod_ghe}
                        key={index}
                      >{`${local.cod_ghe}/${local.nome_ghe}`}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          marginTop: 20
        }}
      >
        <NavLink to="/colaboradores">
          <Button title="Sair" variantstyle="RED" />
        </NavLink>

        <Button
          title="Salvar"
          onClick={colaborador}
          isLoading={isLoadingSave}
          disabled={isLoadingSave}
          variantstyle={isLoadingSave ? 'DEFAULT' : 'PRIMARY'}
        />
      </div>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert severity={severity} variant="filled">
          {cod} {message}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};
