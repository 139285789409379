import { api } from '@services/api';


export type CreateEmployee = {
  nome: string;
  cpf: string;
  senha_usuario: string;
  login_usuario: string;
  administrador: boolean;
  tipo_login: 'atendimento' | 'financeiro' | 'medico' | 'gerente';
  cod_contrato: string;
  rg?: string | undefined;
  usuario: string;
};

export async function createEmployee({
  nome,
  cpf,
  administrador,
  cod_contrato,
  login_usuario,
  senha_usuario,
  tipo_login,
  rg,
  usuario
}: CreateEmployee) {
  const res = await api.post('/ravim/cad_usuario', {
    nome,
    cpf,
    administrador,
    cod_contrato,
    login_usuario,
    senha_usuario,
    tipo_login,
    rg,
    usuario
  });

  return res.data;
}
