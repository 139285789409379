import { Card } from '@material-ui/core';
import styled from 'styled-components';

export const Main = styled.main``;

export const Content = styled.article``;

export const CardEmployee = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;

  p {
    flex-wrap: wrap;
    word-wrap: break-word;
    width: 10rem;
    font-size: 16px;
  }

  span {
    display: flex;
    gap: 0.8rem;
  }
`;

export const DivSearch = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
`;

export const DivEmployeer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  
  
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 20px 0;
  
`;
