import styled from 'styled-components';

export const ContainerPaper = styled.div`
  width: 100%;
  margin-top: 15px;
`;

export const ContainerInsideCard = styled.div`
  padding: 15px;
`;
export const DivInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 15px;
  margin-bottom: 10px;
`;
export const BoxExames = styled.div`
  background-color: #f3f3f3;
  padding: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-top: 10px;
`;

export const DivExames = styled.div`
  flex-direction: column;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ButtonDelete = styled.button`
  margin-top: auto;
  display: flex;
  background-color: red;
  border: transparent;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  transition-duration: 0.3s;
  color: #fff;
  overflow: hidden;
  position: absolute;
  &:hover {
    background-color: #fff;
    border: 1px solid red;
    transition: 0.3s;
    transition-duration: 0.3s;
    color: red;
  }
  &:after {
    content: '';
    background: red;
    display: block;
    position: absolute;
    padding-top: 45px;
    padding-left: 45px;
    opacity: 0;
    transition: all 0.4s;
    border-radius: 50%;
  }
  &:active {
    background-color: #fff;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Span = styled.span`
  font-weight: bold;
`;

export const DivButtons = styled.div``;

export const ButtonGuia = styled.button`
  margin-right: 4%;
  margin-left: 5%;
  margin-top: auto;
  display: flex;
  background-color: #237cf5;
  border: transparent;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  transition-duration: 0.3s;
  color: #fff;
  overflow: hidden;
  position: absolute;
  &:hover {
    background-color: #fff;
    border: 1px solid #237cf5;
    transition: 0.3s;
    transition-duration: 0.3s;
    color: #237cf5;
  }
  &:after {
    content: '';
    background: #237cf5;
    display: block;
    position: absolute;
    padding-top: 45px;
    padding-left: 45px;
    opacity: 0;
    transition: all 0.4s;
    border-radius: 50%;
  }
  &:active {
    background-color: #fff;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }
`;
