import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { useState, useEffect, ReactElement, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../../services/api';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import {
  cpfValidation,
  maskCEP,
  maskCPF,
  maskDate,
  maskDateField,
  maskPhone,
  maskRG,
  sendDateToDb
} from '../../../utils/masks';
import { gen, situ, tur } from '@data/colab';
import { Autocomplete } from '@material-ui/lab';
import dayjs from 'dayjs';

interface ICep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface ILocalObra {
  id_local_obra: number;
  cod_local: string;
  cnpj_local: string;
  nome: string;
  cnpj_empresa: string;
  razao_social: string;
  data_inicio: string;
  data_fim: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  estado: string;
  cidade: string;
  exibir: string;
}

interface ICnpj {
  bairro: string;
  cep: string;
  cidade: string;
  cnae: string;
  cnae_secundario: string;
  complemento: string;
  estado: string;
  idcad_empresa: number;
  inf_contrato: string;
  nome_empresa: string;
  nome_fantacia: string;
  num_cnpj: string;
  numero: number;
  razao_social: string;
  rua: string;
  cnpj: string;
}

interface IGhe {
  cod_ghe: string;
  cod_obra: string;
  id_cad_ghe: number;
  nome_ghe: string;
}

const ButtonAdd = withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B'
    }
  }
}))(Button);
const ButtonSair = withStyles(() => ({
  root: {
    background: 'red',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'red',
      color: 'red',
      border: 'solid 1px red'
    }
  }
}))(Button);

export const CadastrarColaborador = () => {
  const [matriculaEmpresa, setMatriculaEmpresa] = useState('');
  const [matriculaEsocial, setMatriculaEsocial] = useState('');
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [cnh, setCnh] = useState('');
  const [pis, setPis] = useState('');
  const [funcao, setFuncao] = useState('');
  const [genero, setGenero] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [dataAdmissao, setDataAdmissao] = useState('');
  const [hTrabalhoEntrada, setHtrabalhoEntrada] = useState('');
  const [hTrabalhoSaida, setHtrabalhoSaida] = useState('');
  const [codigo, setCodigo] = useState<string>('');
  const [turno, setTurno] = useState('');
  // const [perio] = useState('')
  const [situacao, setSituacao] = useState('Ativo');
  const [cnpj, setCnpj] = useState(undefined);
  const [razao, setRazao] = useState<string>('');
  const [setor, setSetor] = useState('');
  const [localTrabalho, setLocalTrabalho] = useState('');
  const [localObra, setLocalObra] = useState(undefined);
  const [localObraCombo, setLocalObraCombo] = useState<ILocalObra[]>([]);
  const [ghe, setGhe] = useState<IGhe[]>([]);
  const [codGhe, setCodGhe] = useState(undefined);
  const [contratos, setContratos] = useState<ICnpj[]>([]);

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState();
  const [cod, setCod] = useState(0);
  const [severity, setSeverity] = useState<Color>();

  const [cep, setCep] = useState<ICep>();
  const [cepSearch, setCepSearch] = useState<string>('');
  const [rua, setRua] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [complemento, setComplemento] = useState<string>('');
  const [bairro, setBairro] = useState<string>('');
  const [estado, setEstado] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');
  const [validation, setValidation] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  // console.log(ghe)

  async function searchCep() {
    const cep = cepSearch.replace('-', '');
    await api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        if (res.data) {
          setCep(res.data);
          setRua(res.data.logradouro);
          setBairro(res.data.bairro);
          setEstado(res.data.uf);
          setCidade(res.data.localidade);
        } else {
          setRua('');
          setBairro('');
          setEstado('');
          setCidade('');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function colaborador() {
    const data = {
      nome_completo: nome.trim(),
      cpf: cpf.trim(),
      rg: rg.trim(),
      email: email.trim(),
      telefone: telefone.trim(),
      data_nascimento: sendDateToDb(nascimento),
      genero,
      cnh: cnh.trim(),
      situacao,
      nis: pis,
      funcao: funcao.trim(),
      setor: setor.trim(),
      turno,
      data_admissao: sendDateToDb(dataAdmissao),
      num_cnpj: cnpj,
      razao_social: razao,
      horario_trab_entrada: hTrabalhoEntrada,
      horario_trab_saida: hTrabalhoSaida,
      local_obra: localObra,
      ghe: codGhe,
      rua,
      numero,
      cep: cepSearch,
      complemento: complemento.trim(),
      bairro,
      cidade,
      estado,
      matricula_empresa: matriculaEmpresa,
      matricula_esocial: matriculaEsocial,
      cod_ghe: codGhe
    };
    //console.log(data);

    api
      .post(`ravim/cad-colaborador`, data)
      .then((res) => {
        if (res.status === 200) {
          setCod(res.status);
          setSnack(true);
          setSeverity('success');
          setMessage(res.data.message);
          setTimeout(() => {
            window.location.replace('/colaboradores');
          }, 3000);
        } else {
          setCod(res.status);
          setSnack(true);
          setSeverity('error');
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        setCod(err.code);
        setSnack(true);
        setSeverity('error');
        setMessage(err.message);
      });
  }

  async function fetchCnpjConsulta() {
    try {
      const res = await api.get(`ravim/empresas-rh`);
      if (res.status === 404) return;
      if (res.status === 200) {
        setContratos(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDataEmpresa(cnpjSelected: string) {
    try {
      const res = await api.post(`ravim/empresas-rh`, {
        num_cnpj: cnpjSelected
      });
      if (res.status === 404) return;
      const local = JSON.parse(res.data.local_obra);
      if (local !== '' && cnpj !== '') {
        setLocalObraCombo(JSON.parse(res.data.local_obra));
      } else {
        setCodGhe('' || undefined);
        setRazao('');
        setLocalTrabalho('');
      }
      JSON.parse(res.data.local_obra).map((local: any) => {
        setLocalTrabalho(local.bairro);
        setLocalObra(local.rua);
      });

      JSON.parse(res.data.empresa).map((empresa: any) => {
        setRazao(empresa.razao_social);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchGheColab(cod_local_obra: string) {
    try {
      const res = await api.post(`ravim/colab-ghe`, {
        cod_obra: cod_local_obra
      });
      if (res.status === 404) return;
      setGhe(JSON.parse(res.data.dados));
    } catch (error) {
      console.log(error);
    }
  }

  function validationCPF(cpf: string) {
    cpf = cpf.replace(/[.-]/g, '');
    if (cpfValidation(cpf)) {
      setValidation(false);
    } else {
      setValidation(true);
    }
    return cpf;
  }

  useEffect(() => {
    const getCnpjConsulta = new Promise((resolve, reject) => {
      setTimeout(() => {
        fetchCnpjConsulta();
        setLoading(false);
        resolve(10);
      }, 1 * 1000);
    });

    Promise.all([getCnpjConsulta]);
  }, []);

  useEffect(() => {
    if (cepSearch.length === 9 || cepSearch.length === 8) {
      searchCep();
    }
  }, [cepSearch]);

  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Cadastrar Colaborador
      </Typography>
      <Paper style={{ padding: 40 }}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Colaborador
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Matrícula Empresa"
                variant="outlined"
                value={matriculaEmpresa}
                onChange={(e) => {
                  setMatriculaEmpresa(e.target.value);
                }}
              />
              <TextField
                label="Matrícula E-social"
                variant="outlined"
                value={matriculaEsocial}
                onChange={(e) => {
                  setMatriculaEsocial(e.target.value);
                }}
              />
              <TextField
                label="Nome"
                variant="outlined"
                value={nome}
                onChange={(e) => {
                  setNome(e.target.value);
                }}
                style={{ width: '80%' }}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                error={validation}
                label="CPF"
                variant="outlined"
                value={cpf}
                onChange={(e) => {
                  setCpf(maskCPF(validationCPF(e.target.value)));
                }}
                fullWidth
                inputProps={{ maxLength: 14 }}
              />
              <TextField
                label="RG"
                variant="outlined"
                value={rg}
                onChange={(e) => {
                  setRg(e.target.value);
                }}
                fullWidth
              />
              <TextField
                label="CNH"
                variant="outlined"
                value={cnh}
                onChange={(e) => {
                  setCnh(e.target.value);
                }}
                fullWidth
              />
              <TextField
                label="PIS"
                variant="outlined"
                value={pis}
                onChange={(e) => {
                  setPis(e.target.value);
                }}
                fullWidth
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Função"
                variant="outlined"
                value={funcao}
                onChange={(e) => {
                  setFuncao(e.target.value);
                }}
                fullWidth
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Gênero</InputLabel>
                <Select
                  label="Gênero"
                  onChange={(e: any) => {
                    setGenero(e.target.value);
                  }}
                >
                  {gen.map((genero) => (
                    <MenuItem value={genero.genero} key={genero.id}>
                      {genero.genero}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="E-mail"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                fullWidth
              />
              <TextField
                label="Telefone"
                variant="outlined"
                value={telefone}
                onChange={(e) => {
                  setTelefone(maskPhone(e.target.value));
                }}
                fullWidth
                inputProps={{ maxLength: 14 }}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Nascimento"
                variant="outlined"
                value={nascimento}
                onChange={(e) => {
                  setNascimento(maskDateField(e.target.value));
                }}
                fullWidth
              />
              <TextField
                label="Data de Admissão"
                variant="outlined"
                value={dataAdmissao}
                onChange={(e) => {
                  setDataAdmissao(maskDateField(e.target.value));
                }}
                fullWidth
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Horário de Entrada"
                type="time"
                variant="outlined"
                value={hTrabalhoEntrada}
                onChange={(e) => {
                  setHtrabalhoEntrada(e.target.value);
                }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                label="Horário de Saída"
                type="time"
                variant="outlined"
                value={hTrabalhoSaida}
                onChange={(e) => {
                  setHtrabalhoSaida(e.target.value);
                }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />

              <FormControl variant="outlined" fullWidth>
                <InputLabel>Turno</InputLabel>
                <Select
                  label="Turno"
                  onChange={(e: any) => {
                    setTurno(e.target.value);
                  }}
                >
                  {tur &&
                    tur.map((turno) => (
                      <MenuItem value={turno.turno} key={turno.id}>
                        {turno.turno}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl variant="outlined" style={{ width: '20%' }}>
                <InputLabel>Situação</InputLabel>
                <Select
                  label="Situação"
                  defaultValue={'Ativo'}
                  onChange={(e: any) => {
                    setSituacao(e.target.value);
                  }}
                >
                  {situ &&
                    situ.map((situacao) => (
                      <MenuItem value={situacao.situacao} key={situacao.id}>
                        {situacao.situacao}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                label="Setor"
                variant="outlined"
                value={setor}
                onChange={(e) => {
                  setSetor(e.target.value);
                }}
              />
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Endereço
            </Typography>
            <div className="containerFields">
              <TextField
                fullWidth
                variant="outlined"
                value={cepSearch}
                onChange={(e: any) => {
                  setCepSearch(maskCEP(e.target.value));
                }}
                label="CEP"
                inputProps={{ maxLength: 8 }}
              />
              <TextField
                fullWidth
                variant="outlined"
                value={rua}
                onChange={(e: any) => {
                  setRua(e.target.value);
                }}
                label="Rua"
              />
              <TextField
                fullWidth
                variant="outlined"
                value={numero}
                onChange={(e: any) => {
                  setNumero(e.target.value);
                }}
                label="Número"
              />
              <TextField
                fullWidth
                variant="outlined"
                value={complemento}
                onChange={(e: any) => {
                  setComplemento(e.target.value);
                }}
                label="Complemento"
              />
            </div>
            <div className="containerFields">
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e: any) => {
                  setBairro(e.target.value);
                }}
                value={bairro}
                label="Bairro"
              />
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e: any) => {
                  setEstado(e.target.value);
                }}
                value={estado}
                label="Estado"
              />
              <TextField
                fullWidth
                variant="outlined"
                onChange={(e: any) => {
                  setCidade(e.target.value);
                }}
                value={cidade}
                label="Cidade"
              />
            </div>
            <Typography variant="h5" style={{ margin: 10, fontWeight: 'bold' }}>
              Empresa
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <Autocomplete
                fullWidth
                id="tags-outlined"
                options={contratos}
                getOptionLabel={(option: ICnpj) =>
                  `${option.num_cnpj} - ${option.razao_social}`
                }
                onChange={(e, newValue: any) => {
                  setCnpj(newValue?.num_cnpj);
                  fetchDataEmpresa(newValue?.num_cnpj);
                }}
                value={cnpj}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="CNPJ Cadastrados"
                    placeholder="CNPJ Cadastrados"
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
              />

              <Autocomplete
                fullWidth
                id="tags-outlined"
                options={localObraCombo}
                getOptionLabel={(option: ILocalObra) =>
                  `${option?.cod_local} - ${option?.nome}`
                }
                onChange={(e, newValue: any) => {
                  setLocalObra(newValue?.cod_local);
                  fetchGheColab(newValue?.cod_local);
                }}
                value={localObra}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Locais/Obras Cadastrados"
                    placeholder="Locais/Obras Cadastrados"
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
              />

              <Autocomplete
                fullWidth
                id="tags-outlined"
                options={ghe}
                getOptionLabel={(option: IGhe) =>
                  `${option?.cod_ghe} - ${option?.nome_ghe}`
                }
                onChange={(e, newValue: any) => {
                  setCodGhe(newValue?.cod_ghe);
                  setCodigo(newValue?.cod_obra);
                }}
                value={codGhe}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="GHE Cadastrados"
                    placeholder="GHE Cadastrados"
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          marginTop: 20
        }}
      >
        <Link to={'/colaboradores'} style={{ textDecoration: 'none' }}>
          <ButtonSair variant="outlined">Sair</ButtonSair>
        </Link>
        <ButtonAdd
          disabled={validation}
          variant="outlined"
          onClick={colaborador}
        >
          Cadastrar
        </ButtonAdd>
      </div>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
