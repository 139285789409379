import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
  type ReactNode
} from 'react';
import filesize from 'filesize';
import { v4 as uuidv4 } from 'uuid';
import { api } from '@services/api';

export interface IPost {
  _id: string;
  name: string;
  url: string;
  size: number;
  cod_contrato: string;
}

export interface IFile {
  id: string;
  name: string;
  uploaded?: boolean;
  preview: string;
  readableSize: string;
  file: File | null;
  progress?: number;
  error?: boolean;
  url: string;
  cod_contrato: string;
}

interface IFileContextData {
  uploadedFiles: IFile[];
  deleteFile: (id: string) => void;
  handleUpload: (file: any) => void;
  fetchDataFiles: (file: any) => void;
  receivedCodContrato: (cod: string) => void;
}

interface Props {
  children: ReactNode;
}

const FileContext = createContext<IFileContextData>({} as IFileContextData);

const FileProvider: React.FC<Props> = ({ children }) => {
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [codContrato, setCodContrato] = useState('');

  async function receivedCodContrato(cod: string) {
    setCodContrato(cod);
  }

  async function fetchDataFiles(data: any) {
    try {
      setUploadedFiles(
        data.map((file: any) => ({
          id: file?.id_arquivo,
          name: file.arquivo,
          url: file.url,
          uploaded: true
        }))
      );
    } catch (error) {
      console.log(error);
    }
  }

  /* useEffect(() => {
    api.get<IPost[]>('file').then((response) => {
      const postFormatted: IFile[] = response.data.map((post) => {
        return {
          ...post,
          id: post?._id,
          name: post.name,
          preview: post.url,
          readableSize: filesize(post.size),
          file: null,
          error: false,
          uploaded: true,
          cod_contrato: post.cod_contrato
        };
      });
      setUploadedFiles(postFormatted);
    });
  }, []); */

  useEffect(() => {
    return () => {
      uploadedFiles.forEach((file) => { URL.revokeObjectURL(file.preview); });
    };
  }, []);

  const updateFile = useCallback((id: string, data: any) => {
    setUploadedFiles((state) =>
      state.map((file) => (file?.id === id ? { ...file, ...data } : file))
    );
  }, []);

  const processUpload = useCallback(
    async (uploadedFile: IFile) => {
      const data = new FormData();
      if (uploadedFile.file) {
        data.append('arquivo1', uploadedFile.file, uploadedFile.name);
      }
      await api
        .post(`ravim/arquivos/${codContrato}`, data, {
          onUploadProgress: (progressEvent: any) => {
            const progress: number = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            console.log(
              `O arquivo ${uploadedFile.name} está ${progress}% carregada`
            );

            updateFile(uploadedFile?.id, { progress });
          }
        })
        .then((response) => {
          console.log(
            `O arquivo ${uploadedFile.name} já foi enviada para o servidor!`
          );

          updateFile(uploadedFile?.id, {
            uploaded: true,
            id: response.data?._id,
            url: response.data.url
          });
        })
        .catch((err) => {
          console.error(
            `Houve um problema para fazer o upload do arquivos ${uploadedFile.name} no servidor web AWS`
          );
          console.log(err);

          updateFile(uploadedFile?.id, {
            error: true
          });
        });
    },
    [updateFile, codContrato]
  );

  const handleUpload = useCallback(
    (files: File[]) => {
      const newUploadedFiles: IFile[] = files.map((file: File) => ({
        file,
        id: uuidv4(),
        name: file.name,
        preview: URL.createObjectURL(file),
        readableSize: filesize(file.size),
        progress: 0,
        uploaded: false,
        error: false,
        cod_contrato: codContrato,
        url: ''
      }));

      // concat é mais performático que ...spread
      setUploadedFiles((state) => state.concat(newUploadedFiles));
      newUploadedFiles.forEach(processUpload);
    },
    [processUpload, codContrato]
  );

  const deleteFile = useCallback(async (id: string) => {
    try {
      const response = await api.post(`ravim/excluir-arquivo`, { arquivo: id });

      if (response.status === 200) {
        setUploadedFiles((state) =>
          state.filter((file) =>
            file?.id.indexOf('_') !== -1 ? file?.id !== id : file.name !== id
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <FileContext.Provider
      value={{
        uploadedFiles,
        deleteFile,
        handleUpload,
        fetchDataFiles,
        receivedCodContrato
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

function useFiles(): IFileContextData {
  const context = useContext(FileContext);

  if (!context) {
    throw new Error('useFile must be used within FileProvider');
  }

  return context;
}

export { FileProvider, useFiles };
