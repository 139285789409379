import {
  Button,
  Container,
  Paper,
  Snackbar,
  TextField,
  Typography,
  withStyles,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import { api } from '@services/api';
import { Autocomplete } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { maskCEP, maskCNPJ, validaCNPJ } from '@utils/masks';

interface ICep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface IContratos {
  nome_contrato: string;
  cod_contrato: string;
  data_criacao: string | Date;
  data_renovacao: string | Date;
  arquivo: File | null;
}

interface ICadastroLocalObra {
  cod_local: string;
  nome_local: string;
  razao_social: string;
  data_inicio: string;
  data_fim: string;
  num_cnpj: string;
  rua: string;
  numero: string;
  cep: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
}

const ButtonAdd = withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B'
    }
  }
}))(Button);
const ButtonSair = withStyles(() => ({
  root: {
    background: 'red',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'red',
      color: 'red',
      border: 'solid 1px red'
    }
  }
}))(Button);

export const EditLocalObra = (props: any) => {
  // console.log(props)
  const {
    cnpj_empresa,
    cod_local,
    nome,
    cnpj_local,
    razao_social,
    data_inicio,
    data_fim
  } = props.location.state;

  const [contratos, setContratos] = useState<IContratos[]>([]);

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState();
  const [cod, setCod] = useState(0);
  const [severity, setSeverity] = useState<Color>();

  const [codLocal, setCodLocal] = useState(props.location.state.cod_local);
  const [nomeLocal, setNomeLocal] = useState(nome);
  const [cnpjLocal, setCnpjLocal] = useState(cnpj_local);
  const [razao, setRazao] = useState(razao_social);
  const [dataCriacao, setDataCriacao] = useState(data_inicio);
  const [dataFim, setDataFim] = useState(data_fim);

  const [cep, setCep] = useState<ICep>();
  const [cepSearch, setCepSearch] = useState<string>(props.location.state.cep);
  const [rua, setRua] = useState<string>('');
  const [numero, setNumero] = useState<string>(props.location.state.numero);
  const [complemento, setComplemento] = useState<string>(
    props.location.state.complemento
  );
  const [bairro, setBairro] = useState<string>('');
  const [estado, setEstado] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');
  const [validation, setValidation] = useState<boolean>(false);

  useEffect(() => {
    if (cepSearch.length === 9) {
      searchCep();
    }
    if (!cepSearch) {
      setRua('');
      setBairro('');
      setEstado('');
      setCidade('');
    }
  }, [cepSearch]);

  useEffect(() => {
    contract();
  }, []);

  async function cadastroLocalObra() {
    const data = {
      cod_local: codLocal,
      nome_local: nomeLocal,
      razao_social: razao,
      data_inicio: dataCriacao,
      data_fim: dataFim,
      cnpj_local: cnpjLocal,
      num_cnpj: cnpj_empresa,
      rua,
      numero,
      cep: cepSearch,
      complemento,
      bairro,
      cidade,
      estado
    };

    await api
      .post(`ravim/editar-local-obra`, data)
      .then((res) => {
        setCod(res.status);
        setSnack(true);
        setSeverity('success');
        setMessage(res.data.message);
        setTimeout(() => {
          window.location.replace('/local-obra');
        }, 3000);
        // console.log(res.data)
      })
      .catch((err) => {
        setCod(err.code);
        setSnack(true);
        setSeverity('error');
        setMessage(err.message);
      });
    // console.log(data)
  }

  async function contract() {
    await api
      .get(`ravim/cadastro-empresas`)
      .then((res) => {
        setContratos(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function validationCNPJ(cnpj: string) {
    cnpj = cnpj.replace(/[.-]/g, '');
    if (validaCNPJ(cnpj)) {
      setValidation(false);
    } else {
      setValidation(true);
    }
    return cnpj;
  }

  async function searchCep() {
    const cep = cepSearch.replace('-', '');
    await api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        if (res.data) {
          setCep(res.data);
          setRua(res.data.logradouro);
          setBairro(res.data.bairro);
          setEstado(res.data.uf);
          setCidade(res.data.localidade);
        } else {
          setRua('');
          setBairro('');
          setEstado('');
          setCidade('');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Editar Local Obra
      </Typography>
      <div>
        <Paper style={{ padding: 30, width: '100%' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ marginBottom: 20, fontWeight: 'bold' }}
              >
                Informações do Local/Obra
              </Typography>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled
                  value={`${cnpj_empresa}`}
                  onChange={(e) =>
                    { setCodLocal(maskCNPJ(validationCNPJ(e.target.value))); }
                  }
                  label="CNPJ Cadastrados"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled
                  value={codLocal}
                  onChange={(e) => { setCodLocal(e.target.value); }}
                  label="Código do Local"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={nomeLocal}
                  onChange={(e) => { setNomeLocal(e.target.value); }}
                  label="Nome do Local"
                />
              </div>
              <div className="containerFields">
                <TextField
                  required
                  error={validation}
                  fullWidth
                  variant="outlined"
                  value={cnpjLocal}
                  onChange={(e) =>
                    { setCnpjLocal(maskCNPJ(validationCNPJ(e.target.value))); }
                  }
                  label="CNPJ do Local"
                  inputProps={{ maxLength: 14 }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={razao}
                  onChange={(e) => { setRazao(e.target.value); }}
                  label="Razão Social"
                />
                <TextField
                  id="date"
                  label="Data de Criação"
                  type="date"
                  variant="outlined"
                  value={dataCriacao}
                  onChange={(e) => { setDataCriacao(e.target.value); }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  id="date"
                  label="Data de Fim"
                  type="date"
                  value={dataFim}
                  onChange={(e) => { setDataFim(e.target.value); }}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography
                style={{ marginBottom: 20, fontWeight: 'bold' }}
                variant="h5"
              >
                Endereço
              </Typography>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  value={cepSearch}
                  onChange={(e: any) => { setCepSearch(maskCEP(e.target.value)); }}
                  label="CEP"
                  inputProps={{ maxLength: 8 }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={rua}
                  onChange={(e: any) => { setRua(e.target.value); }}
                  label="Rua"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={numero}
                  onChange={(e: any) => { setNumero(e.target.value); }}
                  label="Número"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={complemento}
                  onChange={(e: any) => { setComplemento(e.target.value); }}
                  label="Complemento"
                />
              </div>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => { setBairro(e.target.value); }}
                  value={bairro}
                  label="Bairro"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => { setEstado(e.target.value); }}
                  value={estado}
                  label="Estado"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => { setCidade(e.target.value); }}
                  value={cidade}
                  label="Cidade"
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
        <div className="btnCadastro">
          <Link to={'/local-obra'} style={{ textDecoration: 'none' }}>
            <ButtonSair variant="outlined">Sair</ButtonSair>
          </Link>
          <ButtonAdd
            disabled={validation}
            variant="outlined"
            onClick={cadastroLocalObra}
          >
            Salvar Edição
          </ButtonAdd>
        </div>
      </div>

      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => { setSnack(!snack); }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
