import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { maskCEP, maskCPF, maskDate } from '../../../utils/masks';

interface IClinica {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  razao_social: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  telemedicina: string;
  observacao: string;
}

interface IAgendamento {
  cidade: string;
  cpf_cnpj_comer: string;
  cpf_colaborador: string;
  data_agendamento: string;
  estado: string;
  horario: string;
  id_agendamento: number;
  nome: string;
  nome_comercial: string;
  num_agenda: string;
  observacao: string;
  ordem_chegada: string;
  status: string;
  telemedicina: string;
  tipo_aso: string;
  usuario: string;
  linkDoatendimento: string;
}

interface IColaborador {
  id_colaboradores: number;
  matricula_empresa: string;
  matricula_esocial: string;
  nome_completo: string;
  cpf_colaborador: string;
  rg: string;
  data_nascimento: string;
  genero: string;
  cnh: string;
  email: string;
  telefone: string;
  situacao: string;
  nis: string;
  funcao: string;
  setor: string;
  turno: string;
  cnpj: string;
  razao_social: string;
  data_admissao: string;
  horario_trab_entrada: string;
  horario_trab_saida: string;
  local_obra: string;
  cod_ghe: string;
  ghe: string;
}

interface IEmpresaColab {
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  estado: string;
  numero: string;
  rua: string;
}

interface ITelefoneEmpresa {
  cnpj: string;
  id_empresa_telefone: number;
  telefone: string;
}

interface IExameGHE {
  id_exame_ghe: number;
  cod_ghe: string;
  exame_empresa: string;
  dias: string;
  data: [
    {
      data_agendamento: string;
    }
  ];
}

interface IGhe {
  cod_ghe: string;
  cod_obra: string;
  crm: string;
  crm_estado: string;
  exibir: string;
  id_cad_ghe: number;
  nome_ghe: string;
  nome_med: string;
  obs_aso: string;
  tipo_plano: string;
  valor_plano: string;
}

interface IRisco_Ghe {
  id_risco_ghe: number;
  cod_ghe: string;
  nome: string;
}

interface ITelefone_Credenciado {
  telefone: string;
}

interface IExamesToxico {
  cpf: string;
  exame_credenciado: string;
  exame_empresa: string;
  id_exame_colab: number;
  num_agenda: string;
  valor_credenciado: string;
  valor_empresa: string;
  data: [
    {
      data_agendamento: string;
    }
  ];
}

interface IPdf {
  clinica: IClinica[];
  agendamento: IAgendamento[];
  colaborador: IColaborador[];
  exames_ghe: IExameGHE[];
  ghe: IGhe[];
  risco_ghe: IRisco_Ghe[];
  empresa_colab: IEmpresaColab[];
  telefone_empresa: ITelefoneEmpresa[];
  telefone_credenciado: ITelefone_Credenciado[];
  exames_toxi: IExamesToxico[];
}

export const Guia: React.FC<IPdf[]> = (data, page) => {
  // Logica de Riscos
  const numberOfColumnsRiscos = 2;
  const numberOfRowsRiscos = Math.ceil(
    data[0].risco_ghe.length / numberOfColumnsRiscos
  );

  const leftColumnRiscos = [];
  const rightColumnRiscos = [];
  const leftLengthRiscos = [];

  for (let i = 0; i < numberOfRowsRiscos; i++) {
    leftColumnRiscos[i] = data[0].risco_ghe[i * numberOfColumnsRiscos];
    rightColumnRiscos[i] = data[0].risco_ghe[i * numberOfColumnsRiscos + 1];
    leftLengthRiscos[i] = i;
  }

  const rightRiscos = rightColumnRiscos.map((right) => {
    if (right !== undefined) {
      return [{ text: right.nome ? right.nome : '', colSpan: 3 }, {}, {}];
    } else {
      return [{ text: '', colSpan: 3 }, {}, {}];
    }
  });
  const leftRiscos = leftColumnRiscos.map((left) => {
    if (left !== undefined) {
      return [{ text: left.nome ? left.nome : '', colSpan: 3 }, {}, {}];
    } else {
      return [{ text: '', colSpan: 3 }, {}, {}];
    }
  });

  const countRiscos = leftLengthRiscos.map((a, i) => [
    ...leftRiscos[i],
    ...rightRiscos[i]
  ]); // organiza por index as linhas da coluna de riscos

  //= ====================================Fim da logica de riscos =============================================

  // Aqui divide em duas colunas os exames
  const numberOfColumns = 2;
  const numberOfRows = Math.ceil(data[0].exames_ghe.length / numberOfColumns);

  const leftColumn = [];
  const rightColumn = [];
  const leftLength = [];

  for (let i = 0; i < numberOfRows; i++) {
    leftColumn[i] = data[0].exames_ghe[i * numberOfColumns];
    rightColumn[i] = data[0].exames_ghe[i * numberOfColumns + 1];
    leftLength[i] = i;
  }

  const right = rightColumn.map((right, i) => {
    if (right !== undefined) {
      return [
        {
          text: right.exame_empresa ? right.exame_empresa : '',
          colSpan: 2,
          border: [true, true, false, true]
        },
        {},
        {
          text: `${
            right.data[0].data_agendamento
              ? maskDate(right.data[0].data_agendamento)
              : ''
          } `,
          border: [false, true, true, true],
          bold: true
        }
      ];
    } else {
      return [
        { text: '', colSpan: 2, border: [true, true, false, true] },
        {},
        { text: `${''}`, border: [false, true, true, true], bold: true }
      ];
    }
  });

  const left = leftColumn.map((left, i) => {
    if (left !== undefined) {
      return [
        {
          text: left.exame_empresa ? left.exame_empresa : '',
          colSpan: 2,
          border: [true, true, false, true]
        },
        {},
        {
          text: `${
            left.data[0].data_agendamento
              ? maskDate(left.data[0].data_agendamento)
              : ''
          } `,
          border: [false, true, true, true],
          bold: true
        }
      ];
    } else {
      return [
        { text: '', colSpan: 2, border: [true, true, false, true] },
        {},
        { text: `${''}`, border: [false, true, true, true], bold: true }
      ];
    }
  });

  const countExames = leftLength.map((a, i) => [...left[i], ...right[i]]); // organiza por index as linhas da coluna de exames

  //= ===============================Fim da logica de exames ======================================================
  // Aqui divide em duas colunas os exames-Toxico
  const numberOfColumnsExamesToxico = 2;
  const numberOfRowsExamesToxico = Math.ceil(
    data[0].exames_toxi.length / numberOfColumnsExamesToxico
  );

  const leftColumnExamesToxico = [];
  const rightColumnExamesToxico = [];
  const leftLengthExamesToxico = [];

  for (let i = 0; i < numberOfRowsExamesToxico; i++) {
    leftColumnExamesToxico[i] =
      data[0].exames_toxi[i * numberOfColumnsExamesToxico];
    rightColumnExamesToxico[i] =
      data[0].exames_toxi[i * numberOfColumnsExamesToxico + 1];
    leftLengthExamesToxico[i] = i;
  }

  const rightExamesToxico = rightColumnExamesToxico.map((right, i) => {
    // console.log(right.data[0].data_agendamento)
    if (right !== undefined) {
      return [
        {
          text: right.exame_empresa ? right.exame_empresa : '',
          colSpan: 2,
          border: [true, true, false, true]
        },
        {},
        {
          text: `${
            right.data[i].data_agendamento
              ? maskDate(right.data[i].data_agendamento)
              : ''
          } `,
          border: [false, true, true, true],
          bold: true
        }
      ];
    } else {
      return [
        { text: '', colSpan: 2, border: [true, true, false, true] },
        {},
        { text: `${''}`, border: [false, true, true, true], bold: true }
      ];
    }
  });

  const leftExamesToxico = leftColumnExamesToxico.map((left, i) => {
    if (left !== undefined) {
      return [
        {
          text: left.exame_empresa ? left.exame_empresa : '',
          colSpan: 2,
          border: [true, true, false, true]
        },
        {},
        {
          text: `${
            left.data[i].data_agendamento
              ? maskDate(left.data[i].data_agendamento)
              : ''
          } `,
          border: [false, true, true, true],
          bold: true
        }
      ];
    } else {
      return [
        { text: '', colSpan: 2, border: [true, true, false, true] },
        {},
        { text: `${''}`, border: [false, true, true, true], bold: true }
      ];
    }
  });

  const countExamesExamesToxico = leftLengthExamesToxico.map((a, i) => [
    ...leftExamesToxico[i],
    ...rightExamesToxico[i]
  ]); // organiza por index as linhas da coluna de exames

  //= ===============================Fim da logica de exames Toxico ======================================================

  const selectTypeExames =
    page === 'toxico' ? countExamesExamesToxico : countExames;

  const removeDataClinic = () => {
    if (page === 'telemedicina') {
      return [
        [
          {
            text: 'Clínica',
            fillColor: '#c2aed9',
            color: '#000',
            bold: true,
            alignment: 'center'
          },
          { text: data[0]?.clinica[0].nome_comercial, colSpan: 5 },
          {},
          {},
          {},
          {}
        ]
      ];
    } else {
      return [
        [
          {
            text: 'Clínica',
            fillColor: '#c2aed9',
            color: '#000',
            bold: true,
            alignment: 'center'
          },
          { text: data[0]?.clinica[0].nome_comercial, colSpan: 2 },
          {},
          {
            text: 'Atendimento',
            fillColor: '#c2aed9',
            color: '#000',
            bold: true,
            alignment: 'center'
          },
          { text: data[0]?.clinica[0].dias_funcionamento, colSpan: 2 },
          {}
        ],
        [
          {
            text: 'Endereço',
            fillColor: '#c2aed9',
            color: '#000',
            bold: true,
            alignment: 'center'
          },
          {
            text: `${data[0]?.clinica[0].rua}, ${data[0]?.clinica[0]
              .numero}, ${data[0]?.clinica[0].complemento}, ${data[0]
              ?.clinica[0].bairro} - ${data[0]?.clinica[0].estado}, ${data[0]
              ?.clinica[0].cidade}, ${
              data[0]?.clinica[0].cep
                ? maskCEP(data[0]?.clinica[0].cep)
                : data[0]?.clinica[0].cep
            } `,
            colSpan: 2
          },
          {},
          {
            text: 'Tel',
            fillColor: '#c2aed9',
            color: '#000',
            bold: true,
            alignment: 'center'
          },
          {
            text: data[0]?.telefone_credenciado[0]
              ? data[0]?.telefone_credenciado[0].telefone
              : '',
            colSpan: 2
          },
          {}
        ]
      ];
    }
  };

  const linkDoAtendimento = () => {
    if (page === 'telemedicina') {
      return [
        {
          text: 'Resp. do PCMSO',
          fillColor: '#c2aed9',
          color: '#000',
          bold: true,
          alignment: 'center',
          fontSize: 9
        },
        { text: data[0].ghe[0]?.nome_med },
        {
          text: 'CRM',
          fillColor: '#c2aed9',
          color: '#000',
          bold: true,
          alignment: 'center'
        },
        { text: `${data[0].ghe[0]?.crm}/${data[0].ghe[0]?.crm_estado}` },
        {
          text: 'Link do atendimento',
          fillColor: '#c2aed9',
          color: '#000',
          bold: true,
          alignment: 'center'
        },
        { text: data[0]?.agendamento[0].linkDoatendimento }
      ];
    } else {
      return [
        {
          text: 'Resp. do PCMSO',
          fillColor: '#c2aed9',
          color: '#000',
          bold: true,
          alignment: 'center',
          fontSize: 9
        },
        { text: data[0].ghe[0]?.nome_med, colSpan: 3 },
        {},
        {},
        {
          text: 'CRM',
          fillColor: '#c2aed9',
          color: '#000',
          bold: true,
          alignment: 'center'
        },
        { text: `${data[0].ghe[0]?.crm}/${data[0].ghe[0]?.crm_estado}` }
      ];
    }
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportTitle = [
    {
      text: '',
      fontSize: 15,
      bold: true,
      margin: [30, 30, 0, 45] // left, top, right, bottom
    }
  ];
  const details = [
    {
      style: { fontSize: 10 },
      color: '#444',
      table: {
        widths: [80, 120, 60, 90, '*', 80],
        // widths: ['*', '*', '*', '*', '*', '*'],
        headerRows: 2,
        body: [
          [
            {
              text: [
                {
                  text:
                    page === 'toxico'
                      ? String(
                          'Guia de encaminhamento toxicológico'
                        ).toLocaleUpperCase()
                      : page === 'telemedicina'
                      ? String(
                          'Guia de encaminhamento telemedicina'
                        ).toLocaleUpperCase()
                      : page === 'presencial'
                      ? String(
                          'Guia de encaminhamento presencial'
                        ).toLocaleUpperCase()
                      : String('Guia de encaminhamento').toLocaleUpperCase(),
                  style: 'tableHeader',
                  fontSize: 18,
                  alignment: 'center'
                },
                {
                  text: '\n(11) 96621 8866 - www.ravim.health - agendamento@ravim.health',
                  alignment: 'center'
                }
              ],
              colSpan: 6,
              color: '#5d4c71'
            },
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Dados do Prestador de Serviço',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            },
            {},
            {},
            {},
            {},
            {}
          ],
          ...removeDataClinic(),

          [
            {
              text: 'Dados da Empresa',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            },
            {},
            {},
            {},
            {},
            {}
          ],
          [
            {
              text: 'Empresa',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0].razao_social, colSpan: 2 },
            {},
            {
              text: 'CNPJ',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0].cnpj, colSpan: 2 },
            {}
          ],

          [
            {
              text: 'Dados do Colaborador',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            }
          ],
          [
            {
              text: 'Colaborador',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0].nome_completo, colSpan: 5 }
          ],
          [
            {
              text: 'Setor',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0].setor },
            {
              text: 'Função',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0].funcao, colSpan: 3 }
          ],
          [
            {
              text: 'CPF',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].colaborador[0].cpf_colaborador
                ? maskCPF(data[0].colaborador[0].cpf_colaborador)
                : data[0].colaborador[0].cpf_colaborador
            },
            {
              text: 'RG ou CTPS',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0].rg, colSpan: 3 }
          ],
          [
            {
              text: 'Nascimento',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: maskDate(data[0]?.colaborador[0].data_nascimento) },
            {
              text: 'GHE',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0].ghe.split('/')[1] },
            {
              text: 'Sexo',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0]?.colaborador[0].genero }
          ],

          [
            {
              text: 'Dados do Agendamento',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            }
          ],
          linkDoAtendimento(),
          [
            {
              text: 'Tipo de ASO',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0].agendamento[0].tipo_aso },
            {
              text: 'Data',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            {
              text: data[0].agendamento[0]?.data_agendamento
                ? maskDate(data[0].agendamento[0]?.data_agendamento)
                : data[0].agendamento[0]?.data_agendamento
            },
            {
              text: 'Horário',
              fillColor: '#c2aed9',
              color: '#000',
              bold: true,
              alignment: 'center'
            },
            { text: data[0].agendamento[0]?.horario }
          ],

          [
            {
              text: 'Riscos Ocupacionais',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            }
          ],

          ...countRiscos,

          [
            {
              text: 'Exames Complementares a Serem Realizados',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            }
          ],

          ...selectTypeExames,

          [
            {
              text: 'Observações',
              colSpan: 6,
              style: 'tableHeader',
              alignment: 'center',
              fillColor: '#8c8c8c',
              color: '#FFF',
              bold: true
            }
          ],
          // Observaçõe aso
          [
            { text: data[0].agendamento[0]?.observacao, colSpan: 6, rowSpan: 3 }
          ],
          [{}, {}, {}, {}, {}, {}],
          [{}, {}, {}, {}, {}, {}]
        ]
      }
    }
  ];

  function Rodape(currentPage: string, pageCounf: string) {
    return [
      {
        text: `${currentPage} / ${pageCounf}`,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0] // left, top, right, bottom
      }
    ];
  }

  const docDefinitions: any = {
    info: {
      title: data[0].colaborador[0].nome_completo,
      subject: data[0].colaborador[0].nome_completo,
      creator: data[0].colaborador[0].nome_completo,
      producer: data[0].colaborador[0].nome_completo,
      keywords: data[0].colaborador[0].nome_completo,
      download: data[0].colaborador[0].nome_completo,
      filename: data[0].colaborador[0].nome_completo,
      downloadName: data[0].colaborador[0].nome_completo
    },
    pageSize: 'A4',
    pageMargins: [38, 50, 50, 40], // left, top, right, bottom
    header: [reportTitle],
    content: [details],
    fontSize: 5,
    footer: Rodape
  };

  pdfMake
    .createPdf(docDefinitions)
    .download(`GUIA RAVIM - ${data[0].colaborador[0].nome_completo.replace('.',' ')}`);
  // pdfMake.createPdf(docDefinitions).open()

  return <div />;
};
