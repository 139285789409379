import { getContratos } from '@api/get-contratos';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Controller, Control } from 'react-hook-form';

type Props = TextFieldProps &{
  control: Control<any>;
  errors?: string;
  name: string;
  defaultContract?: string
};

export function SearchContratos({ control, errors, name, defaultContract }: Props) {
  const { data: getAllContratos, isLoading } = useQuery({
    queryKey: ['contratos'],
    queryFn: getContratos
  });

  if (isLoading) return <div>Loading...</div>;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, name, onChange, ...props } }) => {
        return (
          <Autocomplete

            id="tags-outlined"
            getOptionSelected={(option, value) =>
              option.cod_contrato === value.cod_contrato
            }
            options={getAllContratos ?? []}
            getOptionLabel={(option) =>
              `${option.cod_contrato}`
                ? `${option.cod_contrato} - ${option.nome_contrato}`
                : ''
            }
            onChange={(e, newValue, reason) => {
              if (reason === 'clear') {
                onChange(null);
              } else {
                const selectedValue = newValue as {
                  nome_contrato: string;
                  cod_contrato: string;
                };
                onChange(selectedValue);
              }
            }}
            value={value || null}
            
            {...props}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
           
                label="Contrato"
                placeholder="Contrato"
                error={!!errors}
                helperText={errors}
               
              />
            )}
          />
        );
      }}
    />
  );
}
