import React from 'react';
import ReactDOM from 'react-dom';

import { AuthProvider } from '@context/auth';
import { ThemeProvider } from 'styled-components';

import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { defaulTheme } from './theme';
import { FileProvider } from './context/files';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './lib/react-query';
import { GlobalStyle } from './theme/global';
import { Toaster } from 'sonner';

ReactDOM.render(
  /* <React.StrictMode> */

  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider theme={defaulTheme}>
          <GlobalStyle />
          <Toaster richColors/>
          <FileProvider>
            <App />
          </FileProvider>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  </Provider>,

  /* </React.StrictMode> */ document.getElementById('root')
);
