import React, {
  ReactElement,
  ReactHTML,
  ReactHTMLElement,
  useEffect,
  useState
} from 'react';
import {
  Container,
  TextField,
  Grid,
  Paper,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Chip,
  Snackbar
} from '@material-ui/core';
import './styleEmpresaRH.css';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import { Autocomplete } from '@material-ui/lab';
import { api } from '@services/api';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import { type RootState } from '../../../store';
import {
  infoCnpjNumeroCnpj,
  infoEmpNome,
  infoEmpEmails,
  infoEmpTelefone,
  infoCnpjRazaoSocial,
  infoCnpjNomeFantasia
} from '../../../store/cadEmpresaRH';
import { Link } from 'react-router-dom';
import { maskCEP, maskCNPJ, maskPhone, validaCNPJ } from '@utils/masks';

const PurpleSwitch = withStyles({
  switchBase: {
    color: purple[300],
    '&$checked': {
      color: purple[500]
    },
    '&$checked + $track': {
      backgroundColor: purple[500]
    }
  },
  checked: {},
  track: {}
})(Switch);

const ButtonSair = withStyles(() => ({
  root: {
    background: 'red',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'red',
      color: 'red',
      border: 'solid 1px red'
    }
  }
}))(Button);

const ButtonAdd = withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B'
    }
  }
}))(Button);

interface IEmails {
  id?: string;
  email: string;
}

interface ITelefone {
  id: string;
  telefone: string;
}

interface IContratos extends IInfos {
  nome_contrato: string;
  cod_contrato: string;
  data_criacao: string | Date;
  data_renovacao: string | Date;
  arquivo: File | null;
}

interface IInfos {
  infoEmpNome: string;
  infoEmpContrato: string;
  infoEmpEmails: any;
  infoEmpTelefone: string[];
  infoCnpjNumeroCnpj: string;
  infoCnpjRazaoSocial: string;
  infoCnpjNomeFantasia: string;
  infoCnpjCnae: string | undefined;
  infoCnpjCnaeSecundario: object[];
  endComeNumero: string;
  endComeRua: string;
  endComeCep: string;
  endComplemento: string;
  endComeBairro: string;
  endComeEstado: string;
  endComeCidade: string;
  endCobNumero: string;
  endCobCep: string;
  endCobComplemento: string;
  endCobBairro: string;
  endCobRua: string;
  endCobNum: string;
  endCobEstado: string;
  endCobCidade: string;
}

interface ICep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface ICnae {
  id_cnae: string;
  nome: string;
}

export default function EmpresaRH() {
  const cadastroVars = useSelector((state: RootState) => state.cadastroEmpRH);
  const dispatch = useDispatch();
  const [swt, setSwt] = useState<boolean>(false);
  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState();
  const [cod, setCod] = useState(0);
  const [severity, setSeverity] = useState<Color>();
  const [cnaeSecundario, setCnaeSecundario] = useState<ICnae[]>([]);
  const [selectedCnaeSecundario, setSelectedCnaeSecundario] = useState([]);
  const [cnaeSelected, setCnaeSelected] = useState();
  const [validation, setValidation] = useState<boolean>(false);

  const [telefones, setTelefones] = useState<ITelefone[]>([]);
  const [email, setEmail] = useState<IEmails[]>([]);
  const [contratos, setContratos] = useState<IContratos[]>([]);
  const [selectedContract, setSelectedContrato] = useState<any>();

  const [cep, setCep] = useState<ICep>();
  const [cepSearch, setCepSearch] = useState<string>('');
  const [rua, setRua] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [complemento, setComplemento] = useState<string>('');
  const [bairro, setBairro] = useState<string>('');
  const [estado, setEstado] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');

  const [cepSearchCob, setCepSearchCob] = useState<string>('');
  const [ruaCob, setRuaCob] = useState<string>('');
  const [numeroCob, setNumeroCob] = useState<string>('');
  const [complementoCob, setComplementoCob] = useState<string>('');
  const [bairroCob, setBairroCob] = useState<string>('');
  const [estadoCob, setEstadoCob] = useState<string>('');
  const [cidadeCob, setCidadeCob] = useState<string>('');

  useEffect(() => {
    contract();
    setTimeout(() => {
      cnaeSec();
    }, 1200);
    if (cepSearch.length === 9 || cepSearchCob.length === 9) {
      searchCep();
      searchCepCob();
    }
    if (!cepSearch || !cepSearchCob) {
      setRua('');
      setBairro('');
      setEstado('');
      setCidade('');

      setRuaCob('');
      setBairroCob('');
      setEstadoCob('');
      setCidadeCob('');
    }
    if (!swt) {
      setCepSearchCob('');
      setRuaCob('');
      setBairroCob('');
      setEstadoCob('');
      setCidadeCob('');
    }
  }, [cepSearch, cepSearchCob, swt]);

  async function sendInfo() {
    const data = {
      infoEmpNome: cadastroVars.infoEmpNome,
      infoEmpEmails: cadastroVars.infoEmpEmails,
      infoEmpTelefone: cadastroVars.infoEmpTelefone,
      infoCnpjNumeroCnpj: cadastroVars.infoCnpjNumeroCnpj,
      infoCnpjRazaoSocial: cadastroVars.infoCnpjRazaoSocial,
      infoCnpjNomeFantasia: cadastroVars.infoCnpjNomeFantasia,
      infoCnpjCnae: [cnaeSelected],
      infoCnpjCnaeSecundario: selectedCnaeSecundario,
      endComeNumero: numero,
      endComeRua: rua,
      endComeCep: cepSearch,
      endComplemento: complemento,
      endComeBairro: bairro,
      endComeEstado: estado,
      endComeCidade: cidade,
      endCobNumero: numeroCob,
      endCobCep: cepSearchCob,
      endCobComplemento: complementoCob,
      endCobBairro: bairroCob,
      endCobEstado: estadoCob,
      endCobRua: ruaCob,
      endCobNum: numeroCob,
      endCobCidade: cidadeCob,
      nome_contrato: selectedContract.nome_contrato,
      cod_contrato: selectedContract.cod_contrato
    };

    //console.log(data);

    await api
      .post(`ravim/cadastro-empresas`, data)
      .then((res) => {
        setCod(res.status);
        setSnack(true);
        setSeverity('success');
        setMessage(res.data.message);
        setTimeout(() => {
          window.location.replace('/empresa-rh');
        }, 3000);
      })
      .catch((err) => {
        setCod(err.code);
        setSnack(true);
        setSeverity('error');
        setMessage(err.message);
      });
  }

  async function contract() {
    await api
      .get(`ravim/contratos-empresas`)
      .then((res) => {
        setContratos(res.data);
        // console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function searchCep() {
    const cep = cepSearch.replace('-', '');
    await api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        if (res.data) {
          setCep(res.data);
          setRua(res.data.logradouro);
          setBairro(res.data.bairro);
          setEstado(res.data.uf);
          setCidade(res.data.localidade);
        } else {
          setRua('');
          setBairro('');
          setEstado('');
          setCidade('');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function searchCepCob() {
    const cep = cepSearchCob.replace('-', '');
    await api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        if (res.data) {
          setCep(res.data);
          setRuaCob(res.data.logradouro);
          setBairroCob(res.data.bairro);
          setEstadoCob(res.data.uf);
          setCidadeCob(res.data.localidade);
        } else {
          setRuaCob('');
          setBairroCob('');
          setEstadoCob('');
          setCidadeCob('');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function cnaeSec() {
    await api
      .get(`ravim/cnae`)
      .then((res) => {
        setCnaeSecundario(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function validationCnpj(cnpj: string) {
    if (validaCNPJ(String(cnpj))) {
      setValidation(false);
    } else {
      setValidation(true);
    }
    return cnpj;
  }

  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Cadastrar Empresa RH
      </Typography>
      <div>
        <Paper style={{ padding: 30, width: '100%' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ marginBottom: 20, fontWeight: 'bold' }}
              >
                Informações da Empresa
              </Typography>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Nome Comercial"
                  value={cadastroVars.infoEmpNome}
                  onChange={(e: any) => dispatch(infoEmpNome(e.target.value))}
                />

                <Autocomplete
                  fullWidth
                  id="tags-outlined"
                  options={contratos}
                  getOptionLabel={(option: IContratos) =>
                    `${option.cod_contrato}`
                      ? `${option.cod_contrato} - ${option.nome_contrato}`
                      : ''
                  }
                  onChange={(e, newValue: any) => { setSelectedContrato(newValue); }}
                  value={selectedContract}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Contratos"
                      placeholder="Contratos"
                    />
                  )}
                />

                <Autocomplete
                  value={cadastroVars.infoEmpEmails}
                  onChange={(event, newValue) => {
                    dispatch(infoEmpEmails(newValue));
                  }}
                  multiple
                  fullWidth
                  id="tags-filled"
                  options={email.map((option) => {
                    console.log(option);
                  })}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="E-mails"
                      placeholder="Search"
                    />
                  )}
                />
              </div>
              <div className="containerFields">
                <Autocomplete
                  value={cadastroVars.infoEmpTelefone}
                  onChange={(event, newValue) => {
                    dispatch(
                      infoEmpTelefone(newValue.map((mask) => maskPhone(mask)))
                    );
                    console.log(newValue);
                  }}
                  multiple
                  fullWidth
                  id="tags-filled"
                  options={telefones.map((option) => option.telefone)}
                  freeSolo
                  renderTags={(value: string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Telefones"
                      placeholder="Aperte enter para salvar o telefone"
                      autoFocus={false}
                      type="number"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ marginBottom: 20, fontWeight: 'bold' }}
              >
                Informações do CNPJ
              </Typography>
              <div className="containerFields">
                <TextField
                  error={validation}
                  inputProps={{ maxLength: 14, minLength: 14 }}
                  fullWidth
                  variant="outlined"
                  label="Número do CNPJ"
                  value={cadastroVars.infoCnpjNumeroCnpj}
                  onChange={(e: any) =>
                    dispatch(
                      infoCnpjNumeroCnpj(
                        maskCNPJ(validationCnpj(e.target.value))
                      )
                    )
                  }
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Razão Social"
                  value={cadastroVars.infoCnpjRazaoSocial}
                  onChange={(e) =>
                    dispatch(infoCnpjRazaoSocial(e.target.value))
                  }
                />
              </div>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Nome Fantasia"
                  value={cadastroVars.infoCnpjNomeFantasia}
                  onChange={(e) =>
                    dispatch(infoCnpjNomeFantasia(e.target.value))
                  }
                />

                <Autocomplete
                  fullWidth
                  id="tags-outlined"
                  options={cnaeSecundario}
                  getOptionLabel={(option: ICnae) =>
                    `${option.id_cnae}`
                      ? `${option.id_cnae} - ${option.nome}`
                      : ''
                  }
                  onChange={(e, newValue: any) => { setCnaeSelected(newValue); }}
                  value={cnaeSelected}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="CNAE "
                      placeholder="CNAE"
                    />
                  )}
                />
              </div>
              <div className="containerFields">
                <Autocomplete
                  fullWidth
                  multiple
                  id="tags-outlined"
                  options={cnaeSecundario}
                  getOptionLabel={(option: ICnae) =>
                    `${option.id_cnae}`
                      ? `${option.id_cnae} - ${option.nome}`
                      : ''
                  }
                  onChange={(e, newValue: any) =>
                    { setSelectedCnaeSecundario(newValue); }
                  }
                  value={selectedCnaeSecundario}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="CNAE Secundário"
                      placeholder="CNAE Secundário"
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography
                style={{ marginBottom: 20, fontWeight: 'bold' }}
                variant="h5"
              >
                Endereço Comercial
              </Typography>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  value={cepSearch}
                  onChange={(e: any) => { setCepSearch(maskCEP(e.target.value)); }}
                  label="CEP"
                  inputProps={{ maxLength: 8 }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={rua}
                  onChange={(e: any) => { setRua(e.target.value); }}
                  label="Rua"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={numero}
                  onChange={(e: any) => { setNumero(e.target.value); }}
                  label="Número"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={complemento}
                  onChange={(e: any) => { setComplemento(e.target.value); }}
                  label="Complemento"
                />
              </div>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => { setBairro(e.target.value); }}
                  value={bairro}
                  label="Bairro"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => { setEstado(e.target.value); }}
                  value={estado}
                  label="Estado"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => { setCidade(e.target.value); }}
                  value={cidade}
                  label="Cidade"
                />
              </div>
            </Grid>
            <FormControlLabel
              control={
                <PurpleSwitch
                  checked={swt}
                  onChange={(e: any) => { setSwt(e.target.checked); }}
                  name="checkedA"
                />
              }
              label="Endereço de Cobrança"
            />

            {swt && swt ? (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ marginBottom: 20, fontWeight: 'bold' }}
                  >
                    Endereço de Cobrança
                  </Typography>
                  <div className="containerFields">
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={cepSearchCob}
                      onChange={(e) => { setCepSearchCob(maskCEP(e.target.value)); }}
                      inputProps={{ maxLength: 8 }}
                      label="CEP"
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={ruaCob}
                      onChange={(e) => { setRuaCob(e.target.value); }}
                      label="Rua"
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={numeroCob}
                      onChange={(e) => { setNumeroCob(e.target.value); }}
                      label="Número"
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={complementoCob}
                      onChange={(e) => { setComplementoCob(e.target.value); }}
                      label="Complemento"
                    />
                  </div>
                  <div className="containerFields">
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={bairroCob}
                      onChange={(e) => { setBairroCob(e.target.value); }}
                      label="Bairro"
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={estadoCob}
                      onChange={(e) => { setEstadoCob(e.target.value); }}
                      label="Estado"
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={cidadeCob}
                      onChange={(e) => { setCidade(e.target.value); }}
                      label="Cidade"
                    />
                  </div>
                </Grid>
              </>
            ) : (
              ''
            )}
          </Grid>
        </Paper>
        <div className="btnCadastro">
          <Link to={'/empresa-rh'} style={{ textDecoration: 'none' }}>
            <ButtonSair>Sair</ButtonSair>
          </Link>
          <ButtonAdd
            disabled={validation}
            onClick={sendInfo}
            variant="outlined"
          >
            Cadastrar
          </ButtonAdd>
        </div>
      </div>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => { setSnack(!snack); }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
}
