import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { type ColaboradoresProps } from '@pages/ExamesAvencer';
import { type IAgenda } from '@pages/Financeiro/ExamesEmpresas';
import React from 'react';

interface Props {
  dataInicio: string;
  dataFim?: string;
  cnpjExcel: any[];
  selectedCnpj: any;
  isLoandingField?: boolean;
  onChangeDataInicio: (date: string) => void;
  onChangeDateFim?: (date: string) => void;
  onChangeRazaoSocial: (value: string) => void;
  onChangeSelectedCnpj: (value: string) => void;
}

export function FilterDateExcel({
  dataInicio,
  dataFim,
  cnpjExcel,
  selectedCnpj,
  isLoandingField,
  onChangeDataInicio,
  onChangeDateFim,
  onChangeRazaoSocial,
  onChangeSelectedCnpj
}: Props) {
  return (
    <>
      {/* <TextField
        label="Data Inicio"
        variant="outlined"
        type="month"
        
        InputLabelProps={{
          shrink: true
        }}
        fullWidth
        value={dataInicio}
        onChange={(e) => onChangeDataInicio(e.target.value)}
      /> */}
      <TextField
        label="Data"
        variant="outlined"
        type="month"
        InputLabelProps={{
          shrink: true
        }}
        fullWidth
        value={dataInicio}
        onChange={(e) => { onChangeDataInicio(e.target.value); }}
      />
      <Autocomplete
        fullWidth
        id="tags-outlined"
        options={cnpjExcel}
        getOptionLabel={(option: IAgenda & ColaboradoresProps) =>
          `${option?.cnpj || option?.cpf_cnpj_comer}`
            ? `${option.razao_social || option?.nome_comercial} - ${
                option?.cnpj || option?.cpf_cnpj_comer
              }`
            : ''
        }
        onChange={(e, newValue: any) => {
          onChangeRazaoSocial(
            newValue?.razao_social || newValue?.nome_comercial
          );
          onChangeSelectedCnpj(newValue?.cnpj || newValue?.cpf_cnpj_comer);
        }}
        value={selectedCnpj}
        filterSelectedOptions
        loading={isLoandingField}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="CNPJ"
            placeholder="CNPJ"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoandingField ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </>
  );
}
