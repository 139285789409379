import React, {
  useRef,
  useState,
  useEffect,
  ReactElement,
  ReactEventHandler
} from 'react';
import './SearchComponentStyle.css';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

interface IItem {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  razao_social: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  telemedicina: string;
  observacao: string;
}

interface ISelect {
  options: IItem[];
  onChange: (value: IItem) => void;
  defaultValue?: string;
  value?: string;
}

const SelectComponent: React.FC<ISelect> = ({
  options,
  onChange,
  defaultValue,
  value
}) => {
  const [hideDropdown, setHideDropdown] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<IItem | null | undefined>(
    null
  );
  const [search, setSearch] = useState<string>('');
  const [defVal, setDefVal] = useState<string | undefined>(defaultValue);
  const filter =
    options.length > 0
      ? options.filter(
          (agendaSearch: IItem) =>
            agendaSearch.cpf_cnpj
              ?.toUpperCase()
              .includes(search.toUpperCase()) ||
            agendaSearch.nome_comercial
              ?.toUpperCase()
              .includes(search.toUpperCase())
        )
      : [];

  useEffect(() => {
    const handler = () => { setHideDropdown(false); };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  });

  useEffect(() => {
    if (value === 'presencial') {
      setSelectValue(undefined);
    } else {
      setSelectValue(undefined);
    }
  }, [value]);

  const handleInputClick = (e: any) => {
    e.stopPropagation();
    setHideDropdown(!hideDropdown);
  };

  const getValue = () => {
    if (selectValue) {
      return `${selectValue.nome_comercial} - ${selectValue.estado} - ${selectValue.cidade}`;
    }
    return defVal || '';
  };
  const valueSelected = (e: any) => {
    setSelectValue(e.target.value);
    setSearch(e.target.value);
    setDefVal('');
  };

  const onItemClick = (option: IItem) => {
    setSelectValue(option);
    onChange(option);
  };

  const isSelected = (option: IItem) => {
    if (!selectValue) {
      return false;
    }
    return selectValue.cpf_cnpj === option.cpf_cnpj;
  };

  return (
    <div className="containerSearch">
      <div className="inputIcon">
        <input
          autoComplete="do-not-autofill"
          type="text"
          placeholder="Nome Comercial"
          onClick={handleInputClick}
          value={search || getValue()}
          onChange={valueSelected}
          className="input"
        />
        <button onClick={handleInputClick} className="buttonKeyboard">
          {!hideDropdown ? (
            <KeyboardArrowDown className="iconSearch" />
          ) : (
            <KeyboardArrowUp className="iconSearch" />
          )}
        </button>
      </div>
      {hideDropdown && (
        <div className="dropdown-menu">
          {filter &&
            filter?.map((option) => (
              <div
                onClick={() => { onItemClick(option); }}
                key={option.cpf_cnpj}
                className={`dropdown-item ${isSelected(option)} && "selected"`}
              >
                {`${option.nome_comercial} - ${option.estado} - ${option.cidade}`}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SelectComponent;
