import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import { type IStatus } from '.';
import { api } from '../../../services/api';
import { Autocomplete } from '@material-ui/lab';
import { ASO } from '@data/tipoAso';
import { status } from '@data/status';
import { type IColaborador } from '@dtos/colaboradorDTO';
import { Button } from '@components/Button';
import { ColaboradorProps, SearchCPF } from '@components/SearchCPF';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { NavLink } from '@components/NavLink';
import { cpfValidation, maskCPF } from '@utils/masks';
import { AppError } from '@utils/AppError';
import { InputSearchColab } from '@components/InputSearchColab';

interface INomeComercial {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  razao_social: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  telemedicina: string;
  observacao: string;
}

export const ItsTelemedicina: React.FC<IStatus> = (props) => {
  const [listNomeComercial, setListNomeComercial] = useState<INomeComercial[]>(
    []
  );
  const [selectedNomeComercial, setSelectedNomeComercial] = useState<any>();
  const [searchEmail, setSearchEmail] = useState<any>();
  const [emailAgendamento, setEmailAgendamento] = useState([]);
  const [loadingColab, setLoadingColab] = useState(false);

  const [statusAgendamento, setStatusAgendameto] = useState<string>('');
  const [cpfColab, setCpfColab] = useState<string>('');
  const [nomeColab, setNomeColab] = useState<string>('');
  const [estado, setEstado] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');
  const [dataAgendamento, setDataAgendamento] = useState<string>('');
  const [horarioAgendamento, setHorarioAgendamento] = useState<string>('');
  const [tipoAso, setTipoAso] = useState<string>('');
  const [obs, setObs] = useState<string>('');
  const [cnpjComercial, setCnpjComercial] = useState<string>('');
  const [messageError, setMessageError] = useState('');

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color>();
  const [linkDoAtendimento, setLinkDoAtendimento] = useState('');
  const nome_user = localStorage.getItem('NameUser') || '';
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    reqStatus();
  }, [props.location.state]);

  // console.log(selectedNomeComercial)

  async function sendAgendamento() {
    const hora = new Date();
    setIsSubmitting(true);

    const newHora = hora.toLocaleString('pt-BR', { hour12: false });
    const data = {
      status: statusAgendamento,
      observacao: obs,
      tipo_aso: tipoAso,
      horario: horarioAgendamento,
      data_agendamento: dataAgendamento,
      estado,
      cidade,
      nome_comercial: selectedNomeComercial,
      tipo_agendamento: props.location.state.tipo_agendamento,
      nome: nomeColab,
      cpf_colaborador: cpfColab,
      num_agenda: null,
      usuario: `${nome_user} ${newHora}`,
      email_agendamento: emailAgendamento,
      ordem_chegada: null,
      cpf_cnpj_comer: cnpjComercial,
      linkDoatendimento: linkDoAtendimento
    };
    //console.log(data);

    try {
      const res = await api.post(`/ravim/agendamento`, data);
      setCod(String(res.status));
      setSnack(true);
      setSeverity('success');
      setMessage(res.data.message);
      setTimeout(() => {
        window.location.replace('/agendar');
      }, 1000);
    } catch (error) {
      if (error instanceof AppError) {
        setCod(String(error.code));
        setSnack(true);
        setSeverity('error');
        setMessage(String(error.message));
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  async function reqStatus() {
    try {
      const data = {
        tipo_agendamento: props.location.state.tipo_agendamento,
        tipo: props.location.state.tipo_agendamento
      };

      const res = await api.post(`/ravim/telemed-clinicas`, data);
      if (res.status === 200 || res.status === 200) {
        setListNomeComercial(res.data);

        // console.log(res.data)
      } else {
        setListNomeComercial([]);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.status === 500) {
          setListNomeComercial([]);
        }
      }
    }
  }

  async function handleSearchCpf(cpf: string) {
    setCpfColab(cpf);

    try {
      setLoadingColab(true);
      if (cpf.length === 14) {
        const response = await api.post(`ravim/pesq-cpf`, {
          cpf_colaborador: cpf
        });
        setNomeColab(response.data.nome_completo);
      }
    } catch (error) {
      if (error instanceof AppError) {
        if (error.code === 400) {
          setNomeColab('');
          setMessageError(error.message);
        }
      }
    } finally {
      setLoadingColab(false);
    }
  }

  useEffect(() => {
    if (cpfColab.length <= 0) {
      setMessageError('');
      setNomeColab('');
    }
    if (nomeColab) {
      setMessageError('');
    }
  }, [cpfColab, nomeColab]);

  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Agendamento por Telemedicina
      </Typography>
      <Paper style={{ padding: 40 }}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Status
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl variant="outlined" style={{ width: '20%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  value={statusAgendamento}
                  onChange={(e: any) => {
                    setStatusAgendameto(e.target.value);
                  }}
                >
                  {status.map((status, index) => (
                    <MenuItem key={index} value={status.value}>
                      {status.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Colaborador
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <InputSearchColab
                label="CPF"
                value={cpfColab}
                onChange={(e) => handleSearchCpf(e.toString())}
                errorShow={!!messageError}
                textError={messageError}
                isLoading={loadingColab}
              />

              <TextField
                disabled
                label="Nome"
                variant="outlined"
                fullWidth
                value={nomeColab}
                onChange={(e) => {
                  setNomeColab(e.target.value);
                }}
              />
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Atendimento por Telemedicina
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <Autocomplete
                defaultValue={null}
                fullWidth
                id="tags-outlined"
                options={listNomeComercial}
                getOptionLabel={(option: INomeComercial) =>
                  `${option?.cpf_cnpj}`
                    ? `${option?.nome_comercial} - ${option?.estado} - ${option?.cidade}`
                    : ''
                }
                onChange={(e, newValue: any) => {
                  setSearchEmail(newValue.cpf_cnpj);
                  setSelectedNomeComercial(newValue.nome_comercial);
                  setCnpjComercial(newValue.cpf_cnpj);
                  setCidade(newValue.estado);
                  setEstado(newValue.cidade);
                }}
                value={selectedNomeComercial}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Nome Credenciado"
                    placeholder="Nome Credenciado"
                  />
                )}
              />
            </div>

            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Data do agendamento"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                value={dataAgendamento}
                onChange={(e) => {
                  setDataAgendamento(e.target.value);
                }}
              />
              <TextField
                label="Horário"
                type="time"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                value={horarioAgendamento}
                onChange={(e) => {
                  setHorarioAgendamento(e.target.value);
                }}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl variant="outlined" style={{ width: '20%' }}>
                <InputLabel>Tipo de ASO</InputLabel>
                <Select
                  label="Tipo de ASO"
                  value={tipoAso}
                  onChange={(e: any) => {
                    setTipoAso(e.target.value);
                  }}
                >
                  {ASO.map((aso, index) => (
                    <MenuItem key={index} value={aso.value}>
                      {aso.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Link do atendimento"
                variant="outlined"
                value={linkDoAtendimento}
                onChange={(value) => {
                  setLinkDoAtendimento(value.target.value);
                }}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                minRows={4}
                label="OBS"
                multiline
                variant="outlined"
                value={obs}
                onChange={(e) => {
                  setObs(e.target.value);
                }}
                fullWidth
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          marginTop: 20
        }}
      >
        <NavLink to={{ pathname: '/agendar', state: { exit: true } }}>
          <Button title="Sair" variantstyle="RED" />
        </NavLink>

        <Button
          disabled={isSubmitting}
          title="Salvar"
          onClick={sendAgendamento}
        />
      </div>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
