import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from '@pages/Dashboard';

import ListaEmpresas from '@pages/Contratos/ContratosEmpresas';
import ListaCredenciados from '@pages/Contratos/ContratosCredenciados';
import CadastroContratosEmp from '@pages/Contratos/ContratosEmpresas/CadastroContratosEmp';
import CadastroContratosCred from '@pages/Contratos/ContratosCredenciados/CadastroContratosCredenciados';

import EmpresaRH from '@pages/EmpresasRH/CadastroEmpresa';
import { Credenciados } from '@pages/Credenciados';
import { CadastrarCredenciado } from '@pages/Credenciados/CadastroCredenciado/cadastroCredenciado';
import { EditarCredenciado } from '@pages/Credenciados/EditarCredenciado/editarCredenciado';
import { Agendamentos } from '@pages/Agendamentos';
import { EditAgendamento } from '@pages/Agendamentos/MarcarHorario/editAgendamento';
import Administrativo from '@pages/Administrativos';
import Empresas from '@pages/EmpresasRH/Empresa';
import { EditContract } from '@pages/Contratos';
import { Ghe } from '@pages/EmpresasRH/GHE';
import { Localobra } from '@pages/EmpresasRH/LocalObra';
import { Cadastroghi } from '@pages/EmpresasRH/GHE/CadastroGHE';
import { Cadastrolocalobra } from '@pages/EmpresasRH/LocalObra/CadastroLocalObra';
import EditEmpresaRH from '@pages/EmpresasRH/CadastroEmpresa/editEmpRh';
import { EditLocalObra } from '@pages/EmpresasRH/LocalObra/EditLocalObra/editLocalObra';
import { EditGhe } from '@pages/EmpresasRH/GHE/EditGHE/editGhe';
import { Coloboradores } from '@pages/Colaboradores';
import { CadastrarColaborador } from '@pages/Colaboradores/CadastrarColaborador/cadastrarColaborador';
import { EditarColaborador } from '@pages/Colaboradores/EditarColaborador/editarColaborador';
import { MarcarHorario } from '@pages/Agendamentos/MarcarHorario';
import { PDF } from '@pages/Agendamentos/PDF/gerarPdf';
import { Comparativo } from '@pages/Financeiro/Comparativo';
import { ExamesCredenciados } from '@pages/Financeiro/ExamesCredenciados';
import { ExamesEmpresas } from '@pages/Financeiro/ExamesEmpresas';
import Kit from '@pages/Kit';
import { ExamesAvencer } from '@pages/ExamesAvencer';
import { Esocial } from '@pages/Esocial';
import { EditarEsocial } from '@pages/Esocial/EditarEsocial';

const AppRoutes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Dashboard} />
    <Route path="/credenciados" component={Credenciados} />
    <Route path="/cadastro-credenciados" component={CadastrarCredenciado} />
    <Route path="/editar-credenciado" component={EditarCredenciado} />
    <Route path="/agendamentos" component={Agendamentos} />
    <Route path="/agendar" component={MarcarHorario} />
    <Route path="/editar-agendamento" component={EditAgendamento} />

    <Route path="/contratos-empresa" component={ListaEmpresas} />
    <Route path="/contratos-credenciado" component={ListaCredenciados} />
    <Route path="/cadastro-contrato-empresa" component={CadastroContratosEmp} />
    <Route
      path="/cadastro-contrato-credenciado"
      component={CadastroContratosCred}
    />

    <Route path="/cadastro-empresas" component={EmpresaRH} />
    <Route path="/PDF" component={PDF as any} />

    <Route path="/edit-empresa-rh" component={EditEmpresaRH} />
    <Route path="/empresa-rh" component={Empresas} />
    <Route path="/editar-contrato" component={EditContract} />
    <Route path="/local-obra" component={Localobra} />
    <Route path="/editar-local-obra" component={EditLocalObra} />
    <Route path="/ghe" component={Ghe} />
    <Route path="/edit-ghe" component={EditGhe} />
    <Route path="/cadastro-ghe" component={Cadastroghi} />
    <Route path="/cadastro-local-obra" component={Cadastrolocalobra} />
    <Route path="/colaboradores" component={Coloboradores} />
    <Route path="/cadastro-colaborador" component={CadastrarColaborador} />
    <Route path="/editar-colaborador" component={EditarColaborador} />
    <Route path="/comparativo-exames" component={Comparativo} />
    <Route path="/exames-credenciados" component={ExamesCredenciados} />
    <Route path="/exames-empresas" component={ExamesEmpresas} />
    <Route path="/exames-a-vencer" component={ExamesAvencer} />
    <Route path="/e-social" component={Esocial} />
    <Route path="/edit-e-social" component={EditarEsocial} />
    <Route path="/kit" component={Kit} />

    <Route path="/adm" component={Administrativo} />
  </Switch>
);

export default AppRoutes;
