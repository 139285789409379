import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Collapse,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { api } from '@services/api';
import { IAgenda, IExcel } from '@pages/Financeiro/ExamesEmpresas';
import { maskCPF, maskDate } from '@utils/masks';
import { Excel } from '@components/Excel';
import { FilterDateExcel } from '@components/FilterDateExcel';
import { Button } from '@components/Button';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { Pagination } from '@material-ui/lab';
import usePagination from '@hooks/usePagination';
import { AutocompleteCnpj } from './components/AutocompleteCnpj';
import { situ } from '@data/colab';

export interface ExamesAvencerProps {
  nome_completo: string;
  cpf_colaborador: string;
  data_agendamento: string;
  cnpj: string;
  razao_social: string;
  local_obra: string;
  ghe: string;
  cod_ghe: string;
  nome_exame: string;
  dias: number;
  dias_vencer: number;
  cor: string;
  status: string;
}

function createData(
  nome_comercial: string,
  cpf: string,
  nome: string,
  data: string,
  cnpj: string,
  razao_social: string,
  situacao: string,
  ghe: string,
  local_obra: string
) {
  return {
    nome_comercial,
    cpf,
    nome,
    data,
    cnpj,
    razao_social,
    situacao,
    ghe,
    local_obra
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [exames, setExames] = useState<ExamesAvencerProps[]>([]);
  const [loading, setLoading] = useState(true);

  async function fetchExamesAvencer(cpf_colaborador: string) {
    const data = {
      cpf_colaborador
    };

    setOpen(!open);
    try {
      if (!open) {
        setLoading(true);
        const response = await api.post('ravim/exames-vencer', data);
        if (response.data.estado === 404) {
          setExames([]); return;
        }
        setExames(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={async () => { await fetchExamesAvencer(row.cpf); }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.cnpj}
        </TableCell>
        <TableCell align="right">{row.razao_social}</TableCell>
        <TableCell align="right">{row.cpf}</TableCell>
        <TableCell align="right">{row.nome}</TableCell>
        <TableCell align="right">{row.situacao}</TableCell>
        <TableCell align="right">{row.data}</TableCell>
        <TableCell align="right">{row.ghe}</TableCell>
        <TableCell align="right">{row.local_obra}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, background: '#e3e3e3' }}
          colSpan={9}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Exames
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Exame</TableCell>
                    <TableCell>Data do último exame</TableCell>
                    <TableCell>Dias para periódico</TableCell>
                    <TableCell>Dias para vencer o exame</TableCell>
                  </TableRow>
                </TableHead>
                {loading && (
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <CircularProgress />
                  </span>
                )}
                {exames.length === 0 && (
                  <Typography variant="h6">Exame vazio</Typography>
                )}
                <TableBody>
                  {exames?.map((exame, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {exame.nome_exame}
                      </TableCell>
                      <TableCell>
                        {exame?.data_agendamento
                          ? maskDate(exame?.data_agendamento)
                          : ''}
                      </TableCell>
                      <TableCell>{exame.dias}</TableCell>
                      <TableCell
                        style={{
                          background: exame.cor,
                          fontWeight: 'bold'
                        }}
                      >
                        {exame.dias_vencer}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export interface ColaboradoresProps {
  id_colaboradores: number;
  matricula_empresa: string;
  matricula_esocial: string;
  nome_completo: string;
  cpf_colaborador: string;
  rg: string;
  data_nascimento: string;
  genero: string;
  cnh: string;
  email: string;
  telefone: string;
  situacao: string;
  nis: string;
  funcao: string;
  setor: string;
  turno: string;
  cnpj: string;
  razao_social: string;
  data_admissao: string;
  horario_trab_entrada: string;
  horario_trab_saida: string;
  local_obra: string;
  cod_ghe: string;
  ghe: string;
  exibir: string;
  data_ultimo_agendamento: string;
}

export function ExamesAvencer() {
  const [dataUser, setDataUser] = useState<ColaboradoresProps[]>([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [excel, setExcel] = useState<ExamesAvencerProps[]>([]);
  const [selectedCnpj, setSelectedCnpj] = useState<any>();
  const [nomeEmpresa, setNomeEmpresa] = useState<string>('');
  const [cnpjExcel, setCnpjExcel] = useState<ColaboradoresProps[]>([]);
  const [situacao, setSituacao] = useState('');
  const [filterContracts, setFilterContracts] = useState<ColaboradoresProps[]>(
    []
  );
  const [valueSearch, setValueSearch] = useState('');
  const [listEmpty, setListEmpty] = useState('');

  const [page, setPage] = useState(1);
  const PER_PAGE = 30;

  const count = Math.ceil(dataUser.length / PER_PAGE);
  const _DATA = usePagination(dataUser, PER_PAGE);

  const filterContractsPerPage =
    _DATA.currentData().length > 0
      ? _DATA
          .currentData()
          .filter(
            (contrato: ColaboradoresProps) =>
              contrato.cnpj?.toUpperCase().includes(search.toUpperCase()) ||
              contrato?.razao_social
                ?.toUpperCase()
                .includes(search.toUpperCase()) ||
              contrato?.nome_completo
                ?.toUpperCase()
                .includes(search.toUpperCase()) ||
              contrato?.cpf_colaborador
                .toUpperCase()
                .includes(search.toUpperCase())
          )
      : [];

  const filter =
    filterContracts.length > 0 ? filterContracts : filterContractsPerPage;

  const rows = filter.map((colab) => {
    return createData(
      colab.razao_social,
      colab.cpf_colaborador,
      colab.nome_completo,
      colab.data_ultimo_agendamento
        ? maskDate(colab.data_ultimo_agendamento)
        : 'Sem data',
      colab.cnpj,
      colab.razao_social,
      colab.situacao,
      colab.ghe,
      colab.local_obra
    );
  });

  const fetchFilter = useCallback(
    async (name: string, situacao: string) => {
      try {
        handleSearchFilter();

        const nameColab = name.toUpperCase().trim();
        const situacaoColab = situacao.toUpperCase().trim();

        const data = {
          nome: nameColab,
          situacao: situacaoColab
        };

        const response = await api.post('ravim/pesq-exame', data);
        setFilterContracts(response.data);
      } catch (error: any) {
        if (error.response.status === 404) {
          setFilterContracts([]);
          setListEmpty(error.response.data.message);
        }
      }
    },
    [filterContracts]
  );

  async function fetchListAgendamentos() {
    try {
      setIsLoading(true);
      const response = await api.get('/ravim/exames-vencer');
      if (response.data.estado === 404) return;
      setDataUser(response.data);
      setCnpjExcel(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function reqExcel() {
    try {
      const data = {
        cnpj: selectedCnpj
      };
      const response = await api.post('/ravim/excel-exames-vencer', data);
      setExcel(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const adjustExcel = excel.map((data) => {
    return {
      cnpj: data.cnpj,
      razao_social: data.razao_social,
      nome: data.nome_completo,
      cpf: data.cpf_colaborador,
      exame: data.nome_exame,
      dias_a_vencer: data.dias_vencer,
      status: data.status
    };
  });

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    fetchListAgendamentos();
  }, []);

  function handleClearFields() {
    setSearch('');
    setValueSearch('');
    setSituacao('');
    setListEmpty('');
    setFilterContracts([]);
  }

  function handleSearchFilter() {
    setSearch(valueSearch);
  }

  const uniqueCnpj = cnpjExcel.filter((item, index) => {
    return index === cnpjExcel.findIndex((cnpj) => item.cnpj === cnpj.cnpj);
  });

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
          marginBottom: 20,
          alignItems: 'center'
        }}
      >
        <TextField
          value={valueSearch}
          onChange={(e) => { setValueSearch(e.target.value); }}
          label="Pesquisar empresas"
          type="text"
          variant="outlined"
          style={{ background: '#FFF' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setValueSearch(valueSearch);
            }
          }}
        />
        <FormControl variant="outlined" style={{ width: '20%' }}>
          <InputLabel>Situação</InputLabel>
          <Select
            label="Situação"
            value={situacao}
            onChange={(e: any) => { setSituacao(e.target.value); }}
          >
            {situ &&
              situ.map((situacao) => (
                <MenuItem value={situacao.value} key={situacao.id}>
                  {situacao.situacao}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          variantstyle="SECUNDARY"
          icon={<MagnifyingGlass size={25} />}
          onClick={() => {
            fetchFilter(valueSearch, situacao);
          }}
        />
        {filterContracts.length > 0 || !!listEmpty ? (
          <Button
            variantstyle="RED"
            icon={<X size={25} />}
            onClick={handleClearFields}
          />
        ) : (
          <></>
        )}
      </div>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Exames a vencer
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20
        }}
      >
        <Typography variant="h6" align="left">
          Total: {dataUser.length}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 20,
            width: '50%'
          }}
        >
          <AutocompleteCnpj
            cnpjExcel={uniqueCnpj}
            selectedCnpj={selectedCnpj}
            onChangeRazaoSocial={(e) => { setNomeEmpresa(e); }}
            onChangeSelectedCnpj={(e) => { setSelectedCnpj(e); }}
          />
          <Excel
            reqExcell={reqExcel}
            excelData={adjustExcel.flat()}
            fileName={nomeEmpresa}
            cnpjSelected={selectedCnpj}
          />
        </div>
      </div>
      {!listEmpty ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 20
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />

          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>CNPJ</TableCell>
                  <TableCell align="right">Razão Social</TableCell>
                  <TableCell align="right">CPF</TableCell>
                  <TableCell align="right">Nome Completo</TableCell>
                  <TableCell align="right">Situação</TableCell>
                  <TableCell align="right">Data do último ASO</TableCell>
                  <TableCell align="right">GHE</TableCell>
                  <TableCell align="right"> Local/Obra</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <Row key={index} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </div>
      ) : (
        <Typography align="center" variant="h5">
          {listEmpty}
        </Typography>
      )}
      {isLoading && (
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </span>
      )}
    </Container>
  );
}
