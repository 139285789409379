import {
  Button,
  Container,
  Paper,
  Snackbar,
  TextField,
  Typography,
  withStyles,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import { api } from '@services/api';
import { Autocomplete } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { maskCEP, maskCNPJ, maskCharSpecial, validaCNPJ } from '@utils/masks';
import { AppError } from '@utils/AppError';

interface ICep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface IContratos {
  nome_contrato: string;
  cod_contrato: string;
  data_criacao: string | Date;
  data_renovacao: string | Date;
  arquivo: File | null;
}

interface ICadastroLocalObra {
  cod_local: string;
  nome_local: string;
  razao_social: string;
  data_inicio: string;
  data_fim: string;
  num_cnpj: string;
  rua: string;
  numero: string;
  cep: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
}

interface ICnpj {
  bairro: string;
  cep: string;
  cidade: string;
  cnae: string;
  cnae_secundario: string;
  complemento: string;
  estado: string;
  idcad_empresa: number;
  inf_contrato: string;
  nome_empresa: string;
  nome_fantacia: string;
  num_cnpj: string;
  numero: number | null;
  razao_social: string;
  rua: string;
}
const ButtonAdd = withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B'
    }
  }
}))(Button);

const ButtonSair = withStyles(() => ({
  root: {
    background: 'red',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'red',
      color: 'red',
      border: 'solid 1px red'
    }
  }
}))(Button);

export const Cadastrolocalobra = () => {
  const [cnpj, setCnpj] = useState<ICnpj[]>([]);
  const [selectedCnpj, setSelectedCnpj] = useState<any>();

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color>();

  const [codLocal, setCodLocal] = useState('');
  const [nomeLocal, setNomeLocal] = useState('');
  const [cnpjLocal, setCnpjLocal] = useState('');
  const [razao, setRazao] = useState('');
  const [dataCriacao, setDataCriacao] = useState('');
  const [dataFim, setDataFim] = useState('');

  const [cep, setCep] = useState<ICep>();
  const [cepSearch, setCepSearch] = useState<string>('');
  const [rua, setRua] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [complemento, setComplemento] = useState<string>('');
  const [bairro, setBairro] = useState<string>('');
  const [estado, setEstado] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');
  const [validation, setValidation] = useState<boolean>(false);
  const [tagLocalObra, setTagLocalObra] = useState('');

  useEffect(() => {
    if (cepSearch.length === 9) {
      searchCep();
    }
    if (!cepSearch) {
      setRua('');
      setBairro('');
      setEstado('');
      setCidade('');
    }
  }, [cepSearch]);

  useEffect(() => {
    cnpjCadastrado();
  }, []);

  async function cadastroLocalObra() {
    const data = {
      cod_local: tagLocalObra ? `${codLocal}-${tagLocalObra
        .replace(/(\s{1})/g, '-$1')
        .replace(/\s/g, '')}`.trim() : codLocal.trim(),
      cnpj_local: cnpjLocal.trim(),
      nome_local: nomeLocal.trim(),
      razao_social: razao.trim(),
      data_inicio: dataCriacao,
      data_fim: dataFim,
      num_cnpj: String(selectedCnpj),
      rua,
      numero,
      cep: cepSearch,
      complemento: complemento.trim(),
      bairro,
      cidade,
      estado
    };

    await api
      .post(`ravim/local-obra`, data)
      .then((res) => {
        setCod(String(res.status));
        setSnack(true);
        setSeverity('success');
        setMessage(res.data.message);
        setTimeout(() => {
          window.location.replace('/local-obra');
        }, 3000);
        console.log(res.data);
      })
      .catch((err) => {
        if(err instanceof AppError){
          console.log(err)
          setCod(String(err.code));
          setSnack(true);
          setSeverity('error');
          setMessage(err.message);
        }
      });
    console.log(data);
  }

  async function cnpjCadastrado() {
    try {
      const res = await api.get(`ravim/empresas-rh`);
      if (res.status === 404) return;
      setCnpj(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function searchCep() {
    const cep = cepSearch.replace('-', '');
    await api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        if (res.data) {
          setCep(res.data);
          setRua(res.data.logradouro);
          setBairro(res.data.bairro);
          setEstado(res.data.uf);
          setCidade(res.data.localidade);
        } else {
          setRua('');
          setBairro('');
          setEstado('');
          setCidade('');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function validationCNPJ(cnpj: string) {
    cnpj = cnpj.replace(/[.-]/g, '');
    if (validaCNPJ(cnpj)) {
      setValidation(false);
    } else {
      setValidation(true);
    }
    return cnpj;
  }

  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Cadastrar Local Obra
      </Typography>
      <div>
        <Paper style={{ padding: 30, width: '100%' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ marginBottom: 20, fontWeight: 'bold' }}
              >
                Informações do Local/Obra
              </Typography>
              <div className="containerFields">
                <Autocomplete
                  fullWidth
                  id="tags-outlined"
                  options={cnpj}
                  getOptionLabel={(option: ICnpj) =>
                    `${option.num_cnpj}`
                      ? `${option.num_cnpj} - ${option.nome_empresa}`
                      : ''
                  }
                  onChange={(e, newValue: any) => {
                    setSelectedCnpj(newValue.num_cnpj || '');
                    setCodLocal(newValue.cod_contrato);
                    if (newValue.cod_contrato !== codLocal) {
                      setTagLocalObra('');
                    }
                  }}
                  value={selectedCnpj}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="CNPJ Cadastrados"
                      placeholder="CNPJ Cadastrados"
                    />
                  )}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={
                    tagLocalObra
                      ? `${codLocal}-${tagLocalObra
                          .replace(/(\s{1})/g, '-$1')
                          .replace(/\s/g, '')}`
                      : codLocal
                  }
                  disabled
                  onChange={(e) => {
                    setCodLocal(e.target.value);
                  }}
                  label="Código do Local"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Tag Local/Obra"
                  value={tagLocalObra}
                  disabled={!selectedCnpj}
                  onChange={(e) => {
                    setTagLocalObra(maskCharSpecial(e.target.value));
                  }}
                />
              </div>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  value={nomeLocal}
                  onChange={(e) => {
                    setNomeLocal(e.target.value);
                  }}
                  label="Nome do Local"
                />
                <TextField
                  fullWidth
                  required
                  error={validation}
                  variant="outlined"
                  value={cnpjLocal}
                  onChange={(e) => {
                    setCnpjLocal(maskCNPJ(validationCNPJ(e.target.value)));
                  }}
                  label="CNPJ do Local"
                  inputProps={{ maxLength: 14 }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={razao}
                  onChange={(e) => {
                    setRazao(e.target.value);
                  }}
                  label="Razão Social"
                />
              </div>
              <div className="containerFields">
                <TextField
                  id="date"
                  label="Data de Criação"
                  type="date"
                  variant="outlined"
                  value={dataCriacao}
                  onChange={(e) => {
                    setDataCriacao(e.target.value);
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  id="date"
                  label="Data de Fim"
                  type="date"
                  value={dataFim}
                  onChange={(e) => {
                    setDataFim(e.target.value);
                  }}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography
                style={{ marginBottom: 20, fontWeight: 'bold' }}
                variant="h5"
              >
                Endereço
              </Typography>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  value={cepSearch}
                  onChange={(e: any) => {
                    setCepSearch(maskCEP(e.target.value));
                  }}
                  label="CEP"
                  inputProps={{ maxLength: 8 }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={rua}
                  onChange={(e: any) => {
                    setRua(e.target.value);
                  }}
                  label="Rua"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={numero}
                  onChange={(e: any) => {
                    setNumero(e.target.value);
                  }}
                  label="Número"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={complemento}
                  onChange={(e: any) => {
                    setComplemento(e.target.value);
                  }}
                  label="Complemento"
                />
              </div>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => {
                    setBairro(e.target.value);
                  }}
                  value={bairro}
                  label="Bairro"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => {
                    setEstado(e.target.value);
                  }}
                  value={estado}
                  label="Estado"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => {
                    setCidade(e.target.value);
                  }}
                  value={cidade}
                  label="Cidade"
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
        <div className="btnCadastro">
          <Link to={'local-obra'} style={{ textDecoration: 'none' }}>
            <ButtonSair>Sair</ButtonSair>
          </Link>
          <ButtonAdd
            disabled={validation}
            variant="outlined"
            onClick={cadastroLocalObra}
          >
            Cadastrar
          </ButtonAdd>
        </div>
      </div>

      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
