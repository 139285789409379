import { Container, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import {
  CardEmployee,
  ContainerButtons,
  Content,
  DivEmployeer,
  DivSearch,
  Main
} from './styles';
import { Button } from '@components/Button';
import {
  MagnifyingGlass,
  Trash,
  User,
  UserPlus,
  X
} from '@phosphor-icons/react';
import { Pen } from '@phosphor-icons/react/dist/ssr';
import { ModalEditarFuncionario } from './components/ModalEditarFuncionario';
import { ModalDeletarFuncionario } from './components/ModalDeletarFuncionario';
import {
  CadastroFuncionarioFormData,
  ModalCadastrarFuncionario
} from './components/ModalCadastrarFuncionario';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { getFuncionarios } from '@api/get-funcionarios';

const searchSchemaFormData = z.object({
  funcionario: z
    .string()
    .min(3, { message: 'Digite no mínimo 3 caracteres.' })
    .optional(),
  cod_contrato: z.string().optional()
});

type SearchEmployeerProps = z.infer<typeof searchSchemaFormData>;

export function Funcionarios() {
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalCadastro, setOpenModalCadastro] = useState(false);
  const [editEployee, setEditEmployee] = useState(
    {} as CadastroFuncionarioFormData
  );
  const [deleteEmployee, setDeleteEmployee] = useState(
    {} as CadastroFuncionarioFormData
  );

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors, isSubmitting, isValid }
  } = useForm<SearchEmployeerProps>({
    resolver: zodResolver(searchSchemaFormData)
  });

  const {
    data: getDataEmployee,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: ['getUserAdm'],
    queryFn: getFuncionarios
  });

  function handleCloseModalDelete() {
    setOpenModalDelete(false);
  }

  function handleCloseModalEdit() {
    setOpenModalEdit(false);
  }

  function handleCloseModalCadastro() {
    setOpenModalCadastro(false);
  }

  async function handleOpenModalEdit(index: number) {
    if (!getDataEmployee) return;
    setEditEmployee(getDataEmployee[index]);
    setOpenModalEdit(true);
  }

  function handleOpenModalDelete(index: number) {
    if (!getDataEmployee) return;
    setOpenModalDelete(true);
    setDeleteEmployee(getDataEmployee[index]);
  }

  function handleOpenModalCadastro() {
    setOpenModalCadastro(true);
  }

  function handleSearchEmployee(data: SearchEmployeerProps) {
    console.log(data);
  }

  function handleClearSearch() {
    reset();
  }

  return (
    <Container>
      <Typography
        align="center"
        variant="h5"
        display="block"
        style={{ fontWeight: 'bold' }}
      >
        Gerenciamento de funcionários
      </Typography>
      <Main>
        <ContainerButtons>
          <form onSubmit={handleSubmit(handleSearchEmployee)}>
            <DivSearch>
              <TextField
                variant="outlined"
                label="Pesquisar funcionário"
                {...register('funcionario')}
              />
              <Button
                icon={<MagnifyingGlass size={25} />}
                variantstyle="SECUNDARY"
                isLoading={isSubmitting}
                disabled={!isValid}
              />
              {isValid && (
                <Button
                  icon={<X size={25} />}
                  variantstyle="RED"
                  onClick={handleClearSearch}
                />
              )}
            </DivSearch>
          </form>
          <DivEmployeer>
            <Button
              icon={<UserPlus size={25} />}
              onClick={handleOpenModalCadastro}
            />
          </DivEmployeer>
        </ContainerButtons>
        <Content>
          {getDataEmployee &&
            getDataEmployee.map((employee, index) => {
              return (
                <CardEmployee key={index}>
                  <User size={26} />
                  <p>{employee.nome}</p>
                  <p>{employee.tipo_login}</p>
                  <p>
                    {String(employee.administrador).toLowerCase() === 'true'
                      ? 'ADM'
                      : 'Normal'}
                  </p>
                  <span>
                    <Button
                      icon={<Pen size={20} />}
                      variantstyle="SECUNDARY"
                      type="button"
                      isLoading={isLoading}
                      onClick={async () => {
                        await handleOpenModalEdit(index);
                      }}
                    />
                    <Button
                      icon={<Trash size={20} />}
                      variantstyle="RED"
                      type="button"
                      onClick={() => {
                        handleOpenModalDelete(index);
                      }}
                      isLoading={
                        deleteEmployee.cpf === employee.cpf ? isFetching : false
                      }
                    />
                  </span>
                </CardEmployee>
              );
            })}
        </Content>
      </Main>
      <ModalCadastrarFuncionario
        openModal={openModalCadastro}
        closeModal={handleCloseModalCadastro}
      />
      <ModalEditarFuncionario
        openModal={openModalEdit}
        closeModal={handleCloseModalEdit}
        data={editEployee}
      />
      <ModalDeletarFuncionario
        openModal={openModalDelete}
        closeModal={handleCloseModalDelete}
        data={deleteEmployee}
      />
    </Container>
  );
}
