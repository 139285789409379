import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const DivButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

export const PaperInfo = styled(Paper)`
  width: 23rem;
  height: 30rem;
  padding: 0.8rem;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.COLOR.PURPLE};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.COLOR.PURPLE};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.COLOR.LIGHT_PURPLE};
  }
  div {
    span {
      word-break: break-all;
      margin-right: 2px;
      &:first-child {
        line-height: 1.9;
        font-weight: bold;
      }
    }
  }
`;

export const DivExames = styled.div`
  background: ${({ theme }) => theme.COLOR.DEFAULT};
  border-radius: 5px;
  padding: 0.7rem;
  margin-bottom: 10px;
`;

export const DivFields = styled.div`
  display: flex;
  flex-direction: 'row';
  margin-top: 2rem;
  margin-bottom: 2rem;
  gap: 1rem;
`;
