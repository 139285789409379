import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  withStyles,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Snackbar
} from '@material-ui/core';
import { HighlightOff, SettingsBackupRestore } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { api } from '@services/api';
import MuiAlert, { type Color } from '@material-ui/lab/Alert';
import { maskCNPJ } from '@utils/masks';
import { Button } from '@components/Button';
import usePagination from '@hooks/usePagination';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { Pagination } from '@material-ui/lab';
import { ContainerButtonsTop, DivSearch } from './styles';
import { NavLink } from '@components/NavLink';
import { AppError } from '@utils/AppError';

interface ITab {
  cod_local: string;
  cnpj_local: string;
  nome: string;
  razao_social: string;
  data_inicio: string;
  data_fim: string;
  num_cnpj: string;
  rua: string;
  numero: string;
  cep: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
}

export const Localobra = () => {
  const [loading, setLoading] = useState(false);
  const [tabLocalObra, setTabLocalObra] = useState<ITab[]>([]);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [edit, setEdit] = useState<ITab>();
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color | undefined>();
  const [dialog, setDialog] = useState(false);
  const [search, setSearch] = useState<string>('');

  const [value, setValue] = useState('');

  const [page, setPage] = useState(1);
  const PER_PAGE = 30;

  const count = Math.ceil(tabLocalObra.length / PER_PAGE);
  const _DATA = usePagination(tabLocalObra, PER_PAGE);

  const filterLocalObraPerPage =
    _DATA.currentData().length > 0
      ? _DATA
          .currentData()
          .filter(
            (contrato: ITab) =>
              contrato.nome.toUpperCase().includes(search.toUpperCase()) ||
              contrato.cnpj_local.toUpperCase().includes(search.toUpperCase())
          )
      : [];

  const filterLocalObra =
    tabLocalObra.length > 0
      ? tabLocalObra.filter(
          (contrato: ITab) =>
            contrato.nome.toUpperCase().includes(search.toUpperCase()) ||
            contrato.cnpj_local.toUpperCase().includes(search.toUpperCase()) ||
            contrato.cod_local.toUpperCase().includes(search.toUpperCase())
        )
      : [];

  const filter = search.length > 0 ? filterLocalObra : filterLocalObraPerPage;

  async function localObra() {
    try {
      setLoading(true);
      const res = await api.get(`ravim/localObra`);
      if (res.status === 404) return;

      setTabLocalObra(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  function closeDialog() {
    setDialog(false);
    setDialogDelete(false);
  }

  function deleteContract(value: ITab) {
    setDialogDelete(true);
    setEdit(value);
    // console.log(value)
  }

  async function deleteContrato(cod_cont: any) {
    // console.log(cod_cont)

    await api
      .delete(`/ravim/exclu-local-obra`, { data: { cod_local: cod_cont } })
      .then((res) => {
        if (res.status === 200) {
          const newListLocalObra = tabLocalObra.filter(
            (item: ITab) => item.cod_local !== cod_cont
          );
          setSnack(true);
          setDialogDelete(false);
          setMessage(res.data.message);
          setSeverity('success');
          setCod(String(res.status));
          setTabLocalObra(newListLocalObra);
        } else {
          setSeverity('error');
          setSnack(true);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        if (err instanceof AppError) {
          setSeverity('error');
          setSnack(true);
          setMessage(err.message);
          setCod(String(err.code));
        }
      });
  }

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    localObra();
  }, []);

  useEffect(() => {
    if (!value) {
      setSearch('');
    }
  }, [value]);

  return (
    <Container>
      <ContainerButtonsTop>
        <DivSearch>
          <TextField
            label="Pesquisar Local/Obra"
            type="text"
            variant="outlined"
            value={value}
            onChange={(e: any) => {
              setValue(e.target.value);
            }}
          />
          <Button
            variantstyle="SECUNDARY"
            icon={<MagnifyingGlass size={25} />}
            onClick={() => {
              handleSearch(value);
            }}
          />
        </DivSearch>
        <NavLink to="/cadastro-local-obra">
          <Button title="Cadastro de Local/Obra" />
        </NavLink>
      </ContainerButtonsTop>

      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Local Obra
      </Typography>
      <Typography variant="h6" align="left">
        Total de local obra: {tabLocalObra.length}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 20
        }}
      >
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
        {loading ? (
          <TableContainer component={Paper}>
            <Table style={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Numero do CNPJ</TableCell>
                  <TableCell>Código Local/Obra</TableCell>
                  <TableCell>Nome da Obra</TableCell>
                  <TableCell>Cidade e Estado</TableCell>
                  <TableCell>ATUALIZAR</TableCell>
                  <TableCell>DELETAR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter &&
                  filter.map((tabela: ITab, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{maskCNPJ(tabela.cnpj_local)}</TableCell>
                      <TableCell>{tabela.cod_local}</TableCell>
                      <TableCell>{tabela.nome}</TableCell>
                      <TableCell>{tabela.cidade}</TableCell>

                      <TableCell>
                        <Link
                          to={{ pathname: '/editar-local-obra', state: tabela }}
                        >
                          <IconButton>
                            <SettingsBackupRestore
                              fontSize="medium"
                              style={{ color: '#422C59' }}
                            />
                          </IconButton>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            deleteContract(tabela);
                          }}
                        >
                          <HighlightOff
                            fontSize="medium"
                            style={{ color: 'red' }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>
      <Dialog open={dialogDelete} onClose={closeDialog}>
        <DialogTitle>
          Contrato a ser deletado, confira o nome e código
        </DialogTitle>
        <DialogContent>
          <Typography>
            Nome: <b>{edit?.nome}</b>
          </Typography>
          <Typography>
            Código: <b>{edit?.cnpj_local}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            title="Cancelar"
            variantstyle="SECUNDARY"
          />

          <Button
            title="Deletar"
            variantstyle="RED"
            autoFocus
            onClick={async () => {
              await deleteContrato(edit?.cod_local);
            }}
          />
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
