import React from 'react';
import * as S from './styles';
import { type ButtonProps, SvgIconProps } from '@material-ui/core';

type Props = ButtonProps & {
  title?: string;
  isLoading?: boolean;
  variantstyle?: S.ButtonTypeStyleProps;
  icon?: JSX.Element;
};

export function Button({
  title,
  isLoading = false,
  variantstyle = 'PRIMARY',
  icon,
  ...rest
}: Props) {
  return (
    <S.ButtonStyled variantstyle={variantstyle} {...rest}>
      {isLoading ? (
        <S.Loading variantstyle={variantstyle} />
      ) : (
        <S.DivTitleIcon>
          {icon}
          {title}
        </S.DivTitleIcon>
      )}
    </S.ButtonStyled>
  );
}
