import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container, ContainerFiles, DivContainer } from './styles';
import { FilePdf } from '@phosphor-icons/react';
import { IconButton } from '@material-ui/core';
import { DownloadSimple } from '@phosphor-icons/react/dist/ssr';
import { useFiles } from '@base/context/files';

interface Props {
  handleUpload: (files: File[]) => void;
}

export function Upload() {
  const { handleUpload } = useFiles();

  const onDrop = useCallback(
    (files: File[] | null) => {
      handleUpload(files);
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { 'text/pdf': ['.pdf'] },
      onDrop
    });

  return (
    <DivContainer>
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>Arraste os arquivos ou clique aqui para localizar</p>
      </Container>
    </DivContainer>
  );
}
