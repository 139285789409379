import { useAuth } from '@context/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { Container, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { string, z } from 'zod';
import {
  DivBenefits,
  DivButtons,
  DivGridFieldDataContract,
  DivServices,
  PaperContainer
} from './styles';
import { Upload } from '@components/Upload';
import { FileList } from '@components/FileList';
import { Button } from '@components/Button';
import { Exames, type IExames } from '@components/Exames';
import { type IServico, Servicos } from '@components/Servicos';
import { type IExamesToxico, Toxicologico } from '@components/Toxicologico';
import { Plus } from '@phosphor-icons/react';
import { Autocomplete, type Color } from '@material-ui/lab';
import { useQuery } from '@tanstack/react-query';
import { api } from '@services/api';
import { ToastMessage } from '@components/ToastMessage';
import { useFiles } from '@context/files';
import { AppError } from '@utils/AppError';
import { LoadingPage } from '@components/LoadingPage';
import { ModalUpdate } from './components/ModalUpdate';
import { IContractEdit } from '@pages/Contratos';
import { useLocation } from 'react-router';
import { NavLink } from '@components/NavLink';

interface IRiscos {
  id_risco_contr_empresa?: number;
  id_risco: string;
  nome: string;
}

interface ICadastro {
  nome_contrato: string;
  cod_contrato: string;
  data_criacao: string | Date;
  data_renovacao: string | Date;
  arquivo?: string[] | null;
  exames: object[] | null;
  risco: object[];
  servico: object[];
  num_conv_med: string;
  num_conv_odont: string;
  num_conv_acade: string;
  num_seg_vida: string;
  num_apol_pat: string;
}

const contractShema = z.object({
  nome_contrato: z.string().optional(),
  cod_contrato: z
    .string()
    .trim()
    .min(1, { message: 'O campo não pode ser vazio.' }),
  data_criacao: z.string().optional(),
  data_renovacao: z.string().optional(),
  arquivo: z.array(z.string()).optional(),
  exame: z
    .array(
      z.object({
        id_exame_contr_empresa: z.number().optional(),
        id_exames: z.string().or(z.number()).optional(),
        nome: z.string(),
        valor: z.number().or(z.string())
      })
    )
    .optional(),
  risco: z
    .array(
      z.object({
        id_risco: z.string().or(z.number()).optional(),
        id_risco_contr_empresa: z.number().optional(),
        nome: z.string()
      })
    )
    .optional(),
  servico: z
    .array(
      z.object({
        id_servicos: z.string().or(z.number()).optional(),
        id_servico_contr_empresa: z.number().optional(),
        nome: z.string(),
        valor: z.number().or(z.string())
      })
    )
    .optional(),
  num_conv_med: z.string().optional(),
  num_conv_odont: z.string().optional(),
  num_conv_acade: z.string().optional(),
  num_seg_vida: z.string().optional(),
  num_apol_pat: z.string().optional(),
  tipo_plano: z.string().optional(),
  plano_bronze: z.string().optional(),
  valor_bronze: z.string().optional(),
  plano_prata: z.string().optional(),
  valor_prata: z.string().optional(),
  plano_ouro: z.string().optional(),
  valor_ouro: z.string().optional(),
  plano_platina: z.string().optional(),
  valor_platina: z.string().optional(),
  plano_personalizado: z.string().optional(),
  valor_personalizado: z.string().optional(),
  exame_toxi: z
    .array(
      z.object({
        id_exame_tox: z.string().or(z.number()).optional(),
        id_exame_toxi_contr_empresa: z.number().optional(),
        nome: z.string(),
        valor: z.number().or(z.string())
      })
    )
    .optional(),
  usuario: z.string().optional()
});

export type ContractSaveFormData = z.infer<typeof contractShema>;

export function EditarContratoEmpresa() {
  const { user } = useAuth();
  const hora = new Date();
  const newHora = hora.toLocaleString('pt-BR', { hour12: false });
  const userUpdate = `${user.nome} ${newHora}`;

  const location = useLocation();
  const state = location.state as ContractSaveFormData;
  const {
    nome_contrato,
    cod_contrato,
    data_criacao,
    data_renovacao,
    tipo_plano,
    valor_bronze,
    valor_ouro,
    valor_personalizado,
    valor_platina,
    valor_prata,
    arquivo
  } = state;

  const [risco, setRisco] = useState<IRiscos[]>([]);
  const [loading, setLoading] = useState(false);

  const [exames, setExames] = useState<IExames[]>([]);
  const [novoValorExames, setNovoValorExame] = useState<IExames[]>([]);
  const [valorExameTotal, setValorExameTotal] = useState(0);

  const [campoValorSevices, setCampoValorServices] = useState<IServico[]>([]);
  const [valorTotalServices, setValorTotalServices] = useState(0);
  const [servico, setServico] = useState<IServico[]>([]);

  const [valorTotalToxico, setValorTotalToxico] = useState(0);
  const [campoValorToxico, setCampoValorToxico] = useState<IExamesToxico[]>([]);
  const [novoValorToxico, setNovoValorToxico] = useState<IExamesToxico[]>([]);

  const [confirmUpdateContract, setConfirmUpdateContract] = useState(false);
  const [dialogToxico, setDialogToxico] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialogSevices, setDialogServices] = useState(false);
  const [messageSend, setMessageSend] = useState({
    cod: '',
    message: '',
    severity: '' as Color,
    show: false
  });

  const {
    uploadedFiles: files,
    fetchDataFiles,
    receivedCodContrato
  } = useFiles();

  const filesNames = files.map((file) => {
    return file.name;
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm<ContractSaveFormData>({
    resolver: zodResolver(contractShema),
    defaultValues: {
      usuario: userUpdate,
      nome_contrato,
      cod_contrato,
      data_criacao,
      data_renovacao,
      tipo_plano,
      valor_bronze,
      valor_ouro,
      valor_personalizado,
      valor_platina,
      valor_prata,
      arquivo: filesNames
    }
  });

  const { append, remove } = useFieldArray({
    control,
    name: 'risco'
  });

  async function handleSubmitForm(data: ContractSaveFormData) {
    console.log(data);
    try {
      const res = await api.post(`ravim/update`, data);
      if (res.status === 200) {
        setMessageSend({
          cod: String(res.status),
          message: res.data.message,
          severity: 'success',
          show: true
        });
        setTimeout(() => {
          window.location.replace('/contratos-empresa');
        }, 2000);
        // reset();
      } else {
        setMessageSend({
          cod: String(res.status),
          message: res.data.message,
          severity: 'error',
          show: true
        });
      }
    } catch (error) {
      console.log(error);
      if (error instanceof AppError) {
        setMessageSend({
          cod: '',
          message: error.message,
          severity: 'error',
          show: true
        });
      }
    }
  }

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ['comboRiscos'],
    queryFn: async () => {
      const res = await api.get(`/ravim/riscos`);
      return res.data;
    }
  });

  function handleAddRiscos(riscos: IRiscos[]) {
    setRisco(riscos);
  }

  async function editContrato(cod: string) {
    const data = {
      cod_contrato: cod
    };
    try {
      const res = await api.post(`ravim/dados/${cod}`, data);
      const filesLoaded = JSON.parse(res.data.arquivos);
      fetchDataFiles(filesLoaded);

      JSON.parse(res.data.beneficos).map((bene: ICadastro) => {
        setValue('num_conv_med', bene.num_conv_med);
        setValue('num_conv_odont', bene.num_conv_odont);
        setValue('num_conv_acade', bene.num_conv_acade);
        setValue('num_seg_vida', bene.num_seg_vida);
        setValue('num_apol_pat', bene.num_apol_pat);
      });

      //= ================= Filtro exames cadastrados =====================
      const jsonExames = JSON.parse(res.data.exames);
      const valor = JSON.parse(res.data.exames).map((val: any) => {
        return parseFloat(val.valor);
      });
      const valorTot = valor.reduce(
        (soma: number, i: number) => soma - i * -1,
        0
      );

      setExames(jsonExames);
      setNovoValorExame(jsonExames);
      setValorExameTotal(Number(valorTot));
      setValue('exame', jsonExames);

      //= ================= Filtro exames toxico cadastrados =====================
      const jsonExamesToxi = JSON.parse(res.data.exame_toxi);
      const valorToxi = JSON.parse(res.data.exame_toxi).map((val: any) => {
        return parseFloat(val.valor);
      });
      const valorTotToxi = valorToxi.reduce(
        (soma: number, i: number) => soma - i * -1,
        0
      );

      setCampoValorToxico(jsonExamesToxi);
      setNovoValorToxico(jsonExamesToxi);
      setValorTotalToxico(Number(valorTotToxi));
      setValue('exame_toxi', jsonExamesToxi);

      //= ================ Filtro serviços ==============================
      const jsonServices = JSON.parse(res.data.servicos);
      const valorServico = JSON.parse(res.data.servicos).map((val: any) => {
        return parseFloat(val.valor);
      });
      const valorTotalServico = valorServico.reduce(
        (soma: number, i: number) => soma - i * -1,
        0
      );

      setCampoValorServices(jsonServices);
      setServico(jsonServices);
      setValorTotalServices(Number(valorTotalServico));
      setValue('servico', jsonServices);

      setRisco(JSON.parse(res.data.riscos));

      setValue('risco', JSON.parse(res.data.riscos));
    } catch (error) {
      if (error instanceof AppError) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    const getValues = new Promise((resolve) => {
      setLoading(true);
      setTimeout(() => {
        editContrato(cod_contrato);
        resolve(10);
        setLoading(false);
      }, 1 * 1000);
    });

    Promise.all([getValues]);
  }, []);

  useEffect(() => {
    receivedCodContrato(String(cod_contrato));
  }, [cod_contrato]);

  useEffect(() => {
    setValue('arquivo', filesNames);
  }, [files]);

  return (
    <Container>
      <LoadingPage
        open={loading}
        onClick={() => {
          setLoading(false);
        }}
      />
      <Typography variant="h4" align="center">
        Editar contrato empresa
      </Typography>

      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <PaperContainer>
          <Typography variant="h5">Dados do cadastro</Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <DivGridFieldDataContract>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Nome do contrato"
                  {...register('nome_contrato')}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="cod_contrato"
                  disabled
                  {...register('cod_contrato')}
                />

                <TextField
                  id="date"
                  label="Data de Criação"
                  type="date"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register('data_criacao')}
                />
                <TextField
                  id="date"
                  label="Data de Renovação"
                  type="date"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register('data_renovacao')}
                />
              </DivGridFieldDataContract>
              <Upload />
              <FileList />
            </Grid>
          </Grid>

          <Typography variant="h5">Benefícios</Typography>
          <DivBenefits>
            <TextField
              variant="outlined"
              label="Nº Convênio Medico"
              InputLabelProps={{
                shrink: true
              }}
              {...register('num_conv_med')}
            />
            <TextField
              variant="outlined"
              label="Nº Convênio Odontológico"
              InputLabelProps={{
                shrink: true
              }}
              {...register('num_conv_odont')}
            />

            <TextField
              variant="outlined"
              label="Nº Convênio Acedemia"
              InputLabelProps={{
                shrink: true
              }}
              {...register('num_conv_acade')}
            />
            <TextField
              variant="outlined"
              label="Nº Seguro de Vida"
              InputLabelProps={{
                shrink: true
              }}
              {...register('num_seg_vida')}
            />
            <TextField
              label="Nº Apólice PAT"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              {...register('num_apol_pat')}
            />
          </DivBenefits>
          <Typography variant="h5">Serviços</Typography>
          <DivServices>
            <Button
              title="Adicionar Exames"
              onClick={() => {
                setDialog(!dialog);
              }}
              icon={<Plus size={20} />}
              type="button"
            />

            <Exames
              control={control}
              openDialog={dialog}
              closeDialog={() => {
                setDialog(!dialog);
              }}
              getExames={exames}
              getNovoValor={novoValorExames}
              getValorTotal={valorExameTotal}
            />
            <Button
              title="Exames Toxicólogico"
              onClick={() => {
                setDialogToxico(!dialogToxico);
              }}
              icon={<Plus size={20} />}
              type="button"
            />

            <Button
              icon={<Plus size={20} />}
              title="Adicionar Serviços"
              onClick={() => {
                setDialogServices(!dialogSevices);
              }}
              type="button"
            />

            <Servicos
              control={control}
              openModal={dialogSevices}
              closeModal={() => {
                setDialogServices(false);
              }}
              getServico={servico}
              getNovoValor={servico}
              getValorTotal={valorTotalServices}
            />

            <Toxicologico
              control={control}
              openModal={dialogToxico}
              closeModal={() => {
                setDialogToxico(false);
              }}
              getNovoValor={campoValorToxico}
              getToxicologico={campoValorToxico}
              getValorTotal={valorTotalToxico}
            />
          </DivServices>
          <div>
            <Autocomplete
              multiple
              id="tags-outlined"
              loading={isLoading}
              options={isSuccess ? data : []}
              getOptionLabel={(option: IRiscos) => {
                return `${option.nome}` ? `${option.nome}` : '';
              }}
              onChange={(e: any, newValue, reason, detail) => {
                handleAddRiscos(newValue);

                if (reason === 'remove-option' || reason === 'clear') {
                  if (detail) {
                    const index = risco.findIndex((i) => i === detail?.option);
                    remove(index);
                  }
                } else if (reason === 'select-option') {
                  if (detail?.option) {
                    append({ ...detail?.option });
                  }
                }
                // console.log(reason, detail)
              }}
              value={risco}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Riscos"
                  placeholder="Riscos"
                />
              )}
            />
          </div>

          <Typography variant="h5">Plano</Typography>

          <DivBenefits>
            <TextField
              variant="outlined"
              label="Valor Bonze"
              {...register('valor_bronze')}
            />
            <TextField
              variant="outlined"
              label="Valor Prata"
              {...register('valor_prata')}
            />
            <TextField
              variant="outlined"
              label="Valor Ouro"
              {...register('valor_ouro')}
            />
            <TextField
              variant="outlined"
              label="Valor Platina"
              {...register('valor_platina')}
            />
            <TextField
              variant="outlined"
              label="Valor Personalizado"
              {...register('valor_personalizado')}
            />
          </DivBenefits>
        </PaperContainer>

        <DivButtons>
          <NavLink to="/contratos-empresa">
            <Button title="Sair" variantstyle="RED" />
          </NavLink>
          <Button
            type="submit"
            title="Salvar"
            isLoading={isSubmitting}
            onClick={() => {
              setConfirmUpdateContract(true);
            }}
          />
        </DivButtons>
      </form>
      <ToastMessage
        code={messageSend.cod}
        message={messageSend.message}
        onClose={() => {
          setMessageSend({
            cod: messageSend.cod,
            message: messageSend.message,
            severity: messageSend.severity,
            show: false
          });
        }}
        show={messageSend.show}
        severity={messageSend.severity}
      />
    </Container>
  );
}
