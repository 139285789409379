import { Button } from '@components/Button';
import { Upload } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { AppError } from '@utils/AppError';
import { uploadCsv } from '@api/upload-csv';

export function UploadCSV() {
  const { CSVReader } = useCSVReader();
  const [col, setCol] = useState<string[]>([]);
  const [val, setVal] = useState<string[][]>([]);

  const { mutateAsync: uploadFile } = useMutation({
    mutationFn: uploadCsv,
    onSuccess: () => {
      toast.success('Salvo com sucesso.');
    },
    onError(error) {
      if (error instanceof AppError) {
        toast.error(`${error.code} - ${error.message}`);
      }
    }
  });

  

  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        console.log(results);
        const value: string[][] = results.data;
        const filtered = value.filter((_, i) => i !== 0);
        uploadFile(value);
        setCol(value[0]);
        setVal(filtered);
      }}
      config={{
        worker: true,
        encoding: 'utf-8'
      }}
      noDrag
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps
      }: any) => {
        const percentage = ProgressBar();

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {acceptedFile ? (
              <Button
                title="Deseja fazer um novo upload?"
                variantstyle="SECUNDARY"
                {...getRemoveFileProps()}
              />
            ) : (
              <Button
                icon={<Upload size={24} />}
                title="Upload CSV"
                isLoading={percentage.props.display === 'none' ? false : true}
                {...getRootProps()}
              />
            )}

            <div style={{ marginTop: 5 }}>
              <ProgressBar />
            </div>
          </div>
        );
      }}
    </CSVReader>
  );
}
