import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SettingsBackupRestore, Add } from '@material-ui/icons';
import MuiAlert, { type Color } from '@material-ui/lab/Alert';
import { api } from '@services/api';

import { maskCPF, maskDate } from '@utils/masks';
import { ToastMessage } from '@components/ToastMessage';
import { type AxiosError, AxiosResponse } from 'axios';
import { Button } from '@components/Button';
import { LoadingPage } from '@components/LoadingPage';
import Pagination from '@material-ui/lab/Pagination';
import usePagination from '@hooks/usePagination';
import { MagnifyingGlass } from '@phosphor-icons/react/dist/ssr';
import { Eye, X } from '@phosphor-icons/react';
import { Excel } from '@components/Excel';
import { AutocompleteCnpj } from '@pages/ExamesAvencer/components/AutocompleteCnpj';

interface IEsocial {
  nome_completo: string;
  cpf_colaborador: string;
  razao_social: string;
  cnpj: string;
  tipo_aso: string;
  data_ultimo_agendamento: string;
  status: any;
}

export interface Props {
  colaborador: Colaborador;
  esocial: Esocial[];
  exames: Exames;
  risco: Risco[];
}

export interface Colaborador {
  cnpj: string;
  cpf_colaborador: string;
  data_agendamento: string;
  nome_completo: string;
  num_agenda: string;
  razao_social: string;
  tipo_aso: string;
}

export interface Esocial {
  data_envio_s2220: string;
  data_envio_s2240: string;
  observacao_s2220: string;
  observacao_s2240: string;
  recibo_esocial_s2220: string;
  recibo_esocial_s2240: string;
  status: string;
}

export interface Exames {
  id_exame: string;
  nome_exame: string;
}

export interface Risco {
  id_risco: string;
  nome: string;
}

const statusEsocial = [
  { id: 1, name: 'Pendente', value: 'pendente' },
  { id: 2, name: 'Concluído', value: 'concluido' }
];

export const Esocial = () => {
  const [listEsocial, setListEsocial] = useState<IEsocial[]>([]);
  const [search, setSearch] = useState<string>('');
  const [modalAction, setModalAction] = useState<boolean>(false);
  const [edit, setEdit] = useState<IEsocial | undefined>();
  const [errors, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedCnpj, setSelectedCnpj] = useState<any>();
  const [nomeEmpresa, setNomeEmpresa] = useState<string>('');
  const [excel, setExcel] = useState<Props[]>([]);

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState();
  const [cod, setCod] = useState();
  const [severity, setSeverity] = useState<Color>();
  const [value, setValue] = useState('');

  const [filterEsocial, setFilterEsocial] = useState<IEsocial[]>([]);
  const [listEmpty, setListEmpty] = useState('');
  const [statusDoEnvio, setStatusDoEnvio] = useState('');

  const [page, setPage] = useState(1);
  const PER_PAGE = 30;

  const count = Math.ceil(listEsocial.length / PER_PAGE);
  const _DATA = usePagination(listEsocial, PER_PAGE);

  const filterAgendaPerPage =
    _DATA.currentData().length > 0
      ? _DATA
          .currentData()
          .filter(
            (agendaSearch: IEsocial) =>
              agendaSearch.nome_completo
                ?.toUpperCase()
                .includes(search.toUpperCase()) ||
              agendaSearch.cpf_colaborador
                ?.toUpperCase()
                .includes(search.toUpperCase()) ||
              agendaSearch.status
                ?.toUpperCase()
                .includes(search.toUpperCase()) ||
              agendaSearch.razao_social
                ?.toUpperCase()
                .includes(search.toUpperCase()) ||
              maskDate(agendaSearch.data_ultimo_agendamento)
                ?.toUpperCase()
                .includes(search.toUpperCase())
          )
      : [];

  const filter = filterEsocial.length > 0 ? filterEsocial : filterAgendaPerPage;

  async function fetchEsocial() {
    try {
      const res = await api.get(`/ravim/e-social`);
      if (res.status === 404) return;
      setListEsocial(res.data);
    } catch (error: any) {
      setError(error as AxiosError);
      setSeverity('error');
      setSnack(true);
      setMessage(error.response.statusText);
      setCod(error.response.status);
    } finally {
      setLoading(false);
    }
  }

  const fetchFilter = useCallback(
    async (name: string, status: string) => {
      try {
        handleSearchFilter();

        const nameColab = name.toUpperCase().trim();
        const statusEnvio = status.toUpperCase().trim();

        const data = {
          nome: nameColab,
          status: statusEnvio
        };

        const response = await api.post('ravim/pesq-esocial', data);
        setFilterEsocial(response.data);
      } catch (error: any) {
        if (error.response.status === 404) {
          setFilterEsocial([]);
          setListEmpty(error.response.data.message);
        }
      }
    },
    [filterEsocial]
  );

  async function fetchExcel() {
    try {
      const response = await api.post('ravim/excel-esocial', {
        cnpj: selectedCnpj
      });
      setExcel(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const adjustExcel = excel.map((data) => {
    return {
      cnpj: data.colaborador.cnpj,
      cpf_colaborador: data.colaborador.cpf_colaborador,
      data_agendamento: data.colaborador.data_agendamento,
      nome_completo: data.colaborador.nome_completo,
      razao_social: data.colaborador.razao_social,
      tipo_aso: data.colaborador.tipo_aso,
      recibo_esocial_s2220: data.esocial[0]?.recibo_esocial_s2220,
      recibo_esocial_s2240: data.esocial[0]?.recibo_esocial_s2240
    };
  });

  function handleLoading() {
    setLoading(loading);
  }

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  function handleSearchFilter() {
    setSearch(value);
  }

  function handleClearFilter() {
    setSearch('');
    setFilterEsocial([]);
    setValue('');
    setListEmpty('');
    setStatusDoEnvio('');
  }

  useEffect(() => {
    fetchEsocial();
  }, []);

  const uniqueCnpj = listEsocial.filter((item, index) => {
    return index === listEsocial.findIndex((cnpj) => item.cnpj === cnpj.cnpj);
  });

  return (
    <Container>
      <LoadingPage open={loading} onClick={handleLoading} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
          marginBottom: 20,
          alignItems: 'center'
        }}
      >
        <TextField
          label="Pesquisar agendamento"
          value={value}
          onChange={(e) => { setValue(e.target.value); }}
          type="text"
          variant="outlined"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearchFilter();
            }
          }}
        />
        <FormControl variant="outlined" style={{ width: '20%' }}>
          <InputLabel>Situação</InputLabel>
          <Select
            label="Situação"
            value={statusDoEnvio}
            onChange={(e: any) => { setStatusDoEnvio(e.target.value); }}
          >
            {statusEsocial &&
              statusEsocial.map((status) => (
                <MenuItem value={status.value} key={status.id}>
                  {status.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          variantstyle="SECUNDARY"
          icon={<MagnifyingGlass size={25} />}
          onClick={async () => { await fetchFilter(value, statusDoEnvio); }}
        />
        {filterEsocial.length > 0 || !!listEmpty ? (
          <Button
            variantstyle="RED"
            icon={<X size={25} />}
            onClick={handleClearFilter}
          />
        ) : (
          <></>
        )}
      </div>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Esocial
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20
        }}
      >
        <Typography variant="h6" align="left">
          Total agendados: {listEsocial.length}
        </Typography>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 20,
            width: '60%'
          }}
        >
          <AutocompleteCnpj
            cnpjExcel={uniqueCnpj}
            selectedCnpj={selectedCnpj}
            onChangeRazaoSocial={(e) => { setNomeEmpresa(e); }}
            onChangeSelectedCnpj={(e) => { setSelectedCnpj(e); }}
          />
          <Excel
            reqExcell={fetchExcel}
            excelData={adjustExcel.flat()}
            fileName={nomeEmpresa}
            cnpjSelected={selectedCnpj}
          />
        </div>
      </div>
      {!listEmpty ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 20
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
          <TableContainer component={Paper}>
            <Table
              style={{
                minWidth: 650,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>CNPJ</TableCell>
                  <TableCell>Razão Social</TableCell>
                  <TableCell>CPF</TableCell>
                  <TableCell>Nome Completo</TableCell>
                  <TableCell>Data do Agendamento</TableCell>
                  <TableCell>Tipo de ASO</TableCell>
                  <TableCell>Status do Envio</TableCell>
                  <TableCell>Visualizar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter &&
                  filter?.map((esocial: IEsocial, index) => (
                    <TableRow key={index}>
                      <TableCell>{esocial.cnpj}</TableCell>
                      <TableCell>{esocial.razao_social}</TableCell>
                      <TableCell>{esocial.cpf_colaborador}</TableCell>
                      <TableCell>{esocial.nome_completo}</TableCell>
                      <TableCell>
                        {maskDate(esocial.data_ultimo_agendamento)}
                      </TableCell>
                      <TableCell>{esocial.tipo_aso}</TableCell>
                      <TableCell>{esocial.status}</TableCell>

                      <TableCell>
                        <Link
                          to={{ pathname: '/edit-e-social', state: esocial }}
                        >
                          <IconButton>
                            <Eye size={28} style={{ color: '#422C59' }} />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </div>
      ) : (
        <Typography align="center" variant="h5">
          {listEmpty}
        </Typography>
      )}
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => { setSnack(!snack); }}
      >
        <MuiAlert severity={severity} variant="filled">
          {cod} {message}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};
