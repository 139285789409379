import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useAuth } from '@context/auth';

import { DrawerHearder } from '../components/Drawer';
import Auth from './auth.routes';

const Routes: React.FC = () => {
  const { user } = useAuth();

  return (
    <BrowserRouter>{user.token ? <DrawerHearder /> : <Auth />}</BrowserRouter>
  );
};

export default Routes;
