import {
  storageAuthTokenGet,
  storageAuthTokenSave
} from '@base/storage/storageAuthToken';
import { AppError } from '@utils/AppError';
import axios, { type AxiosError, type AxiosInstance } from 'axios';

const { REACT_APP_API_URL } = process.env;

type SignOut = () => void;

interface PromiseType {
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
}

type APIInstanceProps = AxiosInstance & {
  registerInterceptTokenManager: (signOut: SignOut) => () => void;
};

const api = axios.create({
  baseURL: REACT_APP_API_URL
}) as APIInstanceProps;

let failedQueue: PromiseType[] = [];
let isRefreshing = false;

api.registerInterceptTokenManager = (signOut) => {
  const interceptTokenManager = api.interceptors.response.use(
    (response) => response,
    async (requestError) => {
      if (requestError?.response?.status === 401) {
        if (
          requestError.response.data?.msg === 'Token has expired' ||
          requestError.response.data?.msg === 'token.invalid'
        ) {
          const { refresh_token } = await storageAuthTokenGet();

          if (!refresh_token) {
            signOut();
            return await Promise.reject(requestError);
          }

          const originalRequestConfig = requestError.config;

          if (isRefreshing) {
            return await new Promise((resolve, reject) => {
              failedQueue.push({
                onSuccess: (token: string) => {
                  originalRequestConfig.headers = {
                    Authorization: `Bearer ${token}`
                  };
                  resolve(api(originalRequestConfig));
                },
                onFailure: (error: AxiosError) => {
                  reject(error);
                }
              });
            });
          }

          isRefreshing = true;
          return await new Promise(async (resolve, reject) => {
            try {
              const { data } = await api.post(
                'ravim/refresh-token',
                { refresh_token },
                { headers: { Authorization: `Bearer ${refresh_token}` } }
              );

              await storageAuthTokenSave({
                token: data.access_token,
                refresh_token: data.refresh_token
              });

              if (originalRequestConfig.data) {
                originalRequestConfig.data = JSON.parse(
                  originalRequestConfig.data
                );
              }

              originalRequestConfig.headers = {
                Authorization: `Bearer ${data.access_token}`
              };
              api.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;

              failedQueue.forEach((request) => {
                request.onSuccess(data.access_token);
              });

              resolve(api(originalRequestConfig));
            } catch (error: any) {
              failedQueue.forEach((request) => {
                request.onFailure(error);
              });

              signOut();
              reject(error);
            } finally {
              isRefreshing = false;
              failedQueue = [];
            }
          });
        }

        signOut();
      }

      if (requestError.response && requestError.response.data) {
        return Promise.reject(
          new AppError(
            requestError.response.data.message,
            requestError.response.data.code
          )
        );
      } else {
        return await Promise.reject(requestError);
      }
    }
  );

  return () => {
    api.interceptors.response.eject(interceptTokenManager);
  };
};
export { api };
