import React, { useState, useEffect } from 'react';
import { api } from '@services/api';
import {
  Container,
  TextField,
  Typography,
  Grid,
  FormControl
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { type Color } from '@material-ui/lab/Alert';
import { useFiles } from '@base/context/files';
import { Upload } from '@components/Upload';
import { FileList } from '@components/FileList';
import { z } from 'zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  DivButtons,
  DivExames,
  DivGridFieldDataContract,
  PaperContainer
} from '../CadastroContratosCredenciados/styles';
import { Button } from '@components/Button';
import { Plus } from '@phosphor-icons/react';
import { useAuth } from '@context/auth';
import { Exames, type IExames } from '@components/Exames';
import { type IServico, Servicos } from '@components/Servicos';
import { type IExamesToxico, Toxicologico } from '@components/Toxicologico';
import { useQuery } from '@tanstack/react-query';
import { ToastMessage } from '@components/ToastMessage';
import { AppError } from '@utils/AppError';
import { useLocation } from 'react-router';
import { AxiosError } from 'axios';
import { NavLink } from '@components/NavLink';

interface IRiscos {
  id_risco: string;
  nome: string;
}

const contractSchema = z.object({
  nome_contrato: z.string().optional(),
  cod_contrato: z
    .string()
    .trim()
    .min(1, { message: 'O campo não pode estar vazio.' }),
  data_criacao: z.string().optional(),
  data_renovacao: z.string().optional(),
  arquivo: z.string().array().optional(),
  usuario: z.string(),
  exame: z.array(
    z
      .object({
        id_exames: z.string().or(z.number()).optional(),
        nome: z.string(),
        valor: z.number().or(z.string())
      })
      .optional()
  ),
  risco: z
    .array(
      z.object({
        id_risco: z.string().or(z.number()).optional(),
        nome: z.string()
      })
    )
    .optional(),
  servico: z.array(
    z.object({
      id_servicos: z.string().or(z.number()).optional(),
      nome: z.string(),
      valor: z.number().or(z.string())
    })
  ),
  exame_toxi: z.array(
    z
      .object({
        id_exame_tox: z.string().or(z.number()).optional(),
        nome: z.string(),
        valor: z.number().or(z.string())
      })
      .optional()
  )
});

type ContractSaveFormData = z.infer<typeof contractSchema>;

export function EditarContratoCredenciado() {
  const { user } = useAuth();
  const hora = new Date();
  const newHora = hora.toLocaleString('pt-BR', { hour12: false });
  const userUpdate = `${user.nome} ${newHora}`;

  const location = useLocation();
  const state = location.state as ContractSaveFormData;
  const { cod_contrato, arquivo, data_criacao, data_renovacao, nome_contrato } =
    state;

  const [risco, setRisco] = useState<IRiscos[]>([]);

  const [dialogSevices, setDialogServices] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    cod: '',
    message: '',
    color: '' as Color,
    show: false
  });

  const [exames, setExames] = useState<IExames[]>([]);
  const [novoValorExames, setNovoValorExame] = useState<IExames[]>([]);
  const [valorExameTotal, setValorExameTotal] = useState(0);

  const [campoValorSevices, setCampoValorServices] = useState<IServico[]>([]);
  const [valorTotalServices, setValorTotalServices] = useState(0);
  const [servico, setServico] = useState<IServico[]>([]);

  const [valorTotalToxico, setValorTotalToxico] = useState(0);
  const [campoValorToxico, setCampoValorToxico] = useState<IExamesToxico[]>([]);
  const [novoValorToxico, setNovoValorToxico] = useState<IExamesToxico[]>([]);

  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogToxico, setDialogToxico] = useState<boolean>(false);

  const {
    uploadedFiles: files,
    fetchDataFiles,
    receivedCodContrato
  } = useFiles();

  const filesNames = files.map((file) => {
    return file.name;
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    control,
    getValues,
    setValue
  } = useForm<ContractSaveFormData>({
    resolver: zodResolver(contractSchema),

    defaultValues: {
      cod_contrato,
      usuario: userUpdate,
      arquivo,
      data_criacao,
      data_renovacao,
      nome_contrato
    }
  });

  // console.log(errors);

  const { append, remove } = useFieldArray({
    control,
    name: 'risco'
  });

  const { data, isSuccess } = useQuery({
    queryKey: ['comboRiscos'],
    queryFn: async () => {
      const res = await api.get(`/ravim/riscos`);
      return res.data;
    }
  });

  useEffect(() => {
    receivedCodContrato(getValues('cod_contrato'));
  }, [getValues('cod_contrato')]);

  function handleAddRiscos(riscos: IRiscos[]) {
    setRisco(riscos);
  }

  async function handleSubmitForm(data: ContractSaveFormData) {
    console.log(data);
    try {
      await api.post('ravim/update', data);

      setToastMessage({
        cod: '200',
        color: 'success',
        message: 'Salvo com sucesso',
        show: true
      });

      setTimeout(() => {
        window.location.replace('/contratos-credenciado');
      }, 2000);
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        setToastMessage({
          cod: '',
          color: 'error',
          message: error.message,
          show: true
        });
        // throw error.message;
      }
    }
  }

  async function editContrato(cod: string) {
    const data = {
      cod_contrato: cod
    };
    try {
      const res = await api.post(`ravim/dados/${cod}`, data);
      const filesLoaded = JSON.parse(res.data.arquivos);

      fetchDataFiles(filesLoaded);

      JSON.parse(res.data.beneficos).map((bene: ContractSaveFormData) => {
        /* setValue('num_conv_med', bene.num_conv_med);
        setValue('num_conv_odont', bene.num_conv_odont);
        setValue('num_conv_acade', bene.num_conv_acade);
        setValue('num_seg_vida', bene.num_seg_vida);
        setValue('num_apol_pat', bene.num_apol_pat); */
      });

      //= ================= Filtro exames cadastrados =====================
      const jsonExames = JSON.parse(res.data.exames);
      const valor = JSON.parse(res.data.exames).map((val: any) => {
        return parseFloat(val.valor);
      });
      const valorTot = valor.reduce(
        (soma: number, i: number) => soma - i * -1,
        0
      );

      setExames(jsonExames);
      setNovoValorExame(jsonExames);
      setValorExameTotal(Number(valorTot));
      setValue('exame', jsonExames);

      //= ================= Filtro exames toxico cadastrados =====================
      const jsonExamesToxi = JSON.parse(res.data.exame_toxi);
      const valorToxi = JSON.parse(res.data.exame_toxi).map((val: any) => {
        return parseFloat(val.valor);
      });
      const valorTotToxi = valorToxi.reduce(
        (soma: number, i: number) => soma - i * -1,
        0
      );

      setCampoValorToxico(jsonExamesToxi);
      setNovoValorToxico(jsonExamesToxi);
      setValorTotalToxico(Number(valorTotToxi));
      setValue('exame_toxi', jsonExamesToxi);

      //= ================ Filtro serviços ==============================
      const jsonServices = JSON.parse(res.data.servicos);
      const valorServico = JSON.parse(res.data.servicos).map((val: any) => {
        return parseFloat(val.valor);
      });
      const valorTotalServico = valorServico.reduce(
        (soma: number, i: number) => soma - i * -1,
        0
      );

      setCampoValorServices(jsonServices);
      setServico(jsonServices);
      setValorTotalServices(Number(valorTotalServico));
      setValue('servico', jsonServices);

      setRisco(JSON.parse(res.data.riscos));

      setValue('risco', JSON.parse(res.data.riscos));
    } catch (error) {
      if (error instanceof AppError) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    if (filesNames) {
      setValue('arquivo', filesNames);
    }
  }, [files]);

  useEffect(() => {
    fetchDataFiles([]);
  }, []);

  useEffect(() => {
    const getValues = new Promise((resolve) => {
      setTimeout(() => {
        editContrato(cod_contrato);
        resolve(10);
      }, 1 * 1000);
    });

    Promise.all([getValues]);
  }, []);

  return (
    <Container>
      <Typography variant="h4" align="center">
        Editar Contrato de Credenciados
      </Typography>

      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <PaperContainer>
          <Typography variant="h5">Dados do Cadastro</Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <DivGridFieldDataContract>
                <TextField
                  variant="outlined"
                  label="Nome do contrato"
                  {...register('nome_contrato')}
                />
                <TextField
                  variant="outlined"
                  label="Código do Contrato"
                  error={!!errors.cod_contrato?.message}
                  helperText={errors.cod_contrato?.message}
                  {...register('cod_contrato')}
                  disabled
                />
                <TextField
                  id="date"
                  label="Data de Criação"
                  type="date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register('data_criacao')}
                />
                <TextField
                  id="date"
                  label="Data de Renovação"
                  type="date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register('data_renovacao')}
                />
              </DivGridFieldDataContract>
              <Upload />
              <FileList />
            </Grid>
          </Grid>

          <Typography variant="h5">Exames</Typography>
          <DivExames>
            <Button
              title="Adicionar Exames"
              onClick={() => {
                setDialog(!dialog);
              }}
              icon={<Plus size={20} />}
              type="button"
            />
            <Button
              title="Exames Toxicólogico"
              onClick={() => {
                setDialogToxico(!dialogToxico);
              }}
              icon={<Plus size={20} />}
              type="button"
            />
            <Button
              icon={<Plus size={20} />}
              title="Adicionar Serviços"
              onClick={() => {
                setDialogServices(!dialogSevices);
              }}
              type="button"
            />
          </DivExames>
          <FormControl>
            <div>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={isSuccess ? data : []}
                getOptionLabel={(option: IRiscos) => {
                  return `${option.nome}` ? `${option.nome}` : '';
                }}
                onChange={(e: any, newValue, reason, detail) => {
                  handleAddRiscos(newValue);

                  if (reason === 'remove-option' || reason === 'clear') {
                    if (detail) {
                      const index = risco.findIndex(
                        (i) => i === detail?.option
                      );
                      remove(index);
                    }
                  } else if (reason === 'select-option') {
                    if (detail?.option) {
                      append({ ...detail?.option });
                    }
                  }
                  // console.log(reason, detail)
                }}
                value={risco}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Riscos"
                    placeholder="Riscos"
                  />
                )}
              />
            </div>
            <Exames
              control={control}
              openDialog={dialog}
              closeDialog={() => {
                setDialog(!dialog);
              }}
              getExames={exames}
              getNovoValor={novoValorExames}
              getValorTotal={valorExameTotal}
            />
            <Servicos
              control={control}
              openModal={dialogSevices}
              closeModal={() => {
                setDialogServices(false);
              }}
              getServico={servico}
              getNovoValor={servico}
              getValorTotal={valorTotalServices}
            />

            <Toxicologico
              control={control}
              openModal={dialogToxico}
              closeModal={() => {
                setDialogToxico(false);
              }}
              getNovoValor={campoValorToxico}
              getToxicologico={campoValorToxico}
              getValorTotal={valorTotalToxico}
            />
          </FormControl>
        </PaperContainer>
        <DivButtons>
          <NavLink to="/contratos-credenciado">
            <Button title="Sair" variantstyle="RED" type="button" />
          </NavLink>
          <Button type="submit" title="Salvar" isLoading={isSubmitting} />
        </DivButtons>
      </form>

      <ToastMessage
        code={toastMessage.cod}
        message={toastMessage.message}
        show={toastMessage.show}
        severity={toastMessage.color}
        onClose={() => {
          setToastMessage({
            cod: toastMessage.cod,
            color: toastMessage.color,
            message: toastMessage.message,
            show: toastMessage.show
          });
        }}
      />
    </Container>
  );
}
