import dayjs from 'dayjs';

function maskPhone(phone: string) {
  phone = phone.replace(/\D/g, '');
  phone = phone.replace(/(\d{2})(\d{5})(\d{4})/g, '($1)$2-$3');
  return phone;
}

function maskCNPJ(cnpj: string) {
  cnpj = cnpj.replace(/\D/g, '');
  cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  return cnpj;
}

function maskCPF(cpf: string) {
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return cpf;
}

function maskRG(rg: string) {
  rg = rg.replace(/\D/g, '');
  rg = rg.replace(/(\d{1})(\d)/, '$1.$2');
  rg = rg.replace(/(\d{3})(\d)/, '$1.$2');
  rg = rg.replace(/(\d{3})(\d)/, '$1.$2');
  return rg;
}

function maskCEP(cep: string) {
  cep = cep.replace(/\D/g, '');
  cep = cep.replace(/(\d{5})(\d{3})/, '$1-$2');
  return cep;
}

function maskDate(date: string) {
  if (!date) return 'Data não preenchida';
  date = dayjs(date).format('DD/MM/YYYY');
  return date;
}

function maskDateField(date: string) {
  date = date.replace(/\D/g, '');
  date = date.replace(/\s/g, '');
  date = date.replace(/(\d{2})(\d)/, '$1/$2');
  date = date.replace(/(\d{2})(\d)/, '$1/$2');

  if (date.length > 10) {
    date = date.substring(0, 10);
  }

  return date;
}

function sendDateToDb(date: string) {
  const [day, month, year] = date.split('/');

  return `${year}-${month}-${day}`;
}

// Faz o calculo do CPF
function cpfValidation(strCPF: string) {
  let Soma;
  let Resto;
  Soma = 0;
  if (
    strCPF === '00000000000' ||
    strCPF === '11111111111' ||
    strCPF === '22222222222' ||
    strCPF === '33333333333' ||
    strCPF === '44444444444' ||
    strCPF === '55555555555' ||
    strCPF === '66666666666' ||
    strCPF === '77777777777' ||
    strCPF === '88888888888' ||
    strCPF === '99999999999'
  )
    return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

function validaCNPJ(cnpj: string) {
  cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

  if (cnpj.length !== 14) return false;

  // Validação do primeiro dígito verificador
  let soma = 0;
  let peso = 5;
  for (let i = 0; i < 12; i++) {
    soma += parseInt(cnpj.charAt(i)) * peso--;
    if (peso < 2) peso = 9;
  }
  let digito = 11 - (soma % 11);
  if (digito > 9) digito = 0;
  if (parseInt(cnpj.charAt(12)) !== digito) return false;

  // Validação do segundo dígito verificador
  soma = 0;
  peso = 6;
  for (let i = 0; i < 13; i++) {
    soma += parseInt(cnpj.charAt(i)) * peso--;
    if (peso < 2) peso = 9;
  }
  digito = 11 - (soma % 11);
  if (digito > 9) digito = 0;
  if (parseInt(cnpj.charAt(13)) !== digito) return false;

  return true;
}

function getRemoveSpace(space: string) {
  space = space.replace(/\s/g, '');
  return space;
}

function getFileName(file: string) {
  file = file.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  file = file.replace(/([^\w.pdf-]+|\s+)/g, '');
  return file;
}

function sendFileWithoutFake(fileName: string) {
  fileName = fileName.split('fakepath')[1]?.replace(/\s/g, '_');
  fileName = fileName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  fileName = fileName.replace(/([^\w.pdf-]+|\s+)/g, '');
  return fileName;
}

function maskCoin(value: number) {
  return value.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
}

function capitalFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

function maskCharSpecial(cod_ghe: string) {
  cod_ghe = cod_ghe.replace(/^\s/g, '');
  cod_ghe = cod_ghe.replace(/[A-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/g, '');
  return cod_ghe;
}

function verifyNumberOrString(value: string) {
  return typeof value === 'string' && value >= '0' && value <= '9';
}

function verifyUpLoads(upload: string | null) {
  if (upload === null) {
    return '';
  } else if (upload === 'None') {
    return '';
  } else {
    return '';
  }
}

export {
  maskPhone,
  maskCNPJ,
  maskCEP,
  maskCPF,
  maskRG,
  maskDate,
  cpfValidation,
  validaCNPJ,
  getRemoveSpace,
  getFileName,
  maskCoin,
  capitalFirstLetter,
  sendFileWithoutFake,
  maskCharSpecial,
  verifyNumberOrString,
  maskDateField,
  sendDateToDb,
  verifyUpLoads
};
