import React, { useState, useEffect } from 'react';
import { type Color } from '@material-ui/lab/Alert';
import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  NativeSelect,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import { api } from '@services/api';
import { Button } from '@components/Button';
import { ToastMessage } from '@components/ToastMessage';
import { Upload } from '@components/Upload';
import { FileList } from '@components/FileList';
import { DivButtons, PaperInfo, DivExames, DivFields } from './style';
import { maskDate } from '@utils/masks';
import { useFiles } from '@base/context/files';
import { NavLink } from '@components/NavLink';

interface IEsocial {
  dados: Dados;
  exames: Exame[];
  riscos: Risco[];
  e_social: ESocial[];
}
export interface Dados {
  cnpj: string;
  cpf_colaborador: string;
  data_agendamento: string;
  nome_completo: string;
  num_agendanda: string;
  razao_social: string;
  tipo_aso: string;
}

export interface Exame {
  id_exame: string;
  nome_exame: string;
}

export interface Risco {
  id_risco: string;
  nome: string;
}

export interface ESocial {
  data_envio_s2220: string;
  data_envio_s2240: string;
  observacao_s2220: string;
  observacao_s2240: string;
  recibo_esocial_s2220: string;
  recibo_esocial_s2240: string;
  status: string;
}

const statusEsocial = [
  { id: 1, name: 'Pendente', value: 'pendente' },
  { id: 2, name: 'Concluído', value: 'concluido' }
];

export const EditarEsocial: React.FC<any> = (props) => {
  const {
    cnpj,
    nome_completo,
    cpf_colaborador,
    razao_social,
    tipo_aso,
    data_ultimo_agendamento,
    status,
    num_agenda
  } = props.location.state;

  const {
    uploadedFiles: files,
    deleteFile,
    fetchDataFiles,
    receivedCodContrato
  } = useFiles();

  const nome_user = localStorage.getItem('NameUser') || '';

  const [statusError, setStatusError] = useState({
    code: '',
    message: '',
    show: false,
    severity: '' as Color
  });
  const [dataEsocial, setDataEsocial] = useState<IEsocial | null>(null);
  const [arquivo, setArquivo] = useState<string[]>([]);
  const [statusColab, setStatusColab] = useState(status || 'pendente');

  const [dataEnvioS2220, setDataEnvioS2220] = useState('');
  const [observacaoS2220, setObservacaoS2220] = useState('');
  const [reciboSocialS2220, setReciboSocialS2220] = useState('');

  const [dataEnvioS2240, setDataEnvioS2240] = useState('');
  const [observacaoS2240, setObservacaoS2240] = useState('');
  const [reciboSocialS2240, setReciboSocialS2240] = useState('');

  const filesNames = files.map((file) => {
    return file.name;
  });

  async function sendAgendamento() {
    const data = {
      num_agenda,
      nome: nome_completo,
      cpf_colaborador,
      status: statusColab,

      data_envio_s2220: dataEnvioS2220,
      observacao_s2220: observacaoS2220,
      recibo_esocial_s2220: reciboSocialS2220,

      data_envio_s2240: dataEnvioS2240,
      observacao_s2240: observacaoS2240,
      recibo_esocial_s2240: reciboSocialS2240
    };

    try {
      const response = await api.post('ravim/colab-esocial', data);
      if (response.data.estado === 200) {
        setStatusError({
          code: String(response.status),
          message: response.data.message,
          show: true,
          severity: 'success'
        });
        setTimeout(() => {
          window.location.replace('/e-social');
        }, 2000);
      }
    } catch (error: any) {
      console.log(error);
      setStatusError({
        code: String(error.response.status),
        message: error.message,
        show: true,
        severity: 'error'
      });
    }
  }

  async function fetchDataUser(
    cpf_colaborador: string,
    num_agenda: string,
    tipo_aso: string,
    data_ultimo_agendamento: string
  ) {
    try {
      const data = {
        cpf_colaborador,
        num_agenda,
        tipo_aso,
        data_ultimo_agendamento
      };
      const response = await api.post('ravim/e-social', data);
      setDataEsocial(response.data);
      receivedCodContrato(num_agenda);
      fetchCod(num_agenda);
      getData(response.data);
    } catch (error: any) {
      console.log(error);
    }
  }

  async function fetchCod(cod: string) {
    try {
      const data = {
        num_agenda: cod
      };

      const response = await api.post(`ravim/arquivos-salvo`, data);
      fetchDataFiles(response.data);
    } catch (error: any) {
      console.log(error);
    }
  }

  function getData(data: IEsocial) {
    data.e_social?.map((social) => {
      setDataEnvioS2220(social.data_envio_s2220);
      setReciboSocialS2220(social.recibo_esocial_s2220);
      setObservacaoS2220(social.observacao_s2220);

      setDataEnvioS2240(social.data_envio_s2240);
      setReciboSocialS2240(social.recibo_esocial_s2240);
      setObservacaoS2240(social.observacao_s2240);
    });
  }

  useEffect(() => {
    fetchDataUser(
      cpf_colaborador,
      num_agenda,
      tipo_aso,
      data_ultimo_agendamento
    );
    fetchDataFiles([]);
  }, [num_agenda]);

  useEffect(() => {
    if (filesNames) {
      setArquivo(filesNames);
    }
  }, [files]);

  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Editar Esocial
      </Typography>
      <Paper style={{ padding: 40 }}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Uploads
            </Typography>
            <Upload />
            <FileList />
          </Grid>
        </Grid>
      </Paper>
      <Grid
        container
        spacing={1}
        style={{ flexGrow: 1, marginTop: 20, width: '100%' }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <PaperInfo>
                <Typography variant="h6" align="center">
                  Colaborador
                </Typography>
                <div>
                  <Typography>
                    <span>CNPJ:</span>
                    {cnpj}
                  </Typography>
                  <Typography>
                    <span>Razão social:</span>
                    {razao_social}
                  </Typography>
                  <Typography>
                    <span>CPF:</span>
                    {cpf_colaborador}
                  </Typography>
                  <Typography>
                    <span>Nome completo:</span>
                    {nome_completo}
                  </Typography>
                  <Typography>
                    <span>Data do agendamento:</span>
                    {maskDate(data_ultimo_agendamento)}
                  </Typography>
                  <Typography>
                    <span>Tipo de ASO:</span>
                    {tipo_aso}
                  </Typography>
                  <Typography>
                    <span>Status do envio:</span>
                    <FormControl>
                      <NativeSelect
                        onChange={(e) => {
                          setStatusColab(e.target.value);
                        }}
                        value={statusColab}
                      >
                        {statusEsocial.map((esocial) => {
                          return (
                            <option key={esocial.id} value={esocial.value}>
                              {esocial.name}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </Typography>
                </div>
              </PaperInfo>
            </Grid>
            <Grid item>
              <PaperInfo>
                <Typography variant="h6" align="center">
                  Exames
                </Typography>
                {dataEsocial?.exames.map((exame) => {
                  return (
                    <DivExames key={exame.id_exame}>
                      <Typography>
                        {`${exame.id_exame} - ${exame.nome_exame}`}
                      </Typography>
                    </DivExames>
                  );
                })}
              </PaperInfo>
            </Grid>
            <Grid item>
              <PaperInfo>
                <Typography variant="h6" align="center">
                  Riscos
                </Typography>
                {dataEsocial?.riscos.map((risco) => {
                  return (
                    <DivExames key={risco.id_risco}>
                      <Typography>
                        {`${risco.id_risco} - ${risco.nome}`}
                      </Typography>
                    </DivExames>
                  );
                })}
              </PaperInfo>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Paper style={{ marginTop: 10, padding: '0.9rem' }}>
        <Typography>S-2220 - Monitoramento da Saúde do Colaborador</Typography>
        <DivFields>
          <TextField
            type="date"
            label="Data do envio"
            value={dataEnvioS2220}
            onChange={(e) => {
              setDataEnvioS2220(e.target.value);
            }}
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
          />
          <TextField
            value={reciboSocialS2220}
            onChange={(e) => {
              setReciboSocialS2220(e.target.value);
            }}
            fullWidth
            label="Recibo eSocial"
            variant="outlined"
          />
        </DivFields>
        <TextField
          label="Observações"
          variant="outlined"
          value={observacaoS2220}
          onChange={(e) => {
            setObservacaoS2220(e.target.value);
          }}
          minRows={4}
          multiline
          fullWidth
        />
      </Paper>
      {String(tipo_aso).toLowerCase() === 'admissional' ||
      String(tipo_aso).toLowerCase() === 'tipo de riscos' ? (
        <Paper style={{ marginTop: 10, padding: '0.9rem' }}>
          <Typography>S-2240 - Condição Ambiente do Trabalho</Typography>
          <DivFields>
            <TextField
              type="date"
              label="Data do envio"
              variant="outlined"
              value={dataEnvioS2240}
              onChange={(e) => {
                setDataEnvioS2240(e.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
            <TextField
              value={reciboSocialS2240}
              onChange={(e) => {
                setReciboSocialS2240(e.target.value);
              }}
              fullWidth
              label="Recibo eSocial"
              variant="outlined"
            />
          </DivFields>
          <TextField
            label="Observações"
            variant="outlined"
            value={observacaoS2240}
            onChange={(e) => {
              setObservacaoS2240(e.target.value);
            }}
            minRows={4}
            multiline
            fullWidth
          />
        </Paper>
      ) : (
        <></>
      )}

      <DivButtons>
        <NavLink to="/e-social">
          <Button title="Sair" variantstyle="RED" />
        </NavLink>
        <Button title="Salvar" onClick={sendAgendamento} />
      </DivButtons>
      <ToastMessage
        code={statusError.code}
        message={statusError.message}
        onClose={() => {
          setStatusError({
            code: statusError.code,
            message: statusError.message,
            severity: statusError.severity,
            show: false
          });
        }}
        severity={statusError.severity}
        show={statusError.show}
      />
    </Container>
  );
};
