import React, { useState } from 'react';
import * as M from '@material-ui/core';
import * as S from './styles';

import { Add } from '@material-ui/icons';
import { api } from '@services/api';
import { ToastMessage } from '@components/ToastMessage';
import { type Color } from '@material-ui/lab';
import { Button } from '@components/Button';

export function Exames() {
  const [codExame, setCodExame] = useState('');
  const [exameName, setExameName] = useState('');

  const [codRisco, setCodRisco] = useState('');
  const [riscoName, setRiscoName] = useState('');

  const [isLoadingExame, setIsLoadingExame] = useState(false);
  const [isLoadingRisco, setIsLoadingRisco] = useState(false);
  const [messageSend, setMessageSend] = useState({
    cod: '',
    message: '',
    severity: '' as Color,
    show: false
  });

  async function fetchExame() {
    const data = {
      id_exame: codExame,
      nome_exame: exameName
    };

    try {
      setIsLoadingExame(true);
      const res = await api.post('ravim/cad-exames', data);
      if (res.status === 200) {
        setMessageSend({
          cod: String(res.status),
          message: res.data.message,
          severity: 'success',
          show: true
        });

        setCodExame('');
        setExameName('');
      } else if (res.status === 400) {
        setMessageSend({
          cod: String(res.status),
          message: res.data.message,
          severity: 'warning',
          show: true
        });
      }
    } catch (error: any) {
      setMessageSend({
        cod: error.code,
        message: error.message,
        severity: 'error',
        show: true
      });
    } finally {
      setIsLoadingExame(false);
    }
  }

  async function fetchRisco() {
    const data = {
      id_risco: codRisco,
      nome_risco: riscoName
    };

    try {
      setIsLoadingRisco(true);
      const res = await api.post('ravim/cad-risco', data);
      if (res.status === 200) {
        setMessageSend({
          cod: String(res.status),
          message: res.data.message,
          severity: 'success',
          show: true
        });

        setRiscoName('');
        setCodRisco('');
      } else if (res.status === 400) {
        setMessageSend({
          cod: String(res.status),
          message: res.data.message,
          severity: 'warning',
          show: true
        });
      }
    } catch (error: any) {
      setMessageSend({
        cod: error.code,
        message: error.message,
        severity: 'error',
        show: true
      });
    } finally {
      setIsLoadingRisco(false);
    }
  }

  return (
    <M.Container>
      <M.Typography
        style={{ textAlign: 'center', fontWeight: 'bold' }}
        variant="h5"
      >
        Gerenciamento de exames
      </M.Typography>

      <div>
        <S.Div>
          <M.Typography>Cadastrar exame</M.Typography>
          <S.DivExames>
            <M.TextField
              onChange={(e) => { setCodExame(e.target.value); }}
              style={{ width: 80 }}
              label="Código"
              variant="outlined"
              value={codExame}
            />
            <M.TextField
              label="Nome do exame"
              variant="outlined"
              onChange={(e) => { setExameName(e.target.value); }}
              value={exameName}
            />
            <Button
              icon={<Add />}
              onClick={fetchExame}
              disabled={
                !!(codExame.length === 0 || exameName.length === 0)
              }
              isLoading={isLoadingExame}
            />
          </S.DivExames>
        </S.Div>

        <S.Div>
          <M.Typography>Cadastrar risco</M.Typography>
          <S.DivExames>
            <M.TextField
              onChange={(e) => { setCodRisco(e.target.value); }}
              style={{ width: 80 }}
              label="Código"
              variant="outlined"
              value={codRisco}
            />
            <M.TextField
              label="Nome do risco"
              variant="outlined"
              onChange={(e) => { setRiscoName(e.target.value); }}
              value={riscoName}
            />
            <Button
              icon={<Add />}
              onClick={fetchRisco}
              disabled={
                !!(codRisco.length === 0 || riscoName.length === 0)
              }
              isLoading={isLoadingRisco}
            />
          </S.DivExames>
        </S.Div>
      </div>
      <ToastMessage
        code={messageSend.cod}
        message={messageSend.message}
        onClose={() =>
          { setMessageSend({
            cod: messageSend.cod,
            message: messageSend.message,
            severity: messageSend.severity,
            show: false
          }); }
        }
        show={messageSend.show}
        severity={messageSend.severity}
      />
    </M.Container>
  );
}
