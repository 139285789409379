export const gen = [
  { id: 1, genero: 'Masculino' },
  { id: 2, genero: 'Feminino' }
];
export const situ = [
  { id: 1, situacao: 'Ativo', value: 'ativo' },
  { id: 2, situacao: 'Desligado', value: 'desligado' },
  { id: 3, situacao: 'Afastado', value: 'afastado' },
  { id: 4, situacao: 'Cancelado', value: 'cancelado' }
];
export const tur = [
  { id: 1, turno: 'Manhã' },
  { id: 2, turno: 'Tarde' },
  { id: 3, turno: 'Noite' },
  { id: 4, turno: 'Horário comercial' }
];
