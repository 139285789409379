import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactElement
} from 'react';
import {
  Button as ButtonArrow,
  ButtonProps,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  type Theme,
  Typography,
  createStyles,
  makeStyles,
  withStyles
} from '@material-ui/core';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import { type IStatus } from '.';
import { api } from '@services/api';
import { Autocomplete } from '@material-ui/lab';
import { CardHibrid } from '@components/CardHibrid';
import { ASO } from '@data/tipoAso';
import { status } from '@data/status';
import { CardHibridCad } from '@components/CardHibridCad';
import { ColaboradorProps, SearchCPF } from '@components/SearchCPF';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from '@components/Button';
import { NavLink } from '@components/NavLink';
import { InputSearchColab } from '@components/InputSearchColab';
import { AppError } from '@utils/AppError';

interface ISelectedExames {
  id_exame_ghe: number;
  cod_ghe: string;
  exame_credenciado: string;
  exame_empresa: string;
  valor_credenciado: any;
  valor_empresa: string;
}

interface GuiaProps {
  selectType: number;
  cpf_colaborador: string;
  cpf_cnpj_comer: string;
  num_agenda: string;
}

interface IColaborador {
  id_colaboradores: number;
  matricula_empresa: string;
  matricula_esocial: any;
  nome_completo: string;
  cpf_colaborador: string;
  rg: string;
  data_nascimento: string;
  genero: string;
  cnh: string;
  email: string;
  telefone: string;
  situacao: string;
  nis: string;
  funcao: string;
  setor: string;
  turno: string;
  cnpj: string;
  razao_social: string;
  data_admissao: string;
  horario_trab_entrada: string;
  horario_trab_saida: string;
  local_obra: string;
  cod_ghe: any;
  ghe: string;
}
interface INomeComercial {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  razao_social: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  telemedicina: string;
  observacao: string;
  tipo: string;
}

export interface CredentialProps {
  id?: number;
  id_exame_hibrido: number;
  cpf_cnpj_comer: string;
  horario: string;
  data_agendamento: string;
  nome_comercial: string;
  ordem_chegada: string | null;
  tipo_agendamento?: string;
  exames: ISelectedExames[];
  guia?: GuiaProps;
  linkDoatendimento?: string;
  tipo: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto'
    },
    cardHeader: {
      padding: theme.spacing(1, 2)
    },
    list: {
      width: '100%',
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto'
    },
    button: {
      margin: theme.spacing(0.5, 0)
    }
  })
);

function not(a: ISelectedExames[], b: ISelectedExames[]) {
  return a.filter((value) => !b.includes(value));
}

function intersection(a: ISelectedExames[], b: ISelectedExames[]) {
  return a.filter((value) => b.includes(value));
}

function union(a: ISelectedExames[], b: ISelectedExames[]) {
  return [...a, ...not(b, a)];
}

export const AtendimentoHibrido: React.FC<IStatus> = (props) => {
  const classes = useStyles();

  const [isHideCredential, setIsHideCredential] = useState(false);

  const [listNomeComercial, setListNomeComercial] = useState<INomeComercial>(
    {} as INomeComercial
  );
  const [selectedNomeComercial, setSelectedNomeComercial] = useState<any>(null);
  const [searchEmail, setSearchEmail] = useState<any>();
  const [emailAgendamento, setEmailAgendamento] = useState([]);

  const [messageError, setMessageError] = useState('');
  const [loadingColab, setLoadingColab] = useState(false);

  const [statusAgendamento, setStatusAgendameto] = useState<string>('');
  const [cpfColab, setCpfColab] = useState<any>();
  const [nomeColab, setNomeColab] = useState<string>('');
  const [dataAgendamento, setDataAgendamento] = useState<string>('');
  const [horarioAgendamento, setHorarioAgendamento] = useState<string>('');
  const [tipoAso, setTipoAso] = useState<string>('');
  const [obs, setObs] = useState<string>('');
  const [cnpjComercial, setCnpjComercial] = useState<string>('');
  const [comboColab, setComboColab] = useState<IColaborador[]>([]);
  const [atendimento, setAtendimento] = useState<string>('telemedicina');
  const [ordemChegada, setOrdemChegada] = useState<string>('Sim');

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState();
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color>();
  const nome_user = localStorage.getItem('NameUser') || '';

  const [checked, setChecked] = React.useState<ISelectedExames[]>([]);
  const [left, setLeft] = React.useState<ISelectedExames[]>([]);
  const [right, setRight] = React.useState<ISelectedExames[]>([]);

  const [credentialArray, setCredentialArray] = useState<CredentialProps[]>([]);
  const [linkDoAtendimento, setLinkDoAtendimento] = useState('');

  async function sendAgendamento() {
    const hora = new Date();

    const newHora = hora.toLocaleString('pt-BR', { hour12: false });
    const data = {
      status: statusAgendamento,
      observacao: obs,
      tipo_aso: tipoAso,
      horario: horarioAgendamento,
      data_agendamento: dataAgendamento,
      estado: selectedNomeComercial?.cidade || '',
      cidade: selectedNomeComercial?.estado || '',
      nome_comercial: selectedNomeComercial?.nome_comercial || '',
      tipo_agendamento: props.location.state.tipo_agendamento,
      nome: nomeColab,
      cpf_colaborador: cpfColab,
      num_agenda: null,
      usuario: `${nome_user} ${newHora}`,
      email_agendamento: emailAgendamento,
      cpf_cnpj_comer: selectedNomeComercial?.cpf_cnpj || '',
      exame_colab: credentialArray
    };
    // console.log(data);
    await api
      .post(`/ravim/agendamento-hibrido`, data)
      .then((res) => {
        if (res.status === 200) {
          setCod(String(res.status));
          setSnack(true);
          setSeverity('success');
          setMessage(res.data.message);
          setTimeout(() => {
            window.location.replace('/agendamentos');
          }, 1000);
        } else {
          setCod(String(res.status));
          setSnack(true);
          setSeverity('error');
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        setCod(err.code);
        setSnack(true);
        setSeverity('error');
        setMessage(err.message);
      });
  }

  // Adicionar card de credenciado

  function handleAddCredential() {
    const newVar = [...credentialArray];
    const data = {
      id_exame_hibrido: new Date().valueOf(),
      horario: horarioAgendamento,
      cpf_cnpj_comer: selectedNomeComercial.cpf_cnpj,
      data_agendamento: dataAgendamento,
      nome_comercial: selectedNomeComercial.nome_comercial,
      ordem_chegada: ordemChegada,
      tipo_agendamento: atendimento,
      exames: right,
      linkDoatendimento: linkDoAtendimento,
      tipo: listNomeComercial.tipo
    };
    newVar.push(data);
    setCredentialArray(newVar);
  }

  function handleDeleteCredential(id: number) {
    const newList = credentialArray.filter(
      (exame: any) => exame.id_exame_hibrido !== id
    );

    setCredentialArray(newList);
  }

  const {
    data: returListaNomeComercial,
    mutate,
    isPending
  } = useMutation({
    mutationKey: ['postNomeComercial'],
    mutationFn: async (text: object) => {
      return await api.post(`/ravim/telemed-clinicas`, text);
    }
  });

  async function getExames(cnpj: INomeComercial) {
    const data = {
      cpf_colaborador: cpfColab,
      cpf_cnpj_comer: cnpj.cpf_cnpj,
      tipo_aso: tipoAso,
      num_agenda: ''
    };

    await api
      .post(`/ravim/exames-hibrido`, data)
      .then((res) => {
        setLeft(res.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }

  function handleSelectedBusiness(newValue: any) {
    setSelectedNomeComercial(newValue);
    getExames(newValue);
    setListNomeComercial(newValue)
  }


  function handleDisableFields() {
    if (ordemChegada === 'Sim' && atendimento !== 'telemedicina') {
      return true;
    } else {
      return false;
    }
  }

  function handleSelectCredential(text: string) {
    if (text === 'telemedicina') {
      setRight([]);
      setLeft([]);
      setDataAgendamento('');
      setHorarioAgendamento('');
      setSelectedNomeComercial(null);
      setAtendimento(text);
    } else if (text === 'presencial') {
      setRight([]);
      setLeft([]);
      setDataAgendamento('');
      setHorarioAgendamento('');
      setLinkDoAtendimento('');
      setOrdemChegada('Não');
      setSelectedNomeComercial(null);
      setAtendimento(text);
    }
  }

  /* ================================================ */
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: ISelectedExames) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: ISelectedExames[]) =>
    intersection(checked, items)?.length;

  const handleToggleAll = (items: ISelectedExames[]) => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: React.ReactNode, items: ISelectedExames[]) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items?.length && items?.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items?.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items?.length} selecionado`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value: ISelectedExames) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value.cod_ghe}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.includes(value)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.exame_credenciado}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  /* ============================ */

  function handleSelectAtendimento(text: string) {
    setLinkDoAtendimento(text);
  }

  useEffect(() => {
    // reqStatus();
    mutate({
      tipo_agendamento: props.location.state.tipo_agendamento,
      tipo: atendimento
    });
  }, [atendimento, props.location.state.tipo_agendamento]);

  useEffect(() => {
    if (statusAgendamento !== '' && cpfColab !== '' && tipoAso !== '') {
      setIsHideCredential(true);
    } else {
      setIsHideCredential(false);
    }
  }, [statusAgendamento, cpfColab, tipoAso]);

  async function handleSearchCpf(cpf: string) {
    setCpfColab(cpf);

    try {
      setLoadingColab(true);
      if (cpf.length === 14) {
        const response = await api.post(`ravim/pesq-cpf`, {
          cpf_colaborador: cpf
        });
        setNomeColab(response.data.nome_completo);
      }
    } catch (error) {
      if (error instanceof AppError) {
        if (error.code === 400) {
          setNomeColab('');
          setMessageError(error.message);
        }
      }
    } finally {
      setLoadingColab(false);
    }
  }

  useEffect(() => {
    if (cpfColab?.length <= 0) {
      setMessageError('');
      setNomeColab('')
    }
    if (nomeColab) {
      setMessageError('');
    }
  }, [cpfColab, nomeColab]);

  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Agendamento Híbrido
      </Typography>
      <Paper style={{ padding: 40 }}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Status
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl variant="outlined" style={{ width: '20%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  value={statusAgendamento}
                  onChange={(e: any) => {
                    setStatusAgendameto(e.target.value);
                  }}
                >
                  {status.map((status, index) => (
                    <MenuItem key={index} value={status.value}>
                      {status.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Colaborador
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
            <InputSearchColab
                label="CPF"
                value={cpfColab}
                onChange={(e) => handleSearchCpf(e.toString())}
                errorShow={!!messageError}
                textError={messageError}
                isLoading={loadingColab}
              />
              <TextField
                disabled
                label="Nome"
                variant="outlined"
                fullWidth
                value={nomeColab}
                onChange={(e) => {
                  setNomeColab(e.target.value);
                }}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl variant="outlined" style={{ width: '20%' }}>
                <InputLabel>Tipo de ASO</InputLabel>
                <Select
                  label="Tipo de ASO"
                  value={tipoAso}
                  onChange={(e: any) => {
                    setTipoAso(e.target.value);
                  }}
                >
                  {ASO.map((aso, index) => (
                    <MenuItem key={index} value={aso.value}>
                      {aso.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                minRows={4}
                label="OBS"
                multiline
                variant="outlined"
                value={obs}
                onChange={(e) => {
                  setObs(e.target.value);
                }}
                fullWidth
              />
            </div>
            {isHideCredential ? (
              <>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', margin: 10 }}
                >
                  Credenciado
                </Typography>
                <div style={{ display: 'flex', gap: 20, margin: 15 }}>
                  <RadioGroup
                    defaultValue="Telemedicina"
                    style={{ flexDirection: 'row' }}
                    value={atendimento}
                    onChange={(e) => {
                      handleSelectCredential(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value="telemedicina"
                      label="Telemedicina"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value="presencial"
                      label="Presencial"
                    />
                  </RadioGroup>
                </div>

                <div style={{ display: 'flex', gap: 20, margin: 15 }}>
                  <Autocomplete
                    fullWidth
                    id="tags-outlined"
                    options={returListaNomeComercial?.data || []}
                    loading={isPending}
                    getOptionSelected={(option, value) =>
                      option.nome_comercial === value.nome_comercial
                    }
                    getOptionLabel={(option: INomeComercial) =>
                      `${option.nome_comercial} - ${option.estado} - ${option.cidade}`
                    }
                    onChange={(e, newValue: any) => {
                      handleSelectedBusiness(newValue);
                    }}
                    value={selectedNomeComercial}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Nome Credenciado"
                        placeholder="Nome Credenciado"
                      />
                    )}
                  />
                </div>

                <div style={{ display: 'flex', gap: 20, margin: 15 }}>
                  <TextField
                    label="Data do agendamento"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    required
                    value={dataAgendamento}
                    onChange={(e) => {
                      setDataAgendamento(e.target.value);
                    }}
                  />
                  <TextField
                    label="Horário"
                    type="time"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={horarioAgendamento}
                    required
                    onChange={(e) => {
                      setHorarioAgendamento(e.target.value);
                    }}
                    disabled={handleDisableFields()}
                  />

                  {atendimento === 'presencial' ? (
                    <></>
                  ) : (
                    <TextField
                      label="Link do atendimento"
                      variant="outlined"
                      value={linkDoAtendimento}
                      onChange={(value) => {
                        handleSelectAtendimento(value.target.value);
                      }}
                    />
                  )}

                  {atendimento !== 'telemedicina' ? (
                    <FormControl>
                      <Typography>Atendimento por ordem de chegada</Typography>
                      <RadioGroup
                        defaultValue="Sim"
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                        value={ordemChegada}
                        onChange={(e) => {
                          setOrdemChegada(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="Sim"
                          label="Sim"
                          style={{ marginRight: 50 }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="Não"
                          label="Não"
                        />
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.root}
                  >
                    <Grid item>{customList('Exames carregados', left)}</Grid>
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <ButtonArrow
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleCheckedRight}
                          disabled={leftChecked?.length === 0}
                          aria-label="move selected right"
                        >
                          &gt;
                        </ButtonArrow>
                        <ButtonArrow
                          variant="outlined"
                          size="small"
                          className={classes.button}
                          onClick={handleCheckedLeft}
                          disabled={rightChecked?.length === 0}
                          aria-label="move selected left"
                        >
                          &lt;
                        </ButtonArrow>
                      </Grid>
                    </Grid>
                    <Grid item>{customList('Exames Selecionados', right)}</Grid>
                  </Grid>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 20
                  }}
                >
                  <Button
                    title="Adicionar no Agendamento"
                    onClick={handleAddCredential}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Paper>

      <CardHibridCad onDelete={handleDeleteCredential} data={credentialArray} />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          marginTop: 20
        }}
      >
        <NavLink to={{ pathname: '/agendar', state: { exit: true } }}>
          <Button title="Sair" variantstyle="RED" />
        </NavLink>
        <Button
          title="Cadastrar"
          variant="outlined"
          onClick={sendAgendamento}
        />
      </div>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
