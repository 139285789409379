import React, { useState, useEffect } from 'react';
import { api } from '@services/api';

import {
  Container,
  TextField,
  Typography,
  Grid,
  Snackbar
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';
import MuiAlert, { type Color } from '@material-ui/lab/Alert';

import { Upload } from '@components/Upload';
import { FileList } from '@components/FileList';
import { useFiles } from '@base/context/files';
import { Button } from '@components/Button';
import { Plus } from '@phosphor-icons/react/dist/ssr';
import {
  DivBenefits,
  DivButtons,
  DivGridFieldDataContract,
  DivServices,
  PaperContainer
} from './styles';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useFieldArray } from 'react-hook-form';
import { Exames } from '@components/Exames';
import { Servicos } from '@components/Servicos';
import { Toxicologico } from '@components/Toxicologico';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@context/auth';
import { AppError } from '@utils/AppError';
import { NavLink } from '@components/NavLink';

interface IRiscos {
  id_risco: string;
  nome: string;
}

const contractShema = z.object({
  nome_contrato: z.string().trim().optional().default(''),
  cod_contrato: z
    .string()
    .trim()
    .min(1, { message: 'O campo não pode ser vazio.' }),
  data_criacao: z.string().optional(),
  data_renovacao: z.string().optional(),
  arquivo: z.array(z.string()).optional(),
  exame: z.array(
    z.object({
      id_exames: z.string().or(z.number()).optional(),
      nome: z.string(),
      valor: z.number().or(z.string())
    })
  ),
  risco: z.array(
    z.object({
      id_risco: z.string().or(z.number()).optional(),
      nome: z.string()
    })
  ),
  servico: z.array(
    z.object({
      id_servicos: z.string().or(z.number()).optional(),
      nome: z.string(),
      valor: z.number().or(z.string())
    })
  ),
  num_conv_med: z.string().optional(),
  num_conv_odont: z.string().optional(),
  num_conv_acade: z.string().optional(),
  num_seg_vida: z.string().optional(),
  num_apol_pat: z.string().optional(),
  tipo_plano: z.string().optional(),
  plano_bronze: z.string().optional(),
  valor_bronze: z.string().optional(),
  plano_prata: z.string().optional(),
  valor_prata: z.string().optional(),
  plano_ouro: z.string().optional(),
  valor_ouro: z.string().optional(),
  plano_platina: z.string().optional(),
  valor_platina: z.string().optional(),
  plano_personalizado: z.string().optional(),
  valor_personalizado: z.string().optional(),
  exame_toxi: z
    .array(
      z.object({
        id_exame_tox: z.string().or(z.number()).optional(),
        nome: z.string(),
        valor: z.number().or(z.string())
      })
    )
    .optional(),
  usuario: z.string().optional()
});

export type ContractSaveFormData = z.infer<typeof contractShema>;

export default function CadContratos() {
  const { user } = useAuth();
  const [risco, setRisco] = useState<IRiscos[]>([]);

  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<Color | undefined>();
  const [cod, setCod] = useState('');

  const [dialogToxico, setDialogToxico] = useState<boolean>(false);
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogSevices, setDialogServices] = useState(false);

  const hora = new Date();
  const newHora = hora.toLocaleString('pt-BR', { hour12: false });
  const userUpdate = `${user.nome} ${newHora}`;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isValid }
  } = useForm<ContractSaveFormData>({
    resolver: zodResolver(contractShema),
    defaultValues: {
      usuario: userUpdate
    }
  });
  const { append, remove } = useFieldArray({
    control,
    name: 'risco'
  });

  const {
    uploadedFiles: files,
    fetchDataFiles,
    receivedCodContrato
  } = useFiles();

  const filesNames = files.map((file) => {
    return file.name;
  });

  useEffect(() => {
    if (filesNames) {
      setValue('arquivo', filesNames);
    }
  }, [files]);

  useEffect(() => {
    fetchDataFiles([]);
  }, []);

  useEffect(() => {
    receivedCodContrato(getValues('cod_contrato'));
  }, [getValues('cod_contrato')]);

  const { data, isSuccess } = useQuery({
    queryKey: ['comboRiscos'],
    queryFn: async () => {
      const res = await api.get(`/ravim/riscos`);
      return res.data;
    }
  });

  function handleAddRiscos(riscos: IRiscos[]) {
    setRisco(riscos);
  }

  // console.log(errors)

  async function cadastroEmpresas(data: ContractSaveFormData) {
    console.log(data);

    try {
      const res = await api.post(`/ravim/cad_emp`, data);
      if (res.status === 200) {
        setSnack(true);
        setCod(String(res.status));
        setMessage(res.data.message);
        setSeverity('success');
        setTimeout(() => {
          window.location.replace('/contratos-empresa');
        }, 2000);
      } else {
        setSnack(true);
        setCod(String(res.status));
        setMessage(res.data.message);
        setSeverity('error');
      }
    } catch (error) {
      if (error instanceof AppError) {
        setSnack(true);
        setCod(String(error.code));
        setMessage(error.message);
        setSeverity('error');
      }
    }
  }

  return (
    <Container>
      <Typography variant="h4" align="center">
        Cadastro de Contrato de Empresas
      </Typography>
      <form onSubmit={handleSubmit(cadastroEmpresas)}>
        <PaperContainer>
          <Typography variant="h5">Dados do Cadastro</Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <DivGridFieldDataContract>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Nome do contrato"
                  {...register('nome_contrato')}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="cod_contrato"
                  {...register('cod_contrato')}
                  error={!!errors.cod_contrato?.message}
                  helperText={errors.cod_contrato?.message}
                />

                <TextField
                  id="date"
                  label="Data de Criação"
                  type="date"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register('data_criacao')}
                />
                <TextField
                  id="date"
                  label="Data de Renovação"
                  type="date"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register('data_renovacao')}
                />
              </DivGridFieldDataContract>

              <Upload />
              <FileList />
            </Grid>
          </Grid>

          <Typography variant="h5">Benefícios</Typography>

          <DivBenefits>
            <TextField
              fullWidth
              variant="outlined"
              label="Nº Convênio Medico"
              {...register('num_conv_med')}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Nº Convênio Odontológico"
              {...register('num_conv_odont')}
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Nº Convênio Acedemia"
              {...register('num_conv_acade')}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Nº Seguro de Vida"
              {...register('num_seg_vida')}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Nº Apólice PAT"
              {...register('num_apol_pat')}
            />
          </DivBenefits>

          <Typography variant="h5">Serviços</Typography>
          <DivServices>
            <Button
              title="Adicionar Exames"
              onClick={() => {
                setDialog(!dialog);
              }}
              icon={<Plus size={20} />}
              type="button"
            />

            <Exames
              control={control}
              openDialog={dialog}
              closeDialog={() => {
                setDialog(!dialog);
              }}
            />
            <Button
              title="Exames Toxicólogico"
              onClick={() => {
                setDialogToxico(!dialogToxico);
              }}
              icon={<Plus size={20} />}
              type="button"
            />

            <Button
              icon={<Plus size={20} />}
              title="Adicionar Serviços"
              onClick={() => {
                setDialogServices(!dialogSevices);
              }}
              type="button"
            />

            <Servicos
              control={control}
              openModal={dialogSevices}
              closeModal={() => {
                setDialogServices(false);
              }}
            />

            <Toxicologico
              control={control}
              openModal={dialogToxico}
              closeModal={() => {
                setDialogToxico(false);
              }}
            />
          </DivServices>
          <div>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={isSuccess ? data : []}
              getOptionLabel={(option: IRiscos) => {
                return `${option.nome}`
                  ? `${option.id_risco} - ${option.nome}`
                  : '';
              }}
              onChange={(e: any, newValue, reason, detail) => {
                handleAddRiscos(newValue);

                if (reason === 'remove-option' || reason === 'clear') {
                  if (detail) {
                    const index = risco.findIndex((i) => i === detail?.option);
                    remove(index);
                  }
                } else if (reason === 'select-option') {
                  if (detail?.option) {
                    append({ ...detail?.option });
                  }
                }
                // console.log(reason, detail)
              }}
              value={risco}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Riscos"
                  placeholder="Riscos"
                />
              )}
            />
          </div>

          <Typography variant="h5">Plano</Typography>

          <DivBenefits>
            <TextField
              variant="outlined"
              label="Valor Bonze"
              {...register('valor_bronze')}
            />
            <TextField
              variant="outlined"
              label="Valor Prata"
              {...register('valor_prata')}
            />
            <TextField
              variant="outlined"
              label="Valor Ouro"
              {...register('valor_ouro')}
            />
            <TextField
              variant="outlined"
              label="Valor Platina"
              {...register('valor_platina')}
            />
            <TextField
              variant="outlined"
              label="Valor Personalizado"
              {...register('valor_personalizado')}
            />
          </DivBenefits>
        </PaperContainer>

        <DivButtons>
          <NavLink to="/contratos-empresa">
            <Button title="Sair" variantstyle="RED" />
          </NavLink>
          <Button
            type="submit"
            title="Salvar"
            isLoading={isSubmitting}
            disabled={!isValid}
          />
        </DivButtons>
      </form>

      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
}
