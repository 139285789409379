import { api } from '@services/api';

export type GetContratos = Array<{
  nome_contrato: string;
  cod_contrato: string;
  data_criacao: string;
  data_renovacao: string;
}>;

export async function getContratos() {
  const response = await api.get<GetContratos>('ravim/contratos-empresas');

  return response.data;
}
