import React from 'react';
import * as M from '@material-ui/core';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';

interface MessageProps {
  code: string;
  message: string;
  severity: Color;
  show: boolean;
  onClose: () => void;
}

export function ToastMessage({
  code,
  message,
  severity,
  show,
  onClose
}: MessageProps) {
  return (
    <M.Snackbar open={show} autoHideDuration={2000} onClose={onClose}>
      <MuiAlert
        severity={severity}
        variant="filled"
      >{`${code} - ${message}`}</MuiAlert>
    </M.Snackbar>
  );
}
