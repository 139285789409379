import { AUTH_TOKEN_STORAGE } from './storageConfig';

interface StorageAuthTokenProps {
  token: string;
  refresh_token: string;
}

export async function storageAuthTokenSave({
  token,
  refresh_token
}: StorageAuthTokenProps) {
  await localStorage.setItem(
    AUTH_TOKEN_STORAGE,
    JSON.stringify({ token, refresh_token })
  );
}

export async function storageAuthTokenGet() {
  const res = await localStorage.getItem(AUTH_TOKEN_STORAGE);

  const { token, refresh_token }: StorageAuthTokenProps = res
    ? JSON.parse(res)
    : {};

  return { token, refresh_token };
}

export async function storageAuthTokenRemove() {
  await localStorage.removeItem(AUTH_TOKEN_STORAGE);
}
