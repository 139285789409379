import React, { useState } from 'react';
import { Button } from '@components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { api } from '@services/api';
import { ToastMessage } from '@components/ToastMessage';
import { type Color } from '@material-ui/lab';
import { type Contratos } from '@dtos/contratosDTO';

interface Props {
  openModal: boolean;
  closeModal: (action: boolean) => void;
  name: string;
  code: string;
  contratos: Contratos[];
  updatadeList: (newListContracts: Contratos[]) => void;
}

export function DialogActionDelete({
  openModal,
  closeModal,
  contratos,
  name,
  code,
  updatadeList
}: Props) {
  const [toastMessage, setToastMessage] = useState({
    cod: '',
    message: '',
    color: '' as Color,
    show: false
  });

  function handleCloseModal() {
    closeModal(false);
  }

  async function deleteContrato(cod_cont: any) {
    await api
      .delete(`ravim/exclucontr/${cod_cont}`, {
        data: { cod_contrato: cod_cont }
      })
      .then((res) => {
        if (res.status === 200) {
          const contract = contratos.filter(
            (item: Contratos) => item.cod_contrato !== cod_cont
          );
          setToastMessage({
            cod: String(res.status),
            message: res.data.message,
            color: 'success',
            show: true
          });
          closeModal(false);
          updatadeList(contract); 
        } else {
          setToastMessage({
            cod: String(res.status),
            message: res.data.message,
            color: 'error',
            show: true
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>
          Contrato a ser deletado, confira o nome e código
        </DialogTitle>
        <DialogContent>
          <Typography>
            Nome: <b>{name}</b>
          </Typography>
          <Typography>
            Código: <b>{code}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            title="Cancelar"
            variantstyle="DEFAULT"
            onClick={handleCloseModal}
          />

          <Button
            variantstyle="RED"
            title="Deletar"
            autoFocus
            onClick={async () => { await deleteContrato(code); }}
          />
        </DialogActions>
      </Dialog>
      <ToastMessage
        code={toastMessage.cod}
        message={toastMessage.message}
        show={toastMessage.show}
        severity={toastMessage.color}
        onClose={() =>
          { setToastMessage({
            cod: toastMessage.cod,
            color: toastMessage.color,
            message: toastMessage.message,
            show: false
          }); }
        }
      />
    </>
  );
}
