import { configureStore } from '@reduxjs/toolkit';
import cadEmpRh from './cadEmpresaRH';

import stock from './counter';
import editContract from './editContract';

export const store = configureStore({
  reducer: {
    primeiroTool: stock,
    editContractEmp: editContract,
    cadastroEmpRH: cadEmpRh
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
