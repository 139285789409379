import { Button } from '@components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Plus } from '@phosphor-icons/react';
import { api } from '@services/api';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { type Control, FieldValues, useFieldArray } from 'react-hook-form';
import { DivResult, PaperContainer } from './styles';
import { maskCoin } from '@utils/masks';

export interface IExames {
  id_exames?: number | string;
  id_exame_contr_empresa?: number;
  nome: string;
  valor: number;
}

interface Props {
  control: Control<any>;
  openDialog: boolean;
  closeDialog: (action: boolean) => void;
  getExames?: IExames[];
  getNovoValor?: IExames[];
  getValorTotal?: number;
}

export function Exames({
  control,
  openDialog,
  closeDialog,
  getExames,
  getNovoValor,
  getValorTotal
}: Props) {
  const [singleExame, setSingleExame] = useState<IExames | null>(null);
  const [valorTotal, setValorTotal] = useState(0);
  const [campoValor, setCampoValor] = useState<IExames[]>([]);
  const [valor, setValor] = useState<number | null>(null);
  const [novoValor, setNovoValor] = useState<IExames[]>([]);

  const { append: addNewExames, remove: removeExame } = useFieldArray({
    control,
    name: 'exame'
  });

  function saveNewValor() {
    setNovoValor([...campoValor]);
    closeDialog(false);
  }

  function deleteExame(i: number) {
    const newList = campoValor.filter((exame: any, index) => index !== i);
    const verifyValues = newList.map((v: any) => {
      return parseFloat(v.valor);
    });
    const sub = verifyValues.reduce(
      (soma: number, i: number) => soma - i * -1,
      0
    );

    removeExame(i);
    setValorTotal(sub);
    setCampoValor(newList);
  }

  function valueExames() {
    if (singleExame === null || valor === null) {
      alert('Preencha os dois campos');
      
    } else {
      const newVar = [...campoValor];
      const data: IExames = {
        id_exames: singleExame?.id_exames,
        id_exame_contr_empresa: singleExame.id_exame_contr_empresa,
        nome: singleExame?.nome,
        valor: Number(valor)
      };
      newVar.push(data);
      setCampoValor(newVar);
      addNewExames({ ...data });

      const verifyValues = newVar.map((v: any) => {
        return parseFloat(v.valor);
      });
      const soma = verifyValues.reduce((soma, i) => {
        return soma + i;
      });
      setValorTotal(soma);

      setSingleExame(null);
      setValor(null);
    }
  }

  const { data, isSuccess, isLoading, isFetched } = useQuery({
    queryKey: ['exames'],
    queryFn: async () => {
      const res = await api.get(`/ravim/exames`);
      return res.data;
    },
    refetchInterval: 3000
  });

  useEffect(() => {
    if (getExames && getNovoValor && getValorTotal) {
      setCampoValor(getExames);
      setNovoValor(getNovoValor);
      setValorTotal(getValorTotal);
    }
  }, [getExames, getValorTotal, getNovoValor]);

  return (
    <>
      <Dialog open={openDialog} onClose={closeDialog}>
        <DialogTitle>Adicionar Exames</DialogTitle>
        <DialogContent>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 20
              }}
            >
              <Autocomplete
                style={{ width: 400 }}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    valueExames();
                  }
                }}
                loading={isLoading}
                id="tags-outlined"
                options={isSuccess ? data : []}
                getOptionLabel={(option: IExames) =>
                  `${option.id_exames} - ${option.nome}`
                    ? `${option.id_exames}-${option.nome}`
                    : ''
                }
                onChange={(e, newValue: any) => {
                  setSingleExame(newValue);
                }}
                value={singleExame}
                getOptionDisabled={(option) => {
                  const valueDisbled = campoValor.map(
                    (exame: any, i: any) => exame
                  );
                  return !!valueDisbled.find(
                    (element) => element.nome === option.nome
                  );
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Exames"
                    placeholder="Exames"
                  />
                )}
              />
              <TextField
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    valueExames();
                  }
                }}
                type="number"
                label="Valor do Exame"
                variant="outlined"
                value={valor || ''}
                onChange={(e: any) => { setValor(e.target.value); }}
              />
              <Button
                onClick={valueExames}
                icon={<Plus size={20} />}
                variantstyle="SECUNDARY"
              />
            </div>
            {campoValor &&
              campoValor.map((valores, i) => (
                <PaperContainer key={i}>
                  {valores.id_exames && <p>{valores.id_exames}</p>}
                  <p>{valores.nome}</p>
                  <p>{maskCoin(Number(valores.valor))}</p>
                  <button onClick={() => { deleteExame(i); }}>
                    <Delete />
                  </button>
                </PaperContainer>
              ))}
          </div>
          {valorTotal === 0 ? (
            ''
          ) : (
            <DivResult>
              <p>
                Total de exames lançados {campoValor.length} - Valor Total{' '}
                <span>{maskCoin(valorTotal)}</span>
              </p>
            </DivResult>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => { closeDialog(false); }}
            title="Cancelar"
            variantstyle="RED"
          />

          <Button
            disabled={campoValor.length < 1}
            onClick={saveNewValor}
            title="Salvar"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
