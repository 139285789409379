import React, { useEffect } from 'react';
import * as Filesaver from 'file-saver';
import XLSX from 'sheetjs-style';

import { Button, type ButtonProps } from '@material-ui/core';

export type PropsExcell = ButtonProps & {
  excelData: any;
  fileName: any;
  reqExcell: () => Promise<void>;
  cnpjSelected: string;
  dataInicio?: string;
  dataFim?: string;
};

export const Excel = ({
  excelData,
  fileName,
  reqExcell,
  cnpjSelected,
  dataInicio,
  dataFim
}: PropsExcell) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  function selectedMonth(month: number) {
    switch (Number(month)) {
      case 1:
        return (fileName = 'Janeiro');
      case 2:
        return (fileName = 'Fevereiro');
      case 3:
        return (fileName = 'Marco');
      case 4:
        return (fileName = 'Abril');
      case 5:
        return (fileName = 'Maio');
      case 6:
        return (fileName = 'Junho');
      case 7:
        return (fileName = 'Julho');
      case 8:
        return (fileName = 'Agosto');
      case 9:
        return (fileName = 'Setembro');
      case 10:
        return (fileName = 'Outubro');
      case 11:
        return (fileName = 'Novembro');
      case 12:
        return (fileName = 'Dezembro');
      default:
        console.log('Nenhum mês selecionado!');
    }
    return fileName;
  }

  const exportToExcel = async () => {
    const workbook = XLSX.utils.book_new(); // Cria um novo livro

    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    Filesaver.saveAs(data, selectedMonth(fileName) + fileExtension);
  };
  useEffect(() => {
    reqExcell();
  }, [fileName, cnpjSelected, dataInicio, dataFim]);

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        onClick={exportToExcel}
      >
        Exportar para Excel
      </Button>
    </>
  );
};
