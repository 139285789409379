import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface ITeste {
  teste: string;
}

const initialState: ITeste = {
  teste: ''
};

export const stock = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    iniciaState: (state, actions: PayloadAction<string>) => {
      state.teste = actions.payload;
    }
  }
});

export const { iniciaState } = stock.actions;
export default stock.reducer;
