import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
  CircularProgress,
  TextField
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { api } from '../../../services/api';
import { type AxiosResponse } from 'axios';
import { maskCPF, maskDate } from '../../../utils/masks';
import usePagination from '@hooks/usePagination';
import { Button } from '@components/Button';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { Pagination } from '@material-ui/lab';

interface IAgenda {
  id_agendamento: number;
  num_agenda: string;
  nome: string;
  cpf_colaborador: string;
  telemedicina: string;
  nome_comercial: string;
  cidade: string;
  estado: string;
  data_agendamento: string;
  ordem_chegada: string;
  horario: string;
  tipo_aso: string;
  observacao: string;
  status: string;
  usuario: string;
  cpf_cnpj_comer: string;
  cnpj: string;
  tipo_agendamento: string;
}

export interface Root {
  empresa?: IExameEmpresa[];
  nome?: string;
  credenciada?: IExameCredenciada[];
  nome_credenciada?: string;
}

export interface IExameEmpresa {
  exame_empresa: string;
  valor_empresa: string;
}

export interface IExameCredenciada {
  exame_credenciado: string;
  valor_credenciado: string;
}

export interface TObjectCorrect {
  valor_empresa: string;
  valor_credenciado: string;
}

function createData(
  cnpj: string,
  nome_comercial: string,
  cpf: string,
  nome: string,
  data: string,
  tipo_aso: string,
  status: string,
  num_agenda: string,
  cnpj_comer: string,
  tipo_agendamento: string
) {
  return {
    cnpj,
    nome_comercial,
    cpf,
    nome,
    data,
    tipo_aso,
    status,
    num_agenda,
    cnpj_comer,
    tipo_agendamento
  };
}

interface DataProps {
  empresa?: Array<{
    exame_empresa: string;
    valor_empresa: string;
  }>;
  nome?: string;
  credenciada?: Array<{
    exame_credenciado: string;
    valor_credenciado: string;
  }>;
  nome_credenciada?: string;
}

interface DataTableProps {
  data: DataProps[];
}
function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [cliente, setCliente] = useState<DataProps[]>([]);
  const [examesEmpresa, setExamesEmpresa] = useState<IExameEmpresa[]>([]);
  const [examesCredenciado, setExamesCredenciado] = useState<
    IExameCredenciada[]
  >([]);
  const [nomeEmpresa, setNomeEmpresa] = useState<string>('');
  const [valoresCredenciado, setValoresCredenciado] = useState<any[]>([]);
  const validation = calcImposto()
    .map((imp) => imp)
    .reduce((a, b) => a + b, 0);
  const [loading, setLoading] = useState<boolean>(true);

  async function reqComparativoEmpresa(
    cpf_colaborador: string,
    cpf_cnpj_comer: string,
    num_agenda: string,
    tipo_agendamento: string
  ) {
    const data = {
      cpf_colaborador,
      cpf_cnpj_comer,
      num_agenda,
      tipo_agendamento
    };

    await api
      .post(`/ravim/comparativo`, data)
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          setNomeEmpresa(res.data[0].nome);
          setCliente(res.data);
          setNomeEmpresa(res.data[0].nome);

          calcImposto();
          setExamesEmpresa(res.data[0].empresa);
          setExamesCredenciado(res.data[1].credenciada);
        }
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleOpenTable = (
    cpf: string,
    cnpj: string,
    num_agenda: string,
    tipo_agendamento: string
  ) => {
    setOpen(!open);
    if (!open) {
      reqComparativoEmpresa(cpf, cnpj, num_agenda, tipo_agendamento);
    }
  };

  function somaEmp(soma: any[]) {
    const data =
      soma?.length > 0
        ? soma
            ?.map((e) => parseFloat(e?.valor_empresa))
            .reduce((a, b) => a + b, 0)
        : '';
    if (!isNaN(Number(data))) {
      return data.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
      });
    } else {
      return Number(0).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
      });
    }
  }

  function somaCred(soma: any[]) {
    const data =
      soma?.length > 0
        ? soma
            ?.map((e) =>
              parseFloat(
                !isNaN(e?.valor_credenciado) ? e?.valor_credenciado : 0
              )
            )
            .reduce((a, b) => a + b, 0)
        : '';
    if (!isNaN(Number(data))) {
      return data.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
      });
    } else {
      return Number(0).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
      });
    }
  }

  function calcImposto() {
    const cred: number[] = examesCredenciado.map((a) => {
      return Number(
        !isNaN(Number(a.valor_credenciado)) ? a.valor_credenciado : 0
      );
    });
    const emp: number[] = examesEmpresa.map((b) => {
      return Number(!isNaN(Number(b.valor_empresa)) ? b.valor_empresa : 0);
    });

    const soma: number[] = [];
    for (let i = 0; i < cred.length; i++) {
      soma[i] = Number(emp[i]) * 0.8 - Number(cred[i]);
    }

    return soma;
  }

  const result = calcImposto().map((s, i) => {
    return isNaN(Number(s)) ? 0 : Number(s);
  });

  const DataTable: React.FC<DataTableProps> = ({ data }) => {
    return (
      <table>
        <thead>
          <TableRow>
            <TableCell>Nome Empresa</TableCell>
            <TableCell>CNPJ</TableCell>
            <TableCell>Exames</TableCell>
            <TableCell>Subtotal da Empresa</TableCell>
            <TableCell>Subtotal do Credenciado</TableCell>
            <TableCell>Resultado</TableCell>
          </TableRow>
        </thead>
        <tbody>
          {data.map((row2, rowIndex) => {
            if (row2.credenciada) {
              setValoresCredenciado(row2.credenciada);
            } else if (row2.empresa) {
              return (
                row2.empresa &&
                row2.empresa.map((element, elementIndex) => {
                  return (
                    <tr key={`row2-${rowIndex}-${elementIndex}`}>
                      {elementIndex === 0 && (
                        <td rowSpan={row2.empresa?.length}>{row2.nome}</td>
                      )}
                      {elementIndex === 0 && (
                        <td rowSpan={row2.empresa?.length}>{row.cnpj}</td>
                      )}
                      <TableCell>{element.exame_empresa}</TableCell>
                      <TableCell>
                        {Number(
                          isNaN(Number(element.valor_empresa))
                            ? 0
                            : element.valor_empresa
                        ).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                      </TableCell>
                      <TableCell>
                        {!isNaN(
                          valoresCredenciado[elementIndex]?.valor_credenciado
                        )
                          ? Number(
                              valoresCredenciado[elementIndex]
                                ?.valor_credenciado
                            ).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL'
                            })
                          : '-'}
                      </TableCell>
                      <TableCell
                        style={{
                          color: result[elementIndex] <= 0 ? 'red' : 'green',
                          fontWeight: 'bold'
                        }}
                      >
                        {result[elementIndex]?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                      </TableCell>
                    </tr>
                  );
                })
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
    );
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              { handleOpenTable(
                row.cpf,
                row.cnpj_comer,
                row.num_agenda,
                row.tipo_agendamento
              ); }
            }
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.cnpj_comer}
        </TableCell>
        <TableCell align="right">{row.nome_comercial}</TableCell>
        <TableCell align="right">{row.cpf}</TableCell>
        <TableCell align="right">{row.nome}</TableCell>
        <TableCell align="right">{row.tipo_aso}</TableCell>
        <TableCell align="right">{row.tipo_agendamento}</TableCell>
        <TableCell align="right">{row.status}</TableCell>
        <TableCell align="right">{row.data}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, background: '#e3e3e3' }}
          colSpan={9}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Exames
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow></TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <>
                        <DataTable data={cliente} />
                        <div
                          style={{
                            flexDirection: 'column',
                            display: 'flex',
                            border: '1px solid #CCC',
                            padding: 10
                          }}
                        >
                          <div>
                            Soma total da empresa:
                            {cliente?.map((exameEmp, i) => {
                              return exameEmp?.empresa !== undefined ? (
                                <span
                                  key={i}
                                  style={{
                                    fontWeight: 'bold',
                                    color:
                                      Number(somaEmp(exameEmp.empresa)) < 0
                                        ? 'red'
                                        : 'green'
                                  }}
                                >
                                  {' '}
                                  {somaEmp(exameEmp.empresa)}
                                </span>
                              ) : (
                                ''
                              );
                            })}
                          </div>
                          <div>
                            Soma total do credenciado:
                            {cliente?.map((exameCred, i) => {
                              return exameCred?.credenciada !== undefined ? (
                                <span
                                  key={i}
                                  style={{
                                    fontWeight: 'bold',
                                    color:
                                      Number(somaCred(exameCred.credenciada)) <
                                      0
                                        ? 'red'
                                        : 'green'
                                  }}
                                >
                                  {' '}
                                  {somaCred(exameCred.credenciada)}
                                </span>
                              ) : (
                                ''
                              );
                            })}
                          </div>
                          <div>
                            Soma total do resultado:
                            <span
                              style={{
                                color: validation <= 0 ? 'red' : 'green',
                                fontWeight: 'bold'
                              }}
                            >
                              {calcImposto()
                                .map((imp) => imp)
                                .reduce(
                                  (a, b) =>
                                    (isNaN(Number(a)) ? 0 : a) +
                                    (isNaN(Number(b)) ? 0 : b),
                                  0
                                )
                                .toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL'
                                })}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const Comparativo = () => {
  const [colab, setColab] = useState<IAgenda[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState('');

  const [value, setValue] = useState('');

  const [page, setPage] = useState(1);
  const PER_PAGE = 30;

  const count = Math.ceil(colab.length / PER_PAGE);
  const _DATA = usePagination(colab, PER_PAGE);

  const filterContractsPerPage =
    _DATA.currentData().length > 0
      ? _DATA
          .currentData()
          ?.filter(
            (contrato: IAgenda) =>
              contrato?.cpf_cnpj_comer
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              contrato?.nome_comercial
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              contrato.nome.toUpperCase().includes(search.toUpperCase()) ||
              contrato.cpf_colaborador
                .toUpperCase()
                .includes(search.toUpperCase())
          )
      : [];

  const filterContracts =
    colab.length > 0
      ? colab?.filter(
          (contrato: IAgenda) =>
            contrato?.cpf_cnpj_comer
              .toUpperCase()
              .includes(search.toUpperCase()) ||
            contrato?.nome_comercial
              .toUpperCase()
              .includes(search.toUpperCase()) ||
            contrato.nome.toUpperCase().includes(search.toUpperCase()) ||
            contrato.cpf_colaborador
              .toUpperCase()
              .includes(search.toUpperCase())
        )
      : [];

  const filter = search.length > 0 ? filterContracts : filterContractsPerPage;

  const rows = filter.map((colab) => {
    return createData(
      colab.cnpj,
      colab.nome_comercial,
      maskCPF(colab.cpf_colaborador),
      colab.nome,
      maskDate(colab.data_agendamento),
      colab.tipo_aso,
      colab.status,
      colab.num_agenda,
      colab.cpf_cnpj_comer,
      colab.tipo_agendamento
    );
  });

  async function reqAgenda() {
    await api
      .get(`/ravim/agenda-financeiro`)
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          setLoading(false);
          setColab(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    reqAgenda();
  }, []);

  useEffect(() => {
    if (!value) {
      setSearch('');
    }
  }, [value]);

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 20,
          marginBottom: 20,
          alignItems: 'center'
        }}
      >
        <TextField
          value={value}
          onChange={(e) => { setValue(e.target.value); }}
          label="Pesquisar credenciado"
          type="text"
          variant="outlined"
          style={{ background: '#FFF' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch(value);
            }
          }}
        />
        <Button
          variantstyle="SECUNDARY"
          icon={<MagnifyingGlass size={25} />}
          onClick={() => { handleSearch(value); }}
        />
      </div>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Comparativo
      </Typography>
      <Typography variant="h6" align="left">
        Total: {colab.length}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 20
        }}
      >
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>CNPJ</TableCell>
                <TableCell align="right">
                  Nome Comercial do Credenciado
                </TableCell>
                <TableCell align="right">CPF</TableCell>
                <TableCell align="right">Nome Completo</TableCell>
                <TableCell align="right">Tipo ASO</TableCell>
                <TableCell align="right">Tipo de Agendamento</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Data do Agendamento</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return <Row key={index} row={row} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {loading && <CircularProgress />}
      </div>
    </Container>
  );
};
