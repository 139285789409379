import {
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import {
  HighlightOff,
  RemoveRedEye,
  SettingsBackupRestore
} from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import { api } from '../../services/api';
import { maskCNPJ, maskCPF } from '../../utils/masks';
import { Button } from '@components/Button';
import usePagination from '@hooks/usePagination';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { Pagination } from '@material-ui/lab';
import { ContainerButtonsTop, DivSearch } from './styles';
import { NavLink } from '@components/NavLink';

export interface ICredenciado {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  email_agendamento: string;
  email_financeiro: string;
  email_comercial: string;
}

export const Credenciados = () => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState<ICredenciado>();
  const [dialog, setDialog] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color | undefined>();
  const [credenciados, setCredenciados] = useState<ICredenciado[]>([]);
  const [search, setSearch] = useState<string>('');

  const [value, setValue] = useState('');

  const [page, setPage] = useState(1);
  const PER_PAGE = 30;

  const count = Math.ceil(credenciados.length / PER_PAGE);
  const _DATA = usePagination(credenciados, PER_PAGE);

  const filterColabPerPage =
    _DATA.currentData().length > 0
      ? _DATA
          .currentData()
          .filter(
            (colab: ICredenciado) =>
              colab.cpf_cnpj.toUpperCase().includes(search.toUpperCase()) ||
              colab.nome_comercial.toUpperCase().includes(search.toUpperCase())
          )
      : [];
  const filterColab =
    credenciados.length > 0
      ? credenciados.filter(
          (colab: ICredenciado) =>
            colab.cpf_cnpj.toUpperCase().includes(search.toUpperCase()) ||
            colab.nome_comercial.toUpperCase().includes(search.toUpperCase())
        )
      : [];

  const filter = search.length > 0 ? filterColab : filterColabPerPage;

  function closeDialog() {
    setDialog(false);
    setDialogDelete(false);
  }

  async function dataCredenciado() {
    try {
      setLoading(true);
      const res = await api.get(`ravim/clinicas`);
      if (res.status === 404) return;
      setCredenciados(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  function deleteContract(value: ICredenciado) {
    setDialogDelete(true);
    setEdit(value);
  }

  async function deleteCredenciado(cpf_cnpj: string) {
    await api
      .delete(`ravim/exclu-clinica`, { data: { num_cnpj_cpf: cpf_cnpj } })
      .then((res) => {
        if (res.status === 200) {
          const newListColaborador = credenciados.filter(
            (colab: ICredenciado) => colab.cpf_cnpj !== cpf_cnpj
          );
          setSnack(true);
          setDialogDelete(false);
          setMessage(res.data.message);
          setSeverity('success');
          setCredenciados(newListColaborador);
        } else {
          setSeverity('error');
          setSnack(true);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function selectCpfOrCnpj(cpfOrCnpj: string) {
    if (cpfOrCnpj.length <= 11) {
      return maskCPF(cpfOrCnpj);
    } else {
      return maskCNPJ(cpfOrCnpj);
    }
  }

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    dataCredenciado();
  }, []);

  useEffect(() => {
    if (!value) {
      setSearch('');
    }
  }, [value]);

  return (
    <Container>
      <ContainerButtonsTop>
        <DivSearch>
          <TextField
            label="Pesquisar Credenciado"
            type="text"
            variant="outlined"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(value);
              }
            }}
          />
          <Button
            variantstyle="SECUNDARY"
            icon={<MagnifyingGlass size={25} />}
            onClick={() => {
              handleSearch(value);
            }}
          />
        </DivSearch>
        <NavLink to="/cadastro-credenciados">
          <Button title="Cadastro Credenciado" />
        </NavLink>
      </ContainerButtonsTop>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Credenciados
      </Typography>
      <Typography variant="h6" align="left">
        Total de credenciados: {credenciados.length}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 20
        }}
      >
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
        {loading ? (
          <TableContainer component={Paper}>
            <Table style={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>CPF/CNPJ</TableCell>
                  <TableCell>Nome Comercial</TableCell>
                  <TableCell>Horário de Atendimento</TableCell>
                  <TableCell>Cidade/Estado</TableCell>
                  <TableCell>ATUALIZAR</TableCell>
                  <TableCell>DELETAR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter &&
                  filter.map((credenciado: ICredenciado, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        {selectCpfOrCnpj(credenciado.cpf_cnpj)}
                      </TableCell>
                      <TableCell>{credenciado.nome_comercial}</TableCell>
                      <TableCell>{credenciado.dias_funcionamento}</TableCell>
                      <TableCell>
                        {credenciado.cidade}/{credenciado.estado}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{
                            pathname: '/editar-credenciado',
                            state: credenciado
                          }}
                        >
                          <IconButton /* onClick={() => dispatch(editContrato([contrato]))} */
                          >
                            <SettingsBackupRestore
                              fontSize="medium"
                              style={{ color: '#422C59' }}
                            />
                          </IconButton>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            deleteContract(credenciado);
                          }}
                        >
                          <HighlightOff
                            fontSize="medium"
                            style={{ color: 'red' }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>

      <Dialog open={dialogDelete} onClose={closeDialog}>
        <DialogTitle>
          Colaborador a ser deletado, confira o nome e a matrícula
        </DialogTitle>
        <DialogContent>
          <Typography>
            Nome Comercial: <b>{edit?.nome_comercial}</b>
          </Typography>
          <Typography>
            CPF/CNPJ: <b>{edit?.cpf_cnpj}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancelar
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={async () => {
              await deleteCredenciado(edit?.cpf_cnpj!);
            }}
          >
            Deletar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert severity={severity} variant="filled">
          {cod} {message}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};
