import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useFiles , type IFile } from '@base/context/files';
import { ContainerFileList, FileInfo, Preview } from './styles';
import {
  CheckCircle,
  DownloadSimple,
  Eye,
  FilePdf,
  FileX,
  Trash
} from '@phosphor-icons/react';
import { CircularProgress } from '@material-ui/core';

export function FileList() {
  const { uploadedFiles: files, deleteFile } = useFiles();
  const [time, setTime] = useState(true);

  useEffect(() => {
    const time = setTimeout(() => {
      setTime(false);
    }, 3000);

    return () => {
      clearTimeout(time);
    };
  }, []);

  return (
    <ContainerFileList>
      {time && files.length === 0 && (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {files.map((uploadedFile: IFile) => (
        <li key={uploadedFile?.id}>
          <FileInfo>
            <div
              style={{
                border: '1px solid #CCC',
                marginRight: 20,
                borderRadius: 5,
                padding: 20
              }}
            >
              <FilePdf size={35} />
            </div>
            {/* <Preview src={uploadedFile.preview} /> */}
            <div>
              <strong>
                {uploadedFile.name
                  .split('-')[4]
                  ?.replace('_', ' ')
                  ?.split(' ')[1]
                  ?.replaceAll('_', ' ') || uploadedFile.name}
              </strong>
              <span>
                {uploadedFile.readableSize}{' '}
                {!!uploadedFile.url && (
                  <button
                    type="button"
                    onClick={() =>
                      { deleteFile(
                        uploadedFile?.id.indexOf('_') !== -1
                          ? uploadedFile?.id
                          : uploadedFile.name
                      ); }
                    }
                  >
                    Excluir
                  </button>
                )}
              </span>
            </div>
          </FileInfo>
          <div style={{ gap: 15, display: 'flex' }}>
            {!uploadedFile.uploaded && !uploadedFile.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 35 },
                  path: { stroke: '#422c59' }
                }}
                strokeWidth={10}
                text={String(uploadedFile.progress)}
                value={uploadedFile.progress || 0}
              />
            )}
            {uploadedFile.url && (
              <a
                href={uploadedFile.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadSimple size={35} color="#422c59" />
              </a>
            )}
            {uploadedFile.uploaded && <CheckCircle size={35} color="#78e5d5" />}
            {uploadedFile.error && <FileX size={35} color="#e57878" />}
          </div>
        </li>
      ))}
    </ContainerFileList>
  );
}
