import React, { InputHTMLAttributes } from 'react';
import * as S from './styles';
import { type InputProps } from '@material-ui/core';

interface LabelProps {
  text?: string; // Texto que será exibido no label
  htmlFor?: string; // O ID do elemento de entrada que o label descreve
  className?: string; // Classe CSS opcional para estilização adicional
}

type Props = LabelProps & InputHTMLAttributes<HTMLInputElement> &{
  title?: string;
  isLoading?: boolean;
  variantstyle?: S.InputTypeStyleProps;
  icon?: JSX.Element;
};

export function LabelUpload({
  title,
  isLoading = false,
  variantstyle = 'PRIMARY',
  icon,
  htmlFor,
  ...rest
}: Props) {
  return (
    <>
      <S.InputStyled htmlFor={htmlFor} variantstyle={variantstyle} >
        {isLoading ? (
          <S.Loading variantstyle={variantstyle} />
        ) : (
          <S.DivTitleIcon>
            {icon}
            {title}
          </S.DivTitleIcon>
        )}
      </S.InputStyled>
      <input type='file' id={htmlFor} style={{ opacity: 0, display:'none' }} {...rest}/>
    </>
  );
}
