import React, { useState, useEffect } from 'react';
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import MuiAlert, { type Color } from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import { type IStatus } from '.';
import { api } from '@services/api';
import { Autocomplete } from '@material-ui/lab';
import { Add, Delete, ShowChart } from '@material-ui/icons';
import { ASO } from '@data/tipoAso';
import { type IColaborador } from '@dtos/colaboradorDTO';
import { ColaboradorProps, SearchCPF } from '@components/SearchCPF';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@context/auth';
import { NavLink } from '@components/NavLink';
import { Button } from '@components/Button';
import { Exames } from '@components/Exames';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AppError } from '@utils/AppError';
import { InputSearchColab } from '@components/InputSearchColab';

interface IExames {
  id_exames: string;
  nome: string;
  valor: string;
}

interface ICep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface INomeComercial {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  razao_social: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  telemedicina: string;
  observacao: string;
}

const scheduleSchema = z.object({
  tipo_aso: z.string(),
  data_agendamento: z.string(),
  tipo_agendamento: z.string(),
  nome: z.string(),
  cpf_colaborador: z
    .string()
    .length(14, { message: 'Esse campo é obrigátorio' }),
  num_agenda: z.string().optional(),
  usuario: z.string(),

  exame: z.array(
    z.object({
      id_exames: z.string().or(z.number()).optional(),
      nome: z.string(),
      valor: z.number().or(z.string())
    })
  )
});

type ScheduleProps = z.infer<typeof scheduleSchema>;

export const AgendamentoExterno: React.FC<IStatus> = (props) => {
  const { user } = useAuth();
  const [messageError, setMessageError] = useState('');
  const [loadingColab, setLoadingColab] = useState(false);

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState<number | null>(null);
  const [severity, setSeverity] = useState<Color>();

  const [dialog, setDialog] = useState<boolean>(false);

  // console.log(selectedNomeComercial)

  const hora = new Date();
  const newHora = hora.toLocaleString('pt-BR', { hour12: false });
  const userUpdate = `${user.nome} ${newHora}`;

  const {
    control,
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm<ScheduleProps>({
    resolver: zodResolver(scheduleSchema),
    defaultValues: {
      tipo_agendamento: props.location.state.tipo_agendamento,
      usuario: userUpdate,
      num_agenda: ''
    }
  });

  async function submitFormExtern(data: ScheduleProps) {
    try {
      const res = await api.post(`/ravim/agendamento-externo`, data);

      setCod(res.status);
      setSnack(true);
      setSeverity('success');
      setMessage(res.data.message);
      setTimeout(() => {
        window.location.replace('/agendar');
      }, 1000);
    } catch (error) {
      console.log(error);
      if (error instanceof AppError) {
        setCod(error.code);
        setSnack(true);
        setSeverity('error');
        setMessage(error.message);
      }
    }
  }

  async function handleSearchCpf(cpf: string) {
    setValue('cpf_colaborador', cpf);

    try {
      setLoadingColab(true);
      if (cpf.length === 14) {
        const response = await api.post(`ravim/pesq-cpf`, {
          cpf_colaborador: cpf
        });
        setValue('nome', response.data.nome_completo);
        setMessageError('');
      }
    } catch (error) {
      if (error instanceof AppError) {
        if (error.code === 400) {
          setValue('nome', '');
          setMessageError(error.message);
        }
      }
    } finally {
      setLoadingColab(false);
    }
  }

  useEffect(() => {
    if (getValues('cpf_colaborador')?.length <= 0) {
      setMessageError('');
    }
  }, [getValues('cpf_colaborador')]);

  return (
    <Container>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Agendamento Externo
      </Typography>
      <form onSubmit={handleSubmit(submitFormExtern)}>
        <Paper style={{ padding: 40 }}>
          <Grid container spacing={1}>
            <Grid item xs>
              <Typography
                variant="h5"
                style={{ fontWeight: 'bold', margin: 10 }}
              >
                Colaborador
              </Typography>
              <div style={{ display: 'flex', gap: 20, margin: 15 }}>
                <Controller
                  name="cpf_colaborador"
                  control={control}
                  render={({ field: { name, onChange, value } }) => {
                    return (
                      <InputSearchColab
                        name={name}
                        label="CPF"
                        value={value}
                        onChange={(e) => handleSearchCpf(e.toString())}
                        errorShow={!!messageError}
                        textError={messageError}
                        isLoading={loadingColab}
                      />
                    );
                  }}
                />

                <TextField
                  disabled
                  label="Nome"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register('nome')}
                />
              </div>
              <div style={{ display: 'flex', gap: 20, margin: 15 }}>
                <TextField
                  label="Data do agendamento"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register('data_agendamento')}
                />
                <FormControl variant="outlined" style={{ width: '20%' }}>
                  <InputLabel>Tipo de ASO</InputLabel>
                  <Select label="Tipo de ASO" {...register('tipo_aso')}>
                    {ASO.map((aso, index) => (
                      <MenuItem key={index} value={aso.value}>
                        {aso.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div style={{ display: 'flex', gap: 20, margin: 15 }}>
                <FormControl
                  style={{ marginRight: 20, width: '20%', marginBottom: 20 }}
                >
                  <Button
                    title="Adicionar Exames"
                    type="button"
                    onClick={() => {
                      setDialog(!dialog);
                    }}
                    icon={<Add style={{ fontSize: 20 }} />}
                  />
                  <Exames
                    control={control}
                    openDialog={dialog}
                    closeDialog={() => {
                      setDialog(!dialog);
                    }}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 20,
            marginTop: 20
          }}
        >
          <NavLink to={{ pathname: '/agendar', state: { exit: true } }}>
            <Button type="button" title="Sair" variantstyle="RED" />
          </NavLink>
          <Button
            disabled={isSubmitting}
            isLoading={isSubmitting}
            type="submit"
            title="Cadastrar"
          />
        </div>
      </form>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
