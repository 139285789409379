import { api } from "@services/api";

export interface ScheduleProps {
    id_agendamento: number;
    num_agenda: string;
    nome: string;
    cpf_colaborador: string;
    tipo_agendamento: string;
    cpf_cnpj_comer: string;
    nome_comercial: string;
    cidade: string;
    estado: string;
    data_agendamento: string;
    ordem_chegada: string;
    horario: string;
    tipo_aso: string;
    observacao: string;
    status: string;
    usuario: string;
    exibir: string;
    linkDoatendimento: string;
    arquivo_prontuario: string;
    arquivo_aso: string;
    id_colaboradores: number;
    matricula_empresa: string;
    matricula_esocial: string;
    nome_completo: string;
    rg: string;
    data_nascimento: string;
    genero: string;
    cnh: string;
    email: string;
    telefone: string;
    situacao: string;
    nis: string;
    funcao: string;
    setor: string;
    turno: string;
    cnpj: string;
    razao_social: string;
    data_admissao: string;
    horario_trab_entrada: string;
    horario_trab_saida: string;
    local_obra: string;
    cod_ghe: string;
    ghe: string;
  }

export async function getDataScheduling(id: string){
    const response = await api.get<ScheduleProps[]>(`ravim/update-scheduling/${id}`)

    return response.data[0]
}