import { api } from "@services/api";

type PostDownloadFicha = {
  cpf_colaborador: string;
  cnpj_cpf_comer: string;
};

export async function postDownloadFicha(data: PostDownloadFicha) {
    console.log(data)
    return await api.post(`/ravim/colab-kit-salvo`, data);
}
