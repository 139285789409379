import React, { useState, useEffect, useCallback } from 'react';
import {
  Button as ButtonM,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import MuiAlert, { type Color } from '@material-ui/lab/Alert';
import { Link, useLocation, useParams } from 'react-router-dom';
import { type ScheduleProps } from '.';
import { api } from '../../../../services/api';
import { Autocomplete } from '@material-ui/lab';
import { GetApp, Publish } from '@material-ui/icons';
import { type AxiosResponse } from 'axios';
import { Guia } from '@pages/Agendamentos/PDF/gerarGuia';
import { PDF } from '@pages/Agendamentos/PDF/gerarPdf';
import { getFileName, sendFileWithoutFake, verifyUpLoads } from '@utils/masks';
import { ASO } from '@data/tipoAso';
import { useQuery } from '@tanstack/react-query';
import { getDataScheduling } from '@api/get-data-schedule';
import { LoadingPage } from '@components/LoadingPage';
import { AppError } from '@utils/AppError';
import { Button } from '@components/Button';
import { LabelUpload } from './input';
import { Check, Upload } from '@phosphor-icons/react';

interface IColaborador {
  id_colaboradores: number;
  matricula_empresa: string;
  matricula_esocial: any;
  nome_completo: string;
  cpf_colaborador: string;
  rg: string;
  data_nascimento: string;
  genero: string;
  cnh: string;
  email: string;
  telefone: string;
  situacao: string;
  nis: string;
  funcao: string;
  setor: string;
  turno: string;
  cnpj: string;
  razao_social: string;
  data_admissao: string;
  horario_trab_entrada: string;
  horario_trab_saida: string;
  local_obra: string;
  cod_ghe: any;
  ghe: string;
}
interface INomeComercial {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  razao_social: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  telemedicina: string;
  observacao: string;
}

interface IReceivedExames {
  exame_credenciado: string;
  exame_empresa: string;
  id_exame_colab: number;
  valor_credenciado: string;
  valor_empresa: string;
}

const ButtonAdd = withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B'
    }
  }
}))(ButtonM);
const ButtonSair = withStyles(() => ({
  root: {
    background: 'red',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'red',
      color: 'red',
      border: 'solid 1px red'
    }
  }
}))(ButtonM);

const status = [
  { value: 'Solicitado' },
  { value: 'Confirmado' },
  { value: 'Reagendado' },
  { value: 'Atendido' },
  { value: 'Concluído' },
  { value: 'Externo' }
];

export const EditAgendamentoToxicologico: React.FC<any> = (props) => {
  const { id } = useParams<{ id: string }>();
  const {id_agendamento} = props.location.state;
  const [listNomeComercial, setListNomeComercial] = useState<INomeComercial[]>(
    []
  );
  const [selectedNomeComercial, setSelectedNomeComercial] = useState<any>();
  const [searchEmail, setSearchEmail] = useState<any>();
  const [emailAgendamento, setEmailAgendamento] = useState([]);
  const [newValuesExames, setNewValuesExames] = useState<IReceivedExames[]>([]);

  const [statusAgendamento, setStatusAgendameto] = useState<string>('');
  const [cpfColab, setCpfColab] = useState<string>('');
  const [nomeColab, setNomeColab] = useState<string>('');
  const [estado, setEstado] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');
  const [dataAgendamento, setDataAgendamento] = useState<string>('');
  const [horarioAgendamento, setHorarioAgendamento] = useState<string>('');
  const [tipoAso, setTipoAso] = useState<string>('');
  const [obs, setObs] = useState<string>('');
  const [cnpjComercial, setCnpjComercial] = useState<string>('');
  const [comboColab, setComboColab] = useState<IColaborador[]>([]);

  const [arquivoSendProntuario, setArquivoSendProntuario] = useState<
    File | string
  >('');
  const [viewFileProntuario, setViewFileProntuario] = useState<
    object | string | null
  >('');
  const [arquivoProntuario, setArquivoProntuario] = useState<File | string>('');
  const [arquivoASO, setArquivoASO] = useState<File | string>('');
  const [arquivoSendASO, setArquivoSendASO] = useState<File | string>('');
  const [viewFileASO, setViewFileASO] = useState<object | string | null>('');

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState();
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color>();
  const nome_user = localStorage.getItem('NameUser') || '';
  const TOXICOLOGICO_PAGE = 'toxico';

  async function sendAgendamento() {
    const hora = new Date();

    const newHora = hora.toLocaleString('pt-BR', { hour12: false });
    const data = {
      status: statusAgendamento,
      observacao: obs,
      tipo_aso: tipoAso,
      horario: horarioAgendamento,
      data_agendamento: dataAgendamento,
      estado,
      cidade,
      nome_comercial: '',
      tipo_agendamento: getDataSchedule?.tipo_agendamento,
      nome: nomeColab,
      cpf_colaborador: cpfColab,
      num_agenda: getDataSchedule?.num_agenda,
      usuario: `${nome_user} ${newHora}`,
      email_agendamento: emailAgendamento,
      ordem_chegada: null,
      cpf_cnpj_comer: cnpjComercial,
      arquivo_aso: arquivoASO || getDataSchedule?.arquivo_aso,
      arquivo_prontuario:
        arquivoProntuario || getDataSchedule?.arquivo_prontuario
    };
    console.log(data);
    await api
      .post(`/ravim/atua-agendamento-toxi`, data)
      .then((res) => {
        if (res.status === 200) {
          setCod(String(res.status));
          setSnack(true);
          setSeverity('success');
          setMessage(res.data.message);
          setTimeout(() => {
            window.location.replace('/agendamentos');
          }, 1000);
        } else {
          setCod(String(res.status));
          setSnack(true);
          setSeverity('error');
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        setCod(err.code);
        setSnack(true);
        setSeverity('error');
        setMessage(err.message);
      });

    const formData = new FormData();
    formData.append('arquivo1', arquivoSendProntuario);
    formData.append('arquivo2', arquivoSendASO);

    await api
      .post(`/file`, formData)
      .then((res: AxiosResponse) => {
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  const {
    data: getDataSchedule,
    error: errorDataSchedule,
    isFetching
  } = useQuery({
    queryKey: ['getSchedule'],
    queryFn: () => getDataScheduling(id_agendamento)
  });

  async function reqStatus() {
    const data = {
      tipo_agendamento: getDataSchedule?.tipo_agendamento,
      tipo: getDataSchedule?.tipo_agendamento
    };
    await api
      .post(`/ravim/telemed-clinicas`, data)
      .then((res) => {
        if (res.status === 200 || res.status === 200) {
          setListNomeComercial(res.data);
        } else {
          setListNomeComercial([]);
        }
      })
      .catch((err) => {
        if (err.response.status === 500 || err.response.status !== 500) {
          setListNomeComercial([]);
        }
      });
  }

  async function dataColaborador() {
    await api
      .get(`ravim/colaboradores`)
      .then((res) => {
        if (res.status === 200) {
          setComboColab(res.data);
        } else {
          setComboColab([]);
        }
      })
      .catch((err) => {
        console.log(err.estado);
      });
  }

  function handleData(event: IColaborador) {
    setNomeColab(event.nome_completo);
    setCpfColab(event.cpf_colaborador);
  }

  async function downloadFile(arquivo: string) {
    const data = {
      arquivo
    };

    await api
      .post(`/ravim/download`, data)
      .then((res) => {
        const href = res.data.dados;
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', arquivo.replace(/[_]/g, ' '));
        link.download.replace(/[_]/g, ' ');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  const changeFileProntuario = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setArquivoProntuario(sendFileWithoutFake(event.target.value));
      setArquivoSendProntuario(event.target.files[0]);

      // console.log(event.target.files[0])

      setViewFileProntuario(URL.createObjectURL(event.target.files[0]));
    } else {
      console.log('cancelado');
    }
  };

  const changeFileASO = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setArquivoASO(sendFileWithoutFake(event.target.value));
      setArquivoSendASO(file);

      // console.log(event.target.files[0])

      setViewFileASO(URL.createObjectURL(event.target.files[0]));
    } else {
      console.log('cancelado');
    }
  };

  const reqpdf = useCallback(
    async (
      tipo: number,
      cpf_colaborador: string,
      cpf_cnpj_comer: string,
      num_agenda: string
    ) => {
      try {
        const res = await api.post(`/ravim/pdf`, {
          cpf_colaborador,
          cnpj_cpf_comer: cpf_cnpj_comer,
          num_agenda
        });

        if (tipo === 1) {
          Guia(res.data, TOXICOLOGICO_PAGE);
        }
      } catch (error) {
        if (error instanceof AppError) {
          console.log(error);
        }
      }
    },
    []
  );

  useEffect(() => {
    const getDataColaborador = new Promise((resolve, reject) => {
      setTimeout(() => {
        dataColaborador();
        resolve(10);
      }, 1 * 1000);
    });
    const getStatus = new Promise((resolve, reject) => {
      setTimeout(() => {
        reqStatus();
        resolve(20);
      }, 2 * 1000);
    });

    Promise.all([getDataColaborador, getStatus]);
  }, [getDataSchedule]);

  useEffect(() => {
    setStatusAgendameto(getDataSchedule?.status ?? '');
    setCpfColab(getDataSchedule?.cpf_colaborador ?? '');
    setNomeColab(getDataSchedule?.nome ?? '');
    setDataAgendamento(getDataSchedule?.data_agendamento ?? '');
    setHorarioAgendamento(getDataSchedule?.horario ?? '');
    setCnpjComercial(getDataSchedule?.cpf_cnpj_comer ?? '');

    setTipoAso(getDataSchedule?.tipo_aso ?? '');
    setObs(getDataSchedule?.observacao ?? '');
  }, [isFetching]);

  if (!getDataSchedule) return;

  return (
    <Container>
      <LoadingPage open={isFetching} onClick={() => {}} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 20,
          gap: 20
        }}
      >
        {verifyUpLoads(getDataSchedule.arquivo_aso) !== '' ? (
          <Button
            icon={<GetApp />}
            title="Download Resultado"
            onClick={() => {
              downloadFile(getFileName(String(getDataSchedule.arquivo_aso)));
            }}
          />
        ) : (
          <></>
        )}
        {verifyUpLoads(getDataSchedule.arquivo_prontuario) !== '' ? (
          <Button
            title=" Download Relatório"
            icon={<GetApp />}
            onClick={() => {
              downloadFile(
                getFileName(String(getDataSchedule.arquivo_prontuario))
              );
            }}
          />
        ) : (
          <></>
        )}
        <Button
          title="Download Guia"
          icon={<GetApp style={{ fontSize: 20 }} />}
          variantstyle="DARK_PINK"
          onClick={() => {
            reqpdf(
              1,
              getDataSchedule?.cpf_colaborador,
              getDataSchedule?.cpf_cnpj_comer,
              getDataSchedule?.num_agenda
            );
          }}
        />
      </div>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Editar Agendamento Toxicológico
      </Typography>
      <Paper style={{ padding: 40 }}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Upload
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              {/* <input
                id="arquivoProntuario"
                name="fileProntuario"
                type="file"
                onChange={changeFileProntuario}
                style={{ display: 'none' }}
              />
              <label htmlFor="arquivoProntuario">
                <Button title="Upload-Relatório" variantstyle="LIGHT_PINK" />

                <Typography>{String(arquivoProntuario)}</Typography>
              </label>
              <input
                id="arquivoASO"
                name="fileASO"
                type="file"
                onChange={changeFileASO}
                style={{ display: 'none' }}
              />
              <label htmlFor="arquivoASO">
                <Button title="Upload - Resultado" variantstyle="DARK_PINK" />

                <Typography>{String(arquivoASO)}</Typography>
              </label> */}
              <LabelUpload
                title="Upload-Prontuário"
                variantstyle="LIGHT_PINK"
                htmlFor="arquivoProntuario"
                icon={String(arquivoProntuario) ? <Check size={24}/> : <Upload size={24}/>}
                onChange={changeFileProntuario}
              />
              <LabelUpload
                icon={String(arquivoASO) ? <Check size={24}/> : <Upload size={24}/>}
                title="Upload - Resultado"
                variantstyle="DARK_PINK"
                htmlFor="arquivoASO"
                onChange={changeFileASO}
                
              />
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Status
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl variant="outlined" style={{ width: '20%' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  value={statusAgendamento}
                  onChange={(e: any) => {
                    setStatusAgendameto(e.target.value);
                  }}
                >
                  {status.map((status, index) => (
                    <MenuItem key={index} value={status.value}>
                      {status.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Colaborador
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <Autocomplete
                disabled
                fullWidth
                id="tags-outlined"
                options={comboColab}
                defaultValue={{
                  cpf_colaborador: getDataSchedule.cpf_colaborador,
                  value: getDataSchedule.cpf_colaborador
                }}
                getOptionSelected={(option, value) =>
                  option.cpf_colaborador === value.cpf_colaborador
                }
                getOptionLabel={(option) => option.cpf_colaborador}
                onChange={(e, newValue) => {
                  handleData(newValue);
                }}
                value={selectedNomeComercial}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="CPF"
                    placeholder="CPF"
                  />
                )}
              />
              <TextField
                disabled
                label="Nome"
                variant="outlined"
                fullWidth
                value={nomeColab}
                onChange={(e) => {
                  setNomeColab(e.target.value);
                }}
              />
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Atendimento por Telemedicina
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <Autocomplete
                fullWidth
                id="tags-outlined"
                options={listNomeComercial}
                defaultValue={{
                  nome_comercial: getDataSchedule.nome_comercial,
                  value: getDataSchedule.nome_comercial,
                  estado: getDataSchedule.estado,
                  cidade: getDataSchedule.cidade
                }}
                getOptionSelected={(option, value) =>
                  option.cpf_cnpj_comer === value.cpf_cnpj_comer
                }
                getOptionLabel={(option) =>
                  `${option?.cpf_cnpj_comer}`
                    ? `${option?.nome_comercial} - ${option?.estado} - ${option?.cidade}`
                    : ''
                }
                disabled
                onChange={(e, newValue) => {
                  setSearchEmail(newValue.cpf_cnpj);
                  setSelectedNomeComercial(newValue.nome_comercial);
                  setCnpjComercial(newValue.cpf_cnpj);
                  setCidade(newValue.estado);
                  setEstado(newValue.cidade);
                }}
                value={selectedNomeComercial}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Nome Credenciado"
                    placeholder="Nome Credenciado"
                  />
                )}
              />
            </div>

            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Data do agendamento"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                value={dataAgendamento}
                onChange={(e) => {
                  setDataAgendamento(e.target.value);
                }}
              />
              <TextField
                label="Horário"
                type="time"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                value={horarioAgendamento}
                onChange={(e) => {
                  setHorarioAgendamento(e.target.value);
                }}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl variant="outlined" style={{ width: '20%' }}>
                <InputLabel>Tipo de ASO</InputLabel>
                <Select
                  label="Tipo de ASO"
                  value={tipoAso}
                  onChange={(e: any) => {
                    setTipoAso(e.target.value);
                  }}
                >
                  {ASO.map((aso, index) => (
                    <MenuItem key={index} value={aso.value}>
                      {aso.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                minRows={4}
                label="OBS"
                multiline
                variant="outlined"
                value={obs}
                onChange={(e) => {
                  setObs(e.target.value);
                }}
                fullWidth
              />
            </div>
          </Grid>
        </Grid>
      </Paper>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          marginTop: 20
        }}
      >
        <Link to={'/agendamentos'} style={{ textDecoration: 'none' }}>
          <ButtonSair variant="outlined">Sair</ButtonSair>
        </Link>
        <ButtonAdd variant="outlined" onClick={sendAgendamento}>
          Cadastrar
        </ButtonAdd>
      </div>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
