import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  Grid,
  Paper,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Chip,
  Snackbar
} from '@material-ui/core';
import './styleEmpresaRH.css';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import { Autocomplete } from '@material-ui/lab';
import { api } from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import { RootState } from '../../../store';
import { infoEmpNome, infoCnpjNomeFantasia } from '../../../store/cadEmpresaRH';
import { Link } from 'react-router-dom';
import { maskCEP, maskCNPJ, maskPhone } from '../../../utils/masks';
import { LoadingPage } from '@components/LoadingPage';

const PurpleSwitch = withStyles({
  switchBase: {
    color: purple[300],
    '&$checked': {
      color: purple[500]
    },
    '&$checked + $track': {
      backgroundColor: purple[500]
    }
  },
  checked: {},
  track: {}
})(Switch);

const ButtonAdd = withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B'
    }
  }
}))(Button);

const ButtonSair = withStyles(() => ({
  root: {
    background: 'red',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'red',
      color: 'red',
      border: 'solid 1px red'
    }
  }
}))(Button);

interface IEmails {
  id?: string;
  email: string;
}

interface ITelefone {
  id: string;
  telefone: string;
}

interface IContratos extends IInfos {
  nome_contrato: string;
  cod_contrato: string;
  data_criacao: string | Date;
  data_renovacao: string | Date;
  arquivo: File | null;
}

interface IInfos {
  idcad_empresa: number;
  num_cnpj: string;
  nome_empresa: string;
  razao_social: string;
  nome_fantacia: string;
  rua: string;
  numero: string;
  cep: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_contrato: string;
  cod_contrato: string;
  exibir: string;
}

interface ICep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface ICnae {
  id_cnae: string;
  nome: string;
}

export default function EditEmpresaRH(props: any) {
  const {
    cnae,
    nome_fantacia,
    num_cnpj,
    razao_social,
    cnae_secundario,
    cod_contrato,
    nome_contrato,
    nome_empresa,
    inf_contrato
  } = props.location.state;
  // console.log(props)

  const dispatch = useDispatch();
  const [swt, setSwt] = useState<boolean>(false);
  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState();
  const [cod, setCod] = useState(0);
  const [severity, setSeverity] = useState<Color>();

  const [telefones, setTelefones] = useState<ITelefone[]>([]);
  const [emails, setEmail] = useState<IEmails[]>([]);
  const [singleEmail, setSingleEmail] = useState<string[]>([]);
  const [singleTelefone, setSingleTelefone] = useState<string[]>([]);

  const [nomeComercial, setNomeComercial] = useState(nome_empresa);
  const [contrato, setContrato] = useState(cod_contrato);
  const [numeroCnpj, setNumeroCnpj] = useState(num_cnpj);
  const [razaoSocial, setRazaoSocial] = useState(razao_social);
  const [nomeFantasia, setNomeFantasia] = useState(nome_fantacia);

  const [cnaeSecundario, setCnaeSecundario] = useState<ICnae[]>([]);
  const [selectedCnaeSecundario, setSelectedCnaeSecundario] = useState<ICnae[]>(
    []
  );
  const [nomeCnae, setCnae] = useState<ICnae[]>([]);
  const [cnaeSelected, setCnaeSelected] = useState<ICnae[]>([]);
  const [loading, setLoading] = useState(false);

  const [cep, setCep] = useState<ICep>(props.location.state.cep);
  const [cepSearch, setCepSearch] = useState<string>(props.location.state.cep);
  const [rua, setRua] = useState<string>('');
  const [numero, setNumero] = useState<string>(props.location.state.numero);
  const [complemento, setComplemento] = useState<string>(
    props.location.state.complemento
  );
  const [bairro, setBairro] = useState<string>('');
  const [estado, setEstado] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');

  const [cepSearchCob, setCepSearchCob] = useState<string>('');
  const [ruaCob, setRuaCob] = useState<string>('');
  const [numeroCob, setNumeroCob] = useState<string>('');
  const [complementoCob, setComplementoCob] = useState<string>('');
  const [bairroCob, setBairroCob] = useState<string>('');
  const [estadoCob, setEstadoCob] = useState<string>('');
  const [cidadeCob, setCidadeCob] = useState<string>('');

  // console.log(props.location.state)
  useEffect(() => {
    if (cepSearchCob.length === 8 || cepSearchCob.length === 9) {
      searchCepCob();
    }
  }, [cepSearchCob]);

  useEffect(() => {
    // contract()
    cnaeSec();

    if (cepSearch.length === 8 || cepSearch.length === 9) {
      searchCep();
    }
    if (!cepSearch || !cepSearchCob) {
      setRua('');
      setBairro('');
      setEstado('');
      setCidade('');

      setRuaCob('');
      setBairroCob('');
      setEstadoCob('');
      setCidadeCob('');
    }
    if (!swt) {
      dataEmpresa();
      setCepSearchCob('');
      setRuaCob('');
      setBairroCob('');
      setEstadoCob('');
      setCidadeCob('');
    }
  }, [cepSearch, swt]);

  async function sendInfo() {
    const data = {
      nome_empresa: nomeComercial,
      num_cnpj: numeroCnpj,
      razao_social: razaoSocial,
      nome_fantacia: nomeFantasia,
      num_cnae: cnaeSelected,
      num_cnae_sec: selectedCnaeSecundario,
      infoEmpTelefone: singleTelefone,
      infoEmpEmails: singleEmail,
      cod_contrato: contrato,
      numero,
      numeroCob,
      cep: cepSearch,
      complemento,
      bairro,
      cidade,
      estado,
      cep_cobr: String(cepSearchCob),
      complemento_cobr: complementoCob,
      bairro_cobr: bairroCob,
      cidade_cobr: cidadeCob,
      estado_cobr: estadoCob,
      rua,
      ruaCob,
      nome_contrato
    };

    console.log(data);

    await api
      .post(`ravim/update-empresas`, data)
      .then((res) => {
        setCod(res.status);
        setSnack(true);
        setSeverity('success');
        setMessage(res.data.message);
        setTimeout(() => {
          window.location.replace('/empresa-rh');
        }, 3000);
      })
      .catch((err) => {
        setCod(err.code);
        setSnack(true);
        setSeverity('error');
        setMessage(err.message);
      });
  }

  async function cnaeSec() {
    await api
      .get(`ravim/cnae`)
      .then((res) => {
        setCnaeSecundario(res.data);
        setCnae(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function dataEmpresa() {
    setLoading(true);
    const data = {
      num_cnpj
    };
    await api
      .post(`/ravim/empresas-rh`, data)
      .then((res) => {
        JSON.parse(res.data.endereco_cobranca).map((c: any) => {
          setComplementoCob(c.complemento);
          setNumeroCob(c.numero);
        });

        const cnae = JSON.parse(res.data.empresa_cnae).map((cnae: ICnae) => {
          return { id_cnae: cnae.id_cnae, nome: cnae.nome };
        });
        const cnaeSecundario = JSON.parse(res.data.empresa_cnae_secundario).map(
          (cnaeSec: any) => cnaeSec
        );
        const tel = JSON.parse(res.data.telefone).map(
          (tel: any) => tel.telefone
        );
        const email = JSON.parse(res.data.email).map((e: any) => e.email);
        const cep = JSON.parse(res.data.endereco_cobranca).map(
          (e: any) => e.cep
        );

        if (cep[0] !== '') {
          setSwt(true);
          setCepSearchCob(String(cep).replace('-', ''));
          console.log(cep);
        } else {
          setSwt(false);
        }

        setSingleTelefone(tel);
        setSingleEmail(email);
        setSelectedCnaeSecundario(cnaeSecundario);
        setCnaeSelected(cnae);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function searchCep() {
    const cep = cepSearch.replace('-', '');
    await api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        if (res.data) {
          setCep(res.data);
          setRua(res.data.logradouro);
          setBairro(res.data.bairro);
          setEstado(res.data.uf);
          setCidade(res.data.localidade);
        } else {
          setRua('');
          setBairro('');
          setEstado('');
          setCidade('');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function searchCepCob() {
    const cep = cepSearchCob.replace('-', '');
    console.log(cep);
    await api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        if (res.data) {
          setCep(res.data);
          setRuaCob(res.data.logradouro);
          setBairroCob(res.data.bairro);
          setEstadoCob(res.data.uf);
          setCidadeCob(res.data.localidade);
        } else {
          setRuaCob('');
          setBairroCob('');
          setEstadoCob('');
          setCidadeCob('');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Container>
      <LoadingPage
        open={loading}
        onClick={() => {
          setLoading(loading);
        }}
      />
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Editar Empresa RH
      </Typography>
      <div>
        <Paper style={{ padding: 30, width: '100%' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ marginBottom: 20, fontWeight: 'bold' }}
              >
                Informações da Empresa
              </Typography>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Nome Comercial"
                  value={nomeComercial}
                  onChange={(e: any) => {
                    setNomeComercial(e.target.value);
                  }}
                />
                <TextField
                  disabled
                  fullWidth
                  variant="outlined"
                  label="Contrato"
                  value={`${nome_contrato} - ${cod_contrato}`}
                  onChange={(e: any) => dispatch(infoEmpNome(e.target.value))}
                />

                <Autocomplete
                  value={singleEmail}
                  onChange={(event, newValue) => {
                    setSingleEmail(newValue);
                  }}
                  multiple
                  fullWidth
                  id="tags-filled"
                  options={emails.map((option) => option.email)}
                  freeSolo
                  renderTags={(value: string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="E-mails"
                      placeholder="Search"
                    />
                  )}
                />
              </div>
              <div className="containerFields">
                <Autocomplete
                  value={singleTelefone}
                  onChange={(event, newValue) => {
                    setSingleTelefone(newValue.map((e) => maskPhone(e)));
                  }}
                  multiple
                  fullWidth
                  id="tags-filled"
                  options={telefones.map((option) => option.telefone)}
                  freeSolo
                  renderTags={(value: string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Telefones"
                      placeholder="Aperte enter para salvar o telefone"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ marginBottom: 20, fontWeight: 'bold' }}
              >
                Informações do CNPJ
              </Typography>
              <div className="containerFields">
                <TextField
                  disabled
                  fullWidth
                  variant="outlined"
                  label="Número do CNPJ"
                  value={numeroCnpj}
                  inputProps={{ maxLength: 14, minLength: 14 }}
                  onChange={(e) => {
                    setNumeroCnpj(maskCNPJ(e.target.value));
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Razão Social"
                  value={razaoSocial}
                  onChange={(e) => {
                    setRazaoSocial(e.target.value);
                  }}
                />
              </div>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Nome Fantasia"
                  value={nomeFantasia}
                  onChange={(e) => setNomeFantasia(e.target.value)}
                />

                <Autocomplete
                  fullWidth
                  id="tags-outlined"
                  options={nomeCnae}
                  getOptionLabel={(option: ICnae) =>
                    `${option.id_cnae}`
                      ? `${option.id_cnae} - ${option.nome}`
                      : ''
                  }
                  onChange={(e, newValue: any) => {
                    setCnaeSelected([newValue]);
                  }}
                  value={cnaeSelected[0]}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="CNAE "
                      placeholder="CNAE"
                    />
                  )}
                />
              </div>
              <div className="containerFields">
                <Autocomplete
                  fullWidth
                  multiple
                  id="tags-outlined"
                  options={cnaeSecundario}
                  getOptionLabel={(option: ICnae) =>
                    `${option.id_cnae}`
                      ? `${option.id_cnae} - ${option.nome}`
                      : ''
                  }
                  onChange={(e, newValue: any) => {
                    setSelectedCnaeSecundario(newValue);
                  }}
                  value={selectedCnaeSecundario}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="CNAE Secundário"
                      placeholder="CNAE Secundário"
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography
                style={{ marginBottom: 20, fontWeight: 'bold' }}
                variant="h5"
              >
                Endereço Comercial
              </Typography>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  value={cepSearch}
                  onChange={(e: any) => {
                    setCepSearch(maskCEP(e.target.value));
                  }}
                  label="CEP"
                  inputProps={{ maxLength: 8 }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={rua}
                  onChange={(e: any) => {
                    setRua(e.target.value);
                  }}
                  label="Rua"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={numero}
                  onChange={(e: any) => {
                    setNumero(e.target.value);
                  }}
                  label="Número"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  value={complemento}
                  onChange={(e: any) => {
                    setComplemento(e.target.value);
                  }}
                  label="Complemento"
                />
              </div>
              <div className="containerFields">
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => {
                    setBairro(e.target.value);
                  }}
                  value={bairro}
                  label="Bairro"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => {
                    setEstado(e.target.value);
                  }}
                  value={estado}
                  label="Estado"
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e: any) => {
                    setCidade(e.target.value);
                  }}
                  value={cidade}
                  label="Cidade"
                />
              </div>
            </Grid>
            <FormControlLabel
              control={
                <PurpleSwitch
                  checked={swt}
                  onChange={(e: any) => {
                    setSwt(e.target.checked);
                  }}
                  name="checkedA"
                />
              }
              label="Endereço de Cobrança"
            />

            {swt && swt ? (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{ marginBottom: 20, fontWeight: 'bold' }}
                  >
                    Endereço de Cobrança
                  </Typography>
                  <div className="containerFields">
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={cepSearchCob}
                      onChange={(e) => {
                        setCepSearchCob(maskCEP(e.target.value));
                      }}
                      label="CEP"
                      inputProps={{ maxLength: 8 }}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={ruaCob}
                      onChange={(e) => {
                        setRuaCob(e.target.value);
                      }}
                      label="Rua"
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={numeroCob}
                      onChange={(e) => {
                        setNumeroCob(e.target.value);
                      }}
                      label="Número"
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={complementoCob}
                      onChange={(e) => {
                        setComplementoCob(e.target.value);
                      }}
                      label="Complemento"
                    />
                  </div>
                  <div className="containerFields">
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={bairroCob}
                      onChange={(e) => {
                        setBairroCob(e.target.value);
                      }}
                      label="Bairro"
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={estadoCob}
                      onChange={(e) => {
                        setEstadoCob(e.target.value);
                      }}
                      label="Estado"
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={cidadeCob}
                      onChange={(e) => {
                        setCidade(e.target.value);
                      }}
                      label="Cidade"
                    />
                  </div>
                </Grid>
              </>
            ) : (
              ''
            )}
          </Grid>
        </Paper>
        <div className="btnCadastro">
          <Link to="/empresa-rh" style={{ textDecoration: 'none' }}>
            <ButtonSair variant="outlined">Sair</ButtonSair>
          </Link>
          <ButtonAdd onClick={sendInfo} variant="outlined">
            Salvar
          </ButtonAdd>
        </div>
      </div>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
}
