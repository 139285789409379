import React from 'react';
import * as M from '@material-ui/core';
import { maskCPF } from '@utils/masks';
import { type Props } from '../PreencherKit';

export function PreCadastroKit({
  user,
  genero,
  handleSelectedValueRadio,
  fetchSendKit,
  name,
  cpf,
  dateBirth,
  handleCpf,
  handleNomeCompleto,
  handleNomeDataDeNascimento,
  value
}: Props) {
  return (
    <M.Container>
      <M.Paper
        elevation={5}
        style={{ padding: 20, marginTop: 20, marginBottom: 40 }}
      >
        <M.Typography
          variant="h5"
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: 20
          }}
        >
          Pre cadastro
        </M.Typography>
        <div style={{ display: 'flex', gap: 20 }}>
          {handleCpf && handleNomeCompleto && handleNomeDataDeNascimento && (
            <>
              <M.TextField
                label="Nome completo"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(value) => { handleNomeCompleto(value.target.value); }}
              />
              <M.TextField
                label="CPF"
                variant="outlined"
                fullWidth
                value={cpf}
                onChange={(value) => { handleCpf(maskCPF(value.target.value)); }}
                disabled
              />
              <M.TextField
                label="Data de nascimento"
                variant="outlined"
                type="date"
                fullWidth
                value={dateBirth}
                onChange={(value) =>
                  { handleNomeDataDeNascimento(value.target.value); }
                }
                InputLabelProps={{
                  shrink: true
                }}
              />
            </>
          )}
        </div>
      </M.Paper>

      <M.FormGroup>
        <M.Paper
          style={{ padding: 20, marginTop: 20, marginBottom: 40 }}
          elevation={5}
        >
          <M.Typography
            variant="h5"
            gutterBottom
            style={{ textAlign: 'center', fontWeight: 'bold' }}
          >
            Levantamento de Dados e Antecendentes Familiares
          </M.Typography>
          <div
            style={{
              display: 'flex',
              gap: 15,
              marginTop: 20,
              marginBottom: 20
            }}
          >
            <M.TextField
              InputProps={{
                startAdornment: (
                  <M.InputAdornment position="start">(kg)</M.InputAdornment>
                )
              }}
              label="Peso"
              name="peso"
              value={value?.peso}
              onChange={(value) =>
                { handleSelectedValueRadio(value.target.name, value.target.value); }
              }
              variant="outlined"
            />
            <M.TextField
              label="Altura"
              value={value?.altura}
              name="altura"
              InputProps={{
                startAdornment: (
                  <M.InputAdornment position="start">(m)</M.InputAdornment>
                )
              }}
              onChange={(value) =>
                { handleSelectedValueRadio(value.target.name, value.target.value); }
              }
              variant="outlined"
            />
          </div>
          <M.Typography variant="subtitle2" gutterBottom>
            Sua esposa ou qualquer um de seus familiares (pai, mãe, irmãos,
            mesmos que falecidos) tem ou tiveram algumas das doenças abaixo?
          </M.Typography>

          <M.List>
            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Diabete
            </M.FormLabel>
            <M.FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <M.RadioGroup
                value={String(value?.Diabetes_1)}
                name="Diabetes_1"
                style={{ flexDirection: 'row' }}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  label="Sim"
                  control={<M.Radio />}
                />
                <M.FormControlLabel
                  value="Não"
                  label="Não"
                  control={<M.Radio />}
                />
              </M.RadioGroup>

              {value?.Diabetes_1 === 'Sim' ? (
                <M.TextField
                  label="Parentesco"
                  variant="outlined"
                  name="Parentesco_1"
                  value={value?.Parentesco_1}
                  style={{ width: '30%', top: -10, marginLeft: 30 }}
                  onChange={(value) =>
                    { handleSelectedValueRadio(
                      value.target.name,
                      value.target.value
                    ); }
                  }
                />
              ) : (
                <></>
              )}
            </M.FormGroup>

            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Doença do Coração
            </M.FormLabel>
            <M.FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <M.RadioGroup
                value={String(value?.Doenca_do_Coracao)}
                name="Doenca_do_Coracao"
                style={{ flexDirection: 'row' }}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  label="Sim"
                  control={<M.Radio />}
                />
                <M.FormControlLabel
                  value="Não"
                  label="Não"
                  control={<M.Radio />}
                />
              </M.RadioGroup>
              {value?.Doenca_do_Coracao === 'Sim' ? (
                <M.TextField
                  label="Parentesco"
                  variant="outlined"
                  name="Parentesco_2"
                  value={value?.Parentesco_2}
                  style={{ width: '30%', top: -10, marginLeft: 30 }}
                  onChange={(value) =>
                    { handleSelectedValueRadio(
                      value.target.name,
                      value.target.value
                    ); }
                  }
                />
              ) : (
                <></>
              )}
            </M.FormGroup>

            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Doença Psiquiátricas
            </M.FormLabel>
            <M.FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <M.RadioGroup
                value={String(value?.Doenca_Psiquiatricas)}
                name="Doenca_Psiquiatricas"
                style={{ flexDirection: 'row' }}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  label="Sim"
                  control={<M.Radio />}
                />
                <M.FormControlLabel
                  value="Não"
                  label="Não"
                  control={<M.Radio />}
                />
              </M.RadioGroup>

              {value?.Doenca_Psiquiatricas === 'Sim' ? (
                <M.TextField
                  label="Parentesco"
                  variant="outlined"
                  name="Parentesco_3"
                  value={value?.Parentesco_3}
                  style={{ width: '30%', top: -10, marginLeft: 30 }}
                  onChange={(value) =>
                    { handleSelectedValueRadio(
                      value.target.name,
                      value.target.value
                    ); }
                  }
                />
              ) : (
                <></>
              )}
            </M.FormGroup>

            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Tuberculose
            </M.FormLabel>
            <M.FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <M.RadioGroup
                value={String(value?.Tuberculose)}
                name="Tuberculose"
                style={{ flexDirection: 'row' }}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  label="Sim"
                  control={<M.Radio />}
                />
                <M.FormControlLabel
                  value="Não"
                  label="Não"
                  control={<M.Radio />}
                />
              </M.RadioGroup>

              {value?.Tuberculose === 'Sim' ? (
                <M.TextField
                  label="Parentesco"
                  variant="outlined"
                  name="Parentesco_4"
                  value={value?.Parentesco_4}
                  style={{ width: '30%', top: -10, marginLeft: 30 }}
                  onChange={(value) =>
                    { handleSelectedValueRadio(
                      value.target.name,
                      value.target.value
                    ); }
                  }
                />
              ) : (
                <></>
              )}
            </M.FormGroup>

            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Cancêr
            </M.FormLabel>
            <M.FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <M.RadioGroup
                value={String(value?.Cancer)}
                name="Cancer"
                style={{ flexDirection: 'row' }}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  label="Sim"
                  control={<M.Radio />}
                />
                <M.FormControlLabel
                  value="Não"
                  label="Não"
                  control={<M.Radio />}
                />
              </M.RadioGroup>

              {value.Cancer === 'Sim' ? (
                <M.TextField
                  label="Parentesco"
                  variant="outlined"
                  name="Parentesco_5"
                  value={value?.Parentesco_5}
                  style={{ width: '30%', top: -10, marginLeft: 30 }}
                  onChange={(value) =>
                    { handleSelectedValueRadio(
                      value.target.name,
                      value.target.value
                    ); }
                  }
                />
              ) : (
                <></>
              )}
            </M.FormGroup>

            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Pressão Alta
            </M.FormLabel>
            <M.FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <M.RadioGroup
                value={String(value?.Pressao_Alta)}
                name="Pressao_Alta"
                style={{ flexDirection: 'row' }}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  label="Sim"
                  control={<M.Radio />}
                />
                <M.FormControlLabel
                  value="Não"
                  label="Não"
                  control={<M.Radio />}
                />
              </M.RadioGroup>

              {value?.Pressao_Alta === 'Sim' ? (
                <M.TextField
                  label="Parentesco"
                  variant="outlined"
                  name="Parentesco_6"
                  value={value?.Parentesco_6}
                  style={{ width: '30%', top: -10, marginLeft: 30 }}
                  onChange={(value) =>
                    { handleSelectedValueRadio(
                      value.target.name,
                      value.target.value
                    ); }
                  }
                />
              ) : (
                <></>
              )}
            </M.FormGroup>

            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Alergias
            </M.FormLabel>
            <M.FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <M.RadioGroup
                value={String(value?.Alergias)}
                name="Alergias"
                style={{ flexDirection: 'row' }}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  label="Sim"
                  control={<M.Radio />}
                />
                <M.FormControlLabel
                  value="Não"
                  label="Não"
                  control={<M.Radio />}
                />
              </M.RadioGroup>
              {value?.Alergias === 'Sim' ? (
                <M.TextField
                  label="Parentesco"
                  variant="outlined"
                  name="Parentesco_7"
                  value={value?.Parentesco_7}
                  style={{ width: '30%', top: -10, marginLeft: 30 }}
                  onChange={(value) =>
                    { handleSelectedValueRadio(
                      value.target.name,
                      value.target.value
                    ); }
                  }
                />
              ) : (
                <></>
              )}
            </M.FormGroup>

            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Outras:
            </M.FormLabel>
            <M.FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <M.RadioGroup
                value={String(value?.Outras)}
                name="Outras"
                style={{ flexDirection: 'row' }}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  label="Sim"
                  control={<M.Radio />}
                />
                <M.FormControlLabel
                  value="Não"
                  label="Não"
                  control={<M.Radio />}
                />
              </M.RadioGroup>

              {value?.Outras === 'Sim' ? (
                <>
                  <M.TextField
                    label="Parentesco"
                    variant="outlined"
                    name="Parentesco_8"
                    value={value?.Parentesco_8}
                    style={{ width: '30%', top: -10, marginLeft: 30 }}
                    onChange={(value) =>
                      { handleSelectedValueRadio(
                        value.target.name,
                        value.target.value
                      ); }
                    }
                  />
                  <M.TextField
                    label="Nome da doença"
                    variant="outlined"
                    name="Nome_doenca"
                    value={value?.Nome_doenca}
                    style={{ width: '30%', top: -10, marginLeft: 30 }}
                    onChange={(value) =>
                      { handleSelectedValueRadio(
                        value.target.name,
                        value.target.value
                      ); }
                    }
                  />
                </>
              ) : (
                <></>
              )}
            </M.FormGroup>
          </M.List>

          <M.Typography
            variant="h5"
            gutterBottom
            style={{ textAlign: 'center', fontWeight: 'bold' }}
          >
            Antecendentes Pessoais
          </M.Typography>

          <M.Typography variant="subtitle2" gutterBottom>
            Sofreu ou sofre alguma doença ou situação abaixo?Sua esposa ou
            qualquer um de seus familiares (pai, mãe, irmãos, mesmos que
            falecidos) tem ou tiveram algumas das doenças abaixo?
          </M.Typography>

          <M.List>
            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Doenças do coração - Pressão Alta
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Doencas_do_coracao_Pressoao_Alta)}
                name="Doencas_do_coracao_Pressoao_Alta"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Varizes
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                name="Varizes"
                value={String(value?.Varizes)}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Bronquite, Asma, Rinite
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                name="Bronquite_Asma_Rinite"
                value={String(value?.Bronquite_Asma_Rinite)}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Hemorroidas
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                name="Hemorroidas"
                value={String(value?.Hemorroidas)}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Doenças renais
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Doencas_renais)}
                name="Doencas_renais"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Hérnias
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Hernias)}
                name="Hernias"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />
            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Doenças do fígado
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Doencas_do_figado)}
                name="Doencas_do_figado"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Alguma defciência física
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Alguma_deficiencia_fisica)}
                name="Alguma_deficiencia_fisica"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Diabetes
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Diabetes_2)}
                name="Diabetes_2"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Já foi submetido a cirurgia
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Ja_foi_submetido_a_cirurgia)}
                name="Ja_foi_submetido_a_cirurgia"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Doenças do estômago
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Doencas_do_estomago)}
                name="Doencas_do_estomago"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Já esteve internado?
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Ja_esteve_internado)}
                name="Ja_esteve_internado"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Resfriados frequentes, Sinusite
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Resfriados_frequentes_Sinusite)}
                name="Resfriados_frequentes_Sinusite"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Difculdade em tarefa pesada
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Dificuldade_em_tarefa_pesada)}
                name="Dificuldade_em_tarefa_pesada"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Problemas de visão
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Problemas_de_visao)}
                name="Problemas_de_visao"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Tabagismo
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Tabagismo)}
                name="Tabagismo"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />
            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Problemas de audição
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Problemas_de_audicao)}
                name="Problemas_de_audicao"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Etilismo(Bebe)
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Etilismo_bebe)}
                name="Etilismo_bebe"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />
            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Otite crônica, Zumbido no ouvido
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Otite_cronica_Zumbido_no_ouvido)}
                name="Otite_cronica_Zumbido_no_ouvido"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Se considera acima do peso
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                name="Se_considera_acima_do_peso"
                value={String(value?.Se_considera_acima_do_peso)}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Dor de cabeça, Tontura, Convulsões
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Dor_de_cabeca_Tontura_Convulsoes)}
                name="Dor_de_cabeca_Tontura_Convulsoes"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />
            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Sofreu ou sofre de insônia
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Sofreu_ou_sofre_de_insonia)}
                name="Sofreu_ou_sofre_de_insonia"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Doenças de pele
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Doencas_de_pele)}
                name="Doencas_de_pele"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Se considera estressado
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Se_considera_estressado)}
                name="Se_considera_estressado"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Dores nas costas
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Dores_nas_costas)}
                name="Dores_nas_costas"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Pratica esporte regularmente
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Pratica_esporte_regularmente)}
                name="Pratica_esporte_regularmente"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Reumatismo, Dor nas juntas
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Reumatismo_Dor_nas_juntas)}
                name="Reumatismo_Dor_nas_juntas"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Faz uso de medicamento
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Faz_uso_de_medicamento)}
                name="Faz_uso_de_medicamento"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Sofreu alguma fratura óssea
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Sofreu_alguma_fratura_ossea)}
                name="Sofreu_alguma_fratura_ossea"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Outra doença
            </M.FormLabel>
            <M.FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Outra_doenca)}
                name="Outra_doenca"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
              {value?.Outra_doenca === 'Sim' ? (
                <>
                  <M.TextField
                    label="Nome da doença"
                    variant="outlined"
                    name="Nome_doenca_2"
                    value={value?.Nome_doenca_2}
                    style={{ width: '30%', top: -10, marginLeft: 30 }}
                    onChange={(value) =>
                      { handleSelectedValueRadio(
                        value.target.name,
                        value.target.value
                      ); }
                    }
                  />
                </>
              ) : (
                <></>
              )}
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Doença psiquiátrica
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Doenca_psiquiatrica)}
                name="Doenca_psiquiatrica"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Afastado por mais de 15 dias
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Afastado_por_mais_de_15_dias)}
                name="Afastado_por_mais_de_15_dias"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />
          </M.List>

          {genero && genero.slice()[0] === 'F' ? (
            <M.TextField
              type="date"
              name="Data_da_ultima_mestruacao_caso_Sexo_Feminino"
              label="Data da última menstruação"
              variant="outlined"
              onChange={(value) =>
                { handleSelectedValueRadio(
                  'Data_da_ultima_mestruacao_caso_Sexo_Feminino',
                  value.target.value
                ); }
              }
              style={{ width: '30%', marginTop: 20, marginBottom: 20 }}
              InputLabelProps={{
                shrink: true
              }}
            />
          ) : (
            <></>
          )}

          <M.Typography
            variant="h5"
            gutterBottom
            style={{ textAlign: 'center', fontWeight: 'bold' }}
          >
            Antecedentes Ocupacionais
          </M.Typography>

          <M.Typography variant="subtitle2" gutterBottom>
            Sofreu ou sofre alguma doença ou situação abaixo?
          </M.Typography>

          <M.List>
            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Já sofreu acidente do trabalho
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Ja_sofreu_acidente_trabalho)}
                name="Ja_sofreu_acidente_trabalho"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Recebeu indenização por acidente do trabalho
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Recebeu_indenizacao_acidente_trabalho)}
                name="Recebeu_indenizacao_acidente_trabalho"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Já apresentou alguma doença do trabalho ou profssional
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(
                  value?.Ja_apresentou_alguma_doenca_trabalho_profssional
                )}
                name="Ja_apresentou_alguma_doenca_trabalho_profssional"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Já se submeteu a exame complementar anterior
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(
                  value?.Ja_se_submeteu_exame_complementar_anterior
                )}
                name="Ja_se_submeteu_exame_complementar_anterior"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Já apresentou afastamento superior a 15 dias por doença dotrabalho
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(
                  value?.Ja_apresentou_afastamento_superior_a_15_dias
                )}
                name="Ja_apresentou_afastamento_superior_a_15_dias"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Suas condições de saúde exigem atenção especial
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Suas_condicoes_de_saude_exigem)}
                name="Suas_condicoes_de_saude_exigem"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Já exerceu atividade com uso de EPI
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Ja_exerceu_atividade_com_uso_de_EPI)}
                name="Ja_exerceu_atividade_com_uso_de_EPI"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />

            <M.FormLabel component="legend" style={{ marginTop: 20 }}>
              Necessita de uso de EPI em suas atividades
            </M.FormLabel>
            <M.FormGroup>
              <M.RadioGroup
                style={{ flexDirection: 'row' }}
                value={String(value?.Necessita_de_uso_de_EPI_em_suas)}
                name="Necessita_de_uso_de_EPI_em_suas"
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
              >
                <M.FormControlLabel
                  value="Sim"
                  control={<M.Radio />}
                  label="Sim"
                />
                <M.FormControlLabel
                  value="Não"
                  control={<M.Radio />}
                  label="Não"
                />
              </M.RadioGroup>
            </M.FormGroup>
            <M.Divider component="li" />
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <M.TextField
                minRows={4}
                label="Informar qualquer informação que achar pertinente para o médico avaliar (doenças, comportamentos ou tratamentos que realiza)."
                name="observacoes"
                multiline
                variant="outlined"
                value={value.observacoes}
                onChange={(value) =>
                  { handleSelectedValueRadio(
                    value.target.name,
                    value.target.value
                  ); }
                }
                fullWidth
              />
            </div>
          </M.List>
        </M.Paper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
            justifyContent: 'flex-end',
            marginBottom: 30
          }}
        >
          <M.Button variant="outlined" color="primary" onClick={fetchSendKit}>
            Salvar
          </M.Button>
        </div>
      </M.FormGroup>
    </M.Container>
  );
}
