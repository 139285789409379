import React, { useState, useEffect, useCallback } from 'react';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import * as M from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { Publish, GetApp, Description, Create } from '@material-ui/icons';
import { api } from '@services/api';
import { Autocomplete } from '@material-ui/lab';
import {
  maskCPF,
  getFileName,
  sendFileWithoutFake,
  verifyUpLoads
} from '@utils/masks';
import { Guia } from '../../PDF/gerarGuia';
import { PDF } from '../../PDF/gerarPdf';
import FormData from 'form-data';
import { type AxiosResponse } from 'axios';
import { ASO } from '@data/tipoAso';
import { status } from '@data/status';
import { type KitProps } from '@dtos/kitDTO';
import { FichaClinica } from '@pages/Agendamentos/PDF/fichaClinica';
import { AppError } from '@utils/AppError';
import { useQuery } from '@tanstack/react-query';
import { LoadingPage } from '@components/LoadingPage';
import { getDataScheduling } from '@api/get-data-schedule';
import { Button } from '@components/Button';
import { LabelUpload } from './input';
import { Check, Upload } from '@phosphor-icons/react';

export interface INomeComercial {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  razao_social: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  telemedicina: string;
  observacao: string;
}

export interface ScheduleProps {
  id_agendamento: number;
  num_agenda: string;
  nome: string;
  cpf_colaborador: string;
  tipo_agendamento: string;
  cpf_cnpj_comer: string;
  nome_comercial: string;
  cidade: string;
  estado: string;
  data_agendamento: string;
  ordem_chegada: string;
  horario: string;
  tipo_aso: string;
  observacao: string;
  status: string;
  usuario: string;
  exibir: string;
  linkDoatendimento: string;
  arquivo_prontuario: string;
  arquivo_aso: string;
  id_colaboradores: number;
  matricula_empresa: string;
  matricula_esocial: string;
  nome_completo: string;
  rg: string;
  data_nascimento: string;
  genero: string;
  cnh: string;
  email: string;
  telefone: string;
  situacao: string;
  nis: string;
  funcao: string;
  setor: string;
  turno: string;
  cnpj: string;
  razao_social: string;
  data_admissao: string;
  horario_trab_entrada: string;
  horario_trab_saida: string;
  local_obra: string;
  cod_ghe: string;
  ghe: string;
}

const ButtonAdd = M.withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B'
    }
  }
}))(M.Button);
const ButtonSair = M.withStyles(() => ({
  root: {
    background: 'red',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'red',
      color: 'red',
      border: 'solid 1px red'
    }
  }
}))(M.Button);

//= =====================================================

const ButtonDownloadGuia = M.withStyles(() => ({
  root: {
    background: '#6a82fb',
    color: '#FFF',
    border: 'none',
    width: 180,
    height: 40,
    '&:hover': {
      background: '#FFF',
      borderColor: '#6a82fb',
      color: '#6a82fb',
      border: 'solid 1px #6a82fb',
      width: 180,
      height: 40
    }
  }
}))(M.Button);

const PAGE = 'telemedicina';

export const EditItsTelemedicina: React.FC<any> = (props) => {
  //console.log(props);
  const { id } = useParams<{ id: string }>();
  const { id_agendamento } = props.location.state;

  const [listNomeComercial, setListNomeComercial] = useState<INomeComercial[]>(
    []
  );
  const [selectedNomeComercial, setSelectedNomeComercial] = useState<any>();
  const [searchEmail, setSearchEmail] = useState<any>();
  const [emailAgendamento, setEmailAgendamento] = useState([]);

  const [arquivoSendASO, setArquivoSendASO] = useState<File | string>('');
  const [viewFileASO, setViewFileASO] = useState<object | string | null>('');
  const [arquivoASO, setArquivoASO] = useState<File | string>('');

  const [arquivoSendProntuario, setArquivoSendProntuario] = useState<
    File | string
  >('');
  const [viewFileProntuario, setViewFileProntuario] = useState<
    object | string | null
  >('');
  const [arquivoProntuario, setArquivoProntuario] = useState<File | string>('');

  const [statusAgendamento, setStatusAgendameto] = useState<string>('');
  const [cpfColab, setCpfColab] = useState<string>('');
  const [nomeColab, setNomeColab] = useState<string>('');
  const [estado, setEstado] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');
  const [dataAgendamento, setDataAgendamento] = useState<string>('');
  const [horarioAgendamento, setHorarioAgendamento] = useState<string>('');
  const [tipoAso, setTipoAso] = useState<string>('');
  const [obs, setObs] = useState<string>('');
  const [cnpjComercial, setCnpjComercial] = useState<string>('');

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color>();
  const [linkDoAtendimento, setLinkDoAtendimento] = useState('');
  const [editLink, setEditLink] = useState(false);
  const nome_user = localStorage.getItem('NameUser') || '';

  async function sendAgendamento() {
    const hora = new Date();
    const newHora = hora.toLocaleString('pt-BR', { hour12: false });

    const data = {
      status: statusAgendamento,
      observacao: obs,
      tipo_aso: tipoAso,
      horario: horarioAgendamento,
      data_agendamento: dataAgendamento,
      estado,
      cidade,
      nome_comercial: selectedNomeComercial || getDataSchedule?.nome_comercial,
      tipo_agendamento: getDataSchedule?.tipo_agendamento,
      nome: nomeColab,
      cpf_colaborador: cpfColab,
      cpf_cnpj_comer: cnpjComercial,
      usuario: `${nome_user} ${newHora}`,
      email_agendamento: emailAgendamento,
      arquivo_aso: arquivoASO || getDataSchedule?.arquivo_aso,
      arquivo_prontuario:
        arquivoProntuario || getDataSchedule?.arquivo_prontuario,
      num_agenda: getDataSchedule?.num_agenda,
      linkDoatendimento: linkDoAtendimento,
      ordem_chegada: ''
    };
    //console.log(data);
    await api
      .post(`/ravim/atualizar-agendamento`, data)
      .then((res) => {
        if (res.status === 200) {
          setCod(String(res.status));
          setSnack(true);
          setSeverity('success');
          setMessage(res.data.message);
          setTimeout(() => {
            window.location.replace('/agendamentos');
          }, 2000);
        } else {
          setCod(String(res.status));
          setSnack(true);
          setSeverity('error');
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setCod(err.code);
        setSnack(true);
        setSeverity('error');
        setMessage(err.message);
      });

    const formData = new FormData();
    formData.append('arquivo1', arquivoSendProntuario);
    formData.append('arquivo2', arquivoSendASO);

    await api
      .post(`/file`, formData)
      .then((res: AxiosResponse) => {
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  const {
    data: getDataSchedule,
    error: errorDataSchedule,
    isFetching
  } = useQuery({
    queryKey: ['getSchedule'],
    queryFn: () => getDataScheduling(id_agendamento)
  });

  async function reqStatus() {
    const data = {
      tipo_agendamento: getDataSchedule?.tipo_agendamento,
      tipo: getDataSchedule?.tipo_agendamento
    };
    await api
      .post(`/ravim/telemed-clinicas`, data)
      .then((res) => {
        if (res.status === 200) {
          setListNomeComercial(res.data);

          res.data.map((a: INomeComercial) => {
            if (a.nome_comercial === getDataSchedule?.nome_comercial) {
              setCnpjComercial(a.cpf_cnpj);
              setSearchEmail(a.cpf_cnpj);
              setEstado(a.cidade);
              setCidade(a.estado);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const fetchFichaClinica = useCallback(
    async (cpf_colaborador: string, cpf_cnpj: string) => {
      const data = {
        cpf_colaborador,
        cnpj_cpf_comer: cpf_cnpj
      };
      try {
        const res = await api.post(`/ravim/colab-kit-salvo`, data);
        if (res.data) {
          FichaClinica(res.data);
        }
      } catch (error) {
        if (error instanceof AppError) {
          setCod(String(error.code));
          setSnack(true);
          setSeverity('error');
          setMessage(error.message);
        }
      }
    },
    []
  );

  const reqpdf = useCallback(
    async (
      tipo: number,
      cpf_colaborador: string,
      cpf_cnpj_comer: string,
      num_agenda: string
    ) => {
      try {
        const res = await api.post(`/ravim/pdf`, {
          cpf_colaborador,
          cnpj_cpf_comer: cpf_cnpj_comer,
          num_agenda
        });

        if (tipo === 1) {
          Guia(res.data, PAGE);
        } else {
          PDF(res.data, '');
        }
      } catch (error) {
        if (error instanceof AppError) {
          console.log(error);
        }
      }
    },
    []
  );

  // -${btoa(Date.now().toString())}
  const changeFileASO = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setArquivoASO(sendFileWithoutFake(event.target.value));
      setArquivoSendASO(file);

      // console.log(event.target.files[0])

      setViewFileASO(URL.createObjectURL(event.target.files[0]));
    } else {
      console.log('cancelado');
    }
  };

  async function downloadFile(arquivo: string) {
    const data = {
      arquivo
    };

    await api
      .post(`/ravim/download`, data)
      .then((res) => {
        const href = res.data.dados;
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', arquivo.replace(/[_]/g, ' '));
        link.download.replace(/[_]/g, ' ');

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  const changeFileProntuario = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setArquivoProntuario(sendFileWithoutFake(event.target.value));
      setArquivoSendProntuario(event.target.files[0]);

      // console.log(event.target.files[0])

      setViewFileProntuario(URL.createObjectURL(event.target.files[0]));
    } else {
      console.log('cancelado');
    }
  };

  useEffect(() => {
    setStatusAgendameto(getDataSchedule?.status ?? '');
    setCpfColab(getDataSchedule?.cpf_colaborador ?? '');
    setNomeColab(getDataSchedule?.nome ?? '');
    setDataAgendamento(getDataSchedule?.data_agendamento ?? '');
    setHorarioAgendamento(getDataSchedule?.horario ?? '');
    setTipoAso(getDataSchedule?.tipo_aso ?? '');
    setObs(getDataSchedule?.observacao ?? '');
  }, [isFetching]);

  useEffect(() => {
    reqStatus();
  }, [isFetching]);

  function handleData(event: INomeComercial) {
    setSearchEmail(event.cpf_cnpj);
    setSelectedNomeComercial(event.nome_comercial);
    setCnpjComercial(event.cpf_cnpj);
    setEstado(event.cidade);
    setCidade(event.estado);
  }

  if (!getDataSchedule) return;

  return (
    <M.Container>
      <LoadingPage open={isFetching} onClick={() => {}} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 20,
          gap: 20
        }}
      >
        {verifyUpLoads(getDataSchedule?.arquivo_aso) !== '' ? (
          <Button
            title="Download ASO"
            icon={<GetApp />}
            onClick={async () => {
              await downloadFile(
                getFileName(String(getDataSchedule?.arquivo_aso))
              );
            }}
          />
        ) : (
          <></>
        )}
        {verifyUpLoads(getDataSchedule?.arquivo_prontuario) !== '' ? (
          <Button
            title="Download Prontuário"
            icon={<GetApp />}
            onClick={async () => {
              await downloadFile(
                getFileName(String(getDataSchedule?.arquivo_prontuario))
              );
            }}
          />
        ) : (
          <></>
        )}

        <Button
          icon={<GetApp style={{ fontSize: 20 }} />}
          onClick={() => {
            fetchFichaClinica(
              getDataSchedule.cpf_colaborador,
              getDataSchedule.cpf_cnpj_comer
            );
          }}
          title="Download KIT - Ficha clínica"
          variantstyle="PURPLE"
        />

        <Button
          title="Download KIT - ASO"
          icon={<GetApp style={{ fontSize: 20 }} />}
          variantstyle="PURPLE"
          onClick={() =>
            reqpdf(
              2,
              getDataSchedule?.cpf_colaborador,
              getDataSchedule?.cpf_cnpj_comer,
              getDataSchedule?.num_agenda
            )
          }
        />

        <Button
          title="Download Guia"
          icon={<GetApp style={{ fontSize: 20 }} />}
          variantstyle="DARK_PINK"
          onClick={() => {
            reqpdf(
              1,
              getDataSchedule?.cpf_colaborador,
              getDataSchedule?.cpf_cnpj_comer,
              getDataSchedule?.num_agenda
            );
          }}
        />
      </div>
      <M.Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Editar Agendamento por Telemedicina
      </M.Typography>
      <M.Paper style={{ padding: 40 }}>
        <M.Grid container spacing={1}>
          <M.Grid item xs>
            <M.Typography
              variant="h5"
              style={{ fontWeight: 'bold', margin: 10 }}
            >
              Uploads
            </M.Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              {/* <input
                id="arquivoProntuario"
                name="fileProntuario"
                type="file"
                onChange={changeFileProntuario}
                style={{ display: 'none' }}
              />
              <label htmlFor="arquivoProntuario">
                <Button title="Upload-Prontuário" variantstyle="LIGHT_PINK" />
                <M.Typography>{String(arquivoProntuario)}</M.Typography>
              </label>
              <input
                id="arquivoASO"
                name="fileASO"
                type="file"
                onChange={changeFileASO}
                style={{ display: 'none' }}
              />
              <label htmlFor="arquivoASO">
                <Button title="Upload - ASO" variantstyle="DARK_PINK" />
                <M.Typography>{String(arquivoASO)}</M.Typography>
              </label> */}
              <LabelUpload
                icon={
                  String(arquivoASO) ? (
                    <Check size={24} />
                  ) : (
                    <Upload size={24} />
                  )
                }
                title="Upload - ASO"
                variantstyle="DARK_PINK"
                htmlFor="arquivoASO"
                onChange={changeFileASO}
              />
              <LabelUpload
                title="Upload-Prontuário"
                variantstyle="LIGHT_PINK"
                htmlFor="arquivoProntuario"
                icon={
                  String(arquivoProntuario) ? (
                    <Check size={24} />
                  ) : (
                    <Upload size={24} />
                  )
                }
                onChange={changeFileProntuario}
              />
            </div>
            <M.Typography
              variant="h5"
              style={{ fontWeight: 'bold', margin: 10 }}
            >
              Status
            </M.Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <M.FormControl variant="outlined" style={{ width: '20%' }}>
                <M.InputLabel>Status</M.InputLabel>
                <M.Select
                  label="Status"
                  value={statusAgendamento}
                  onChange={(e: any) => {
                    setStatusAgendameto(e.target.value);
                  }}
                >
                  {status.map((status, index) => (
                    <M.MenuItem key={index} value={status.value}>
                      {status.value}
                    </M.MenuItem>
                  ))}
                </M.Select>
              </M.FormControl>
            </div>
            <M.Typography
              variant="h5"
              style={{ fontWeight: 'bold', margin: 10 }}
            >
              Colaborador
            </M.Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <M.TextField
                label="CPF"
                disabled
                variant="outlined"
                fullWidth
                value={cpfColab}
                onChange={(e) => {
                  setCpfColab(maskCPF(e.target.value));
                }}
              />
              <M.TextField
                label="Nome"
                disabled
                variant="outlined"
                fullWidth
                value={nomeColab}
                onChange={(e) => {
                  setNomeColab(e.target.value);
                }}
              />
            </div>
            <M.Typography
              variant="h5"
              style={{ fontWeight: 'bold', margin: 10 }}
            >
              Atendimento por Telemedicina
            </M.Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <Autocomplete
                defaultValue={{
                  nome_comercial: getDataSchedule.nome_comercial,
                  value: getDataSchedule.nome_comercial
                }}
                fullWidth
                id="tags-outlined"
                options={listNomeComercial}
                getOptionLabel={(option) => option.nome_comercial}
                onChange={(e, newValue) => {
                  handleData(newValue);
                }}
                value={selectedNomeComercial}
                filterSelectedOptions
                renderInput={(params) => (
                  <M.TextField
                    {...params}
                    variant="outlined"
                    label="Nome Comercial"
                    placeholder="Nome Comercial"
                  />
                )}
              />
            </div>

            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <M.TextField
                label="Data do agendamento"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                value={dataAgendamento}
                onChange={(e) => {
                  setDataAgendamento(e.target.value);
                }}
              />
              <M.TextField
                label="Horário"
                type="time"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                value={horarioAgendamento}
                onChange={(e) => {
                  setHorarioAgendamento(e.target.value);
                }}
              />
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <M.FormControl variant="outlined" style={{ width: '20%' }}>
                <M.InputLabel>Tipo de ASO</M.InputLabel>
                <M.Select
                  label="Tipo de ASO"
                  value={tipoAso}
                  onChange={(e: any) => {
                    setTipoAso(e.target.value);
                  }}
                >
                  {ASO.map((aso, index) => (
                    <M.MenuItem key={index} value={aso.value}>
                      {aso.value}
                    </M.MenuItem>
                  ))}
                </M.Select>
              </M.FormControl>

              {linkDoAtendimento !== '' ? (
                <div
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    gap: 10
                  }}
                >
                  {editLink ? (
                    <M.TextField
                      variant="outlined"
                      value={linkDoAtendimento}
                      onChange={(value) => {
                        setLinkDoAtendimento(value.target.value);
                      }}
                    />
                  ) : (
                    <M.Link href={linkDoAtendimento} target="_blank">
                      <M.Typography>{linkDoAtendimento}</M.Typography>
                    </M.Link>
                  )}
                  <ButtonDownloadGuia
                    variant="outlined"
                    onClick={() => {
                      setEditLink(!editLink);
                    }}
                    style={{ width: 'auto' }}
                  >
                    {editLink ? <Check /> : <Create />}
                  </ButtonDownloadGuia>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <M.TextField
                minRows={4}
                label="OBS"
                multiline
                variant="outlined"
                value={obs}
                onChange={(e) => {
                  setObs(e.target.value);
                }}
                fullWidth
              />
            </div>
          </M.Grid>
        </M.Grid>
      </M.Paper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          marginTop: 20
        }}
      >
        <Link to={'/agendamentos'} style={{ textDecoration: 'none' }}>
          <ButtonSair variant="outlined">Sair</ButtonSair>
        </Link>
        <ButtonAdd variant="outlined" onClick={sendAgendamento}>
          Salvar
        </ButtonAdd>
      </div>
      <M.Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </M.Snackbar>
    </M.Container>
  );
};
