import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import MuiAlert, { AlertProps, type Color } from '@material-ui/lab/Alert';
import { Link, useParams } from 'react-router-dom';

import { api } from '../../../../services/api';
import { Autocomplete } from '@material-ui/lab';
import { Add, Check, Delete, GetApp, Publish } from '@material-ui/icons';
import { type AxiosResponse } from 'axios';
import { LoadingPage } from '@components/LoadingPage';
import { getFileName, sendFileWithoutFake } from '@utils/masks';
import { ASO } from '@data/tipoAso';
import { useQuery } from '@tanstack/react-query';
import { getDataScheduling } from '@api/get-data-schedule';

interface IExames {
  id_exames: string;
  nome: string;
  valor: string;
}

interface ICep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface IColaborador {
  id_colaboradores: number;
  matricula_empresa: string;
  matricula_esocial: any;
  nome_completo: string;
  cpf_colaborador: string;
  rg: string;
  data_nascimento: string;
  genero: string;
  cnh: string;
  email: string;
  telefone: string;
  situacao: string;
  nis: string;
  funcao: string;
  setor: string;
  turno: string;
  cnpj: string;
  razao_social: string;
  data_admissao: string;
  horario_trab_entrada: string;
  horario_trab_saida: string;
  local_obra: string;
  cod_ghe: any;
  ghe: string;
}
interface INomeComercial {
  id_clinicas_credenciadas: number;
  contrato: string;
  nome_comercial: string;
  razao_social: string;
  cpf_cnpj: string;
  dias_funcionamento: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  nome_med: string;
  crm: string;
  crm_estado: string;
  trabalho_agendamento: string;
  pagamento_adiantado: string;
  tipo_agendamento: string;
  observacao: string;
}

interface IReceivedExames {
  exame_credenciado: string;
  exame_empresa: string;
  id_exame_colab: number;
  valor_credenciado: string;
  valor_empresa: string;
}

const ButtonDownloadAso = withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    width: 'auto',
    height: 40,
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B',
      width: 'auto',
      height: 40
    }
  }
}))(Button);

const ButtonAdd = withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B'
    }
  }
}))(Button);

const ButtonSair = withStyles(() => ({
  root: {
    background: 'red',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'red',
      color: 'red',
      border: 'solid 1px red'
    }
  }
}))(Button);

const ButtonDownloadProntuario = withStyles(() => ({
  root: {
    background: '#0D9C5B',
    color: '#FFF',
    border: 'none',
    width: 'auto',
    height: 40,
    '&:hover': {
      background: '#FFF',
      borderColor: '#0D9C5B',
      color: '#0D9C5B',
      border: 'solid 1px #0D9C5B',
      width: 'auto',
      height: 40
    }
  }
}))(Button);

const AddExames = withStyles(() => ({
  root: {
    background: 'green',
    color: '#FFF',
    border: 'none',
    '&:hover': {
      background: '#FFF',
      borderColor: 'green',
      color: 'green',
      border: 'solid 1px red'
    }
  }
}))(Button);

export const EditAgendamentoExterno: React.FC<any> = (props) => {
  // console.log(props)
  const { id } = useParams<{ id: string }>();
  const {id_agendamento} = props.location.state;
  const [listNomeComercial, setListNomeComercial] = useState<INomeComercial[]>(
    []
  );
  const [selectedNomeComercial, setSelectedNomeComercial] = useState<any>();
  const [newValuesExames, setNewValuesExames] = useState<IReceivedExames[]>([]);

  const [cpfColab, setCpfColab] = useState<string>('');
  const [nomeColab, setNomeColab] = useState<string>('');
  const [dataAgendamento, setDataAgendamento] = useState<string>('');
  const [tipoAso, setTipoAso] = useState<string>('');
  const [comboColab, setComboColab] = useState<IColaborador[]>([]);
  const [loading, setLoading] = useState(false);

  const [snack, setSnack] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState<number | null>(null);
  const [severity, setSeverity] = useState<Color>();
  const nome_user = localStorage.getItem('NameUser') || '';

  const [valor, setValor] = useState<number | null>(null);
  const [novoValor, setNovoValor] = useState<object[]>([]);
  const [campoValor, setCampoValor] = useState<object[]>([]);
  const [dialog, setDialog] = useState<boolean>(false);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [singleExame, setSingleExame] = useState<IExames | null>(null);
  const [valorTotal, setValorTotal] = useState<number>(0);
  const [newData, setNewData] = useState([]);

  const [arquivoProntuario, setArquivoProntuario] = useState<File | string>('');
  const [arquivoSendProntuario, setArquivoSendProntuario] = useState<
    File | string
  >('');
  const [viewFileProntuario, setViewFileProntuario] = useState<
    object | string | null
  >('');

  const [arquivoASO, setArquivoASO] = useState<File | string>('');
  const [arquivoSendASO, setArquivoSendASO] = useState<File | string>('');
  const [viewFileASO, setViewFileASO] = useState<object | string | null>('');

  const ButtonUploadProntuario = withStyles(() => ({
    root: {
      background: getDataSchedule?.arquivo_prontuario ? '#08681d' : '#d46398',
      color: '#FFF',
      border: 'none',
      width: 'auto',
      height: 40,
      '&:hover': {
        background: '#FFF',
        borderColor: getDataSchedule?.arquivo_prontuario
          ? '#08681d'
          : '#d46398',
        color: getDataSchedule?.arquivo_prontuario ? '#08681d' : '#d46398',
        border: `solid 1px ${
          getDataSchedule?.arquivo_prontuario ? '#08681d' : '#d46398'
        }`,
        width: 'auto',
        height: 40
      }
    }
  }))(({ classes, ...props }: { classes: Record<string, string> }) => (
    <Button
      {...props}
      classes={classes}
      component="span"
      startIcon={getDataSchedule?.arquivo_prontuario ? <Check /> : <Publish />}
    />
  ));

  const ButtonUploadAso = withStyles(() => ({
    root: {
      background: !!getDataSchedule?.arquivo_aso ? '#08681d' : '#e95779',
      color: '#FFF',
      border: 'none',
      width: 'auto',
      height: 40,
      '&:hover': {
        background: '#FFF',
        borderColor: !!getDataSchedule?.arquivo_aso ? '#08681d' : '#e95779',
        color: !!getDataSchedule?.arquivo_aso ? '#08681d' : '#e95779',
        border: `solid 1px ${
          !!getDataSchedule?.arquivo_aso ? '#08681d' : '#e95779'
        }`,
        width: 'auto',
        height: 40
      }
    }
  }))(({ classes, ...props }: { classes: Record<string, string> }) => (
    <Button
      {...props}
      classes={classes}
      component="span"
      startIcon={getDataSchedule?.arquivo_aso ? <Check /> : <Publish />}
    />
  ));

  async function sendAgendamento() {
    if (campoValor.length <= 0) {
      setCod(400);
      setSnack(true);
      setSeverity('warning');
      setMessage('Adicione um exame');
    } else {
      const hora = new Date();

      const newHora = hora.toLocaleString('pt-BR', { hour12: false });
      const data = {
        tipo_aso: tipoAso,
        data_agendamento: dataAgendamento,
        tipo_agendamento: getDataSchedule?.tipo_agendamento,
        nome: nomeColab,
        cpf_colaborador: cpfColab,
        num_agenda: getDataSchedule?.num_agenda,
        usuario: `${nome_user} ${newHora}`,
        exame: campoValor,
        arquivo_aso: arquivoASO || getDataSchedule?.arquivo_aso,
        arquivo_prontuario: arquivoProntuario || arquivoProntuario
        // email_agendamento: emailAgendamento,
      };
      //console.log(data);

      await api
        .post(`/ravim/atua-agendamento-externo`, data)
        .then((res) => {
          if (res.status === 200) {
            setCod(res.status);
            setSnack(true);
            setSeverity('success');
            setMessage(res.data.message);
            setTimeout(() => {
              window.location.replace('/agendamentos');
            }, 1000);
          } else {
            setCod(res.status);
            setSnack(true);
            setSeverity('error');
            setMessage(res.data.message);
          }
        })
        .catch((err) => {
          setCod(err.code);
          setSnack(true);
          setSeverity('error');
          setMessage(err.message);
        });

      const formData = new FormData();
      formData.append('arquivo1', arquivoSendProntuario);
      formData.append('arquivo2', arquivoSendASO);

      await api
        .post(`/file`, formData)
        .then((res: AxiosResponse) => {
          // console.log(res.data)
        })
        .catch((err) => {
          // console.log(err)
        });
    }
  }

  const {
    data: getDataSchedule,
    error: errorDataSchedule,
    isFetching
  } = useQuery({
    queryKey: ['getSchedule'],
    queryFn: () => getDataScheduling(id_agendamento)
  });



  // ============================= CRUD - EXAMES ==================================

  function closeDialog() {
    setDialog(false);
    setDialogDelete(false);
  }

  function saveNewValor() {
    setNovoValor([...campoValor]);
    setDialog(false);
  }

  function deleteExame(id: string) {
    const newList = campoValor.filter((exame: any) => exame.id_exames !== id);

    setCampoValor(newList);
  }

  function valueExames() {
    if (singleExame === null) {
      alert('Adicione um exame');
    } else {
      const newVar = [...campoValor];
      const data = {
        id_exames: singleExame?.id_exames,
        nome: singleExame?.nome,
        valor: ''
      };
      newVar.push(data);
      setCampoValor(newVar);

      const verifyValues = newVar.map((v: any) => {
        return parseFloat(v.valor);
      });
      const soma = verifyValues.reduce((soma, i) => {
        return soma + i;
      });
      setValorTotal(soma);

      setSingleExame(null);
      setValor(null);
    }
  }

  async function reqExames(cpf: string, num_agenda: string) {
    setLoading(true);
    if (!getDataSchedule) return;
    const data = {
      cpf_colaborador: cpf,
      num_agenda,
      tipo_agendamento: getDataSchedule.tipo_agendamento,
      tipo: getDataSchedule.tipo_agendamento
    };

    try {
      const res = await api.post(`/ravim/contrato-exames`, data);

      if (res.status !== 200) {
        return setNewValuesExames([]);
      }
      setNewValuesExames(res.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const newArrayExames =
    newValuesExames &&
    newValuesExames?.map((exames) => {
      return {
        id_exames: exames?.id_exame_colab,
        nome: exames?.exame_empresa,
        valor: exames?.valor_empresa
      };
    });

  function getValuesUpdated() {
    setDialog(!dialog);

    // setCampoValor(newArrayExames)
    const verifyValues =
      newArrayExames &&
      newArrayExames?.map((v: any) => {
        return parseFloat(v?.valor);
      });
    const soma = verifyValues?.reduce((soma, i) => {
      return soma + i;
    }, 0);
    setValorTotal(soma);
  }

  async function reqStatus() {
    const data = {
      tipo_agendamento: getDataSchedule?.tipo_agendamento,
      tipo: getDataSchedule?.tipo_agendamento
    };

    await api
      .post(`/ravim/telemed-clinicas`, data)
      .then((res) => {
        setListNomeComercial(res.data);
        console.log(res.data);
        if (res.status === 200 || res.status === 200) {
        } else {
          setListNomeComercial([]);
        }
      })
      .catch((err) => {
        // console.log(err.response.status)
        if (err.response.status === 500 || err.response.status !== 500) {
          setListNomeComercial([]);
        }
      });
  }

  async function dataColaborador() {
    await api
      .get(`ravim/colaboradores`)
      .then((res) => {
        if (res.status === 200) {
          setComboColab(res.data);
        } else {
          setComboColab([]);
        }

        // console.log(res.data)
      })
      .catch((err) => {
        console.log(err.estado);
      });
  }

  async function comboExames() {
    await api
      .get(`/ravim/exames`)
      .then((res) => {
        setNewData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleData(event: IColaborador) {
    setNomeColab(event.nome_completo);
    setCpfColab(event.cpf_colaborador);
  }

  const changeFileProntuario = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setArquivoProntuario(sendFileWithoutFake(event.target.value));
      setArquivoSendProntuario(event.target.files[0]);

      // console.log(event.target.files[0])

      setViewFileProntuario(URL.createObjectURL(event.target.files[0]));
    } else {
      console.log('cancelado');
    }
  };

  const changeFileASO = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setArquivoASO(sendFileWithoutFake(event.target.value));
      setArquivoSendASO(file);

      // console.log(event.target.files[0])

      setViewFileASO(URL.createObjectURL(event.target.files[0]));
    } else {
      console.log('cancelado');
    }
  };

  async function downloadFile(arquivo: string) {
    const data = {
      arquivo
    };

    api
      .post(`/ravim/download`, data)
      .then((res) => {
        const url = res.data.dados;
        const link = document.createElement('a');
        link.href = url;
        link.download = arquivo.replace(/[_]/g, ' ');
        link.dispatchEvent(new MouseEvent('click'));
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  useEffect(() => {
    setCpfColab(getDataSchedule?.cpf_colaborador ?? '');
    setNomeColab(getDataSchedule?.nome ?? '');

    setDataAgendamento(getDataSchedule?.data_agendamento ?? '');

    setTipoAso(getDataSchedule?.tipo_aso ?? '');
    reqExames(
      getDataSchedule?.cpf_colaborador ?? '',
      getDataSchedule?.num_agenda ?? ''
    );
  }, [isFetching]);

  useEffect(() => {
    dataColaborador();
  }, [nome_user]);

  useEffect(() => {
    reqStatus();
    setTimeout(() => {
      comboExames();
    }, 100);
  }, []);

  useEffect(() => {
    setCampoValor(newArrayExames);
  }, [newValuesExames]);

  if (!getDataSchedule) return;

  return (
    <Container>
      <LoadingPage
        open={loading}
        onClick={() => {
          setLoading(loading);
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 20,
          gap: 20
        }}
      >
        {getDataSchedule.arquivo_aso !== 'None' ||
        getDataSchedule.arquivo_aso !== null ? (
          <ButtonDownloadAso
            startIcon={<GetApp />}
            onClick={async () => {
              await downloadFile(
                getFileName(String(getDataSchedule.arquivo_aso))
              );
            }}
          >
            Download Resultado ASO
          </ButtonDownloadAso>
        ) : (
          <></>
        )}
        {getDataSchedule.arquivo_prontuario ? (
          <ButtonDownloadProntuario
            startIcon={<GetApp />}
            onClick={async () => {
              await downloadFile(
                getFileName(String(getDataSchedule.arquivo_prontuario))
              );
            }}
          >
            Download Relátorio Prontuário
          </ButtonDownloadProntuario>
        ) : (
          <></>
        )}
      </div>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Editar Agendamento Externo
      </Typography>
      <Paper style={{ padding: 40 }}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Upload
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <input
                id="arquivoProntuario"
                name="fileProntuario"
                type="file"
                onChange={changeFileProntuario}
                style={{ display: 'none' }}
              />
              <label htmlFor="arquivoProntuario">
                <ButtonUploadProntuario>
                  Upload - Relátorio Prontuário
                </ButtonUploadProntuario>
                <Typography>{String(arquivoProntuario)}</Typography>
              </label>
              <input
                id="arquivoASO"
                name="fileASO"
                type="file"
                onChange={changeFileASO}
                style={{ display: 'none' }}
              />
              <label htmlFor="arquivoASO">
                <ButtonUploadAso>Upload - Resultado ASO</ButtonUploadAso>
                <Typography>{String(arquivoASO)}</Typography>
              </label>
            </div>
            <Typography variant="h5" style={{ fontWeight: 'bold', margin: 10 }}>
              Colaborador
            </Typography>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <Autocomplete
                fullWidth
                disabled
                defaultValue={{
                  cpf_colaborador: getDataSchedule?.cpf_colaborador,
                  value: getDataSchedule?.cpf_colaborador
                }}
                getOptionSelected={(option, value) =>
                  option.cpf_colaborador === value.cpf_colaborador
                }
                id="tags-outlined"
                options={comboColab}
                getOptionLabel={(option) => option.cpf_colaborador}
                onChange={(e, newValue) => {
                  handleData(newValue);
                }}
                value={selectedNomeComercial}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="CPF"
                    placeholder="CPF"
                  />
                )}
              />
              <TextField
                disabled
                label="Nome"
                variant="outlined"
                fullWidth
                value={nomeColab}
                onChange={(e) => {
                  setNomeColab(e.target.value);
                }}
              />
            </div>

            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <TextField
                label="Data do agendamento"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={dataAgendamento}
                onChange={(e) => {
                  setDataAgendamento(e.target.value);
                }}
              />
              <FormControl variant="outlined" style={{ width: '20%' }}>
                <InputLabel>Tipo de ASO</InputLabel>
                <Select
                  label="Tipo de ASO"
                  value={tipoAso}
                  onChange={(e: any) => {
                    setTipoAso(e.target.value);
                  }}
                >
                  {ASO.map((aso, index) => (
                    <MenuItem key={index} value={aso.value}>
                      {aso.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: 'flex', gap: 20, margin: 15 }}>
              <FormControl
                style={{ marginRight: 20, width: '20%', marginBottom: 20 }}
              >
                <AddExames
                  style={{ height: 55 }}
                  type="button"
                  variant="outlined"
                  onClick={getValuesUpdated}
                >
                  <Add style={{ fontSize: 20 }} />
                  Adicionar Exames
                </AddExames>

                <Dialog open={dialog} onClose={closeDialog}>
                  <DialogTitle>Adicionar Exames</DialogTitle>
                  <DialogContent>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: 20
                        }}
                      >
                        <Autocomplete
                          style={{ width: 400 }}
                          onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                              valueExames();
                            }
                          }}
                          id="tags-outlined"
                          options={newData}
                          getOptionLabel={(option: IExames) =>
                            `${option.id_exames} - ${option.nome}`
                              ? `${option.id_exames}-${option.nome}`
                              : ''
                          }
                          onChange={(e, newValue: any) => {
                            setSingleExame(newValue);
                          }}
                          value={singleExame}
                          getOptionDisabled={(option) => {
                            const valueDisbled = campoValor.map(
                              (exame: any, i: any) => exame
                            );
                            return !!valueDisbled.find(
                              (element) => element.nome === option.nome
                            );
                          }}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Exames"
                              placeholder="Exames"
                            />
                          )}
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={valueExames}
                        >
                          Adicionar
                        </Button>
                      </div>
                      {campoValor &&
                        campoValor.map((valores: any, i) => (
                          <Paper
                            key={i}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: 10,
                              alignItems: 'center',
                              marginBottom: 10,
                              marginTop: 10
                            }}
                          >
                            <Typography>
                              {valores.id_exames === undefined
                                ? ''
                                : `ID: ${valores.id_exames}`}{' '}
                            </Typography>
                            <Typography>Exame: {valores.nome}</Typography>

                            <button
                              style={{
                                background: 'red',
                                color: 'white',
                                border: 0,
                                padding: 5,
                                borderRadius: 5
                              }}
                              onClick={() => {
                                deleteExame(valores.id_exames);
                              }}
                            >
                              <Delete />
                            </button>
                          </Paper>
                        ))}
                    </div>
                    {valorTotal === 0 ? (
                      ''
                    ) : (
                      <div className="textValorGeral">
                        <Typography>
                          Total de exames lançados{' '}
                          <span className="textValor">{campoValor.length}</span>
                        </Typography>
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                      Cancelar
                    </Button>
                    <Button onClick={saveNewValor} color="primary" autoFocus>
                      Salvar
                    </Button>
                  </DialogActions>
                </Dialog>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          marginTop: 20
        }}
      >
        <Link to={'/agendamentos'} style={{ textDecoration: 'none' }}>
          <ButtonSair variant="outlined">Sair</ButtonSair>
        </Link>
        <ButtonAdd variant="outlined" onClick={sendAgendamento}>
          Cadastrar
        </ButtonAdd>
      </div>
      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert
          severity={severity}
          variant="filled"
        >{`${cod} - ${message}`}</MuiAlert>
      </Snackbar>
    </Container>
  );
};
