import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SignIn from '@pages/SignIn';
import Kit from '@pages/Kit';

const AuthRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/kit" component={Kit} />
    </Switch>
  );
};

export default AuthRoutes;
