import { Switch } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerFieldsUser = styled.div`
  display: grid;
  grid-template-row: repeat(3, 1fr);
  grid-gap: 1rem;
`;

export const ContainerFieldsLogin = styled.div`
  display: grid;
  grid-template-row: repeat(3, 1fr);
  grid-gap: 1rem;
`;

export const ContainerSwitch = styled.div`
  margin-bottom: 10px;
`;

export const SwitchAdm = styled(Switch)`
  & .MuiSwitch-switchBase.Mui-checked {
    color: #52d869; /* Cor quando o Switch está ligado */

    &:hover {
      background-color: transparent; /* Cor do fundo quando hover no Switch */
    }
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #52d869; /* Cor da trilha quando o Switch está ligado */
  }
  & .MuiSwitch-switchBase.Mui-checked:hover + .MuiSwitch-track {
    background-color: #52d869; /* Cor da trilha quando hover no Switch */
  }
`;
