import { MENU_COLLAPSE } from './storageConfig';

type StorageMenuCollapseProps = {
  schedule: boolean;
  aso: boolean;
  enterpriseRh: boolean;
};

export async function storageMenuCollapse({
  schedule,
  aso,
  enterpriseRh
}: StorageMenuCollapseProps) {
  await localStorage.setItem(
    MENU_COLLAPSE,
    JSON.stringify({ schedule, aso, enterpriseRh })
  );
}

export async function storageMenuCollapsGet() {
  const res = await localStorage.getItem(MENU_COLLAPSE);

  const { schedule, aso, enterpriseRh }: StorageMenuCollapseProps = res
    ? JSON.parse(res)
    : {};

  return { schedule, aso, enterpriseRh };
}
