import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { HighlightOff, SettingsBackupRestore } from '@material-ui/icons';
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import MuiAlert, { type Color } from '@material-ui/lab/Alert';
import { api } from '../../services/api';
import { maskCNPJ, maskCPF } from '../../utils/masks';
import usePagination from '@hooks/usePagination';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { Button } from '@components/Button';
import { Pagination } from '@material-ui/lab';
import { situ } from '@data/colab';
import { LoadingPage } from '@components/LoadingPage';
import { AxiosError } from 'axios';
import {
  UseMutationResult,
  useMutation,
  useQuery
} from '@tanstack/react-query';
import { ContainerButtonsTop, DivSearch } from './styles';
import { NavLink } from '@components/NavLink';
import { UploadCSV } from '@components/UploadCSV';

interface IColaborador {
  cnh: string;
  cnpj: string;
  cod_ghe: string;
  cpf_colaborador: string;
  email: string;
  telefone: string;
  data_admissao: string;
  data_nascimento: string;
  funcao: string;
  genero: string;
  ghe: string;
  horario_trabalho: string;
  id_colaboradores: number;
  local_obra: string;
  local_trabalho: string;
  matricula_empresa: string;
  matricula_esocial: string;
  nis: string;
  nome_completo: string;
  razao_social: string;
  rg: string;
  setor: string;
  situacao: string;
  turno: string;
}

export const Coloboradores = () => {
  const [edit, setEdit] = useState<IColaborador>();
  const [dialog, setDialog] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [cod, setCod] = useState('');
  const [severity, setSeverity] = useState<Color | undefined>();
  const [colaboradores, setColaboradores] = useState<IColaborador[]>([]);
  const [search, setSearch] = useState<string>('');
  const [value, setValue] = useState('');
  const [situacao, setSituacao] = useState('');

  const [filterColab, setFilterColab] = useState<IColaborador[]>([]);
  const [listEmpty, setListEmpty] = useState('');

  const [page, setPage] = useState(1);
  const PER_PAGE = 30;

  const count = Math.ceil(colaboradores.length / PER_PAGE);
  const _DATA = usePagination(colaboradores, PER_PAGE);

  const filterColabPerPage =
    _DATA.currentData().length > 0
      ? _DATA
          .currentData()
          .filter(
            (colab: IColaborador) =>
              colab.matricula_empresa
                ?.toUpperCase()
                .includes(search.toUpperCase()) ||
              colab.matricula_esocial
                ?.toUpperCase()
                .includes(search.toUpperCase()) ||
              colab?.nome_completo
                ?.toUpperCase()
                .includes(search.toUpperCase()) ||
              colab.situacao?.toUpperCase().includes(search.toUpperCase())
          )
      : [];

  const filter = filterColab.length > 0 ? filterColab : filterColabPerPage;

  const {
    data: returnResultFilter,
    mutate,
    isPending,
    error: errorFilterColab,
    isError
  } = useMutation({
    mutationKey: ['pequisaColaborador'],
    mutationFn: async (text: object) => {
      return await api.post('ravim/pesq-colab', text);
    }
  });

  const fetchFilter = async (nome: string, situacao: string) => {
    const dataColabConverted = nome.includes('.')
      ? ''
      : nome.toUpperCase().trim();
    const situacaoColab = situacao.toUpperCase().trim();
    const cpf = nome.includes('.') ? nome : '';

    mutate({
      nome: dataColabConverted,
      situacao: situacaoColab,
      cpf
    });
  };

  function closeDialog() {
    setDialog(false);
    setDialogDelete(false);
  }

  const handleChange = (_: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  function deleteContract(value: IColaborador) {
    setDialogDelete(true);
    setEdit(value);
  }

  async function deleteColaborador(cpf: any) {
    await api
      .delete(`ravim/exclu-colaboradores`, { data: { cpf } })
      .then((res) => {
        if (res.status === 200) {
          const newListColaborador = colaboradores.filter(
            (colab: IColaborador) => colab.cpf_colaborador !== cpf
          );
          setSnack(true);
          setDialogDelete(false);
          setMessage(res.data.message);
          setSeverity('success');
          setColaboradores(newListColaborador);
        } else {
          setSeverity('error');
          setSnack(true);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClearFields() {
    setValue('');
    setSituacao('');
    setListEmpty('');
    setFilterColab([]);
    setSearch('');
  }

  const {
    data: getColaborador,
    isLoading: loadingColaborador,
    error: errorColaborador
  } = useQuery<IColaborador[]>({
    queryKey: ['colaborador'],
    queryFn: async () => {
      const res = await api.get(`ravim/colaboradores`);
      return res.data;
    }
  });

  useEffect(() => {
    if (getColaborador) {
      setColaboradores(getColaborador);
    } else {
      console.log(errorColaborador);
    }
  }, [getColaborador]);

  useEffect(() => {
    if (isError) {
      setListEmpty(String(errorFilterColab.message));
      return;
    }
    if (returnResultFilter) {
      setFilterColab(returnResultFilter.data);
    } else {
      setFilterColab([]);
    }
  }, [returnResultFilter, isError]);

  return (
    <Container>
      <LoadingPage
        open={loadingColaborador}
        onClick={() => loadingColaborador}
      />
      <ContainerButtonsTop>
        <DivSearch>
          <TextField
            label="Pesquisar colaborador"
            type="text"
            variant="outlined"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                fetchFilter(value, situacao);
              }
            }}
          />
          <FormControl variant="outlined" style={{ width: 100 }}>
            <InputLabel>Situação</InputLabel>
            <Select
              label="Situação"
              value={situacao}
              onChange={(e: any) => {
                setSituacao(e.target.value);
              }}
            >
              {situ &&
                situ.map((situacao) => (
                  <MenuItem value={situacao.value} key={situacao.id}>
                    {situacao.situacao}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Button
            variantstyle="SECUNDARY"
            icon={<MagnifyingGlass size={20} />}
            isLoading={isPending}
            disabled={value.length < 3 && !situacao}
            onClick={() => {
              fetchFilter(value, situacao);
            }}
          />
          {value.length >= 3 || !!situacao ? (
            <Button
              variantstyle="RED"
              icon={<X size={20} />}
              onClick={handleClearFields}
            />
          ) : (
            <></>
          )}
        </DivSearch>
        <div style={{display:'flex', gap:10}}>
          <UploadCSV />
          <NavLink to="/cadastro-colaborador">
            <Button title="Cadastro Colaborador" />
          </NavLink>
        </div>
      </ContainerButtonsTop>
      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Colaboradores
      </Typography>
      <Typography variant="h6" align="left">
        Total de colaboradores: {colaboradores.length}
      </Typography>
      {!listEmpty ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 20
          }}
        >
          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />

          <TableContainer component={Paper}>
            <Table style={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>CNPJ</TableCell>
                  <TableCell>CPF</TableCell>
                  <TableCell>Nome do Colaborador</TableCell>
                  <TableCell>Local/Obra</TableCell>
                  <TableCell>GHE</TableCell>
                  <TableCell>Situação</TableCell>
                  <TableCell>ATUALIZAR</TableCell>
                  <TableCell>DELETAR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter &&
                  filter.map((colaborador: IColaborador, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{maskCNPJ(colaborador.cnpj)}</TableCell>
                      <TableCell>
                        {maskCPF(colaborador.cpf_colaborador)}
                      </TableCell>
                      <TableCell>{colaborador.nome_completo}</TableCell>
                      <TableCell>{colaborador.local_obra}</TableCell>
                      <TableCell>{colaborador.ghe}</TableCell>
                      <TableCell>{colaborador.situacao}</TableCell>
                      <TableCell>
                        <Link
                          to={{
                            pathname: '/editar-colaborador',
                            state: colaborador
                          }}
                        >
                          <IconButton>
                            <SettingsBackupRestore
                              fontSize="medium"
                              style={{ color: '#422C59' }}
                            />
                          </IconButton>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            deleteContract(colaborador);
                          }}
                        >
                          <HighlightOff
                            fontSize="medium"
                            style={{ color: 'red' }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </div>
      ) : (
        <Typography align="center" variant="h6">
          {listEmpty}
        </Typography>
      )}

      <Dialog open={dialogDelete} onClose={closeDialog}>
        <DialogTitle>
          Colaborador a ser deletado, confira o nome e a matrícula
        </DialogTitle>
        <DialogContent>
          <Typography>
            Nome: <b>{edit?.nome_completo}</b>
          </Typography>
          <Typography>
            Matrícula: <b>{edit?.matricula_empresa}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            title="Cancelar"
            variantstyle="SECUNDARY"
            onClick={closeDialog}
          />

          <Button
            autoFocus
            onClick={async () => {
              await deleteColaborador(edit?.cpf_colaborador);
            }}
            title="Deletar"
            variantstyle="RED"
          />
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snack}
        autoHideDuration={2000}
        onClose={() => {
          setSnack(!snack);
        }}
      >
        <MuiAlert severity={severity} variant="filled">
          {cod} {message}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};
