import React from 'react';
import * as M from '@material-ui/core';
import { makeStyles, createStyles, type Theme } from '@material-ui/core/styles';

type Props = M.BackdropProps & {
  open: boolean;
  onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  })
);

export function LoadingPage({ open, onClick, ...rest }: Props) {
  const classes = useStyles();
  return (
    <M.Backdrop
      className={classes.backdrop}
      open={open}
      onClick={onClick}
      {...rest}
    >
      <M.CircularProgress color="inherit" />
    </M.Backdrop>
  );
}
