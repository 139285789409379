export const defaulTheme = {
  COLOR: {
    DEFAULT: '#C4C4C4',
    WHITE: '#FFFFFF',
    PURPLE: '#887bdf',
    LIGHT_PURPLE: '#6a82fb',
    LIGHT_PINK: '#e95779',
    DARK_PINK: '#d46298',
    BLUE: '#237cf5',
    GREEN: '#0e9c61',
    RED: '#ff002c'
  }
};
