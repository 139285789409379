import React from 'react';
import { EditItsTelemedicina, INomeComercial } from './editTelemedicina';
import { EditNotisTelemedicina } from './editPresencial';
import { AgendamentoExterno } from '../agendamentoExterno';
import { AtendimentoHibrido } from '../agendamentoHibrido';
import { EditAgendamentoExterno } from './editAgendamentoExterno';
import { EditAgendamentoToxicologico } from './editAgendamentoToxico';
import { EditAtendimentoHibrido } from './editAgendamentoHibrido';

export interface ScheduleProps {
  location: {
    state: {
      id_agendamento: number;
      num_agenda: string;
      nome: string;
      cpf_colaborador: string;
      tipo_agendamento: string;
      cpf_cnpj_comer: string;
      nome_comercial: string;
      cidade: string;
      estado: string;
      data_agendamento: string;
      ordem_chegada: string;
      horario: string;
      tipo_aso: string;
      observacao: string;
      status: string;
      usuario: string;
      exibir: string;
      linkDoatendimento: string;
      arquivo_prontuario: string;
      arquivo_aso: string;
      id_colaboradores: number;
      matricula_empresa: string;
      matricula_esocial: string;
      nome_completo: string;
      rg: string;
      data_nascimento: string;
      genero: string;
      cnh: string;
      email: string;
      telefone: string;
      situacao: string;
      nis: string;
      funcao: string;
      setor: string;
      turno: string;
      cnpj: string;
      razao_social: string;
      data_admissao: string;
      horario_trab_entrada: string;
      horario_trab_saida: string;
      local_obra: string;
      cod_ghe: string;
      ghe: string;
    };
  };
}

export const EditAgendamento: React.FC<ScheduleProps> = (props) => {
  const { tipo_agendamento } = props.location.state;

  switch (tipo_agendamento) {
    case 'telemedicina':
      return <EditItsTelemedicina {...props}/>;
    case 'presencial':
      return <EditNotisTelemedicina {...props}/>;
    case 'externo':
      return <EditAgendamentoExterno {...props}/>;
    case 'hibrido':
      return <EditAtendimentoHibrido />;
    case 'toxicologico':
      return <EditAgendamentoToxicologico {...props}/>;
    default:
      return <></>;
  }
};
