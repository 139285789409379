import React from 'react';

import { EditarContratoEmpresa } from './ContratosEmpresas/EditarContratoEmpresa';
import { EditarContratoCredenciado } from './ContratosCredenciados/EditarContratoCredenciado';
import { useLocation } from 'react-router';

export interface IContractEdit {
  arquivo: string;
  cod_contrato: string;
  data_criacao: string;
  data_renovacao: string;
  id_cad_cont_cred: number;
  nome_contrato: string;
  tipo: string | undefined;
}
export const EditContract = () => {
  const location = useLocation().state as IContractEdit;
  const { tipo } = location;
  return (
    <>
      {tipo && tipo === 'empresa' ? (
        <EditarContratoEmpresa />
      ) : (
        <EditarContratoCredenciado />
      )}
    </>
  );
};
