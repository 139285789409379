import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const PaperContainer = styled(Paper)`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

export const DivGridFieldDataContract = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-columns: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const DivBenefits = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-bottom: 15px;
`;

export const DivServices = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
`;

export const DivPlan = styled(DivBenefits)``;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-end;
`;
