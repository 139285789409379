import React, { useState, useEffect } from 'react';
import { api } from '@services/api';
import {
  Container,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { HighlightOff, SettingsBackupRestore } from '@material-ui/icons';
import { type Color } from '@material-ui/lab/Alert';
import { maskDate } from '@utils/masks';
import { Button } from '@components/Button';
import { ToastMessage } from '@components/ToastMessage';
import usePagination from '@hooks/usePagination';
import { FilePdf, FileX, MagnifyingGlass } from '@phosphor-icons/react';
import { Pagination } from '@material-ui/lab';
import { DialogActionDelete } from '@components/DialogAction';
import { type Contratos } from '@dtos/contratosDTO';
import { ContainerButtonsTop, DivSearch } from './styles';
import { NavLink } from '@components/NavLink';

const ListaCredenciados: React.FC = () => {
  const [contratos, setContratos] = useState<Contratos[]>([]);
  const [edit, setEdit] = useState<Contratos>({} as Contratos);
  const [dialog, setDialog] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    cod: '',
    message: '',
    color: '' as Color,
    show: false
  });
  const [value, setValue] = useState('');

  const [page, setPage] = useState(1);
  const PER_PAGE = 30;

  const count = Math.ceil(contratos.length / PER_PAGE);
  const _DATA = usePagination(contratos, PER_PAGE);

  function deleteContract(value: Contratos) {
    setDialogDelete(true);
    setEdit(value);
  }

  function closeDialog() {
    setDialog(false);
    setDialogDelete(false);
  }

  const filterContractsPerPage =
    _DATA.currentData().length > 0
      ? _DATA
          .currentData()
          .filter(
            (contrato: Contratos) =>
              contrato.nome_contrato
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              contrato.cod_contrato.toUpperCase().includes(search.toUpperCase())
          )
      : [];

  const filterContracts =
    contratos.length > 0
      ? contratos.filter(
          (contrato: Contratos) =>
            contrato.nome_contrato
              .toUpperCase()
              .includes(search.toUpperCase()) ||
            contrato.cod_contrato.toUpperCase().includes(search.toUpperCase())
        )
      : [];

  const filter = search.length > 0 ? filterContracts : filterContractsPerPage;

  async function fetchContratos() {
    try {
      setLoading(false);
      const res = await api.get(`/ravim/contratos-credenciados`);
      if (res.status === 404) return;

      setContratos(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(true);
    }
  }

  const handleChange = (e: any, p: number) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  function updateListContrats(contratos: Contratos[]) {
    setContratos(contratos);
  }

  useEffect(() => {
    fetchContratos();
  }, []);

  useEffect(() => {
    if (!value) {
      setSearch('');
    }
  }, [value]);

  return (
    <Container>
      <ContainerButtonsTop>
        <DivSearch>
          <TextField
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            label="Pesquisar empresas"
            type="text"
            variant="outlined"
            style={{ background: '#FFF' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(value);
              }
            }}
          />
          <Button
            variantstyle="SECUNDARY"
            icon={<MagnifyingGlass size={25} />}
            onClick={() => {
              handleSearch(value);
            }}
          />
        </DivSearch>
        <NavLink to="/cadastro-contrato-credenciado">
          <Button
            title="Cadastrar contrato"
          />
        </NavLink>
      </ContainerButtonsTop>

      <Typography
        variant="h4"
        align="center"
        style={{ marginBottom: 20, fontWeight: 'bold' }}
      >
        Contratos Credenciados
      </Typography>
      <Typography variant="h6" align="left">
        Total contratos: {contratos.length}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 20
        }}
      >
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
        {loading ? (
          <TableContainer component={Paper}>
            <Table style={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Nome do Contrato</TableCell>
                  <TableCell>Código do Contrato</TableCell>
                  <TableCell>Data de Criação</TableCell>
                  <TableCell>Data de Renovação</TableCell>
                  <TableCell>Arquivo</TableCell>
                  <TableCell>ATUALIZAR</TableCell>
                  <TableCell>DELETAR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filter &&
                  filter.map((contrato: Contratos, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{contrato.nome_contrato}</TableCell>
                      <TableCell>{contrato.cod_contrato}</TableCell>
                      <TableCell>
                        {maskDate(String(contrato.data_criacao))}
                      </TableCell>
                      <TableCell>
                        {maskDate(String(contrato.data_renovacao))}
                      </TableCell>
                      <TableCell>
                        {contrato.arquivo?.toLowerCase() === 'true' ? (
                          <FilePdf size={30} />
                        ) : (
                          <FileX size={30} />
                        )}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{ pathname: '/editar-contrato', state: contrato }}
                        >
                          <IconButton>
                            <SettingsBackupRestore
                              fontSize="medium"
                              style={{ color: '#422C59' }}
                            />
                          </IconButton>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            deleteContract(contrato);
                          }}
                        >
                          <HighlightOff
                            fontSize="medium"
                            style={{ color: 'red' }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )}
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>

      <DialogActionDelete
        openModal={dialogDelete}
        closeModal={closeDialog}
        name={edit.nome_contrato}
        code={edit.cod_contrato}
        contratos={contratos}
        updatadeList={(contrato) => {
          updateListContrats(contrato);
        }}
      />

      <ToastMessage
        code={toastMessage.cod}
        message={toastMessage.message}
        show={toastMessage.show}
        severity={toastMessage.color}
        onClose={() => {
          setToastMessage({
            cod: toastMessage.cod,
            color: toastMessage.color,
            message: toastMessage.message,
            show: toastMessage.show
          });
        }}
      />
    </Container>
  );
};

export default ListaCredenciados;
